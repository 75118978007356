<mat-card
  class="w-100 mb-4 shadow pointer"
  [class.disabled]="endedOrFullText"
  [class.h-250p]="isMobile && group.cancelButtonText"
  [ngClass]="[group.isNew ? 'hover-main-border' : 'hover-primary-border', isTablet ? (group.alreadyStarted ? 'h-175p' : 'h-200p') : 'h-80p']"
  (click)="openGroupDetails()"
>
  <mat-card-content class="h-100">
    <div class="d-flex h-100">
      <div class="card-label d-flex align-items-center justify-content-center" [ngClass]="group.isNew ? 'bg-main' : 'bg-primary'">
        <p class="rotate uppercase text-white font-weight-bold m-0 font-12" *ngIf="group.isNew">new</p>
      </div>
      <div class="row py-2 w-100 mx-0 align-items-center">
        <div
          class="font-weight-bold"
          [ngClass]="isTablet ? 'col-12' : group.church ? 'col-4' : group.cancelButtonText ? 'col-6 col-xl-7' : 'col-xl-8 col-7'"
        >
          <div class="d-flex flex-column">
            <span date-title="group-name" [title]="group.name" class="truncated-single-line mb-1 font-18 bold-text">
              {{ group.name }}
            </span>
            <div class="d-flex align-items-center" [class.my-2]="isTablet">
              <span class="mr-4">
                <i class="fas fa-map-marker-alt primary-color mr-2"></i>
                <span *ngIf="group.type !== groupTypes.VIRTUAL">{{ group.location }}</span>
                <span *ngIf="group.type === groupTypes.VIRTUAL">Online</span>
              </span>
              <ng-container *ngIf="isTablet"><ng-container *ngTemplateOutlet="churchCard"></ng-container></ng-container>
            </div>
          </div>
        </div>
        <div
          *ngIf="!isTablet && group.church"
          class="d-flex justify-content-center"
          [ngClass]="group.cancelButtonText ? 'col-2 col-xl-3' : 'col-3 col-xl-4'"
        >
          <ng-container *ngTemplateOutlet="churchCard"></ng-container>
        </div>
        <div [ngClass]="[isTablet ? 'col-12' : group.cancelButtonText ? 'col-6' : 'col-5', group.cancelButtonText ? 'col-xl-5' : 'col-xl-4']">
          <div class="d-flex flex-sm-row flex-column justify-content-between">
            <div class="d-flex justify-content-center flex-column flex-grow" [class.mr-4]="group.cancelButtonText">
              <ng-container *ngIf="endedOrFullText || group.state === groupStates.CLOSED; else openGroup">
                <span *ngIf="group.state === groupStates.CLOSED" class="text-sm-nowrap font-weight-bold">Group Closed</span>
                <span *ngIf="endedOrFullText" class="text-sm-nowrap font-weight-bold">{{ endedOrFullText }}</span>
              </ng-container>
              <ng-template #openGroup>
                <div *ngIf="isTablet" class="font-weight-bold text-gray uppercase truncated-single-line">
                  MEETING DAY & TIME
                  <span class="font-12">(IN YOUR LOCAL TIME)</span>:
                </div>
                <div class="d-flex" [class.justify-content-between]="!isTablet">
                  <div class="d-flex align-items-center mr-2" [class.mr-4]="isTablet">
                    <i class="fas fa-calendar-alt primary-color mr-2"></i>
                    <div class="d-flex flex-column">
                      <span class="text-sm-nowrap font-weight-bold">
                        {{ group.dayName + 's' | lowercase | titlecase }} at {{ group.startTimeWithDaylightSavingTime | date : 'h:mm a' }}
                      </span>
                      <span *ngIf="!group.alreadyStarted" class="text-sm-nowrap font-weight-bold">
                        {{ 'Starts ' + (group.startingDate | date : 'MMM d') }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mr-2">
                    <i class="fas fa-history primary-color mr-2 flip"></i>
                    <span class="text-sm-nowrap font-weight-bold">{{ group.frequency | enumToString | lowercase | titlecase }}</span>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="d-flex align-items-center mr-2 align-self-end mt-sm-0 mt-3">
              <button
                *ngIf="group.cancelButtonText && !this.groupEnded"
                appClickStopPropagation
                mat-flat-button
                class="mat-medium-button w-182p hover-light"
                color="warn"
                (click)="withdrawFromGroup()"
              >
                {{ group.cancelButtonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #churchCard>
  <mat-card *ngIf="group.church" class="church-card">
    <mat-card-content>
      <div class="d-flex p-1">
        <img
          width="50"
          height="50"
          class="mr-2"
          [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
          [lazyLoad]="group.church.imageUrl"
        />
        <div class="d-flex flex-column">
          <div class="truncated-single-line font-weight-bold">{{ group.church.name }}</div>
          <div class="truncated-single-line primary-color uppercase font-weight-bold font-12">{{ group.church.mainland?.state }}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
