import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FileType } from 'src/app/_core/constants/FileType';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import FileUtils from 'src/app/_core/helpers/FileUtils';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UserImportService } from 'src/app/_core/services/user-import.service';
import { UsersImport } from './import-users.model';

@Component({
  selector: 'app-import-users-modal',
  templateUrl: './import-users-modal.component.html',
  styleUrls: ['./import-users-modal.component.scss'],
})
export class ImportUsersModalComponent implements OnInit {
  uploader: FileUploader;
  file: FileItem;
  fileAdded: boolean = false;
  importUsers: UsersImport;
  subscription: Subscription = new Subscription();
  loading: boolean;
  attemptedSubmit: boolean;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private toastr: ToastrService,
    private userImportService: UserImportService,
    private dialogRef: MatDialogRef<any>,
  ) {
    this.subscription = this.userImportService.uploaderObservable.subscribe(data => {
      if (!data.result.response) {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
        this.fileAdded = false;
        this.attemptedSubmit = false;
      } else if (!data.progress) {
        this.setFileFields(data.result);
      }
    });
  }

  ngOnInit(): void {
    this.uploader = this.userImportService.uploaderInstance;
  }

  close(): void {
    this.modalService.closeModal();
  }

  fileChangeEvent(event: any): void {
    if (event) {
      if (!FileUtils.checkFileType(event[0].name, event[0].type, FileType.DOC)) {
        this.toastr.error(...ToastrMessages.INVALID_FORMAT);
        this.uploader.removeFromQueue(this.uploader.queue[this.uploader.queue.length - 1]);
        return;
      }
      this.file = this.uploader.queue[this.uploader.queue.length - 1];
      this.fileAdded = true;
      this.uploadFile(true);
    }
  }

  uploadFile(validateFile: boolean): void {
    this.loading = true;
    const fileToUpload: FileItem = this.uploader.queue[this.uploader.queue.length - 1];
    fileToUpload.formData.checkOnly = validateFile;
    fileToUpload._file = this.file.file.rawFile as File;
    this.userImportService.uploadFile();
  }

  removeFile(): void {
    this.uploader.clearQueue();
    this.fileAdded = false;
    this.dialogRef.updateSize('480px', 'auto');
    this.importUsers = null;
  }

  submit(): void {
    this.file.isSuccess = false;
    this.file.isUploaded = false;
    this.attemptedSubmit = true;
    this.uploadFile(false);
  }

  setFileFields(result: any): void {
    const responseData = JSON.parse(result.response);
    this.importUsers = new UsersImport(responseData.response);
    this.dialogRef.updateSize('95vw', 'auto');
    this.loading = false;
  }
}
