import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminUserService } from 'src/app/_core/api/admin-user.service';
import { GroupFormat, GroupStates, GroupTypes } from 'src/app/_core/constants/AdminGroups';
import { ModalActions, Modals } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import { GroupCard } from 'src/app/_core/models/Groups';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss'],
})
export class GroupCardComponent implements OnInit, OnDestroy {
  @Input() group: GroupCard;
  @Output() withdrawnSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();

  isMobile: boolean;
  isTablet: boolean;
  breakpointSubscription: Subscription;
  groupTypes = GroupTypes;
  groupStates = GroupStates;
  groupType = GroupFormat;
  endedOrFullText: string;
  groupEnded: boolean = false;

  // prettier-ignore
  constructor(
    private breakpointObserver: BreakpointObserver,
    private modalsService: ModalsService,
    private adminUserService: AdminUserService,
    private toastrService: ToastrService,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 580px)', '(max-width: 1000px)']).subscribe(() => {
        this.isTablet = breakpointObserver.isMatched(['(max-width: 1000px)']);
        this.isMobile = breakpointObserver.isMatched(['(max-width: 580px)']);

    });
  }

  ngOnInit(): void {
    if (this.group.spotsLeft === 0) this.endedOrFullText = 'Group Full';
    if (DateUtils.compareDates(this.group.endsOn)) {
      this.endedOrFullText = 'Group Ended';
      this.groupEnded = true;
    }
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  openGroup(uuid: string): void {
    this.modalsService.openModal(Modals.GROUP_DETAILS, new ModalRequest(ModalActions.VIEW_GROUP, uuid));
  }

  withdrawFromGroup(): void {
    this.adminUserService.withdrawUserFromGroup(this.group.uuid).subscribe(
      (response: any) => {
        if (response.response) {
          this.withdrawnSuccess.emit(true);
          this.toastrService.success(...ToastrMessages.WITHDRAW_SUCCESS);
        } else {
          this.withdrawnSuccess.emit(false);
          this.toastrService.error(...ToastrMessages.WITHDRAW_ERROR);
        }
      },
      () => {
        this.withdrawnSuccess.emit(false);
        this.toastrService.error(...ToastrMessages.WITHDRAW_ERROR);
      }
    );
  }

  openGroupDetails(): void {
    if (!this.endedOrFullText) {
      this.selected.emit(this.group.uuid);
    }
  }
}
