import { Component, Input, OnInit } from '@angular/core';
import { GroupFormat, GroupTypes } from 'src/app/_core/constants/AdminGroups';
import { ModalActions, Modals } from 'src/app/_core/constants/Modals';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import Utils from 'src/app/_core/helpers/Utils';
import { Meeting } from 'src/app/_core/models/AdminGroups';
import { Group, GroupChurchPartnerOrganization, GroupFacilitator } from 'src/app/_core/models/Groups';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-study-card-facilitator',
  templateUrl: './study-card-facilitator.component.html',
  styleUrls: ['./study-card-facilitator.component.scss'],
})
export class StudyCardFacilitatorComponent implements OnInit {
  @Input() group: Group;
  @Input() isMobile: boolean = false;
  previewFacilitator: GroupFacilitator;
  previewChurch: GroupChurchPartnerOrganization;
  groupTypes = GroupTypes;
  structuredMeetings: string[];
  meetingType = GroupFormat;
  nextMeetingDate: string;

  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {
    this.previewFacilitator = this.group.facilitatorsDtoList[0];
    this.structuredMeetings = Utils.getEventStructuredMeetings(this.group.meetings);
    this.nextMeetingDate = DateUtils.UTCtoLocalNoOffset((DateUtils.getNextMeeting(this.group.meetings) as Meeting)?.meetingStartTime);
  }

  showDetails(uuid: string): void {
    this.modalsService.openModal(Modals.GROUP_DETAILS, new ModalRequest(ModalActions.VIEW_GROUP, uuid));
  }

  setPreeviewFacilitator(facilitator) {
    this.previewChurch = null;
    this.previewFacilitator = facilitator;
  }

  setPreviewChurch(church) {
    this.previewFacilitator = null;
    this.previewChurch = church;
  }

  isFacilitator() {
    return this.previewFacilitator?.lastName || this.previewFacilitator?.firstName || this.previewFacilitator?.imgUrl || this.previewFacilitator?.bio;
  }

  buildLocationString(): string {
    let region = null;
    if (this.group?.mainlands?.length > 0) {
      region = this.group?.mainlands[0]?.region;
    }
    return [this.group?.city, region].filter(Boolean).join(', ');
  }

  navigate(url: string): void {
    Utils.navigate(url);
  }
}
