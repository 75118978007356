import { GroupRoles, GroupTypes, GroupUnitsCounter, MainGroupRoles, UnitStatusName, UserFormatType } from 'src/app/_core/constants/AdminGroups';
import { MultiFilterOptionData } from './multi-filter.model';

export enum INPUT_TYPE {
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  DATE_PICKER = 'DATE_PICKER',
  SELECT = 'SELECT',
  TOGGLE = 'TOGGLE',
}

export enum FILTER_NAME {
  USER_ROLE = 'userRole',
  INVESTOR_TYPES = 'investorTypes',
  USER_TYPE = 'userType',
  UNITS_STATUS = 'unitStatuses',
  UNITS_COUNTER = 'unitsCounter',
  WEEK_DAYS = 'weekDays',
  TIME_INTERVALS = 'timeIntervals',
  MEETING_TYPE = 'meetingType',
  REGIONS = 'regions',
  LOCATIONS = 'locations',
  CHURCHES = 'churches',
  FACILITATORS = 'facilitators',
  STUDIES = 'studies',
  SEARCH_TEXT = 'searchText',
  COHORT = 'cohort',
  START_DATE = 'startDate',
  GROUP_OWNERS = 'groupOwners',
  GROUP_AUDIENCE = 'groupAudience',
  GROUP_LANGUAGE = 'groupLanguage',
  ARCHIVED = 'archived',
  INDUSTRY = 'industry',
  COMPANY_SIZE = 'companySize',
  GROUP_STATE = 'groupState',
}

export const UserRoles: MultiFilterOptionData[] = [
  {
    uuid: MainGroupRoles.ENTREPRENEUR,
    name: 'Entrepreneur',
    helperText: FILTER_NAME.USER_ROLE,
  },
  {
    uuid: MainGroupRoles.INVESTOR,
    name: 'Investor',
    helperText: FILTER_NAME.USER_ROLE,
  },
];

export const InvestorTypes: MultiFilterOptionData[] = [
  {
    uuid: GroupRoles.QUALIFIED_PURCHASER,
    name: GroupRoles.QUALIFIED_PURCHASER,
  },
  {
    uuid: GroupRoles.ACCREDITED_INVESTOR,
    name: GroupRoles.ACCREDITED_INVESTOR,
  },
  {
    uuid: GroupRoles.FUND_MANAGER,
    name: GroupRoles.FUND_MANAGER,
  },
  {
    uuid: GroupRoles.FINANCIAL_ADVISOR,
    name: GroupRoles.FINANCIAL_ADVISOR,
  },
  {
    uuid: GroupRoles.NON_ACCREDITED_INVESTOR,
    name: GroupRoles.NON_ACCREDITED_INVESTOR,
  },
];

export const UserFormats: MultiFilterOptionData[] = [
  {
    uuid: UserFormatType.NEW,
    name: UserFormatType.NEW,
    helperText: '',
  },
  {
    uuid: UserFormatType.RETURNING,
    name: UserFormatType.RETURNING,
    helperText: '',
  },
];

export const MeetingTypes: MultiFilterOptionData[] = [
  {
    uuid: GroupTypes.VIRTUAL,
    name: 'Online Groups',
  },
  {
    uuid: GroupTypes.IN_PERSON,
    name: 'In-Person Groups',
  },
];

export const UnitStates: MultiFilterOptionData[] = [
  {
    uuid: UnitStatusName.ACTIVE,
    name: UnitStatusName.ACTIVE,
  },
  {
    uuid: UnitStatusName.INACTIVE,
    name: UnitStatusName.INACTIVE,
  },
];
export const GroupUnitCounterOptions: MultiFilterOptionData[] = [
  {
    uuid: GroupUnitsCounter.ONE_UNIT,
    name: GroupUnitsCounter.ONE_UNIT,
  },
  {
    uuid: GroupUnitsCounter.TWO_UNITS,
    name: GroupUnitsCounter.TWO_UNITS,
  },
];
