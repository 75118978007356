import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateUpdateUserRequest, GetUsersRequest, UserGroupFilters } from '../../_core/models/AdminUsers';
import { BasicPaginatedRequest } from '../models/Shared';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminUserService {
  private readonly resourceUrl: string = '/v1/users';
  private readonly InterceptorSkipBearer = 'X-Skip-Authorization-Bearer';

  constructor(private apiService: ApiService) {}

  getUsers(body: GetUsersRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/get-users`, body);
  }

  downloadUsersFile(body: GetUsersRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/download-user-file`, body);
  }

  downloadUsersWithAttendanceFile(body: GetUsersRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/download-user-attendance-file`, body);
  }

  deleteUser(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/${uuid}`);
  }

  getUserDetails(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/${uuid}`);
  }

  updateUser(user: CreateUpdateUserRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/update-user`, user);
  }

  getUserInfo(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/get-user-info/${uuid}`);
  }

  inviteNewUser(user: CreateUpdateUserRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/invite-new-user`, user);
  }

  inviteFacilitator(user: CreateUpdateUserRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/invite-facilitator`, user);
  }

  getUserGroups(body: UserGroupFilters): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/attending/groups`, body);
  }

  promoteToLevel2(email: string): Observable<any> {
    return this.apiService.put(`${this.resourceUrl}/invite-fde-team-member`, null, { email });
  }

  getTimezones(): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipBearer, '');
    return this.apiService.get(`${this.resourceUrl}/timezones`, null, headers);
  }

  synchronizeHubspot(): Observable<any> {
    return this.apiService.put(`${this.resourceUrl}/synchronize/hubspot`);
  }

  downloadFacilitatorsFile(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/download-facilitators-file`);
  }

  getUSerOrFacilitatorByEmail(email: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/get-user-or-facilitator/${email}`);
  }

  withdrawUserFromGroup(groupUuid: string) {
    return this.apiService.post(`${this.resourceUrl}/withdrawFromGroup/${groupUuid}`);
  }

  resendConfirmation(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/resend-confirmation`, body);
  }

  changeUserRole(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/change-role`, body);
  }

  mergeAccounts(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/merge/account`, body);
  }

  searchAccount(body: BasicPaginatedRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/search/account`, body);
  }
}
