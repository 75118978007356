import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UploaderApiService } from 'src/app/_core/api/uploader-api.service';
import { FileType } from 'src/app/_core/constants/FileType';
import { FilterType } from 'src/app/_core/constants/Home';
import { ModalActions, Modals } from 'src/app/_core/constants/Modals';
import { ServerError } from 'src/app/_core/constants/ServerErrors';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import { CustomValidators } from 'src/app/_core/helpers/CustomValidators.helpers';
import FileUtils from 'src/app/_core/helpers/FileUtils';
import { LanguagePayload } from 'src/app/_core/models/AdminTags';
import { FileDetails } from 'src/app/_core/models/FileInfo';
import { ModalFileResponse, ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { UploadPromiseResponse } from 'src/app/_core/models/Upload';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UploadService } from 'src/app/_core/services/upload.service';
import { AdminTagsService } from '../../../../../_core/api/admin-tags.service';
import { INDUSTRY_TYPE } from '../../tags.const';

@Component({
  selector: 'app-tag-setup',
  templateUrl: './tag-setup.component.html',
  styleUrls: ['./tag-setup.component.scss'],
})
export class TagSetupComponent implements OnInit, OnDestroy {
  modalRequest: ModalRequest;
  filterTypes = FilterType;
  tag: any | null;
  tagType: FilterType;
  tagFirstName: UntypedFormControl = new UntypedFormControl(null, [CustomValidators.required]);
  tagLastName: UntypedFormControl = new UntypedFormControl(null);
  tagEmail: UntypedFormControl = new UntypedFormControl(null);
  tagImage: UntypedFormControl = new UntypedFormControl(null);
  tagIndustryType: UntypedFormControl = new UntypedFormControl(null, CustomValidators.required);
  tagTypeName: string;
  languagePhoto: FileDetails = new FileDetails(null, null, null, null);
  uploader: FileUploader;
  subscription = new Subscription();
  industryTypes = INDUSTRY_TYPE;

  constructor(
    private modalService: ModalsService,
    private toastr: ToastrService,
    private adminTagsService: AdminTagsService,
    private uploaderApiService: UploaderApiService,
    private uploadService: UploadService
  ) {
    this.subscription.add(
      this.modalService.modalResponse$.subscribe((response: ModalFileResponse) => {
        this.handleModalResponse(response);
      })
    );

    this.subscription.add(
      this.uploadService.uploaderObservable.subscribe((data) => (data.progress ? this.setProgress(data.result) : this.setFileFields(data.result)))
    );
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  ngOnInit(): void {
    this.uploader = this.uploadService.uploaderInstance;
    this.modalRequest = this.modalService.params;
    this.tag = this.modalService.params.payload.tag || {};
    this.tagType = this.modalService.params.payload.tagType;
    this.tagTypeName =
      this.tagType === FilterType.GROUP_AUDIENCE
        ? 'Audience'
        : this.tagType === FilterType.GROUP_OWNERS
        ? 'Owner'
        : this.tagType === FilterType.GROUP_INDUSTRY
        ? 'Industry/Affinity'
        : 'Language';
    if (this.tagType === FilterType.GROUP_OWNERS) {
      this.tagLastName.setValidators([CustomValidators.required]);
      this.tagEmail.setValidators([CustomValidators.email]);
    }

    if (this.tagType === FilterType.GROUP_OWNERS) {
      this.tagImage.setValidators([CustomValidators.required]);
    }
    if (this.tag) this.prefillForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  prefillForm(): void {
    const firstName =
      this.tagType === FilterType.GROUP_AUDIENCE || this.tagType === FilterType.GROUP_LANGUAGE || this.tagType === FilterType.GROUP_INDUSTRY
        ? this.tag.name
        : this.tag.firstName;
    this.tagFirstName.setValue(firstName);
    if (this.tagType === FilterType.GROUP_OWNERS) {
      this.tagLastName.setValue(this.tag.lastName);
      this.tagEmail.setValue(this.tag.email);
    }
    if (this.tagType === FilterType.GROUP_INDUSTRY) {
      this.tagIndustryType.setValue(this.tag.type);
    }

    this.setLanguageImage();
  }

  submit(): void {
    if (
      this.tagFirstName.untouched &&
      (this.tagType === FilterType.GROUP_AUDIENCE ||
        (this.tagType === FilterType.GROUP_OWNERS && this.tagLastName.untouched && this.tagEmail.untouched) ||
        (this.tagType === FilterType.GROUP_INDUSTRY && this.tagIndustryType.untouched))
    ) {
      this.toastr.info(...ToastrMessages.NO_CHANGES);
      return;
    }

    this.tagFirstName.markAsTouched();
    this.tagType === FilterType.GROUP_OWNERS && this.tagLastName.markAsTouched();

    if (
      this.tagFirstName.invalid ||
      (this.tagType === FilterType.GROUP_OWNERS && this.tagLastName.invalid) ||
      (this.tagType === FilterType.GROUP_INDUSTRY && this.tagIndustryType.invalid)
    ) {
      this.toastr.error(...ToastrMessages.MISSING_REQUIRED_FIELDS);
      return;
    }

    if (this.tagType === FilterType.GROUP_OWNERS) this.submitOwner();
    else if (this.tagType === FilterType.GROUP_AUDIENCE) this.submitAudience();
    else if (this.tagType === FilterType.GROUP_LANGUAGE) this.submitLanguage();
    else if (this.tagType === FilterType.GROUP_INDUSTRY) this.submitIndustry();
  }

  submitOwner(): void {
    const handleResponse = {
      next: (res) => {
        if (!this.tag.uuid) {
          this.tag.uuid = res.response.uuid;
          this.tag.noOfGroups = 0;
        }
        this.tag.firstName = this.tagFirstName.value;
        this.tag.lastName = this.tagLastName.value;
        this.tag.email = this.tagEmail.value;
        if (!this.tag.uuid) this.toastr.success(...ToastrMessages.OWNER_ADDED);
        else this.toastr.success(...ToastrMessages.OWNER_UPDATED);
        this.emitTrue();
      },
      error: (err) => {
        if (err === ServerError.ALREADY_EXISTS) this.toastr.error(...ToastrMessages.DUPLICATED_OWNER);
        else if (err === ServerError.USER_NOT_FOUND) this.toastr.error(...ToastrMessages.OWNER_NOT_FOUND);
        else this.toastr.error(...ToastrMessages.BASIC_ERROR);
      },
    };
    if (!this.tag.uuid) {
      this.adminTagsService.addOwner(this.tagFirstName.value, this.tagLastName.value, this.tagEmail.value).subscribe(handleResponse);
    } else {
      this.adminTagsService.editOwner(this.tag.uuid, this.tagFirstName.value, this.tagLastName.value, this.tagEmail.value).subscribe(handleResponse);
    }
  }

  submitAudience(): void {
    const handleResponse = {
      next: (res) => {
        if (!this.tag.uuid) {
          this.tag.uuid = res.response.uuid;
          this.tag.noOfGroups = 0;
        }
        this.tag.name = this.tagFirstName.value;
        if (!this.tag.uuid) this.toastr.success(...ToastrMessages.AUDIENCE_ADDED);
        else this.toastr.success(...ToastrMessages.AUDIENCE_UPDATED);
        this.emitTrue();
      },
      error: (err) => {
        if (err === ServerError.ALREADY_EXISTS) this.toastr.error(...ToastrMessages.DUPLICATED_AUDIENCE);
        else if (err === ServerError.AUDIENCE_NOT_FOUND) this.toastr.error(...ToastrMessages.AUDIENCE_NOT_FOUND);
        else this.toastr.error(...ToastrMessages.BASIC_ERROR);
      },
    };
    if (!this.tag.uuid) {
      this.adminTagsService.addAudience(this.tagFirstName.value).subscribe(handleResponse);
    } else {
      this.adminTagsService.editAudience(this.tag.uuid, this.tagFirstName.value).subscribe(handleResponse);
    }
  }

  submitLanguage() {
    const payload: LanguagePayload = {
      uuid: this.tag.uuid ?? null,
      name: this.tagFirstName.value.trim(),
      flagImgUrl: this.languagePhoto.path,
      flagPictureUuid: this.languagePhoto.uuid,
    };

    this.adminTagsService.createUpdateLanguage(payload).subscribe({
      next: (res) => {
        if (!this.tag.uuid) {
          this.tag.noOfGroups = 0;
        }
        this.tag.uuid = this.tag?.uuid ? this.tag.uuid : res.response;
        this.tag.name = this.tagFirstName.value;
        this.tag.languagePictureUrl = this.languagePhoto.path;
        this.tag.languagePictureUuid = this.languagePhoto.uuid;
        this.tag.languagePictureUuid = this.languagePhoto.uuid;

        if (!this.tag.uuid) this.toastr.success(...ToastrMessages.LANGUAGE_ADDED);
        else this.toastr.success(...ToastrMessages.LANGUAGE_UPDATED);
        this.emitTrue();
      },
      error: (err) => {
        if (err === ServerError.ALREADY_EXISTS) this.toastr.error(...ToastrMessages.DUPLICATED_LANGUAGE);
        else if (err === ServerError.LANGUAGE_NOT_FOUND) this.toastr.error(...ToastrMessages.LANGUAGE_NOT_FOUND);
        else this.toastr.error(...ToastrMessages.BASIC_ERROR);
      },
    });
  }

  emitTrue(): void {
    const modalRequest = new ModalRequest(this.modalRequest.scope, this.tag?.uuid, null, this.tag);
    const response = new ModalResponse(true, modalRequest);
    this.modalService.emitResponse(response);
    this.close();
  }

  setLanguageImage(): void {
    if (this.tag?.languagePictureUrl && this.tag.languagePictureUuid) {
      this.languagePhoto.uuid = this.tag.languagePictureUuid;
      this.languagePhoto.path = this.tag?.languagePictureUrl;
    }
  }

  //Upload language photo
  handleImageUpload(): void {
    this.tagImage.reset();
    if (this.languagePhoto?.path) {
      this.uploaderApiService
        .deleteImage({ entityUuid: this.tag.uuid, fileType: FileType.LANGUAGE_IMAGE, fileUuid: this.languagePhoto.uuid })
        .subscribe(() => (this.languagePhoto = new FileDetails(null, null, null, null)));
    } else {
      this.modalService.openModal(Modals.CROP_IMAGE, {
        aspectRatio: 3 / 2,
        fileType: FileType.LANGUAGE_IMAGE,
        idx: null,
        target: 'tagImage',
      });
    }
  }

  handleModalResponse(response: ModalFileResponse): void {
    if (response?.confirmed) {
      switch (response.modalRequest.scope) {
        case ModalActions.CROP:
          this.uploadImageAfterCrop(response.payload, response.fileType, response.target);
          break;
        default:
          break;
      }
    }
  }

  uploadImageAfterCrop(payload: string, fileType: FileType, target: string): void {
    const fileToUpload: FileItem = this.uploader.queue[this.uploader.queue.length - 1];
    fileToUpload.formData.fileType = fileType;
    fileToUpload.formData.entityUuid = this.tag?.uuid;
    fileToUpload.formData.formControlName = target;
    fileToUpload._file = FileUtils.convertB64ToImage(payload, fileToUpload);
    this[target].pendingUpload += 1;
    this.uploadService.uploadFile();
  }

  setProgress(response: UploadPromiseResponse): void {
    this.languagePhoto.progress = +response.response;
  }

  setFileFields(response: UploadPromiseResponse): void {
    const responseData = JSON.parse(response.response);
    const parsedData = responseData.response;
    const fileName = FileUtils.computeFileName(response.fileItem.file.name);

    if (responseData.hasOwnProperty('error')) {
      this.toastr.error(...ToastrMessages.BASIC_ERROR);
      this.languagePhoto.pendingUpload -= 1;
      return;
    }

    this.tagImage.patchValue(parsedData.uuid);
    if (parsedData.objectUuid) {
      this.tag.uuid = parsedData.objectUuid;
      this.tag.noOfGroups = 0;
    }

    this.languagePhoto.name = fileName;
    this.languagePhoto.uuid = parsedData.uuid;
    this.languagePhoto.path = parsedData.url;
    this.languagePhoto.fileType = FileType.STUDY_IMAGE;
    this.languagePhoto.isFormatValid = true;
    this.languagePhoto.pendingUpload -= 1;
  }

  close(): void {
    this.modalService.closeAll();
  }

  private submitIndustry() {
    const handleResponse = {
      next: (res) => {
        this.tag.name = this.tagFirstName.value;
        this.tag.type = this.tagIndustryType.value;
        if (!this.tag.uuid) {
          this.tag.uuid = res.response;
          this.toastr.success(...ToastrMessages.INDUSTRY_ADDED);
        } else {
          this.toastr.success(...ToastrMessages.INDUSTRY_UPDATED);
        }
        this.emitTrue();
      },
      error: (err) => {
        if (err === ServerError.ALREADY_EXISTS) this.toastr.error(...ToastrMessages.DUPLICATED_INDUSTRY);
        else if (err === ServerError.INDUSTRY_NOT_FOUND) this.toastr.error(...ToastrMessages.INDUSTRY_NOT_FOUND);
        else this.toastr.error(...ToastrMessages.BASIC_ERROR);
      },
    };
    if (!this.tag.uuid) {
      this.adminTagsService.addIndustryCategory(this.tagFirstName.value, this.tagIndustryType.value).subscribe(handleResponse);
    } else {
      this.adminTagsService.editIndustryCategory(this.tag.uuid, this.tagFirstName.value, this.tagIndustryType.value).subscribe(handleResponse);
    }
  }
}
