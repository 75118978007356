import { Component, Input, OnInit } from '@angular/core';
import { UnitStudy } from 'src/app/_core/models/AdminGroups';

@Component({
  selector: 'app-study-section-modal',
  templateUrl: './study-section-modal.component.html',
  styleUrls: ['./study-section-modal.component.scss'],
})
export class StudySectionModalComponent implements OnInit {
  @Input() study: UnitStudy;

  constructor() {}

  ngOnInit(): void {}
}
