<div class="modal-container">
  <div class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
    <h1 class="mb-0">{{ studyInfo?.name }}</h1>
    <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
  </div>

  <div class="px-3 px-md-5 mt-2" *ngIf="studyInfo">
    <div class="custom-container">
      <h1 class="primary-color">About</h1>
      <div class="d-flex flex-column flex-md-row">
        <div>
          <div class="image-container position-relative" (click)="openMediaModal()">
            <img
              class="study-image mr-4 mr-xs-0"
              [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
              [lazyLoad]="studyInfo.studyPictureUrl"
            />
            <a class="play-icon"><i class="fas fa-2x fa-play text-white"></i></a>
          </div>
        </div>
        <div>
          <h2 class="mb-0">{{ studyInfo.name }}</h2>
          <p class="smaller-text primary-color uppercase font-weight-bold mb-0">{{ studyInfo.author }}</p>
          <div class="mt-2">
            {{ studyInfo.description }}
          </div>
          <div class="mt-2 primary-color pointer" (click)="openMediaModal()">Watch the Trailer</div>
        </div>
      </div>
    </div>
    <div class="custom-container" *ngIf="groups.length">
      <h1 class="primary-color">Current Groups</h1>
      <div infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScrollDown()" [fromRoot]="true" [infiniteScrollContainer]="'.modal-container'">
        <div class="mb-4" *ngFor="let group of groups">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </div>
    </div>

    <div class="custom-container py-1 mb-4">
      <button mat-button class="uppercase" color="primary" (click)="openModal(modals.ADD_STUDY, modalActions.EDIT_STUDY)">
        <i class="fas fa-pencil-alt mr-2"></i>Edit
      </button>
      <button mat-button class="uppercase" color="warn" (click)="emit(modalEmitActions.DELETE_STUDY_SPECIAL_CASE_REQUEST)">
        <i class="far fa-trash-alt mr-2"></i>Delete
      </button>
    </div>
  </div>
</div>
