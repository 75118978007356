<div class="custom-container" [class.pt-0]="viewedByFacilitator">
  <h1 class="primary-color">About the group</h1>
  <div class="row">
    <div class="col-md-6">
      <div class="gray-color font-weight-bold">GROUP SCHEDULE</div>
      <h2 class="mb-0">{{ group.meetingDate | titlecase }} at {{ group.localStartDate | date : 'h:mm a' }}</h2>
      <div class="local-time">in your local time</div>
    </div>
    <div class="col-md-6 d-flex align-items-center">
      <div>
        <div class="gray-color font-weight-bold">STARTS ON</div>
        <h2 class="mb-0">{{ group.startsOn | date : 'MMM d, yyyy' }}</h2>
      </div>
      <ng-container *ngIf="!group.neverEnding">
        <i class="fas fa-arrow-right primary-color px-3"></i>
        <div>
          <div class="gray-color font-weight-bold">ENDS ON</div>
          <h2 class="mb-0">{{ group.endsOn | date : 'MMM d, yyyy' }}</h2>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- <ng-template #single>
    <div class="d-flex justify-content-between flex-wrap">
      <div>
        <table class="table table-borderless event-table">
          <thead>
            <tr class="gray-color font-weight-bold font-14">
              <th scope="col" class="pl-0">EVENT DATES</th>
              <th scope="col">STARTING HOUR</th>
              <th scope="col">ENDING HOUR</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let event of group.meetings">
              <tr [class.meeting-row]="event.skipped">
                <td scope="row" class="mb-0 pl-0">{{ event.startTime | date: 'MMMM dd, yyyy' }}</td>
                <td>{{ event.startTime | date: 'shortTime' }}</td>
                <td>{{ event.endTime | date: 'shortTime' }}</td>
                <hr />
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template> -->
  <div class="row mt-3">
    <div class="col-md-6">
      <div class="gray-color font-weight-bold">STAGE SIZE</div>
      <h2 class="mb-0" *ngFor="let companySize of companySizes">{{ companySize.name }}</h2>
    </div>
    <div class="col-md-6 mt-2 mt-sm-0">
      <div *ngIf="!group.isVirtual">
        <div class="gray-color font-weight-bold">MEETING ADDRESS</div>
        <h2 class="mb-0">{{ group.meetingAddress }}</h2>
      </div>
      <div *ngIf="!group.isInPerson">
        <div class="gray-color font-weight-bold">MEETING LINK</div>
        <h2 class="mb-0 pointer" (click)="openLink()">
          <i
            ><u>{{ group.meetingLink | truncate : 20 }}</u></i
          >
        </h2>
      </div>
    </div>
  </div>
</div>
