import { Church, ChurchDetails } from './../models/Home';
import { ChurchesPaginatedRequest } from './../models/Shared';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import ComputePayload from '../helpers/ComputePayload';
import { UsersFilters } from '../models/AdminUsers';
import { ResponseObject } from '../models/GenericObject';
import { Group, GroupDetails, Study, StudyDetails } from '../models/Home';
import { BasicPaginatedRequestMain, PaginatedResponse } from '../models/Shared';

@Injectable({
  providedIn: 'root',
})
export class UserDropdownService {
  filtersDropdowns$ = new AsyncSubject<UsersFilters>();
  private filters: UsersFilters;

  // prettier-ignore
  constructor(
    private http: HttpClient
  ) {
    if (this.filters === undefined) {
      this.getFilter();
    }
   }

  private getFilter(): void {
    this.filters = new UsersFilters();
    this.setFilters(this.filters);
  }

  private setFilters(filters: UsersFilters): void {
    this.filtersDropdowns$.next(filters);
    this.filtersDropdowns$.complete();
  }

  loadStudies(): Observable<any> {
    const payload: BasicPaginatedRequestMain = ComputePayload.basicPaginatedRequestStudies(this.filters.study);

    return this.http
      .post(`${environment.server}/v1/study/get-studies-main`, payload, {
        params: { groupRole: 'NONE' },
      })
      .pipe(
        tap((res: ResponseObject<PaginatedResponse<StudyDetails>>) => {
          this.filters.study.filters = [
            ...this.filters.study.filters,
            ...res.response.objects.map((study: StudyDetails, index: number) => {
              const selected = this.filters.group.shouldBeSelected(index, study.studyUuid);
              return new Study(study, selected);
            }),
          ];

          this.filters.study.page++;
          this.filters.study.pagination.setPagination(res.response.totalObjects, res.response.to);
        })
      );
  }

  reloadStudies(): Observable<any> {
    this.filters.study.page = 0;
    this.filters.study.filters = [];
    this.filters.study.searchText = '';
    this.filters.preloaded = [];
    return this.loadStudies();
  }

  loadGroups(): Observable<any> {
    const payload: BasicPaginatedRequestMain = ComputePayload.basicPaginatedRequestStudies(this.filters.group);

    return this.http.post(`${environment.server}/v1/groups/get-groups-main`, payload).pipe(
      tap((res: ResponseObject<PaginatedResponse<GroupDetails>>) => {
        this.filters.group.filters = [
          ...this.filters.group.filters,
          ...res.response.objects.map((group: GroupDetails, index: number) => {
            const selected = this.filters.group.shouldBeSelected(index, group.groupUuid);
            return new Group(group, selected);
          }),
        ];

        this.filters.group.page++;
        this.filters.group.pagination.setPagination(res.response.totalObjects, res.response.to);
      })
    );
  }

  reloadGroups(): Observable<any> {
    this.filters.group.page = 0;
    this.filters.group.filters = [];
    this.filters.group.searchText = '';
    this.filters.preloaded = [];
    return this.loadGroups();
  }

  loadChurches(): Observable<any> {
    const payload: ChurchesPaginatedRequest = ComputePayload.basicPaginatedRequestChurches(this.filters.church, null);
    return this.http.post(`${environment.server}/v1/church/get-churches`, payload).pipe(
      tap((res: ResponseObject<PaginatedResponse<ChurchDetails>>) => {
        this.filters.church.filters = [
          ...this.filters.church.filters,
          ...res.response.objects.map((church: ChurchDetails, index: number) => {
            const selected = this.filters.group.shouldBeSelected(index, church.uuid);
            return new Church(church, selected);
          }),
        ];

        this.filters.church.page++;
        this.filters.church.pagination.setPagination(res.response.totalObjects, res.response.to);
      })
    );
  }

  reloadChurches(): Observable<any> {
    this.filters.church.page = 0;
    this.filters.church.filters = [];
    this.filters.church.searchText = '';
    this.filters.preloaded = [];
    return this.loadChurches();
  }
}
