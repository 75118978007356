<div
  class="resume-container d-flex justify-content-between flex-wrap"
  [class.gold-border-color]="!this.group.onBoarding"
  [class.black-border-color]="this.group.onBoarding"
>
  <div class="row flex-grow mr-md-3">
    <div class="col-12 col-md-5 flex-grow">
      <h2 class="pointer m-0 p-0" (click)="goToGroupDetails()">{{ group.groupName }}</h2>
      <div class="d-flex mb-1">
        <div>
          <i class="fas primary-color fa-calendar-alt mr-2"></i>
        </div>
        <div class="d-flex flex-column">
          <div class="text-uppercase pl-0">Meets on {{ group.meetingDay + 's' }} at {{ group.meetingTime }}</div>
          <div class="text-uppercase local-time">in your local time</div>
          <div class="facilitator-section">with {{ group.facilitatorFirstName }} {{ group.facilitatorLastName }}</div>
        </div>
      </div>
    </div>
    <div class="col col-sm-7 col-md-4 mt-2">
      <p class="gray-color text-uppercase m-0">Details</p>
      <div class="d-flex flex-column">
        <div>{{ group.studyName }}</div>
        <div>{{ group.cohortName }}</div>
      </div>
    </div>
    <div class="col col-sm-5 col-md-3 mt-2">
      <p class="gray-color text-uppercase m-0">Participants</p>
      <div class="m-0 d-flex flex-nowrap align-items-center font-weight-bold text-nowrap">
        <span> {{ group.spotsTaken }} joined&nbsp; </span>
        <div class="dot"></div>
        <span>&nbsp;{{ group.spotsLeft }} spots left</span>
      </div>
    </div>
  </div>
</div>
