<div class="bg-white w-75">
  <h2>Design <a href="https://xd.adobe.com/view/71443761-28f8-47cc-afd3-addf3684d38d-2417/specs/">link</a></h2>
  <div class="mb-4">
    <h1 class="text-centers">Buttons</h1>
    <div class="d-flex justify-content-between p-4">
      <button mat-button disableRipple>SIGN IN</button>
      <button mat-flat-button color="primary" class="mat-medium-button">Next</button>
      <button mat-button class="mat-medium-button black-border">Back</button>
    </div>
    <div class="d-flex justify-content-between bg-main p-4">
      <button mat-button class="mat-longer-button black-button">Text</button>
      <button mat-button class="mat-longer-button white-button">Facilitator</button>
    </div>

    <div class="d-flex justify-content-between bg-main p-4">
      <button mat-button class="mat-long-button black-button">Mark Twin</button>
      <button mat-button class="mat-long-button white-button">Samantha Crew</button>
    </div>
  </div>

  <div class="mb-4">
    <h1 class="text-center">Icons</h1>
    <div class="d-flex justify-content-between">
      <a><i class="fas fa-times gray-color pointer"></i></a>
      <i class="fas fa-clock gray-color"></i>
      <i class="fas fa-comment-alt gray-color"></i>
    </div>
  </div>

  <div class="mb-4">
    <h1 class="text-center">Headers</h1>
    <h1>1/25: How would you like to find your small group?</h1>
    <h2>7/25: Here we'll have the group name</h2>
    <h3>1/25: Schedule</h3>
    <div>
      If you want to edit the font size of default elements like h1-h4, p & others, consider doing it globally. See
      <a href="https://material.angular.io/guide/typography">documentation</a>
    </div>
    <div>Try using the default styles instead of changing the font size of random elements</div>
  </div>

  <div class="mb-4">
    <h1 class="text-center">Headers</h1>
    <h1>1/25: How would you like to find your small group?</h1>
    <h2>7/25: Here we'll have the group name</h2>
    <h3>1/25: Schedule</h3>
    <div>
      If you want to edit the font size of default elements like h1-h4, p & others, consider doing it globally. See
      <a href="https://material.angular.io/guide/typography">documentation</a>
    </div>
    <div>Try using the default styles instead of changing the font size of random elements</div>
  </div>

  <div class="mb-4">
    <h1 class="text-center">Headers</h1>
    <h1>1/25: How would you like to find your small group?</h1>
    <h2>7/25: Here we'll have the group name</h2>
    <h3>1/25: Schedule</h3>
    <div>
      If you want to edit the font size of default elements like h1-h4, p & others, consider doing it globally. See
      <a href="https://material.angular.io/guide/typography">documentation</a>
    </div>
    <div>Try using the default styles instead of changing the font size of random elements</div>
  </div>
</div>
