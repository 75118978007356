export default class StorageHelper {
  static readonly tokenKey: string = 'jwtToken';
  static readonly refreshTokenKey: string = 'refreshJwtToken';
  static readonly username: string = 'username';
  static readonly facilitator: string = 'facilitator';
  static readonly firstAccess: string = 'firstAccess';
  static readonly selectedGroupUuid: string = 'selectedGroupUuid';
  static readonly userFirstRegister: string = 'userFirstRegister';

  static getToken(): string {
    return window.localStorage[this.tokenKey];
  }

  static saveTokensAndUsername(token: string, refreshToken?: string, username?: string): void {
    this.saveToken(token);
    window.localStorage[this.refreshTokenKey] = refreshToken;
    window.localStorage[this.username] = username;
  }

  static getRefreshToken(): string {
    return window.localStorage[this.refreshTokenKey];
  }

  static getUsername(): string {
    return window.localStorage[this.username];
  }

  static getFacilitator(): string {
    const value = window.localStorage[this.facilitator];
    return value ? window.atob(value) : null;
  }

  static saveToken(token: string): void {
    window.localStorage[this.tokenKey] = token;
  }

  static saveFacilitator(facilitator: boolean): void {
    window.localStorage[this.facilitator] = window.btoa(facilitator.toString());
  }

  static killSession(): void {
    window.localStorage.removeItem(this.tokenKey);
    window.localStorage.removeItem(this.refreshTokenKey);
    window.localStorage.removeItem(this.username);
    window.localStorage.removeItem(this.facilitator);
    window.sessionStorage.removeItem(this.selectedGroupUuid);
    window.localStorage.removeItem(this.userFirstRegister);
  }

  static clearToken(): void {
    window.localStorage.removeItem(this.tokenKey);
  }

  static saveFirstAccess(): void {
    window.localStorage[this.firstAccess] = true;
  }

  static isFirstAccess(): boolean {
    return window.localStorage.getItem(this.firstAccess) === null;
  }

  static isFirstRegister(): boolean {
    return window.localStorage.getItem(this.userFirstRegister) == 'true';
  }

  static saveSelectedGroupUuid(uuid: string): void {
    window.localStorage.setItem(this.selectedGroupUuid, uuid);
  }

  static getSelectedGroupUuid(): string {
    return window.localStorage.getItem(this.selectedGroupUuid);
  }

  static clearSelectedGroupUuid(): void {
    window.localStorage.removeItem(this.selectedGroupUuid);
  }
}
