import { FilterType } from 'src/app/_core/constants/Home';

export enum COHORT_TYPE {
  MONTHLY = 'Ongoing',
  FOUNDATION = 'Foundation',
}

export enum INDUSTRY_TYPE {
  INDUSTRY = 'INDUSTRY',
  AFFINITY = 'AFFINITY',
}

export const CohortYears = ['2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
export const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const GroupTags = [
  {
    name: 'Cohorts',
    filter: FilterType.COHORT,
  },
  {
    name: 'Audience',
    filter: FilterType.GROUP_AUDIENCE,
  },
  {
    name: 'Owners',
    filter: FilterType.GROUP_OWNERS,
  },
  {
    name: 'Languages',
    filter: FilterType.GROUP_LANGUAGE,
  },
  {
    name: 'Industry/Affinity',
    filter: FilterType.GROUP_INDUSTRY,
  },
];
