<div class="change-container" *ngFor="let change of history.fieldValueList">
  <div class="change-item">
    <span class="change-date">{{ history.date | date : 'MMMM dd, yyyy' }}</span> -
    <span>
      <span class="font-16 ont-weight-bolder primary-color">{{ history.user.firstName }} {{ history.user.lastName }}</span>
      ({{ history.user.email }})
    </span>
    changed <span class="field-info">{{ change.fieldName }}</span>
    <ng-container *ngIf="change.fieldName.toLowerCase().trim() !== 'image'">
      from
      <span *ngIf="change.oldValue; else noOldValue" class="old-value">{{ change.oldValue }}</span>
      <ng-template #noOldValue> <span #noOldValue class="font-weight-bolder">&quot; &quot;</span> </ng-template>
    </ng-container>
    to
    <span *ngIf="change.newValue; else noNewValue" class="new-value">{{ change.newValue }}</span>
    <ng-template #noNewValue> <span *ngIf="!change.newValue" class="font-weight-bolder">&quot; &quot;</span></ng-template>
  </div>
</div>
