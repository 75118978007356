import { RegionType } from '../constants/RegionType';

export interface LocationDto {
  imageUrl: string;
  state: string;
  uuid: string;
  noOfGroupsForAdmin: number;
  noOfGroups: number;
  region: RegionType;
  subRegion?: string;
  latitude: number;
  longitude: number;
}

export class Location {
  imageUuid: string;
  name: string;
  imageUrl: string;
  regionName: RegionType;
  noOfGroups: number;
  visible: boolean;
  selected: boolean;
  uuid: string;
  subRegion: string;
  latitude: number;
  longitude: number;

  constructor(locationData: LocationDto, regionName?: RegionType) {
    this.imageUrl = locationData.imageUrl;
    this.name = locationData.state;
    this.noOfGroups = locationData.noOfGroups || locationData.noOfGroupsForAdmin;
    this.uuid = locationData.uuid;
    this.visible = false;
    this.selected = false;
    this.regionName = regionName || locationData.region;
    this.subRegion = locationData.subRegion;
    this.latitude = locationData.latitude;
    this.longitude = locationData.longitude;
  }
}
