<div class="d-flex flex-column justify-content-between">
  <a class="text-right" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center font-18">
    <div>You are already <span class="font-weight-bold">approved</span> for other groups:</div>
    <ng-container *ngFor="let alreadyApprovedGroup of modalRequest.payload; let i = index">
      <div *ngIf="i < 3" class="font-16">
        <u class="primary-color font-weight-bold">{{ alreadyApprovedGroup.groupName }}</u>
        <span class="mx-1">with</span>
        <span class="primary-color font-weight-bold">{{ alreadyApprovedGroup.facilitatorsName.join(', ') }}</span>
      </div>
    </ng-container>

    <div class="font-weight-bold my-3">Do you still want to apply for this group?</div>
  </div>
  <ng-container *ngIf="!isAuth; else authView">
    <div class="d-flex justify-content-between mt-4">
      <button mat-flat-button class="mat-medium-button" color="primary" (click)="close()">No, cancel application</button>
      <button mat-flat-button class="mat-medium-button" color="accent" [mat-dialog-close]="emit(true)">Yes, I do</button>
    </div>
  </ng-container>
  <ng-template #authView>
    <div class="d-flex flex-column">
      <button mat-flat-button class="mat-medium-button rounded w-100 mb-3" color="primary" [mat-dialog-close]="emit(true)">Yes, I do</button>
      <button mat-stroked-button class="mat-outline-button bg-white text-dark rounded" [mat-dialog-close]="emit(false)">
        No, cancel application
      </button>
    </div>
  </ng-template>
</div>
