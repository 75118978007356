<div class="d-flex justify-content-between align-items-center px-4 py-3 bg-gray">
  <h1 class="mb-0">Select your {{ filter.misc?.secondaryText || filter.misc.text }}</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>
<div class="d-flex justify-content-end mr-5 pr-4 mt-3">
  <u class="primary-color font-weight-bold pointer" (click)="selectAll()">{{ selectedAll ? 'Deselect All' : 'Select All' }}</u>
</div>
<div class="px-1 px-sm-3 pt-2 position-relative">
  <div
    class="container d-flex flex-column"
    infiniteScroll
    (scrolled)="onScrollDown()"
    [infiniteScrollContainer]="'.mat-dialog-container'"
    [fromRoot]="true"
  >
    <div *ngFor="let item of selections; let i = index">
      <mat-checkbox class="mr-2" [(ngModel)]="item.selected">
        <div class="d-flex align-items-center">
          <img
            *ngIf="filter.name === filterType.FACILITATOR || filter.name === filterType.STUDY || filter.name === filterType.CHURCH"
            [ngClass]="filter.name === filterType.STUDY ? 'study-image' : 'small-square-image'"
            [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
            [lazyLoad]="item.profileImgUrl || item.imgUrl || item.imageUrl"
          />
          <div class="ml-2 p-1">
            <h2 class="mb-0 truncated-single-line">{{ item.name | titlecase }}</h2>
            <div class="primary-color text-uppercase font-weight-bold truncated-single-line">{{ item.title || item.author }}</div>
          </div>
        </div>
      </mat-checkbox>
      <mat-divider *ngIf="i !== selections.length - 1" class="my-3"></mat-divider>
    </div>
  </div>
</div>
<div class="sticky-div position-sticky">
  <mat-divider></mat-divider>
  <div class="py-3 px-3">
    <button mat-flat-button color="primary" class="mat-medium-button" (click)="applyFilter()">Apply Filter</button>
  </div>
</div>
