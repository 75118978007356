import { FileType } from '../constants/FileType';
import { SortValue } from '../constants/SortByOptions';
import { SortByRequest, SortOption } from './Filters';

export interface PaginatedResponse<T> {
  from: number;
  objects: T[];
  objectsMap: ObjectsMap<T>[];
  pageNumber: number;
  to: number;
  totalObjects: number;
  totalUnits?: number;
}

export interface ObjectsMap<T> {
  [key: string]: T[];
}

export interface BasicPaginatedRequest {
  page: number;
  searchText: string;
  size: number;
}

export interface BasicPaginatedRequestMain extends BasicPaginatedRequest {
  entityUuids: string[];
}

export interface ChurchesPaginatedRequest extends BasicPaginatedRequest {
  region: string;
  groupRole?: string;
}

export interface FacilitatorsMainPaginatedRequestMain extends BasicPaginatedRequestMain {
  hideFacilitators: boolean;
}

export interface BasicPaginatedRequestWithSort extends BasicPaginatedRequest {
  sortBy: SortByRequest | {};
}

export interface ChurchesPaginatedRequestWithSort extends BasicPaginatedRequestWithSort {
  region: string;
  groupRole: string;
}

export class BasicFilters implements BasicPaginatedRequest {
  page: number;
  searchText: string;
  size: number;
  sortBy: SortOption[];

  constructor() {
    this.page = 0;
    this.searchText = '';
    this.size = 10;
    this.sortBy = [
      new SortOption(SortValue.USERNAME, null, false),
      new SortOption(SortValue.POSITION, null, false),
      new SortOption(SortValue.EMAIL, null, false),
      new SortOption(SortValue.GROUP, 'desc', true),
    ];
  }
}

export interface DeleteImage {
  entityUuid?: string;
  fileType: FileType;
  fileUuid: string;
}
