import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Urls } from 'src/app/_core/constants/Urls';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-fdi-study-disclaimer',
  templateUrl: './fdi-study-disclaimer.component.html',
  styleUrls: ['./fdi-study-disclaimer.component.scss'],
})
export class FdiStudyDisclaimerComponent implements OnInit {
  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private router: Router
  ) { }

  ngOnInit(): void {}

  close(): void {
    this.modalService.closeModal();
  }

  navigate(): void {
    this.router.navigate([`${Urls.APP}/${Urls.FDI_STUDY}`]);
    this.modalService.closeAll();
  }
}
