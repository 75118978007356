<div class="w-100 h-100 crop-modal-container">
  <div
    [hidden]="editingFile"
    class="file-drop h-100 drop-normal align-items-center justify-content-center"
    [ngClass]="{ 'drop-hovered': hasBaseDropZoneOver }"
    data-name="images"
    ng2FileDrop
    (onFileDrop)="fileChangeEvent($event)"
    (fileOver)="changeFileOver($event)"
    [uploader]="uploader"
  >
    <div>
      <img class="d-block m-auto" src="assets/icons/add_images.svg" />
      <div class="main-copy">
        Drop your image here, or
        <label class="browse pointer"
          >browse
          <input
            class="d-none"
            type="file"
            ng2FileSelect
            placeholder="Upload file"
            [uploader]="uploader"
            (onFileSelected)="fileChangeEvent($event)"
          />
        </label>
      </div>
    </div>
  </div>

  <div *ngIf="editingFile" class="height">
    <div class="image-cropper-container">
      <image-cropper
        #cropper
        class="crop-zone"
        [imageFile]="image"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatio"
        [transform]="transform"
        format="jpeg"
        [imageQuality]="70"
        [onlyScaleDown]="true"
        [resizeToWidth]="1920"
        [autoCrop]="true"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <div class="action-btns d-flex justify-content-center my-2">
        <button class="mat-outline-button gray-border mr-2" mat-stroked-button color="ternary" (click)="zoomIn()">Zoom In</button>
        <button class="mat-outline-button gray-border" mat-stroked-button color="ternary" (click)="zoomOut()">Zoom Out</button>
      </div>
    </div>

    <div class="preview-crop-container">
      <img
        class="preview-crop"
        [src]="croppedImages"
        [style.width]="aspectRatio == 1 ? '150px' : ''"
        [style.border]="croppedImages ? '1px solid black' : 'none'"
      />
      <div class="text-center font-weight-bold mt-2">This is how your image will be displayed</div>
    </div>
  </div>

  <div class="d-flex flex-column align-items-between buttons-container" *ngIf="image && editingFile">
    <div class="d-flex w-100 justify-content-end my-3">
      <button mat-flat-button class="mr-2" color="primary" [mat-dialog-close]="uploadImages()">Upload</button>
      <button mat-flat-button class="border" (click)="close()">Cancel</button>
    </div>
  </div>
</div>
