import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainGroupRoles } from 'src/app/_core/constants/AdminGroups';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { MultiFilterService } from 'src/app/_core/services/multi-filter.service';
import { QueryParamsService } from 'src/app/_core/services/query-params.service';
import { ModalActions, Modals } from '../../../_core/constants/Modals';
import { Urls } from '../../../_core/constants/Urls';
import { ModalRequest } from '../../../_core/models/ModalEvent';
import { User } from '../../../_core/models/User';
import { ModalsService } from '../../../_core/services/modals.service';
import { UserService } from '../../../_core/services/user.service';
import { AnalyticsService } from './../../../_core/services/analytics.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  user: User;
  userSubscription: Subscription;
  urls = Urls;
  modals = Modals;
  modalActions = ModalActions;
  showGoToBtn = false;
  goToEntrepreneurOrInvestor: string;
  breakpointSubscription: Subscription;
  isMobile: boolean;

  // prettier-ignore
  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: ModalsService,
    private breakpointObserver: BreakpointObserver,
    private QPService: QueryParamsService,
    private multiFilterService: MultiFilterService,
    private analyticsService: AnalyticsService
  ) {
    this.userSubscription = this.userService.currentUser$.subscribe((user: User) => {
      this.user = user;
    });

    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 767px)']).subscribe((result: BreakpointState) => {
      this.isMobile = result.matches;
    });

    this.router.events.subscribe((nav: NavigationEnd) => {
      if (nav instanceof NavigationEnd) {
        this.setEntrepreneurOrInvestor(nav.url);
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.breakpointSubscription.unsubscribe();
  }

  goTo(url: string | Urls): void {
    if (url === this.urls.AUTH + '/' + this.urls.LOGIN) {
      this.analyticsService.logClickEvent(AnalyticsEvent.CLICK_REGISTER_OR_LOGIN);
      this.analyticsService.logCustomEvent(AnalyticsEvent.APPLY_JOURNEY_CLICK_REGISTER_OR_LOGIN);
    }
    this.router.navigate([url]);
  }

  openModal(modalName: number, modalAction: ModalActions): void {
    const params = modalAction ? new ModalRequest(modalAction) : null;
    this.modalService.openModal(modalName, params);
  }

  showGoToButton(): void {
    this.router.events.subscribe((nav: NavigationEnd) => {
      if (nav instanceof NavigationEnd) {
        this.setEntrepreneurOrInvestor(nav.url);
      }
    });
  }

  setEntrepreneurOrInvestor(url: string) {
    if (
      url !== `/${Urls.APP}/${Urls.HOME}` &&
      (url.indexOf(`${Urls.APP}/${Urls.HOME}`) > -1 || url.indexOf(`${Urls.APP}/${Urls.GROUPS}`) > -1) &&
      url.indexOf(`redirect`) < 1
    ) {
      this.showGoToBtn = true;
      if (url.toLowerCase().indexOf(Urls.ENTREPRENEUR.toLowerCase()) > -1) {
        this.goToEntrepreneurOrInvestor = Urls.INVESTOR;
      } else if (url.toLowerCase().indexOf(Urls.INVESTOR.toLowerCase())) {
        this.goToEntrepreneurOrInvestor = Urls.ENTREPRENEUR;
      }
    } else {
      this.showGoToBtn = false;
    }
  }

  navigateToInvestorOrEntrepreneur(): void {
    if (this.goToEntrepreneurOrInvestor.toUpperCase() === MainGroupRoles.INVESTOR) {
      this.analyticsService.logClickEvent(AnalyticsEvent.GO_TO_INVESTOR_GROUPS_BTN);
      if (this.router.url.includes(Urls.FILTER)) {
        this.QPService.resetParams({ userRole: MainGroupRoles.INVESTOR });
        this.router.navigate([Urls.WIZARD_INVESTOR], { queryParams: this.QPService.getParams() });
      } else {
        this.multiFilterService.changeUserRole(MainGroupRoles.INVESTOR);
      }
    } else {
      this.analyticsService.logClickEvent(AnalyticsEvent.GO_TO_ENTREPRENEUR_GROUPS_BTN);
      if (this.router.url.includes(Urls.FILTER)) {
        this.QPService.resetParams({ userRole: MainGroupRoles.ENTREPRENEUR });
        this.router.navigate([Urls.WIZARD_USER_FORMAT], { queryParams: this.QPService.getParams() });
      } else {
        this.multiFilterService.changeUserRole(MainGroupRoles.ENTREPRENEUR);
      }
    }
  }
}
