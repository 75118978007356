import { RegionType } from '../constants/RegionType';
import { Location, LocationDto } from './Location';

export interface RegionDto {
  name: RegionType;
  subRegions: SubRegionDto[];
}

export interface SubRegionDto {
  name: string;
  mainlands: LocationDto[];
}

export class SubRegion {
  name: string;
  locations: Location[];
  selected: boolean;

  constructor(subRegionData: SubRegionDto, regionName: RegionType) {
    this.name = subRegionData.name;
    this.locations = subRegionData.mainlands.map((locationData) => new Location(locationData, regionName));
  }

  hasVisibleLocations(): boolean {
    return this.locations.findIndex((location) => location.visible) >= 0;
  }

  getVisibleLocations(): Location[] {
    return this.locations.filter((location) => location.visible);
  }

  hasSelectedLocations(): boolean {
    return this.locations.findIndex((location) => location.selected) >= 0;
  }

  resetLocations(): void {
    this.locations.map((location) => {
      location.visible = false;
      location.selected = false;
    });
    this.selected = false;
  }

  unselectLocations(): void {
    this.locations.map((location) => {
      location.selected = false;
    });
  }

  setLocationsVisible(): void {
    this.locations.map((location) => (location.visible = true));
  }

  findSearchedLocation(searchText: string): void {
    const searchedLocation = this.locations.find((location: Location) => location.name.toLowerCase() === searchText.toLowerCase());
    if (searchedLocation) {
      searchedLocation.selected = true;
      searchedLocation.visible = true;
    }
  }

  getSelectedLocationsUuids(): string {
    return this.locations
      .filter((location) => location.selected)
      .map((selectedLocation) => selectedLocation.uuid)
      .join(',');
  }
}

export class Region {
  name: RegionType;
  subRegions: SubRegion[];
  selected: boolean;

  constructor(regionData: RegionDto) {
    this.name = regionData.name;
    this.subRegions = regionData.subRegions.map((subRegionData) => new SubRegion(subRegionData, this.name));
    this.selected = true;
  }

  resetLocations(): void {
    this.subRegions.map((location) => {
      location.selected = false;
    });
    this.selected = false;
  }

  unselectLocations(): void {
    this.subRegions.forEach((subRegion) => {
      subRegion.unselectLocations();
    });
  }

  resetSelectedLocations() {
    this.subRegions.forEach((subRegion) => {
      subRegion.locations.forEach((location) => {
        location.selected = false;
      });
    });
  }

  get allLocations(): Location[] {
    let locations = [];
    this.subRegions.forEach((subRegion) => (locations = [...locations, ...subRegion.locations]));
    return locations;
  }
}
