<div [ngClass]="group.meetingType !== meetingType.SINGLE ? 'group-grid-wrapper py-3 pr-3' : 'd-flex p-3'">
  <div
    *ngIf="group.meetingType !== meetingType.SINGLE"
    class="column group-card-side-bar h-100 pr-2 py-1"
    [class.bg-primary]="group.meetingType === meetingType.WEEKLY"
    [class.bg-main]="group.meetingType === meetingType.MONTHLY"
  ></div>
  <div [ngClass]="group.meetingType !== meetingType.SINGLE ? 'column' : 'w-100'">
    <h3 *ngIf="group.meetingType === meetingType.WEEKLY">
      <span class="uppercase primary-color font-weight-bold bg-light-primary rounded px-2 py-1">
        <i class="fas fa-circle fa-xs primary-color mr-2"></i>WEEKLY FOUNDATION GROUP</span
      >
    </h3>
    <h3 *ngIf="group.meetingType === meetingType.MONTHLY">
      <span class="uppercase main-color font-weight-bold bg-light-grey rounded px-2 py-1">
        <i class="fas fa-circle fa-xs main-color mr-2"></i>MONTHLY GROUP</span
      >
    </h3>
    <div class="row">
      <div class="col-12 col-lg-4">
        <h2 class="m-0 truncated-single-line">{{ group.name }}</h2>
        <div class="mt-2" *ngIf="group.meetingType !== meetingType.SINGLE">
          <i class="fas fa-clock primary-color mr-2"></i>
          <span class="font-weight-bold">{{ group.meetingType === meetingType.WEEKLY ? 'WEEKLY' : 'MONTHLY' }}</span>
          <span> on {{ group.meetingDay }} at {{ group.meetingTime }}</span>
          <span class="local-time ml-1">in your local time</span>
        </div>
        <div class="d-flex align-items-baseline m-0">
          <i class="fas fa-calendar-alt primary-color mr-2"></i>
          <div class="d-flex align-items-center" *ngIf="group.meetingType === meetingType.WEEKLY">
            <span class="text-sm-nowrap">{{ group.startsOn | date: 'MMMM dd, yyyy' }}</span>
            <i class="fas fa-arrow-right gray-color px-2"></i>
            <span class="text-sm-nowrap">{{ group.endsOn | date: 'MMMM dd, yyyy' }}</span>
          </div>
          <div class="d-flex align-items-start" *ngIf="group.meetingType === meetingType.MONTHLY">
            <span class="text-sm-nowrap mr-1">Next session meeting on</span>
            <span class="text-sm-nowrap">{{ nextMeetingDate ? (nextMeetingDate | date: 'MMMM dd, yyyy') : 'N/A' }}</span>
          </div>
          <div class="d-flex align-items-start flex-column mt-2" *ngIf="group.meetingType === meetingType.SINGLE">
            <ng-container *ngFor="let meeting of structuredMeetings">
              <span class="text-sm-nowrap">{{ meeting }}</span>
            </ng-container>
          </div>
        </div>
        <div *ngIf="group.meetingType === meetingType.SINGLE">
          <i class="fas fa-clock primary-color mr-2"></i>
          <span class="text-sm-nowrap text-uppercase">Read more for event time.</span>
        </div>

        <p class="text-uppercase mb-1">
          <i class="fas primary-color mr-1 fa-users"></i>
          {{ group.spotsLeft }} spots available
        </p>
        <div>
          <i class="fas fa-map-marker-alt primary-color mr-2"></i>
          <span *ngIf="group?.groupType !== groupTypes.VIRTUAL">{{ buildLocationString() }}</span>
          <span *ngIf="group?.groupType === groupTypes.VIRTUAL">Online</span>
        </div>
      </div>

      <div *ngIf="isFacilitator()" class="col-12 col-lg-6 mt-4 mt-lg-0">
        <div class="d-flex flex-nowrap">
          <img
            class="facilitator-image mr-3"
            [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
            [lazyLoad]="previewFacilitator?.imgUrl"
          />
          <div>
            <p class="gray-label">Facilitator</p>
            <div class="d-flex align-items-baseline flex-column flex-md-row">
              <h2 class="m-0 mb-0 mb-md-0 text-md-nowrap" [ngClass]="{ 'truncated-2-lines': isMobile }">
                {{ previewFacilitator?.firstName + ' ' + previewFacilitator?.lastName | truncate: (!isMobile ? 38 : 100) }}&nbsp;
              </h2>
              <p class="smaller-text primary-color uppercase font-weight-bold mb-0 truncated-single-line">{{ previewFacilitator?.title }}</p>
            </div>
            <p class="truncated-3-lines" *ngIf="!isMobile">{{ previewFacilitator?.bio }}</p>
          </div>
        </div>
        <p class="truncated-3-lines mt-2" *ngIf="isMobile">{{ previewFacilitator?.bio }}</p>
      </div>

      <div *ngIf="previewChurch" class="col-12 col-lg-6 mt-4 mt-lg-0">
        <div class="d-flex flex-nowrap">
          <img
            class="facilitator-image mr-3"
            [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
            [lazyLoad]="previewChurch?.imageUrl"
          />
          <div>
            <p class="gray-label">Church</p>
            <div class="d-flex align-items-baseline flex-column flex-md-row">
              <h2 class="m-0 mb-0 mb-md-0 text-md-nowrap" [ngClass]="{ 'truncated-2-lines': isMobile }">
                {{ previewChurch?.name | truncate: (!isMobile ? 38 : 100) }}&nbsp;
              </h2>
            </div>
            <div class="smaller-text primary-color uppercase font-weight-bold mb-0">{{ previewChurch?.headline }}</div>
            <div class="smaller-text mb-0" *ngIf="!isMobile">{{ previewChurch?.description }}</div>
            <div class="d-flex my-2" *ngIf="previewChurch?.websiteUrl">
              <i class="fas fa-link mr-2 pointer" (click)="navigate(previewChurch.websiteUrl)"></i>
            </div>
          </div>
        </div>
        <div class="smaller-text mb-0" *ngIf="isMobile">{{ previewChurch?.description }}</div>
      </div>

      <div
        class="col-12 mt-0 mt-md-2 mt-lg-0 d-flex justify-content-end align-items-center"
        [ngClass]="isFacilitator() || previewChurch ? 'col-lg-2 justify-content-lg-center' : 'col-lg-8 justify-content-lg-end pr-4'"
      >
        <div class="d-flex flex-column">
          <div class="mb-2" *ngIf="group?.groupType">
            <div class="d-flex justify-content-end" [ngSwitch]="group.groupType">
              <div *ngSwitchCase="groupTypes.IN_PERSON"><i class="fas fa-users fa-2x primary-color"></i></div>
              <div class="d-flex flex-nowrap" *ngSwitchCase="groupTypes.HYBRID">
                <i class="fas fa-users fa-2x primary-color"></i> <span class="big-text primary-color">+</span>
                <i class="fas fa-laptop fa-2x primary-color"></i>
              </div>
              <div *ngSwitchCase="groupTypes.VIRTUAL"><i class="fas fa-laptop fa-2x primary-color"></i></div>
              <div *ngSwitchDefault></div>
            </div>
          </div>
          <div>
            <button (click)="showDetails(group.uuid)" mat-flat-button color="primary" class="read-more-button align-self-center">Read more</button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <app-facilitators-slider
        appClickStopPropagation
        [facilitators]="group.facilitatorsDtoList"
        [churches]="group.churches"
        [readOnly]="false"
        (facilitatorEmitter)="setPreeviewFacilitator($event)"
        (churchEmitter)="setPreviewChurch($event)"
      ></app-facilitators-slider>
    </div>
  </div>
</div>
