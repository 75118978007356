export enum ServerError {
  INVALID_GRANT = 'invalid_grant',
  BAD_REQUEST = 'Bad Request',
  GROUPFINDER_NOT_FOUND = 'GROUPFINDER_NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NO_USER = 'USER_NOT_FOUND',
  EMAIL_USED = 'EMAIL_USED',
  INVALID_PASSWORD_FORMAT = 'INVALID_PASSWORD_FORMAT',
  NEW_PASSWORD_SAME_AS_OLD = 'NEW_PASSWORD_SAME_AS_OLD',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  GROUP_NOT_FOUND = 'GROUP_NOT_FOUND',
  CAPTCHA_INVALID = 'CAPTCHA_INVALID',
  ALREADY_FACILITATOR = 'ALREADY_FACILITATOR',
  DATE_CANNOT_BE_LOWER_THAN_CURRENT = 'DATE_CANNOT_BE_LOWER_THAN_CURRENT',
  INVALID_EMAIL_ADDRESS = 'INVALID_EMAIL_ADDRESS',
  PREVIOUS_MEETING_NOT_SUBMITTED = 'PREVIOUS_MEETING_NOT_SUBMITTED',
  MEETING_ALREADY_SUBMITTED = 'MEETING_ALREADY_SUBMITTED',
  MEETING_NOT_SUBMITTED_OR_SAVED = 'MEETING_NOT_SUBMITTED_OR_SAVED',
  HUBSPOT_ERROR = 'HUBSPOT_ERROR',
  HUBSPOT_SYNC_RUNNING = 'HUBSPOT_SYNC_RUNNING',
  LINK_EXPIRED = 'LINK_EXPIRED',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  TIMEZONE_NOT_FOUND = 'TIMEZONE_NOT_FOUND',
  INVESTOR_QUESTIONS_NOT_ANSWERED = 'INVESTOR_QUESTIONS_NOT_ANSWERED',
  ALREADY_APPROVED_TO_FOUNDATION_GROUP = 'ALREADY_APPROVED_TO_FOUNDATION_GROUP',
  GROUP_ENDED = 'GROUP_ENDED',
  NO_MAINLAND_FOR_GROUP = 'NO_MAINLAND_FOR_GROUP',
  GROUP_ALREADY_FULL = 'GROUP_ALREADY_FULL',
  AUDIENCE_NOT_FOUND = 'GROUPAUDIENCE_NOT_FOUND',
  LANGUAGE_NOT_FOUND = 'LANGUAGE_NOT_FOUND',
  INDUSTRY_NOT_FOUND = 'INDUSTRY_NOT_FOUND',
  FACILITATOR_ALREADY_PARTICIPANT = 'FACILITATOR_ALREADY_PARTICIPANT',
  PRIMARY_FACILITATOR_NOT_ADDED = 'PRIMARY_FACILITATOR_NOT_ADDED',
  OPEN_UNTIL_DATE_GREATER_THAN_END_DATE = 'OPEN_UNTIL_DATE_GREATER_THAN_END_DATE',
  MAXIMUM_APPLICATIONS_REACHED = 'MAXIMUM_APPLICATIONS_REACHED',
  UNIT1_CANNOT_END_AFTER_UNIT2_STARTS = 'ENDS_DATE_CANNOT_BE_PRIOR_STARTS_DATE',
  BASIC_ERROR = 'BASIC_ERROR',
  CANNOT_MODIFY_UNIT_START_DATE_FOR_UNITS_WITH_SUBMITTED_MEETINGS = 'CANNOT_MODIFY_UNIT_START_DATE_FOR_UNITS_WITH_SUBMITTED_MEETINGS',
}
