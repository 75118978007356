import { MainGroupRoles } from 'src/app/_core/constants/AdminGroups';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import { GroupsFilters } from 'src/app/_core/models/Home';
import { Location } from 'src/app/_core/models/Location';
import { Region } from 'src/app/_core/models/Region';
import { RegionType } from '../../../_core/constants/RegionType';
import { SortValue } from '../../../_core/constants/SortByOptions';
import { FilterOption, SortBy, SortByOption, SortOption } from '../../../_core/models/Filters';
import {
  FILTER_NAME,
  GroupUnitCounterOptions,
  INPUT_TYPE,
  InvestorTypes,
  MeetingTypes,
  UnitStates,
  UserFormats,
  UserRoles,
} from './multi-filter.const';

export interface MultiFilterOptionData {
  uuid: string;
  name: string;
  helperText?: string;
  options?: MultiFilterOption[];
  parentUuid?: string;
}

export class MultiFilterOption extends FilterOption {
  helperText: string;
  expanded: boolean;
  disabled: boolean;
  options: MultiFilterOption[];
  parentUuid: string;

  constructor(data: MultiFilterOptionData) {
    super(data.uuid, data.name);
    this.helperText = data.helperText;
    this.disabled = false;
    this.expanded = false;
    this.options = data.options || [];
    this.parentUuid = data.parentUuid;
  }

  toggleSelected(): void {
    this.selected = !this.selected;
  }

  getValue(): string[] {
    return this.options.filter((option) => option.selected).map((option) => option.uuid);
  }
}

export class MultiFilterItem {
  options: MultiFilterOption[];
  helperData: { [key: string]: any };
  disabled?: boolean;
  expanded: boolean;
  name: FILTER_NAME;
  type: INPUT_TYPE;
  value: any;

  constructor(options: MultiFilterOptionData[], name: FILTER_NAME, inputType: INPUT_TYPE = INPUT_TYPE.CHECKBOX, helperData?: { [key: string]: any }) {
    this.options = options.map((option) => new MultiFilterOption(option));
    this.disabled = false;
    this.expanded = false;
    this.name = name;
    this.type = inputType;
    this.helperData = helperData;
  }

  setSelected(uuid: string, name?: string): void {
    switch (this.type) {
      case INPUT_TYPE.RADIO:
      case INPUT_TYPE.SELECT:
        this.options.forEach((option) => (option.selected = option.uuid === uuid));
        break;
      case INPUT_TYPE.DATE_PICKER:
        this.value = new Date(uuid);
        break;
      case INPUT_TYPE.CHECKBOX:
        let SelectedOption = this.options.find((option) => option.uuid === uuid);
        if (this.isAdvanced() && !SelectedOption) {
          const NewOption = new MultiFilterOption({ uuid, name });
          this.options.push(NewOption);
          SelectedOption = NewOption;
        }
        SelectedOption.toggleSelected();
        if (SelectedOption.options.length) SelectedOption.options.forEach((child) => (child.selected = SelectedOption.selected));
        break;
      case INPUT_TYPE.TOGGLE:
        const NewOption = new MultiFilterOption({ uuid, name });
        this.options.push(NewOption);
        NewOption.toggleSelected();
    }
    this.setExpanded();
  }

  setChildSelected(childOption: MultiFilterOption, firstChange: boolean = false): void {
    const Parent = this.options.find((option) => option.uuid === childOption.parentUuid);
    Parent.options.find((option) => option.uuid === childOption.uuid).toggleSelected();
    Parent.selected = Parent.options.every((child) => child.selected);
    if (firstChange) Parent.expanded = Parent.options.some((child) => child.selected) && !Parent.selected;
    this.setExpanded();
  }

  getValue(): string[] | string | number {
    switch (this.type) {
      case INPUT_TYPE.RADIO:
      case INPUT_TYPE.SELECT:
        return this.options.filter((option) => option.selected).map((option) => option.uuid)[0] as string;
      case INPUT_TYPE.DATE_PICKER:
        return DateUtils.localToUTCNoOffset(this.value);
      case INPUT_TYPE.CHECKBOX:
        return this.options.filter((option) => option.selected).map((option) => option.uuid) as string[];
      case INPUT_TYPE.TOGGLE:
        return this.options[0]?.uuid || null;
    }
  }

  getQueryParamValue(): string {
    if (this.type === INPUT_TYPE.CHECKBOX) {
      return (this.getValue() as string[]).join(',');
    } else {
      return this.getValue() as string;
    }
  }

  getSelectedChildrenByParent(parentUuid: string): MultiFilterOption[] {
    const Parent = this.options.find((option) => option.uuid === parentUuid);
    return Parent.options.filter((child) => child.selected);
  }

  getSelectedChildrenUuids(): string[] {
    let selectedUuids: string[] = [];
    this.options.forEach((option) => {
      selectedUuids = [...selectedUuids, ...option.options.filter((child) => child.selected).map((child) => child.uuid)];
    });
    return selectedUuids;
  }

  isSelected(uuid: string): boolean {
    return !!this.options.find((option) => option.uuid === uuid).selected;
  }

  optionInList(uuid: string): boolean {
    return !!this.options.some((option) => option.uuid === uuid);
  }

  isAdvanced(): boolean {
    const advancedFilters = [
      FILTER_NAME.CHURCHES,
      FILTER_NAME.FACILITATORS,
      FILTER_NAME.STUDIES,
      FILTER_NAME.GROUP_AUDIENCE,
      FILTER_NAME.GROUP_LANGUAGE,
      FILTER_NAME.GROUP_OWNERS,
      FILTER_NAME.ARCHIVED,
      FILTER_NAME.COMPANY_SIZE,
    ];
    return advancedFilters.includes(this.name);
  }

  setExpanded(): void {
    this.expanded =
      this.options.some((option) => option.selected || (option.options && option.options.some((child) => child.selected))) || this.value;
  }

  getChildByUuid(uuid): MultiFilterOption {
    let searchedChild: MultiFilterOption;
    this.options.forEach((option) => {
      const Child = option.options.find((child) => child.uuid === uuid);
      if (Child) searchedChild = Child;
    });
    return searchedChild;
  }

  reset(): void {
    if (this.isAdvanced()) {
      this.options = [];
    } else {
      this.options.forEach((option) => {
        option.selected = false;
        if (option.options.length) {
          option.options.forEach((child) => {
            child.selected = false;
          });
        }
      });
    }
    if (this.type === INPUT_TYPE.DATE_PICKER && this.value) this.value = null;
    this.expanded = false;
  }

  toggleDisable(optionUuid?: string): void {
    if (optionUuid) {
      const TargetOption = this.options.find((option) => option.uuid === optionUuid);
      if (TargetOption) TargetOption.disabled = !TargetOption.disabled;
      return;
    }
    this.options.forEach((option) => {
      option.disabled = !option.disabled;
    });
  }

  getChildOptionByUuid(uuid: string): MultiFilterOption {
    let SearchedChild: MultiFilterOption;
    this.options.forEach((option) => {
      const Item = option.options.find((child) => child.uuid === uuid);
      if (Item) SearchedChild = Item;
    });
    return SearchedChild;
  }

  addSelectedLocation(searchedLocation: Location): void {
    let selectedSubRegion = this.options.find((subRegion) => subRegion.name === searchedLocation.subRegion);
    if (!selectedSubRegion) {
      selectedSubRegion = new MultiFilterOption({
        uuid: searchedLocation.subRegion,
        name: searchedLocation.subRegion,
        options: [],
      });
      this.options.push(selectedSubRegion);
    }
    let selectedLocation = selectedSubRegion.options.find((location) => location.name === searchedLocation.name);
    if (!selectedLocation) {
      selectedLocation = new MultiFilterOption({
        uuid: searchedLocation.uuid,
        helperText: searchedLocation.subRegion,
        name: searchedLocation.name,
        parentUuid: selectedSubRegion.uuid,
      });
      selectedSubRegion.options.push(selectedLocation);
    }
    if (!selectedSubRegion.expanded) selectedSubRegion.expanded = true;
    if (!this.helperData.subRegionsExpanded) this.helperData.subRegionsExpanded = true;
    this.expanded = true;
  }
}

export class MultiFilter extends SortBy {
  userRole: MultiFilterItem;
  investorTypes: MultiFilterItem;
  userType: MultiFilterItem;
  unitStatuses: MultiFilterItem;
  unitsCounter: MultiFilterItem;
  weekDays: MultiFilterItem;
  timeIntervals: MultiFilterItem;
  meetingType: MultiFilterItem;
  mainlands: MultiFilterItem;
  churches: MultiFilterItem;
  facilitators: MultiFilterItem;
  studies: MultiFilterItem;
  searchText: string;
  cohort: MultiFilterItem;
  startDate: MultiFilterItem;
  groupOwners: MultiFilterItem;
  groupAudience: MultiFilterItem;
  groupLanguage: MultiFilterItem;
  archived: MultiFilterItem;
  industryAffinity: MultiFilterItem;
  justAffinity: MultiFilterItem;
  companySize: MultiFilterItem;
  groupState: MultiFilterItem;

  private isAdmin: boolean;

  constructor(filtersDropdowns: GroupsFilters, regions: Region[], isAdminList: boolean) {
    super();
    this.isAdmin = isAdminList;
    this.userRole = new MultiFilterItem(UserRoles, FILTER_NAME.USER_ROLE, INPUT_TYPE.RADIO);
    this.investorTypes = new MultiFilterItem(InvestorTypes, FILTER_NAME.INVESTOR_TYPES);
    this.userType = new MultiFilterItem(UserFormats, FILTER_NAME.USER_TYPE);
    this.unitStatuses = new MultiFilterItem(UnitStates, FILTER_NAME.UNITS_STATUS);
    this.unitsCounter = new MultiFilterItem(GroupUnitCounterOptions, FILTER_NAME.UNITS_COUNTER);
    this.weekDays = new MultiFilterItem(filtersDropdowns.days.filters, FILTER_NAME.WEEK_DAYS);
    this.timeIntervals = new MultiFilterItem(filtersDropdowns.dayIntervals.filters, FILTER_NAME.TIME_INTERVALS);
    this.meetingType = new MultiFilterItem(MeetingTypes, FILTER_NAME.MEETING_TYPE);
    this.mainlands = this.setMainlands(regions, isAdminList);
    this.churches = new MultiFilterItem([], FILTER_NAME.CHURCHES);
    this.facilitators = new MultiFilterItem([], FILTER_NAME.FACILITATORS);
    this.studies = new MultiFilterItem([], FILTER_NAME.STUDIES);
    this.sortBy = this.setSortByOptions();
    this.searchText = '';
    this.cohort = new MultiFilterItem(filtersDropdowns.cohorts.filters, FILTER_NAME.COHORT, INPUT_TYPE.SELECT);
    this.startDate = new MultiFilterItem([], FILTER_NAME.START_DATE, INPUT_TYPE.DATE_PICKER);
    this.groupOwners = new MultiFilterItem([], FILTER_NAME.GROUP_OWNERS);
    this.groupAudience = new MultiFilterItem([], FILTER_NAME.GROUP_AUDIENCE);
    this.groupLanguage = new MultiFilterItem([], FILTER_NAME.GROUP_LANGUAGE);
    this.archived = new MultiFilterItem([], FILTER_NAME.ARCHIVED, INPUT_TYPE.TOGGLE);
    this.industryAffinity = new MultiFilterItem(filtersDropdowns.industryAffinity.filters, FILTER_NAME.INDUSTRY, INPUT_TYPE.SELECT);
    this.justAffinity = new MultiFilterItem(filtersDropdowns.justAffinity.filters, FILTER_NAME.INDUSTRY, INPUT_TYPE.SELECT);
    this.companySize = new MultiFilterItem(filtersDropdowns.companySize.filters, FILTER_NAME.COMPANY_SIZE, INPUT_TYPE.SELECT);
    this.groupState = new MultiFilterItem(filtersDropdowns.groupState.filters, FILTER_NAME.GROUP_STATE);
  }

  private setSortByOptions(): SortOption<'asc' | 'desc'>[] {
    return (this.sortBy = [
      new SortOption(SortValue.NAME, null, false),
      new SortOption(SortValue.TIME, null, false),
      new SortOption(SortValue.FACILITATOR, null, false),
      new SortOption(SortValue.STAGE_SIZE, null, false),
      new SortOption(SortValue.PARTICIPANTS, null, false),
      new SortOption(SortValue.PENDING_USERS, 'desc', true),
    ]);
  }

  getSortOption(): SortByOption<'asc' | 'desc'> {
    const sortOption = this.sortBy?.find((i) => i.checked === true);
    return new SortByOption<'asc' | 'desc'>(sortOption.field, sortOption.order);
  }

  setMainlands(regions: Region[], isAdminList: boolean): MultiFilterItem {
    if (!isAdminList) return new MultiFilterItem([], FILTER_NAME.REGIONS);
    let subRegions: MultiFilterOptionData[] = [];
    regions.forEach((region: Region) => {
      region.subRegions.forEach((subRegion) => {
        if (subRegions.find((filteredSubregion) => filteredSubregion.name === subRegion.name)) return;
        const SubRegionLocations = subRegion.locations.map(
          (location) =>
            new MultiFilterOption({
              uuid: location.uuid,
              name: location.name,
              helperText: subRegion.name,
              parentUuid: subRegion.name,
            })
        );
        subRegions.push({
          uuid: subRegion.name,
          name: subRegion.name,
          options: SubRegionLocations,
        });
      });
    });
    subRegions.unshift({ uuid: RegionType.ALL, name: 'Global Groups' });
    return new MultiFilterItem(subRegions, FILTER_NAME.REGIONS, INPUT_TYPE.CHECKBOX, { subRegionsExpanded: false });
  }

  resetAll(): void {
    this.userRole.reset();
    this.investorTypes.reset();
    this.userType.reset();
    this.unitStatuses.reset();
    this.unitsCounter.reset();
    this.weekDays.reset();
    this.timeIntervals.reset();
    this.meetingType.reset();
    this.mainlands.reset();
    this.isAdmin ? (this.mainlands.helperData.subRegionsExpanded = false) : null;
    this.churches.reset();
    this.facilitators.reset();
    this.studies.reset();
    this.searchText = '';
    this.cohort.reset();
    this.startDate.reset();
    this.groupOwners.reset();
    this.groupAudience.reset();
    this.groupLanguage.reset();
    this.archived.reset();
    this.industryAffinity.reset();
    this.justAffinity.reset();
    this.companySize.reset();
  }

  isExpanded(): boolean {
    return (
      this.userRole.expanded ||
      this.investorTypes.expanded ||
      this.userType.expanded ||
      this.unitStatuses.expanded ||
      this.unitsCounter.expanded ||
      this.weekDays.expanded ||
      this.timeIntervals.expanded ||
      this.meetingType.expanded ||
      this.mainlands.expanded ||
      this.churches.expanded ||
      this.facilitators.expanded ||
      this.studies.expanded ||
      this.searchText != '' ||
      this.cohort.expanded ||
      this.startDate.expanded ||
      this.groupOwners.expanded ||
      this.groupAudience.expanded ||
      this.groupLanguage.expanded ||
      this.industryAffinity.expanded ||
      this.companySize.expanded
    );
  }

  isEntrepreneur(): boolean {
    return this.userRole.getValue() === MainGroupRoles.ENTREPRENEUR;
  }

  isInvestor(): boolean {
    return this.userRole.getValue() !== MainGroupRoles.ENTREPRENEUR;
  }

  get contactLink(): string {
    return this.userRole.value === MainGroupRoles.ENTREPRENEUR
      ? 'https://share.hsforms.com/1fvGfz-nvQiiNLz8Y6c7Oiw50pia'
      : 'https://share.hsforms.com/1shZcUuRaQumhyyKArdUV3A50pia';
  }
}
