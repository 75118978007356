import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminStudyDetails, ViewStudyGroupBody } from '../models/AdminStudies';
import { BasicPaginatedRequestWithSort } from '../models/Shared';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminStudiesService {
  private readonly resourceUrl: string = '/v1/study';

  constructor(private apiService: ApiService) {}

  getStudies(body: BasicPaginatedRequestWithSort): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/get-studies`, body);
  }

  getStudyDetails(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/v2/${uuid}/info`);
  }

  getGroupsWithStudy(body: ViewStudyGroupBody): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/v2/groups`, body);
  }

  createUpdateStudy(body: AdminStudyDetails): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/create-update`, body);
  }

  getStudyDetailsForm(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/${uuid}`);
  }

  deleteStudy(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/${uuid}`);
  }

  getFdiStudy(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/get-onboarding-study-details`);
  }
}
