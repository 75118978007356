import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Modals } from 'src/app/_core/constants/Modals';
import Utils from 'src/app/_core/helpers/Utils';
import { GroupFacilitator } from 'src/app/_core/models/Groups';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UserService } from 'src/app/_core/services/user.service';
import { GroupChurchPartnerOrganization } from '../../../../_core/models/Groups';

@Component({
  selector: 'app-facilitator-section-modal',
  templateUrl: './facilitator-section-modal.component.html',
  styleUrls: ['./facilitator-section-modal.component.scss'],
})
export class FacilitatorSectionModalComponent implements OnInit, OnDestroy {
  @Input() facilitators: GroupFacilitator[];
  @Input() churches: GroupChurchPartnerOrganization[];
  @Input() emailsTurnedOn: boolean;
  @Input() isArchived: boolean;
  selectedFacilitator: GroupFacilitator;
  selectedChurch: GroupChurchPartnerOrganization;
  subscriptions: Subscription = new Subscription();
  isMobile: boolean;
  isAdmin: boolean;

  // prettier-ignore
  constructor(private breakpointObserver: BreakpointObserver, private userService: UserService, private modalsService: ModalsService) {
    this.subscriptions.add(
      this.breakpointObserver.observe(['(max-width: 767px)']).subscribe((result: BreakpointState) => {
        this.isMobile = result.matches;
      })
    );
  }

  ngOnInit(): void {
    if (this.facilitators) {
      this.selectedFacilitator = this.facilitators[0];
    } else {
      this.selectedChurch = this.churches[0];
    }
    this.isAdmin = this.userService.isAdmin();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setSelectedFacilitator(facilitator): void {
    this.selectedChurch = null;
    this.selectedFacilitator = facilitator;
  }

  setSelectedChurch(church): void {
    this.selectedFacilitator = null;
    this.selectedChurch = church;
  }

  resendConfirmation(): void {
    const facilitatorName = `${this.selectedFacilitator.firstName} ${this.selectedFacilitator.lastName}`;
    this.modalsService.openModal(Modals.CONFIRM_ACTION, {
      text: `Are you sure you want to resend the confirmation email (includes calendar invites) to ${facilitatorName}?`,
      confirmButtonText: 'Send',
      payload: {
        userData: this.selectedFacilitator,
        facilitator: true,
      },
    });
  }

  navigate(url: string): void {
    Utils.navigate(url);
  }
}
