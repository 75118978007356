import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FileUploadModule } from 'ng2-file-upload';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ModalContainerComponent } from './_modals/_modals-container/modals-container.component';
import { AddParticipantModalComponent } from './_modals/add-participant-modal/add-participant-modal.component';
import { AddUserFacilitatorComponent } from './_modals/add-user-facilitator/add-user-facilitator.component';
import { AlreadyApprovedForGroupComponent } from './_modals/already-approved-for-group/already-approved-for-group.component';
import { ApplyToInPersonGroupComponent } from './_modals/apply-to-in-person-group/apply-to-in-person-group.component';
import { ConfirmCreateAccountComponent } from './_modals/confirm-create-account/confirm-create-account.component';
import { ConfirmDeleteComponent } from './_modals/confirm-delete/confirm-delete.component';
import { ConfirmSkipMeetingComponent } from './_modals/confirm-skip-meeting/confirm-skip-meeting.component';
import { CropModalComponent } from './_modals/crop-modal/crop-modal.component';
import { DeleteSpecialCaseComponent } from './_modals/delete-special-case/delete-special-case.component';
import { EditLocationComponent } from './_modals/edit-location/edit-location.component';
import { MediaModalComponent } from './_modals/media-modal/media-modal.component';
import { MultiFilterModalComponent } from './_modals/multi-filter-modal/multi-filter-modal.component';
import { SelectRegionMobileFilterComponent } from './_modals/select-region-mobile-filter/select-region-mobile-filter.component';
import { SendEmailComponent } from './_modals/send-email/send-email.component';
import { UpgradeUserConfirmationComponent } from './_modals/upgrade-user-confirmation/upgrade-user-confirmation.component';
import { EmptyComponent } from './components/empty/empty.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FacilitatorsSliderComponent } from './components/facilitators-slider/facilitators-slider.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FooterComponent } from './components/footer/footer.component';
import { FacilitatorSectionModalComponent } from './components/groups/facilitator-section-modal/facilitator-section-modal.component';
import { GroupCardComponent } from './components/groups/group-card/group-card.component';
import { GroupDetailsComponent } from './components/groups/group-details/group-details.component';
import { GroupResumeAdminComponent } from './components/groups/group-resume-admin/group-resume-admin.component';
import { StudyCardComponent } from './components/groups/study-card/study-card.component';
import { StudySectionModalComponent } from './components/groups/study-section-modal/study-section-modal.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MultiFilterComponent } from './components/multi-filter/multi-filter.component';
import { NoResultsFoundComponent } from './components/no-results-found/no-results-found.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { RegionFiltersComponent } from './components/region-filters/region-filters.component';
import { RegionSearchByComponent } from './components/region-search-by/region-search-by.component';
import { RegionComponent } from './components/region/region.component';
import { SearchComponent } from './components/search/search.component';
import { ServerErrorPageComponent } from './components/server-error-page/server-error-page.component';
import { StudyCardFacilitatorComponent } from './components/study-card-facilitator/study-card-facilitator.component';
import { StylesComponent } from './components/styles-standards/styles.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { BlockCutCopyDirective } from './directives/block-cut-copy.directive';
import { BlockPasteDirective } from './directives/block-paste.directive';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { DebounceInputDirective } from './directives/debounce-input.directive';
import { RecaptchaDirective } from './directives/recaptcha.directive';
import { EnumToStringPipe } from './pipes/enum-to-string.pipe';
import { FirstUpperCasePipe } from './pipes/first-upper-case.pipe';
import { ObjectIterationPipe } from './pipes/object-iteration.pipe';
import { TruncateCharactersPipe } from './pipes/truncate.pipe';

//material
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { ConfirmationModalComponent } from './_modals/confirmation-modal/confirmation-modal.component';
import { ImportUsersModalComponent } from './_modals/import-users-modal/import-users-modal.component';
import { SelectFilterComponent } from './_modals/select-filter/select-filter.component';
import { ChangeHistoryComponent } from './components/change-history/change-history.component';
import { NoteExpansionPanel } from './components/groups/note-expansion-panel/note-expansion-panel.component';
import { ImportUsersTableComponent } from './components/import-users-table/import-users-table.component';
import { MessagesComponent } from './components/messages/messages.component';
import { NormalAdminAccess, SuperAdminAccess } from './directives/access-control.directive';
import { AutocompletePositionDirective } from './directives/autocomplete-position.directive';
import { ChipListAutocompleteDirective } from './directives/chip-list-autocomplete.directive';
import { CallbackPipe } from './pipes/callback.pipe';
import { FormatHeaderNamePipe } from './pipes/header-name.pipe';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import {MergeAccountsModalComponent} from './_modals/merge-accounts/merge-accounts.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    //components
    HeaderComponent,
    StylesComponent,
    FooterComponent,
    ModalContainerComponent,
    SelectFilterComponent,
    ValidationMessageComponent,
    CropModalComponent,
    StudyCardComponent,
    GroupDetailsComponent,
    RegionFiltersComponent,
    ConfirmDeleteComponent,
    RegionComponent,
    LoaderComponent,
    ErrorPageComponent,
    GroupResumeAdminComponent,
    FacilitatorSectionModalComponent,
    GroupCardComponent,
    MediaModalComponent,
    StudySectionModalComponent,
    AddUserFacilitatorComponent,
    NoResultsFoundComponent,
    DeleteSpecialCaseComponent,
    UpgradeUserConfirmationComponent,
    AddParticipantModalComponent,
    FacilitatorsSliderComponent,
    StudyCardFacilitatorComponent,
    SendEmailComponent,
    RegionSearchByComponent,
    FiltersComponent,
    EditLocationComponent,
    SelectRegionMobileFilterComponent,
    ServerErrorPageComponent,
    LoadingSpinnerComponent,
    ReadMoreComponent,
    ConfirmSkipMeetingComponent,
    ConfirmCreateAccountComponent,
    AlreadyApprovedForGroupComponent,
    ApplyToInPersonGroupComponent,
    SearchComponent,
    MultiFilterComponent,
    EmptyComponent,
    MultiFilterModalComponent,
    NoteExpansionPanel,
    MessagesComponent,
    ChipListAutocompleteDirective,
    AutocompletePositionDirective,
    ConfirmationModalComponent,
    ChangeHistoryComponent,
    MergeAccountsModalComponent,

    //directives
    ClickStopPropagationDirective,
    RecaptchaDirective,
    BlockCutCopyDirective,
    BlockPasteDirective,
    DebounceInputDirective,
    NormalAdminAccess,
    SuperAdminAccess,

    //pipes
    EnumToStringPipe,
    TruncateCharactersPipe,
    FirstUpperCasePipe,
    SanitizeUrlPipe,
    CallbackPipe,
    ObjectIterationPipe,
    ImportUsersModalComponent,
    ImportUsersTableComponent,
    FormatHeaderNamePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LazyLoadImageModule,
    GooglePlaceModule,
    FileUploadModule,
    ImageCropperModule,
    InfiniteScrollModule,
    SlickCarouselModule,
    ClipboardModule,

    //material modules
    MatButtonModule,
    MatDividerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatCardModule,
    MatDatepickerModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    NgxIntlTelInputModule,
    MatTableModule,
    MatMenuModule,
    MatChipsModule,
    MatProgressSpinnerModule
  ],
  exports: [
    //components
    HeaderComponent,
    FooterComponent,
    ModalContainerComponent,
    ValidationMessageComponent,
    CropModalComponent,
    StudyCardComponent,
    GroupDetailsComponent,
    GroupResumeAdminComponent,
    FacilitatorSectionModalComponent,
    RegionComponent,
    LoaderComponent,
    StudySectionModalComponent,
    GroupCardComponent,
    AddUserFacilitatorComponent,
    NoResultsFoundComponent,
    DeleteSpecialCaseComponent,
    RegionSearchByComponent,
    FiltersComponent,
    RegionFiltersComponent,
    LoadingSpinnerComponent,
    ReadMoreComponent,
    SearchComponent,
    EmptyComponent,
    MultiFilterComponent,
    ChangeHistoryComponent,

    //directives
    RecaptchaDirective,
    BlockCutCopyDirective,
    BlockPasteDirective,
    ChipListAutocompleteDirective,
    AutocompletePositionDirective,
    DebounceInputDirective,
    NormalAdminAccess,
    SuperAdminAccess,

    //pipes
    EnumToStringPipe,
    TruncateCharactersPipe,
    FirstUpperCasePipe,
    ObjectIterationPipe,
    CallbackPipe,

    //modules
    InfiniteScrollModule,
    GooglePlaceModule,
    MatRadioModule,
    MatIconModule,
    MatTooltipModule,
    NoteExpansionPanel,
    FormatHeaderNamePipe
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule {
}
