import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { AdminGroupService } from 'src/app/_core/api/admin-group.service';
import { GroupService } from 'src/app/_core/api/group.service';
import { GroupRoles, GroupTypes, MainGroupRoles } from 'src/app/_core/constants/AdminGroups';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { FilterType } from 'src/app/_core/constants/Home';
import { ModalActions, ModalEmitActions, Modals } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import Utils from 'src/app/_core/helpers/Utils';
import { CustomAddress } from 'src/app/_core/models/Address';
import { AdminGroupDetails, ViewGroupUnit } from 'src/app/_core/models/AdminGroups';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { GroupsFilters } from 'src/app/_core/models/Home';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { GroupDropdownService } from 'src/app/_core/services/group-dropdown.service';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { MultiFilterService } from 'src/app/_core/services/multi-filter.service';
import { QueryParams, QueryParamsService } from 'src/app/_core/services/query-params.service';
import { MultiFilter } from 'src/app/_shared/components/multi-filter/multi-filter.model';
import { environment } from 'src/environments/environment';
import { GroupCard, GroupsResponse } from '../../../_core/models/Groups';
import { ModalRequest, ModalResponse } from '../../../_core/models/ModalEvent';
import { UserService } from '../../../_core/services/user.service';
import { GroupFormat } from './../../../_core/constants/AdminGroups';
import { GROUP_CATEGORY } from './groups.const';
import { GroupsList, GroupsListPayload, OldProximityGroupsListPayload } from './groups.model';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit, OnDestroy {
  @ViewChild('onlineAudience') onlineAudience: ElementRef<HTMLElement>;
  filterType = FilterType;
  groupTypes = GroupTypes;
  userTypes = MainGroupRoles;

  filters: GroupsFilters;
  subscription: Subscription = new Subscription();
  groups: GroupsList = new GroupsList();
  dropdowns: GroupsFilters;
  dynamicFilters: QueryParams;
  multiFilter: MultiFilter;

  addressInfo: CustomAddress;
  locationName: string;
  mainlandUuid: string;
  contactLink: string;
  cityState: string;
  isMobile: boolean;
  isTablet: boolean;
  isLarge: boolean;
  loading: boolean;
  hasPreviousNavigation: boolean;
  readyToLoadGroups: boolean;
  resultsFor: string;
  alphabetizedGroupType: GroupTypes = null;
  scrollOnOtherOnlineGroups = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private groupService: GroupService,
    private modalsService: ModalsService,
    private groupDropdownsService: GroupDropdownService,
    private userService: UserService,
    private toastr: ToastrService,
    private breakpointObserver: BreakpointObserver,
    private multiFilterService: MultiFilterService,
    private modalService: ModalsService,
    private adminGroupService: AdminGroupService,
    private analyticsService: AnalyticsService,
    private QPService: QueryParamsService
  ) {
    if (this.route.snapshot.params.id) this.handlePathVariable();
    else this.readyToLoadGroups = true;
    this.hasPreviousNavigation = !!this.router.getCurrentNavigation()?.previousNavigation;
    this.groupDropdownsService.filtersDropdowns$.pipe(first()).subscribe((res) => (this.dropdowns = res));

    this.subscription.add(
      this.groupDropdownsService.filtersDropdowns$.subscribe((groupsFilters: GroupsFilters) => {
        this.filters = groupsFilters || this.filters;
      })
    );
    this.subscription.add(
      this.modalService.modalResponse$.subscribe((response: ModalResponse<any>) => {
        if (response?.confirmed) this.handleModalResponse(response);
      })
    );
    this.subscription.add(
      this.multiFilterService.multiFilter$.pipe(debounceTime(100)).subscribe((multiFilter: MultiFilter) => {
        if (!multiFilter) return;
        this.mainlandUuid = this.route.snapshot.queryParams.mainland;
        this.multiFilter = multiFilter;
        this.alphabetizedGroupType = null;
        this.contactLink =
          this.multiFilter.userRole.getValue() === MainGroupRoles.ENTREPRENEUR
            ? 'https://share.hsforms.com/1fvGfz-nvQiiNLz8Y6c7Oiw50pia'
            : 'https://share.hsforms.com/1shZcUuRaQumhyyKArdUV3A50pia';
        this.resetList();
        if (this.readyToLoadGroups) {
          this.getGroupsList();
        }
      })
    );
    this.subscription.add(
      multiFilterService.address$.subscribe((address) => {
        this.addressInfo = address;
      })
    );
    this.subscription.add(
      multiFilterService.locationName$.subscribe((locationName) => {
        this.locationName = locationName;
      })
    );
    this.subscription.add(
      this.breakpointObserver.observe(['(max-width: 767px)', '(max-width: 1000px)', '(min-width: 1265px)']).subscribe(() => {
        this.isMobile = this.breakpointObserver.isMatched(['(max-width: 767px)']);
        this.isTablet = this.breakpointObserver.isMatched(['(max-width: 1000px)']);
        this.isLarge = this.breakpointObserver.isMatched(['(max-width: 1265px)']);
      })
    );
  }

  ngOnInit(): void {
    if (!this.route.snapshot.params.id) window.sessionStorage.clear();
    this.QPService.setAdminUrlToQPService();
    // this.resultsFor = this.QPService.getParams()?.resultsFor;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.filters.clearFilters();
    this.multiFilterService.setMultiFilter(null);
  }

  handleModalResponse(response: ModalResponse<any>): void {
    if (response) {
      switch (response.modalEmitAction) {
        case ModalEmitActions.DELETE_CONFIRMED:
          this.modalService.closeAll();
          this.archiveGroup(response.modalRequest);
          break;
        case ModalEmitActions.GO_TO_WEEKLY_GROUPS:
          if (!response.modalRequest.uuid) return;
          this.dynamicFilters = {
            studies: environment.faithDrivenEntrepreneurUuid,
            userRole: MainGroupRoles.ENTREPRENEUR.toLowerCase(),
            groupFormat: GroupFormat.WEEKLY,
          };
          this.QPService.resetParams(this.dynamicFilters);
          this.QPService.updateUrl();
          break;
        case ModalEmitActions.TRY_ENROLL:
          const groupUuid = response.modalRequest.payload;
          const modalRequest = new ModalRequest(ModalActions.VIEW_GROUP, groupUuid, null, { hasPreviousNavigation: this.hasPreviousNavigation });
          this.getGroupDetailsAndOpenModal(groupUuid, Modals.GROUP_DETAILS, modalRequest);
          break;
      }
    }
  }

  handlePathVariable(): void {
    const id = this.route.snapshot.params.id;
    // apply without having an account
    if (!this.userService.currentUser?.completedProfile && !!this.route.snapshot.queryParams?.apply) {
      this.modalsService.openModal(Modals.ADD_USER, new ModalRequest(ModalActions.MY_PROFILE_THEN_TRY_ENROLL, null, null, id));
    } else {
      // open group from share link
      const modalRequest = new ModalRequest(ModalActions.VIEW_GROUP, id, null, { hasPreviousNavigation: this.hasPreviousNavigation });
      this.getGroupDetailsAndOpenModal(id, Modals.GROUP_DETAILS, modalRequest);
    }
  }

  getGroupDetailsAndOpenModal(groupUuid: string, modalName: Modals, modalRequest: ModalRequest<any>): void {
    this.loading = true;
    this.groupService.getGroupDetails(groupUuid).subscribe(
      (res: ResponseObject<AdminGroupDetails>) => {
        this.router.navigate([], { queryParams: this.getQueryParamsAndLocation(res.response), replaceUrl: true });
        this.readyToLoadGroups = true;
        this.modalsService.openModal(modalName, modalRequest);
      },
      () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      }
    );
  }

  getQueryParamsAndLocation(groupDetails: AdminGroupDetails): { [key: string]: any } {
    const activeUnit: ViewGroupUnit = groupDetails.units.find((unit) => unit.active);
    let queryParams: { [key: string]: any } = {};
    if (Utils.objectHasValue(activeUnit.location) && activeUnit.meetingPlaceType !== GroupTypes.VIRTUAL) {
      this.addressInfo = activeUnit.location;
      queryParams = {
        address: activeUnit.location.address,
        lat: activeUnit.location.latitude,
        long: activeUnit.location.longitude,
      };
    } else if (activeUnit.mainlands[0]) {
      this.locationName = activeUnit.mainlands[0].region;
      queryParams = { mainland: activeUnit.mainlands[0].uuid };
    } else if (Utils.objectHasValue(activeUnit.primaryFacilitatorLocation)) {
      this.addressInfo = activeUnit.primaryFacilitatorLocation;
      this.cityState = `${this.addressInfo.city}, ${this.addressInfo.stateProvince}`;
      queryParams = {
        address: activeUnit.primaryFacilitatorLocation.address,
        lat: activeUnit.primaryFacilitatorLocation.latitude,
        long: activeUnit.primaryFacilitatorLocation.longitude,
      };
    }
    if (groupDetails.groupRole === GroupRoles.ENTREPRENEUR) queryParams.userRole = groupDetails.groupRole;
    else {
      queryParams.investorTypes = groupDetails.groupRole;
      queryParams.userRole = MainGroupRoles.INVESTOR;
    }
    return queryParams;
  }

  getGroupsList(): void {
    if (this.addressInfo) {
      this.getProximityGroups();
    } else {
      this.getAlphabetizedGroups();
    }
  }

  getAlphabetizedGroups(): void {
    this.loading = true;
    if (!this.alphabetizedGroupType && !(this.multiFilter.meetingType.getValue() as string).length) {
      this.alphabetizedGroupType = GroupTypes.IN_PERSON;
    }
    const payload = new GroupsListPayload(this.multiFilter, this.groups.aplhabetizedPage, this.mainlandUuid, this.alphabetizedGroupType);
    this.groupService.getGroupsAphabeticallyOrdered(payload).subscribe({
      next: (res: ResponseObject<GroupsResponse>) => {
        if (
          ((this.multiFilter.meetingType.getValue() as string).includes(GroupTypes.IN_PERSON) ||
            !(this.multiFilter.meetingType.getValue() as string).length) &&
          (this.alphabetizedGroupType === GroupTypes.IN_PERSON || this.alphabetizedGroupType === null)
        ) {
          this.groups.alphabetized = [...this.groups.alphabetized, ...res.response.objects.map((group) => new GroupCard(group))];
          this.groups.aplhabetizedPage++;
          this.loading = false;
          if (!res.response.objects.length || res.response.objects.length < 10) {
            this.groups.aplhabetizedPage = 0;
            this.alphabetizedGroupType = GroupTypes.VIRTUAL;
            this.getAlphabetizedGroups();
          }
        } else {
          this.groups.alphabetizedOnline = [...this.groups.alphabetizedOnline, ...res.response.objects.map((group) => new GroupCard(group))];
          this.groups.aplhabetizedPage++;
          this.loading = false;
          if (!this.groups.alphabetized.length && !this.groups.alphabetizedOnline.length && !this.groups.hasOpenGroups) {
            this.getOtherOnlineGroups();
          }
        }
      },
      error: () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      },
    });
  }

  getProximityGroups(scrollToOnline: boolean = false): void {
    if (!this.isActiveCategorySuitableForRequest && this.groups.isInPersonActive) {
      this.groups.activateNextCategory();
    }
    if (!this.isActiveCategorySuitableForRequest) {
      this.loading = false;
      return;
    }
    this.loading = true;
    let getGroupsRequest;
    const payload = new OldProximityGroupsListPayload(
      this.multiFilter,
      this.groups.oldActiveCategory,
      this.addressInfo,
      this.dropdowns,
      this.mainlandUuid
    );
    getGroupsRequest = this.groupService.getGroupsProximityOrdered(payload);
    getGroupsRequest.subscribe({
      next: (res: ResponseObject<GroupsResponse>) => {
        this.groups.addGroupsToCategory(res.response.objects);

        if (!res.response.objects.length || this.shouldLoadMore) {
          this.groups.activateNextCategory();
          if (this.groups.oldActiveCategory?.categoryName === GROUP_CATEGORY.OTHER_ONLINE && !this.groups.totalProximityItems.length) {
            this.getOtherOnlineGroups();
          } else {
            this.getProximityGroups(scrollToOnline);
          }
          return;
        } else if (scrollToOnline) {
          this.scrollToOnlineGroups();
        }
        this.loading = false;
      },
      error: () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      },
    });
  }

  getOtherOnlineGroups() {
    const payload = new GroupsListPayload(
      this.multiFilter,
      this.groups.otherOnlinePage,
      this.mainlandUuid,
      this.alphabetizedGroupType,
      true,
      this.dropdowns
    );
    this.groupService.getGroupsAphabeticallyOrdered(payload).subscribe({
      next: (res: ResponseObject<GroupsResponse>) => {
        this.groups.otherOnlineGroups = [...this.groups.otherOnlineGroups, ...res.response.objects.map((group) => new GroupCard(group))];
        this.groups.otherOnlinePage++;
        this.loading = false;
        if (res.response.objects.length === 0 || res.response.objects.length < 10) {
          this.scrollOnOtherOnlineGroups = false;
        }
      },
      error: () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      },
    });
  }

  openGroup(uuid: string): void {
    this.modalsService.openModal(Modals.GROUP_DETAILS, new ModalRequest(ModalActions.VIEW_GROUP, uuid));
  }

  openMultiFilter(): void {
    this.modalsService.openModal(Modals.MULTI_FILTER, new ModalRequest<any>(ModalActions.OPEN_MULTI_FILTER, null, null, { fromAdmin: false }));
  }

  onScrollDown(): void {
    if (this.groups.oldActiveCategory && this.multiFilter && !this.loading) {
      this.getGroupsList();
    }
  }

  onScrollOtherOnlineGroups() {
    if (this.scrollOnOtherOnlineGroups) {
      this.getOtherOnlineGroups();
    }
  }

  resetList(): void {
    this.groups.setEmptyLists();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  scrollToOnlineGroups(): void {
    if (this.groups.isInPersonActive || this.groups.isOnlineRegionalActive) {
      this.getProximityGroups(true);
      return;
    } else this.onlineAudience.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  archiveGroup(modalRequest: ModalRequest<any>): void {
    this.loading = true;
    this.adminGroupService.archiveGroup(modalRequest.uuid).subscribe({
      next: () => {
        this.resetList();
        this.getGroupsList();
        this.toastr.success(...ToastrMessages.GROUP_ARCHIVED);
        this.analyticsService.logClickEvent(AnalyticsEvent.ARCHIVE_GROUP);
        this.loading = false;
      },
      error: () => {
        this.toastr.error(...ToastrMessages.GROUP_ARCHIVE_ERROR);
        this.loading = false;
      },
    });
  }

  get showJumpButton(): boolean {
    return this.groups.hasRegionalGroups && (this.multiFilter.meetingType.getValue() as string[]).length !== 1;
  }

  get showGroupsInYourAreaHeader(): boolean {
    return !!this.groups.inPersonRegional.items.length || !!this.groups.alphabetized.length;
  }

  get shouldLoadMore(): boolean {
    if (!this.groups.oldActiveCategory) return false;
    switch (this.groups.oldActiveCategory.categoryName) {
      case GROUP_CATEGORY.IN_PERSON_REGIONAL:
        return this.groups.inPersonRegional.items.length < 10;
      case GROUP_CATEGORY.ONLINE_REGIONAL:
        return this.groups.onlineRegional.items.length + this.groups.inPersonRegional.items.length < 10;
      case GROUP_CATEGORY.INDUSTRY:
        return this.groups.onlineRegional.items.length + this.groups.inPersonRegional.items.length + this.groups.industry.items.length < 10;
      case GROUP_CATEGORY.GLOBAL:
        return this.groups.totalProximityItems.length < 10;
    }
  }

  get isActiveCategorySuitableForRequest(): boolean {
    if (!this.groups.oldActiveCategory || !this.multiFilter) return false;

    const meetingTypesArray = this.multiFilter.meetingType.getValue() as string[];
    // const inPersonOnly = meetingTypesArray.includes(GroupTypes.IN_PERSON) && meetingTypesArray.length === 1;
    // if (inPersonOnly && !this.groups.isInPersonActive) return false;

    const onlineOnly = meetingTypesArray.includes(GroupTypes.VIRTUAL) && meetingTypesArray.length === 1;
    if (onlineOnly && this.groups.isInPersonActive) return false;
    return true;
  }
}
