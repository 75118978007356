<div class="card-container" *ngFor="let item of historyItems">
  <div class="d-flex justify-content-between mb-2">
    <div [matTooltip]="item.user.email" matTooltipPosition="above">
      <i class="fas fa-user mr-2 primary-color"></i>{{ item.user.firstName }} {{ item.user.lastName }}
    </div>
    <div><i class="fas fa-calendar-day mr-2"></i>{{ item.date | date : "MMM dd', 'yyyy', ' h:mm a" }}</div>
  </div>

  <div class="mb-2" *ngFor="let fieldValue of item.fieldValueList">
    <div class="font-16 font-weight-bold">{{ fieldValue.fieldName | titlecase | enumToString }}</div>
    <div class="history-item-container">
      <div>
        <span *ngIf="fieldValue.oldValue; else noOldValue" class="strikethrough">{{ fieldValue.oldValue }} </span>
        <ng-template #noOldValue> <span>&quot; &quot;</span> </ng-template>
      </div>
      <div><i class="fas fa-long-arrow-alt-right fa-lg text-gray mx-2"></i></div>
      <div>
        <span *ngIf="fieldValue.newValue; else noNewValue">{{ fieldValue.newValue }}</span>
        <ng-template #noNewValue> <span>&quot; &quot;</span> </ng-template>
      </div>
    </div>
  </div>
</div>
