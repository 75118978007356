import { Component, Input, OnInit } from '@angular/core';
import { GroupFormat } from 'src/app/_core/constants/AdminGroups';
import { ModalActions, Modals } from 'src/app/_core/constants/Modals';
import { AdminGroupResume } from 'src/app/_core/models/AdminFacilitators';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-group-resume-admin',
  templateUrl: './group-resume-admin.component.html',
  styleUrls: ['./group-resume-admin.component.scss'],
})
export class GroupResumeAdminComponent implements OnInit {
  @Input() group: AdminGroupResume = null;
  groupFormat = GroupFormat;

  // prettier-ignore
  constructor(
    private modalsService: ModalsService
  ) {}

  ngOnInit(): void {}

  goToGroupDetails(): void {
    this.openModal(Modals.VIEW_GROUP, ModalActions.VIEW_GROUP, this.group.groupUuid);
  }

  openModal<T = void>(modalName: Modals, scope: ModalActions, uuid: string = null, index: number = null, payload: T = null): void {
    this.modalsService.openModal(modalName, new ModalRequest<T>(scope, uuid, index, payload));
  }
}
