<div class="d-flex">
  <div class="d-flex flex-column">
    <img
      class="logo-image"
      [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
      [lazyLoad]="userInfo?.profileImageUrl"
    />
    <div class="d-flex mt-2 ml-2">
      <i class="fab fa-linkedin-in mr-2 pointer" (click)="externalNavigate(userInfo?.linkedIn)"></i>
    </div>
  </div>
  <div class="ml-4 ml-xs-0">
    <h2 class="mb-0">{{ userInfo?.firstName + ' ' + userInfo?.lastName }}</h2>
    <p class="primary-color uppercase font-weight-bold mb-0">{{ userInfo?.title }}</p>
    <h3 class="mt-3 mb-0">Email</h3>
    <div class="bold">{{ userInfo?.email }}</div>
    <h3 class="mt-3 mb-0">Address</h3>
    <div class="bold">{{ getAddressString() }}</div>
    <h3 class="mt-3 mb-0" *ngIf="userInfo?.userType || userInfo?.userSubType || userInfo?.secondaryUserType || userInfo?.secondaryUserSubType">
      Primary User Type and Secondary User Type
    </h3>
    <div class="bold">
      <span *ngIf="userInfo.userType">{{ userRoles[userInfo.userType] }}</span
      ><span *ngIf="userInfo.userSubType"> ({{ userRoles[userInfo.userSubType] }})</span>
      <span *ngIf="(userInfo.userType || userInfo.userSubType) && (userInfo.secondaryUserType || userInfo.secondaryUserSubType)">, </span>
      <span *ngIf="userInfo.secondaryUserType">{{ userRoles[userInfo.secondaryUserType] }}</span
      ><span *ngIf="userInfo.secondaryUserSubType"> ({{ userRoles[userInfo.secondaryUserSubType] }})</span>
    </div>
  </div>
</div>
