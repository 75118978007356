import { Component, OnInit } from '@angular/core';
import { ModalEmitActions } from 'src/app/_core/constants/Modals';
import { CreateUpdateUserRequest } from 'src/app/_core/models/AdminUsers';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-upgrade-user-confirmation',
  templateUrl: './upgrade-user-confirmation.component.html',
  styleUrls: ['./upgrade-user-confirmation.component.scss'],
})
export class UpgradeUserConfirmationComponent implements OnInit {
  modalRequest: ModalRequest<CreateUpdateUserRequest>;

  constructor(private modalService: ModalsService) {}

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
  }

  close(): void {
    this.modalService.closeModal();
  }

  emit(overwrite: boolean): void {
    this.modalRequest.payload.overrideUserInfo = overwrite;
    this.modalService.emitResponse(new ModalResponse(true, this.modalRequest, ModalEmitActions.UPGRADE_USER_CONFIRMATION));
    this.close();
  }
}
