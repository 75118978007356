import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdminCohortsService } from 'src/app/_core/api/admin-cohorts.service';
import { ModalActions } from 'src/app/_core/constants/Modals';
import { ServerError } from 'src/app/_core/constants/ServerErrors';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import Utils from 'src/app/_core/helpers/Utils';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { COHORT_TYPE, CohortYears, Months } from '../../tags.const';
import { AdminCohort } from '../../tags.model';

@Component({
  selector: 'app-cohort-setup',
  templateUrl: './cohort-setup.component.html',
  styleUrls: ['./cohort-setup.component.scss'],
})
export class CohortSetupComponent implements OnInit {
  years = CohortYears;
  months = Months;
  types = COHORT_TYPE;
  cohort: AdminCohort;
  cohortForm: UntypedFormGroup;
  submitted: boolean;

  constructor(
    private modalService: ModalsService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private adminCohortService: AdminCohortsService
  ) {}

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }
  ngOnInit(): void {
    this.cohort = this.modalService.params.payload;
    this.createForm();
  }

  close(): void {
    this.modalService.closeAll();
  }

  createForm(): void {
    this.cohortForm = this.formBuilder.group({
      year: [null, [Validators.required]],
      month: [null, Validators.required],
      type: [null, [Validators.required]],
      hubspotGroupName: [null, [Validators.required, Validators.maxLength(255)]],
      hubspotGroupStatus: [null, [Validators.required, Validators.maxLength(255)]],
    });

    this.cohort && this.prefillForm();
  }

  prefillForm(): void {
    this.cohortForm.patchValue({
      year: this.cohort.year,
      month: this.cohort.month,
      type: this.cohort.type,
      hubspotGroupName: this.cohort.hubspotGroupName,
      hubspotGroupStatus: this.cohort.hubspotGroupStatus,
    });
  }

  submit(): void {
    if (this.cohortForm.pristine && this.cohortForm.untouched) {
      Utils.clearErrorsOfFormGroup(this.cohortForm);
      this.toastr.info(...ToastrMessages.NO_CHANGES);
      return;
    }

    this.cohortForm.markAllAsTouched();
    Utils.checkValueAndValidityOfFormGroup(this.cohortForm);

    if (this.cohortForm.invalid) {
      this.toastr.error(...ToastrMessages.MISSING_REQUIRED_FIELDS);
      return;
    }

    const newCohort: AdminCohort = this.cohortForm.getRawValue();
    if (this.cohort) {
      newCohort.uuid = this.cohort.uuid;
      newCohort.noOfGroups = this.cohort.noOfGroups;
    }
    this.adminCohortService.createUpdateCohort(newCohort).subscribe({
      next: (res) => {
        !newCohort.uuid && (newCohort.uuid = res.response);
        const titleCaseType = newCohort.type.charAt(0).toUpperCase() + newCohort.type.slice(1, newCohort.type.length).toLowerCase();
        newCohort.name = `[${newCohort.year}-${newCohort.month}-${titleCaseType}]`;

        if (this.cohort) {
          this.toastr.success(...ToastrMessages.COHORT_UPDATED);
        } else {
          this.toastr.success(...ToastrMessages.COHORT_CREATED);
        }
        this.emitTrue(newCohort);
      },
      error: (err) => {
        if (err === ServerError.ALREADY_EXISTS) {
          this.toastr.error(...ToastrMessages.COHORT_ALREADY_EXISTS);
        } else {
          this.toastr.error(...ToastrMessages.BASIC_ERROR);
        }
      },
    });
  }

  emitTrue(newCohort: AdminCohort): void {
    const modalRequest = new ModalRequest(ModalActions.EDIT_COHORT, null, null, newCohort);
    const response = new ModalResponse(true, modalRequest);
    this.modalService.emitResponse(response);
    this.close();
  }

  get year() {
    return this.cohortForm.controls.year;
  }

  get month() {
    return this.cohortForm.controls.month;
  }

  get type() {
    return this.cohortForm.controls.type;
  }

  get hubspotGroupName() {
    return this.cohortForm.controls.hubspotGroupName;
  }

  get hubspotGroupStatus() {
    return this.cohortForm.controls.hubspotGroupStatus;
  }
}
