<div class="content" *ngIf="group">
  <div
    class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3"
    [ngClass]="selectedUnit.isNew ? 'bg-main' : 'bg-primary'"
  >
    <h1 class="mb-0 text-white">{{ group.name | truncate : 50 }}</h1>
    <a (click)="close()"><i class="fas fa-2x fa-times pointer text-white"></i></a>
  </div>

  <mat-tab-group
    [(selectedIndex)]="selectedTab"
    (animationDone)="showCarousel()"
    (selectedIndexChange)="changeTab()"
    [class.hide-tabs]="group.units.length < 2"
  >
    <ng-container *ngFor="let unit of group.units; index as i">
      <mat-tab [label]="unit.isNew ? 'Foundation Study' : 'Ongoing Study'" class="font-17">
        <div class="px-3 px-md-5 w-100">
          <!-- EDIT & SHARE -->
          <div class="d-flex justify-content-between flex-wrap py-1 pt-3">
            <div class="d-flex align-items-center">
              <button
                *ngIf="group.status !== statuses.ARCHIVED"
                mat-button
                [disabled]="isFacilitator && !isAdmin && group.state === groupState.CLOSED"
                class="uppercase"
                color="primary"
                (click)="goToGroupSetup()"
              >
                <i *ngIf="!(isFacilitator && !isAdmin && group.state === groupState.CLOSED)" class="fas fa-pencil-alt mr-2"></i
                ><i *ngIf="isFacilitator && !isAdmin && group.state === groupState.CLOSED" class="fas fa-lock mr-2"></i>Edit
              </button>
              <button
                *ngAccess-admin
                mat-button
                [color]="group.status === statuses.ARCHIVED ? 'accent' : 'warn'"
                class="pr-0"
                [class.pl-3]="group.status !== statuses.ARCHIVED"
                (click)="toggleArchive()"
              >
                <span class="d-flex align-items-center">
                  <ng-container *ngIf="group.status === statuses.ARCHIVED; else archiveBtn">
                    <i class="fas fa-arrow-alt-circle-up mr-2"></i>Restore
                  </ng-container>
                  <ng-template #archiveBtn> <i class="far fa-trash-alt mr-2"></i>Archive </ng-template>
                </span>
              </button>
            </div>
            <div *ngIf="group.status !== statuses.ARCHIVED" class="d-flex flex-wrap align-items-center ml-3">
              <h2 class="primary-color mb-0 mr-2">Share this group</h2>
              <div class="d-flex flex-nowrap align-items-center">
                <a class="mx-2 pointer" (click)="openWindow(socialMedia.FACEBOOK)"><i class="fab fa-facebook-f fa-lg"></i></a>
                <a class="mx-2 pointer" (click)="openWindow(socialMedia.LINKEDIN)"><i class="fab fa-linkedin-in fa-lg"></i></a>
                <a class="mx-2 pointer" (click)="openWindow(socialMedia.TWITTER)"><i class="fab fa-twitter fa-lg"></i></a>
                <div class="pointer mx-2" (click)="copyToClipboard()">
                  <i class="fas fa-link fa-lg"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- About -->
          <app-view-group-about-info [group]="selectedUnit" [companySizes]="group.companySizes"></app-view-group-about-info>

          <!-- Admin view -->
          <ng-container *ngIf="currentUrl.indexOf(urls.GROUPS_I_LEAD) < 0">
            <app-view-group-participants
              [group]="group"
              [selectedUnit]="selectedUnit"
              (changeType)="changeParticipantType($event.changeType, getParticipantsListByStatus($event.prevType, selectedUnit), $event.prevType)"
            ></app-view-group-participants>
            <ng-container *ngTemplateOutlet="facilitators"></ng-container>
            <ng-container *ngTemplateOutlet="churches"></ng-container>
            <ng-container *ngTemplateOutlet="partners"></ng-container>
            <ng-container *ngTemplateOutlet="study"></ng-container>
            <app-view-group-attendance *ngIf="selectedUnit.meetings.length" [group]="group" [selectedUnit]="selectedUnit"></app-view-group-attendance>
          </ng-container>

          <!-- Facilitator view -->
          <ng-container *ngIf="currentUrl.indexOf(urls.GROUPS_I_LEAD) >= 0">
            <app-view-group-participants
              [group]="group"
              [selectedUnit]="selectedUnit"
              (changeType)="
                changeParticipantType($event.changeType, getParticipantsListByStatus($event.changeType.newType, selectedUnit), $event.prevType)
              "
            ></app-view-group-participants>
            <app-view-group-attendance *ngIf="selectedUnit.meetings.length" [group]="group" [selectedUnit]="selectedUnit"></app-view-group-attendance>
            <ng-container *ngTemplateOutlet="study"></ng-container>
            <ng-container *ngTemplateOutlet="facilitators"></ng-container>
            <ng-container *ngTemplateOutlet="churches"></ng-container>
          </ng-container>
        </div>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
  <div class="loading-spinner" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>

<ng-template #study>
  <div class="custom-template mb-3">
    <h1 class="primary-color">Study discussed</h1>
    <app-study-section-modal [study]="selectedUnit.study"></app-study-section-modal>
  </div>
</ng-template>

<ng-template #facilitators>
  <div class="custom-container">
    <h1 class="primary-color">Facilitators of the Group</h1>
    <app-facilitator-section-modal
      *ngIf="showCarousels"
      [facilitators]="selectedUnit.facilitators"
      [emailsTurnedOn]="selectedUnit.areConfirmationEmailsOn"
      [isArchived]="group.status === statuses.ARCHIVED"
    ></app-facilitator-section-modal>
  </div>
</ng-template>

<ng-template #churches>
  <div class="custom-container" *ngIf="group.churches.length">
    <h1 class="primary-color">Churches of the Group</h1>
    <app-facilitator-section-modal [churches]="group.churches"></app-facilitator-section-modal>
  </div>
</ng-template>

<ng-template #partners>
  <div class="custom-container" *ngIf="group.partners.length">
    <h1 class="primary-color">Partner Organizations</h1>
    <app-facilitator-section-modal [churches]="group.partners"></app-facilitator-section-modal>
  </div>
</ng-template>
