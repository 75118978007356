import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import StorageHelper from '../helpers/Storage.helper';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // prettier-ignore
  constructor(
    private http: HttpClient
  ) {
  }

  login(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const body = new HttpParams()
      .set('username', email.trim())
      .set('password', encodeURIComponent(password.trim()))
      .set('grant_type', 'password')
      .set('scope', 'message:write')
      .set('platform', 'GroupFinder');

    return this.http.post(`${environment.authServer}/oauth/token`, body.toString(), { headers });
  }

  refreshToken(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const body = new HttpParams()
      .set('username', StorageHelper.getUsername())
      .set('refresh_token', StorageHelper.getRefreshToken())
      .set('grant_type', 'refresh_token');
    return this.http.post(`${environment.authServer}/oauth/token`, body.toString(), { headers });
  }

  forgotPassword(email): Observable<any> {
    return this.http.post(`${environment.server}/v1/users/forgot-password?email=${email.trim()}`, null);
  }

  resetPassword(token, newPassword): Observable<any> {
    return this.http.post(`${environment.authServer}/reset-password`, { token, newPassword });
  }

  checkUserEmail(email: string): Observable<any> {
    return this.http.get(`${environment.authServer}/user/check-email/${email}`, { params: { platform: 'GROUPFINDER' } });
  }

  getMattermostLogin(email): Observable<any> {
    const headers = new HttpHeaders().set('apiKey', '611ADE782A6E4A57A378A6FA26491');
    return this.http.get(`${environment.authServer}/chat-login?email=${email}`, { headers });
  }
}
