<div class="d-flex flex-column justify-content-between h-100">
  <a class="text-right" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center">
    <h3 class="font-weight-bold">This {{ item }} is part of an active group!</h3>

    <div class="gray-color font-weight-bold px-4">
      <ng-container [ngSwitch]="modalRequest.scope">
        <ng-container *ngSwitchCase="modalActions.DOWNGRADE_FACILITATOR_SPECIAL_CASE">
          Before you downgrade this {{ item }} please select another {{ item }} that will take its place in the currently active groups.
        </ng-container>
        <ng-container *ngSwitchDefault>
          Before you delete this {{ item }} please select another {{ item }} that will take its place in the currently active groups.
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="d-flex justify-content-between my-3 mx-4">
    <button mat-flat-button class="mat-medium-button" color="primary" (click)="close()">Cancel</button>
    <button mat-flat-button class="mat-medium-button" color="warn" (click)="emit(true)">Go to groups</button>
  </div>
</div>
