<div class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
  <h1 class="mb-0">{{ cohort ? cohort.name : 'Add new cohort' }}</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>

<div class="h-computed px-3 px-md-5 mt-4 d-flex flex-column justify-content-between">
  <form [formGroup]="cohortForm">
    <!-- Year -->
    <div class="admin-input">
      <label for="year" class="label">Year <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="year">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }} </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="(year.invalid && (year.dirty || year.touched)) || (year.invalid && submitted)" class="error">
        <app-validation-message [control]="year" [controlName]="'year'"> </app-validation-message>
      </mat-error>
    </div>

    <!-- Month -->
    <div class="admin-input">
      <label for="month" class="label">Month <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="month">
          <mat-option *ngFor="let month of months" [value]="month">{{ month }} </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="(month.invalid && (month.dirty || month.touched)) || (month.invalid && submitted)" class="error">
        <app-validation-message [control]="month" [controlName]="'month'"> </app-validation-message>
      </mat-error>
    </div>

    <!-- Type -->
    <div class="admin-input">
      <label for="type" class="label">Type <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of types | objectIteration" [value]="type">{{ types[type] }} </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="(type.invalid && (type.dirty || type.touched)) || (type.invalid && submitted)" class="error">
        <app-validation-message [control]="type" [controlName]="'type'"> </app-validation-message>
      </mat-error>
    </div>

    <!-- Hubspot Name -->
    <div class="admin-input">
      <label for="hubspotGroupName" class="label">Hubspot Group Name <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="hubspotGroupName" [maxlength]="255" />
      </mat-form-field>
      <mat-error
        *ngIf="(hubspotGroupName.invalid && (hubspotGroupName.dirty || hubspotGroupName.touched)) || (hubspotGroupName.invalid && submitted)"
        class="error"
      >
        <app-validation-message [control]="hubspotGroupName" [controlName]="'hubspotGroupName'"> </app-validation-message>
      </mat-error>
    </div>

    <!-- Hubspot Status -->
    <div class="admin-input">
      <label for="hubspotGroupStatus" class="label">Hubspot Group Status <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="hubspotGroupStatus" [maxlength]="255" />
      </mat-form-field>
      <mat-error
        *ngIf="(hubspotGroupStatus.invalid && (hubspotGroupStatus.dirty || hubspotGroupStatus.touched)) || (hubspotGroupStatus.invalid && submitted)"
        class="error"
      >
        <app-validation-message [control]="hubspotGroupStatus" [controlName]="'hubspotGroupStatus'"> </app-validation-message>
      </mat-error>
    </div>
  </form>

  <div class="d-flex justify-content-between my-4 pb-4">
    <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="close()">Cancel</button>
    <button mat-flat-button class="filter-button uppercase" color="accent" (click)="submit()">Save</button>
  </div>
</div>
