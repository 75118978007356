import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminStudiesService } from 'src/app/_core/api/admin-studies.service';
import { ModalActions, ModalEmitActions, Modals } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import { AdminGroupResume } from 'src/app/_core/models/AdminFacilitators';
import { AdminStudyDetails, ViewStudyGroupBody } from 'src/app/_core/models/AdminStudies';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { MediaModel, ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import DateUtils from '../../../../../_core/helpers/DateUtils';

@Component({
  selector: 'app-view-study',
  templateUrl: './view-study.component.html',
  styleUrls: ['./view-study.component.scss'],
})
export class ViewStudyComponent implements OnInit, OnDestroy {
  modals = Modals;
  modalActions = ModalActions;
  modalEmitActions = ModalEmitActions;
  studyInfo: AdminStudyDetails;
  groups: AdminGroupResume[] = [];
  modalRequest: ModalRequest;
  viewStudyGroupsBody: ViewStudyGroupBody = new ViewStudyGroupBody();
  isRequestEmpty: boolean = false;
  breakpointSubscription: Subscription;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private adminStudiesService: AdminStudiesService,
    private toastr: ToastrService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<ViewStudyComponent>
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vh')
        : this.dialogRef.updateSize('75vw', '100vh');
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService?.params;
    this.viewStudyGroupsBody.uuid = this.modalRequest.uuid;
    this.getStudyDetails();
    this.getGroupsWithStudy();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  getStudyDetails(): void {
    this.adminStudiesService.getStudyDetails(this.modalRequest.uuid).subscribe(
      (res: ResponseObject<AdminStudyDetails>) => {
        this.studyInfo = res.response;
      },
      (error) => {
        switch (error) {
          default:
            this.toastr.error(...ToastrMessages.BASIC_ERROR);
            break;
        }
      }
    );
  }

  getGroupsWithStudy() {
    this.adminStudiesService.getGroupsWithStudy(this.viewStudyGroupsBody).subscribe(
      (res: ResponseObject<AdminGroupResume[]>) => {
        const groupsResume = res.response;
        if (!groupsResume.length) {
          this.isRequestEmpty = true;
          return;
        }
        groupsResume.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.groups.push(...groupsResume);
        this.viewStudyGroupsBody.page++;
      },
      (error) => {
        switch (error) {
          default:
            this.toastr.error(...ToastrMessages.BASIC_ERROR);
            break;
        }
      }
    );
  }

  onScrollDown(): void {
    if (this.isRequestEmpty) return;
    this.getGroupsWithStudy();
  }

  openModal(modalName: Modals, modalAction: ModalActions, payload: any = null): void {
    this.modalService.openModal(modalName, { ...this.modalRequest, scope: modalAction, payload });
  }

  openMediaModal(): void {
    if (this.studyInfo.trailerLink) {
      this.modalService.openModal(Modals.MEDIA, new MediaModel(this.studyInfo.trailerLink, this.studyInfo.name, null));
    } else {
      this.toastr.info(...ToastrMessages.NO_TRAILER_LINK);
    }
  }

  emit(modalEmitAction: ModalEmitActions): void {
    const response = new ModalResponse(true, this.modalRequest, modalEmitAction);
    this.modalService.emitResponse(response);
  }

  close(): void {
    this.modalService.closeAll();
  }
}
