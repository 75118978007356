<div class="d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
  <h1 class="mb-0 modal-header-name">Edit location</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>

<div class="px-3 px-md-5 mt-4">
  <img class="profilePic" [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'" [lazyLoad]="locationPhoto?.path" />
  <h5 class="primary-color pointer mb-0 mt-1 font-weight-bold" (click)="handleImageUpload()">
    <i class="fas mr-1" [ngClass]="locationPhoto?.path ? 'fa-trash' : 'fa-upload'"></i>
    {{ locationPhoto?.path ? 'DELETE PICTURE' : 'UPLOAD PICTURE' }}
  </h5>
  <div>
    <label class="label">LOCATION NAME <span class="primary-color">*</span></label>
    <mat-form-field appearance="outline" class="w-100">
      <input id="firstName" matInput [(ngModel)]="modalRequestLocation.name" [disabled]="true" />
    </mat-form-field>
  </div>
</div>
