import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ModalActions, ModalEmitActions } from 'src/app/_core/constants/Modals';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import { ParticipantStatus } from 'src/app/_core/models/AdminGroups';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { FacilitatorSendEmail } from 'src/app/_core/models/User';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { DECLINED_EMAIL, Frequencies, GroupFormat, GroupTypes, ParticipantType } from './../../../_core/constants/AdminGroups';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent implements OnInit {
  modalRequest: ModalRequest<any>;
  participant: ParticipantStatus;
  groupUnits: any;
  modalActions = ModalActions;
  groupFormats = GroupFormat;
  frequency = Frequencies;
  meetingTypes = GroupTypes;
  declinedEmailOptions = DECLINED_EMAIL;
  breakpointSubscription: Subscription;
  periodOfTime: string;
  periofOfTimeFacilitators: string;
  participantTypes = ParticipantType;
  isCancelApplicationAction: boolean = false;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private dialogRef: MatDialogRef<SendEmailComponent>,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('95vw', 'auto')
        : this.dialogRef.updateSize('580px', 'auto');
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.isCancelApplicationAction =
      this.modalRequest.payload.prevType === this.participantTypes.PENDING && this.modalRequest.scope === this.modalActions.WITHDRAWN_PARTICIPANT
        ? true
        : false;
    this.setValues();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  closeAll(): void {
    this.modalService.closeAll();
  }

  setValues(): void {
    switch (this.modalRequest.scope) {
      case ModalActions.SEND_EMAIL_DECLINE_PARTICIPANT:
      case ModalActions.SEND_EMAIL_APPROVE_PARTICIPANT:
        this.participant = this.modalRequest.payload;
        this.getPeriodOfTime();
        break;
      case ModalActions.SEND_EMAIL_NEW_FACILITATOR:
        this.groupUnits = this.modalRequest.payload.groupUnits;
        break;
      case ModalActions.PENDING_PARTICIPANT:
      case ModalActions.WITHDRAWN_PARTICIPANT:
        this.participant = this.modalRequest.payload;
      default:
        break;
    }
  }

  emit(confimation: boolean): void {
    switch (this.modalRequest.scope) {
      case ModalActions.SEND_EMAIL_DECLINE_PARTICIPANT:
        this.modalService.emitResponse(new ModalResponse<any>(confimation, this.modalRequest, ModalEmitActions.PARTICIPANT_DECLINED_SEND_EMAIL));
        break;
      case ModalActions.SEND_EMAIL_NEW_FACILITATOR:
        this.modalService.emitResponse(new ModalResponse<any>(confimation, this.modalRequest, ModalEmitActions.NEW_FACILITATOR_SEND_EMAIL));
        break;
      case ModalActions.SEND_EMAIL_APPROVE_PARTICIPANT:
        this.modalService.emitResponse(new ModalResponse<any>(confimation, this.modalRequest, ModalEmitActions.PARTICIPANT_APPROVED_SEND_EMAIL));
        break;
      case ModalActions.PENDING_PARTICIPANT:
        this.modalService.emitResponse(new ModalResponse<any>(confimation, this.modalRequest, ModalEmitActions.PARTICIPANT_PENDING));
        break;
      case ModalActions.WITHDRAWN_PARTICIPANT:
        this.modalService.emitResponse(new ModalResponse<any>(confimation, this.modalRequest, ModalEmitActions.PARTICIPANT_WITHDRAWN));
        break;
      default:
        break;
    }
    this.close();
  }

  emitDeclineParticipants() {
    if (!this.participant.declinedEmailOption) {
      this.participant.declinedEmailOption = DECLINED_EMAIL.NO_EMAIL;
    }
    this.emit(true);
  }

  mutipleNewFacilitators(): boolean {
    let facilitators = [];
    this.groupUnits.forEach((unit) => (facilitators = [...facilitators, ...unit.selectedFacilitators]));
    return facilitators.filter((facilitator: FacilitatorSendEmail) => facilitator.newFacilitator).length > 1;
  }

  getPeriodOfTime() {
    if (this.participant.freeBooks === undefined) {
      this.periodOfTime = '';
      return;
    } else if (this.participant.before3days !== undefined) {
      this.periodOfTime = '3 days';
      return;
    } else if (this.participant.before7days !== undefined) {
      this.periodOfTime = '1 week';
      return;
    }
    this.periodOfTime = '3 weeks';
  }

  soonerThan(daysBefore: number, startDate: Date): boolean {
    return DateUtils.soonerThan(startDate, daysBefore);
  }

  handleDeclinedEmail(checked: boolean, emailOption: DECLINED_EMAIL): void {
    this.participant.declinedEmailOption = checked ? emailOption : null;
  }
}
