import { Clipboard } from '@angular/cdk/clipboard';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/_core/api/group.service';
import {
  Frequencies,
  GroupFormat,
  GroupFormating,
  GroupRoles,
  GroupStates,
  GroupTypes,
  MainGroupRoles,
  ParticipantType,
} from 'src/app/_core/constants/AdminGroups';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { ModalActions, ModalEmitActions, Modals } from 'src/app/_core/constants/Modals';
import { ServerError } from 'src/app/_core/constants/ServerErrors';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import { Urls } from 'src/app/_core/constants/Urls';
import { UserStatusEnum } from 'src/app/_core/constants/UserEnums';
import { GroupDetails, GroupDetailsUnit } from 'src/app/_core/models/AdminGroups';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { AlreadyApprovedGroups } from 'src/app/_core/models/Groups';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { User } from 'src/app/_core/models/User';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { QueryParamsService } from 'src/app/_core/services/query-params.service';
import { UserService } from 'src/app/_core/services/user.service';
import { RedirectReason } from 'src/app/auth/auth.model';
import { environment } from '../../../../../environments/environment';
import { SocialMedia } from '../../../../_core/constants/SocialMedia';
import DateUtils from '../../../../_core/helpers/DateUtils';
import Utils from '../../../../_core/helpers/Utils';
import { ModalsService } from '../../../../_core/services/modals.service';
import { AdminUserService } from './../../../../_core/api/admin-user.service';
import { GroupApplicationService } from './../../../../_core/services/group-application.service';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
})
export class GroupDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('applyButton') applyButton;
  @ViewChild('showSuccessMessage') showSuccessMessage;
  dateUtils = DateUtils;
  socialMedia = SocialMedia;
  participantType = ParticipantType;
  groupTypes = GroupTypes;
  groupType = GroupFormat;
  groupFormat = GroupFormating;
  frequencies = Frequencies;
  modals = Modals;
  modalActions = ModalActions;
  groupState = GroupStates;
  groupUserType = GroupRoles;
  group: GroupDetails;
  activeUnit: GroupDetailsUnit;
  modalSubscription: Subscription;
  modalRequest: ModalRequest<any>;
  userSubscription: Subscription;
  currentUser: User;
  subscriptions: Subscription = new Subscription();
  alreadyApprovedGroups: AlreadyApprovedGroups[];
  fromShareLink: boolean;
  isAdmin: boolean;
  isApply: boolean;
  isLoading: boolean;
  hasEnded: boolean;
  hasStarted: boolean;
  canApply: boolean;
  shareLink: string;
  groupUuid: string;
  groupLanguages: string;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private dialogRef: MatDialogRef<GroupDetailsComponent>,
    private breakpointObserver: BreakpointObserver,
    private groupService: GroupService,
    private toastr: ToastrService,
    private userService: UserService,
    private router: Router,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private adminUserService: AdminUserService,
    private analyticsService: AnalyticsService,
    private groupApplicationService: GroupApplicationService,
    private analytics: AnalyticsService,
    private QPService: QueryParamsService
  ) {
    this.subscriptions.add(this.breakpointObserver.observe(['(max-width: 767px)']).subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vh')
        : this.dialogRef.updateSize('75vw', '100vh');
    }));
    this.subscriptions.add(this.userService.currentUser$.subscribe((user: User) => {
      this.currentUser = user;
    }));
    this.subscriptions.add(this.modalService.modalResponse$.subscribe((response: ModalResponse) => {
      if (response) {
        this.handleModalResponse(response);
      }
    }));
    this.subscriptions.add(this.groupApplicationService.$loading.subscribe(loading => this.isLoading = loading));

    this.isApply = !!this.route.snapshot.queryParams?.apply;
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.analyticsForDirectNavigation(this.modalRequest);
    this.shareLink = `${environment.url}/${Urls.APP}/${Urls.GROUPS}/${this.modalRequest.uuid}`;
    this.isAdmin = this.userService.isAdmin();
    this.getGroupDetails();
  }

  ngOnDestroy(): void {
    this.toastr.clear();
    this.subscriptions.unsubscribe();
  }

  getGroupDetails(): void {
    this.isLoading = true;
    this.groupService.getGroupDetails(this.modalRequest.uuid).subscribe(
      (res: ResponseObject<GroupDetails>) => {
        this.group = res.response;
        if (this.modalRequest.scope === ModalActions.VIEW_GROUP_FROM_MY_GROUPS) {
          this.activeUnit = this.group.units.find((unit) => unit.userStatus === UserStatusEnum.APPROVED || unit.active);
        } else {
          this.activeUnit = this.group.units.find((unit) => unit.active);
        }
        this.activeUnit.frequencyText = this.getFrequency();
        this.activeUnit.startTimeWithDaylightSavingTime = DateUtils.getStartDateTimeWithDaylightSavingTime(
          this.activeUnit.startsOn,
          this.activeUnit.timezone
        );
        this.activeUnit.meetingDay = DateUtils.getDayNameOutOfMilliseconds(this.activeUnit.startsOn);
        this.canApply = this.showApplyButton();
        this.hasEnded = DateUtils.compareDates(this.activeUnit.endsOn);
        this.hasStarted = DateUtils.compareDates(this.activeUnit.startsOn);
        this.groupLanguages = this.group.groupLanguage.map((language) => language.name).join(', ');
        // DateUtils.setLocalTime(this.activeUnit);
        this.isLoading = false;
      },
      (error) => {
        switch (error) {
          case ServerError.GROUPFINDER_NOT_FOUND:
            this.toastr.error(...ToastrMessages.GROUP_NOT_FOUND);
            break;
          default:
            this.toastr.error(...ToastrMessages.BASIC_ERROR);
            break;
        }
        this.isLoading = false;
      }
    );
  }

  getFrequency(): string {
    switch (this.activeUnit.frequencyType) {
      case Frequencies.MONTHLY:
      case Frequencies.WEEKLY:
        return this.activeUnit.frequencyType;
      case Frequencies.EVERY_2_WEEKS:
        return this.activeUnit.frequencyType.split('_').join(' ');
      case Frequencies.CUSTOM:
        return parseInt(this.activeUnit.interval) === 1 ? 'Weekly' : `Every ${this.activeUnit.interval} weeks`;
    }
  }

  handleModalResponse(response: ModalResponse): void {
    if (response.modalRequest.uuid === this.modalRequest.uuid) return;
    switch (response.modalEmitAction) {
      case ModalEmitActions.GO_TO_WEEKLY_GROUPS:
        const modalResponse = new ModalResponse(true, this.modalRequest, ModalEmitActions.GO_TO_WEEKLY_GROUPS);
        this.modalService.emitResponse(modalResponse);
        this.close();
        break;
      case ModalEmitActions.FIND_GROUPS:
        this.close();
        this.router.navigate([`${Urls.APP}/${Urls.GROUPS}`]);
        break;
    }
  }

  attemptApplication(): void {
    this.analytics.logClickEvent(AnalyticsEvent.CLICK_APPLY_TO_GROUP);
    this.analytics.logCustomEvent(AnalyticsEvent.APPLY_JOURNEY_CLICK_APPLY);
    this.groupApplicationService.setGroupData(this.group, this.activeUnit);
    const user = this.userService.currentUser;
    const groupUserType = this.group.groupRole === GroupRoles.ENTREPRENEUR ? MainGroupRoles.ENTREPRENEUR : MainGroupRoles.INVESTOR;
    switch (true) {
      case !user:
        this.router.navigate([`${Urls.AUTH}/${Urls.LOGIN}`], { queryParams: { reason: RedirectReason.APPLIED_WITHOUT_AN_ACCOUNT } });
        break;
      case !user.completedProfile:
        this.modalService.openModal(Modals.ADD_USER, new ModalRequest(ModalActions.MY_PROFILE_THEN_TRY_ENROLL, null, null, this.modalRequest.uuid));
        break;
      case !user.userType || !user.userSubType:
        this.router.navigate([`${Urls.AUTH}/${Urls.REGISTER}`], { queryParams: { reason: RedirectReason.APPLIED_WITHOUT_PRIMARY_TYPE } });
        break;
      case groupUserType !== user.userType && (!user.secondaryUserType || !user.secondaryUserSubType):
        this.router.navigate([`${Urls.AUTH}/${Urls.REGISTER}`], { queryParams: { reason: RedirectReason.APPLIED_TO_DIFFERENT_TYPE } });
        break;
      default:
        this.groupApplicationService.attemptApplication(RedirectReason.APPLICATION_ALLOWED);
        return;
    }
    this.close();
  }

  externalNavigate(value: string): void {
    Utils.navigate(value);
  }

  openWindow(socialMedia: SocialMedia): void {
    let url: string;
    switch (socialMedia) {
      case SocialMedia.FACEBOOK:
        url = `http://www.facebook.com/sharer/sharer.php?u=${this.shareLink}&title=${this.group.name}`;
        break;
      case SocialMedia.LINKEDIN:
        url = `http://www.linkedin.com/shareArticle?mini=true&url=${this.shareLink}&title==${this.group.name}&source=${environment.url}`;
        break;
      case SocialMedia.TWITTER:
        url = `http://twitter.com/intent/tweet?original_referer=${environment.url}&text=${this.group.name}&url=${this.shareLink}`;
        break;
      default:
        break;
    }
    window.open(url, 'newwindow', 'width: 300, height: 500');
  }

  openModal(modalName: Modals, modalAction: ModalActions, payload: any = null): void {
    this.modalService.openModal(modalName, { ...this.modalRequest, scope: modalAction, payload });
  }

  goToGroupSetup(): void {
    this.QPService.storeParams();
    this.router.navigate([`${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_GROUPS}/${this.group.uuid}`]);
    this.modalService.closeAll();
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.shareLink);
    this.toastr.success(...ToastrMessages.COPIED_TO_CLIPBOARD);
  }

  scrollToElement(): void {
    const userState: HTMLElement = document.getElementById('userState');
    userState.scrollIntoView({ behavior: 'smooth' });
    userState.className = 'highlight-animation';
    setTimeout(() => {
      userState.classList.remove('highlight-animation');
    }, 3000);
  }

  withdrawFromGroup(): void {
    this.adminUserService.withdrawUserFromGroup(this.group.uuid).subscribe(
      (response: any) => {
        if (response.response) {
          this.activeUnit.userStatus = ParticipantType.WITHDRAWN;
          this.toastr.success(...ToastrMessages.WITHDRAW_SUCCESS);
        } else {
          this.toastr.error(...ToastrMessages.WITHDRAW_ERROR);
        }
      },
      () => {
        this.toastr.error(...ToastrMessages.WITHDRAW_ERROR);
      }
    );
  }

  buildLocationString(): string {
    let region = null;
    if (this.activeUnit?.mainlands?.length > 0) {
      region = this.activeUnit?.mainlands[0]?.region;
    }
    return [this.activeUnit?.location?.city, region].filter(Boolean).join(', ');
  }

  close(): void {
    this.dialogRef.close();
  }

  showApplyButton(): boolean {
    if (this.group.groupState === GroupStates.CLOSED) {
      return false;
    }
    return !DateUtils.compareDates(this.group.openUntilDate);
  }

  analyticsForDirectNavigation(modalRequest: ModalRequest<any>) {
    if (!modalRequest.payload?.hasPreviousNavigation) {
      this.analyticsService.logClickEvent(AnalyticsEvent.DIRECT_NAVIGATION_TO_GROUP_DETAILS);
    }
  }

  get pendingOrDeclinedUser(): boolean {
    return this.activeUnit.userStatus === ParticipantType.PENDING || this.activeUnit.userStatus === ParticipantType.DECLINED;
  }

  get canSeeMeetingDetails(): boolean {
    if (!this.currentUser) return false;
    return (
      this.activeUnit.userStatus === ParticipantType.APPROVED ||
      this.userService.isAdmin() ||
      !!this.activeUnit.facilitatorsList.find((facilitator) => facilitator.uuid === this.currentUser.userUuid)
    );
  }
}
