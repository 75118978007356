import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UploaderApiService } from 'src/app/_core/api/uploader-api.service';
import { FileType } from 'src/app/_core/constants/FileType';
import { ModalActions, ModalEmitActions, Modals } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import FileUtils from 'src/app/_core/helpers/FileUtils';
import { FileDetails } from 'src/app/_core/models/FileInfo';
import { Location } from 'src/app/_core/models/Location';
import { ModalFileResponse, ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { UploadPromiseResponse } from 'src/app/_core/models/Upload';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UploadService } from 'src/app/_core/services/upload.service';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss'],
})
export class EditLocationComponent implements OnInit, OnDestroy {
  locationPhoto: FileDetails = new FileDetails(null, null, null, null);
  modalRequestLocation: Location;
  locationForm: UntypedFormGroup;
  uploaderSubscription: Subscription;
  modalSubscription: Subscription;
  uploader: FileUploader;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private uploaderApiService: UploaderApiService,
    private uploadService: UploadService,
    private toastr: ToastrService,
    ) {
      this.modalSubscription = this.modalService.modalResponse$.subscribe((response: ModalFileResponse) => {
        this.handleModalResponse(response);
      });

      this.uploaderSubscription = this.uploadService.uploaderObservable.subscribe(data =>
        data.progress ? this.setProgress(data.result) : this.setFileFields(data.result)
      );
    }

  ngOnInit(): void {
    this.uploader = this.uploadService.uploaderInstance;
    this.modalRequestLocation = this.modalService.params.payload;
    this.setLocationImage();
  }

  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
    this.uploaderSubscription.unsubscribe();
  }

  setLocationImage(): void {
    if (this.modalRequestLocation?.imageUrl) {
      this.locationPhoto.uuid = this.modalRequestLocation.uuid;
      this.locationPhoto.path = this.modalRequestLocation.imageUrl;
    }
  }

  close(): void {
    this.modalService.closeAll();
  }

  handleImageUpload(): void {
    if (this.locationPhoto?.path) {
      this.uploaderApiService.deleteImage({ fileType: FileType.LOCATION_IMAGE, fileUuid: this.locationPhoto.uuid }).subscribe(() => {
        this.locationPhoto = new FileDetails(null, null, null, null);
        this.emitResponse();
      });
    } else {
      this.openCropModal();
    }
  }

  openCropModal(): void {
    this.modalService.openModal(Modals.CROP_IMAGE, { aspectRatio: 1, fileType: FileType.LOCATION_IMAGE });
  }

  uploadImageAfterCrop(payload: string, fileType: FileType, target: string): void {
    const fileToUpload: FileItem = this.uploader.queue[this.uploader.queue.length - 1];
    fileToUpload.formData.fileType = fileType;
    fileToUpload.formData.entityUuid = this.modalRequestLocation?.uuid;
    fileToUpload.formData.formControlName = target;
    fileToUpload._file = FileUtils.convertB64ToImage(payload, fileToUpload);
    this.locationPhoto.pendingUpload += 1;
    this.uploadService.uploadFile();
  }

  setProgress(response: UploadPromiseResponse): void {
    this.locationPhoto.progress = +response.response;
  }

  setFileFields(response: UploadPromiseResponse): void {
    const responseData = JSON.parse(response.response);
    const parsedData = responseData.response;
    const fileName = FileUtils.computeFileName(response.fileItem.file.name);

    if (responseData.hasOwnProperty('error')) {
      this.toastr.error(...ToastrMessages.BASIC_ERROR);
      this.locationPhoto.pendingUpload -= 1;
      return;
    }

    this.locationPhoto.name = fileName;
    this.locationPhoto.uuid = parsedData.objectUuid;
    this.locationPhoto.path = parsedData.url;
    this.locationPhoto.fileType = FileType.LOCATION_IMAGE;
    this.locationPhoto.isFormatValid = true;
    this.locationPhoto.pendingUpload -= 1;
    this.emitResponse();
  }

  emitResponse(): void {
    const payload = {
      imageUrl: this.locationPhoto.path,
      locationUuid: this.modalRequestLocation.uuid,
    };
    const modalRequest = new ModalRequest(ModalActions.SELECT_MOBILE_REGION_FILTER, null, null, payload);
    const modalResponse = new ModalResponse(true, modalRequest, ModalEmitActions.LOCATION_IMAGE_UPDATED);
    this.modalService.emitResponse(modalResponse);
  }

  handleModalResponse(response: ModalFileResponse): void {
    if (response?.confirmed) {
      switch (response.modalRequest.scope) {
        case ModalActions.CROP:
          this.uploadImageAfterCrop(response.payload, response.fileType, response.target);
          break;
        default:
          break;
      }
    }
  }
}
