import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupTypes } from '../../../_core/constants/AdminGroups';
import { RegionSearchOptions } from '../../../_core/models/RegionSearchOptions';

@Component({
  selector: 'app-region-search-by',
  templateUrl: './region-search-by.component.html',
  styleUrls: ['./region-search-by.component.scss'],
})
export class RegionSearchByComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() selected: boolean;
  @Input() options: RegionSearchOptions;
  @Output() searchGroups: EventEmitter<GroupTypes> = new EventEmitter<GroupTypes>();
  breakpointSubscription: Subscription;
  isMobile: boolean;
  groupTypes = GroupTypes;

  // prettier-ignore
  constructor(
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 767px)']).subscribe((result: BreakpointState) => {
      if (result.matches !== this.isMobile) {
        this.isMobile = result.matches;
      }
    });
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected.currentValue) {
      this.selected = changes.selected.currentValue;
    }
  }
}
