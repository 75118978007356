import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MainGroupRoles } from 'src/app/_core/constants/AdminGroups';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { FileType } from 'src/app/_core/constants/FileType';
import { APP_LINKS } from 'src/app/_core/constants/Links';
import { Modals } from 'src/app/_core/constants/Modals';
import { Role } from 'src/app/_core/constants/UserEnums';
import { MediaModel } from 'src/app/_core/models/ModalEvent';
import { User } from 'src/app/_core/models/User';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { QueryParamsService } from 'src/app/_core/services/query-params.service';
import { UserService } from 'src/app/_core/services/user.service';
import { SocialLinks } from '../../../_core/constants/SocialMedia';
import { Urls } from '../../../_core/constants/Urls';
import Utils from '../../../_core/helpers/Utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  links = { ...SocialLinks, ...APP_LINKS };
  user: User;
  userSubscription: Subscription;
  role = Role;
  groupRole = MainGroupRoles;
  urls = Urls;
  currentUrl: string;
  isJustFaciltator: boolean = false;

  // prettier-ignore
  constructor(
    private router: Router,
    private userService: UserService,
    private modalsService: ModalsService,
    private analyticsService: AnalyticsService,
    // private multiFilterService: MultiFilterService,
    private QPService: QueryParamsService,
  ) {
    this.subscription.add(this.userService.currentUser$.subscribe((user: User) => {
      this.user = user;
      this.isJustFaciltator = user?.facilitator && user?.role !== Role.SUPER_ADMIN;
    }));
  }

  ngOnInit(): void {
    this.getCurrentUrl();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goHome(): void {
    this.router.navigate([`${Urls.APP}/${Urls.HOME}`]);
  }

  externalNavigate(value: string): void {
    Utils.navigate(value);
  }

  openModal(url: string, label: string): void {
    this.modalsService.openModal(Modals.MEDIA, new MediaModel(url, label, FileType.VIDEO));
  }

  goToGroups(groupRole: MainGroupRoles): void {
    switch (groupRole) {
      case MainGroupRoles.INVESTOR:
        this.analyticsService.logClickEvent(AnalyticsEvent.GO_TO_INVESTOR_GROUPS_BTN);
        this.QPService.resetParams({ userRole: MainGroupRoles.INVESTOR });
        this.router.navigate([`${Urls.APP}/${Urls.GROUPS}`], { queryParams: { userRole: MainGroupRoles.INVESTOR } });
        break;
      case MainGroupRoles.ENTREPRENEUR:
        this.analyticsService.logClickEvent(AnalyticsEvent.GO_TO_ENTREPRENEUR_GROUPS_BTN);
        this.QPService.resetParams({ userRole: MainGroupRoles.ENTREPRENEUR });
        this.router.navigate([`${Urls.APP}/${Urls.GROUPS}`], { queryParams: { userRole: MainGroupRoles.ENTREPRENEUR } });
        break;
    }
  }

  getCurrentUrl() {
    this.currentUrl = this.router.url;
    this.subscription.add(
      this.router.events.subscribe((events) => {
        if (events instanceof NavigationEnd) {
          this.currentUrl = events.url;
        }
      })
    );
  }
}
