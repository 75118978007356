import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupFormat } from 'src/app/_core/constants/AdminGroups';
import { ModalActions, Modals } from 'src/app/_core/constants/Modals';
import { Group, GroupsStudyOrdered } from 'src/app/_core/models/Groups';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import DateUtils from '../../../../_core/helpers/DateUtils';

@Component({
  selector: 'app-study-card',
  templateUrl: './study-card.component.html',
  styleUrls: ['./study-card.component.scss'],
})
export class StudyCardComponent implements OnInit, OnDestroy {
  @Input() groupStudy: GroupsStudyOrdered;
  isMobile = false;
  breakpointSubscription: Subscription;
  meetingType = GroupFormat;

  // prettier-ignore
  constructor(
    private modalsService: ModalsService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 767px)']).subscribe((result: BreakpointState) => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.groupStudy.groups.forEach((group: Group) => DateUtils.setLocalTime(group));
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  showDetails(uuid: string): void {
    this.modalsService.openModal(Modals.GROUP_DETAILS, new ModalRequest(ModalActions.VIEW_GROUP, uuid));
  }
}
