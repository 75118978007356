import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Modals } from '../constants/Modals';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private modalResponse = new Subject<any>();
  private modalSource = new Subject<any>();

  modal$ = this.modalSource.asObservable();
  modalResponse$ = this.modalResponse.asObservable();

  modals = Object.values(Modals);
  params: any = null;

  openModal(modalName, params?): void {
    if (this.modals.indexOf(modalName) === -1) {
      console.error('Modal ' + modalName + ' not found!');
      return;
    }

    this.params = params;
    this.modalSource.next(modalName);
  }

  closeModal(): void {
    this.modalSource.next('close');
  }

  closeAll(): void {
    this.modalSource.next('closeAll');
  }

  emitResponse(response): void {
    this.modalResponse.next(response);
  }
}
