import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  private readonly resourceUrl: string = '/v1/groups';

  constructor(private apiService: ApiService) {}

  getRegions(groupRole: string = null): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/v2/get-mainlands`, { groupRole: groupRole ? groupRole?.toUpperCase() : 'NONE' });
  }
}
