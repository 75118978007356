<div class="admin-input">
  <div class="search-input-container">
    <mat-form-field appearance="outline" class="search-input">
      <input
        matInput
        placeholder="Search by address, city, or zip code"
        ngx-google-places-autocomplete
        #placesRef
        (onAddressChange)="handleAddressChange($event)"
      />
    </mat-form-field>
  </div>
</div>
