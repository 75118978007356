export enum FileType {
  DOC = 'DOC',
  VIDEO = 'VIDEO',
  IMG = 'IMG',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  STUDY_IMAGE = 'STUDY_IMAGE',
  LOCATION_IMAGE = 'LOCATION_IMAGE',
  CHURCH_IMAGE = 'CHURCH_IMAGE',
  PARTNER_ORGANIZATION_IMAGE = 'PARTNER_ORGANIZATION',
  LANGUAGE_IMAGE = 'LANGUAGE_IMAGE',
}

export const DOC_FORMATS = ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'];

export const IMG_FORMATS = ['jpg', 'jpeg', 'bmp', 'png'];

export const VIDEO_FORMATS = ['mp4', 'mov', 'avi', 'mpeg', 'ogv', 'webm', '3gp'];

export const DOC_MIME_TYPES = [
  'text/plain',
  'text/csv',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const IMG_MIME_TYPES = ['image/jpeg', 'image/bmp', 'image/png'];

export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/avi',
  'video/quicktime',
  'video/mpeg',
  'video/ogg',
  'video/x-msvideo',
  'video/mp2t',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
];
