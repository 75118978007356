import { UntypedFormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import jwt_decode from 'jwt-decode';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { Group } from 'src/app/_core/models/Groups';
import { AddressComponentTypes } from '../constants/GooglePlaces';
import { FilterType } from '../constants/Home';
import { Meeting } from '../models/AdminGroups';
import { Filter, FilterOption, SortOption } from '../models/Filters';
import { GroupsFilters } from '../models/Home';
import { TokenData } from '../models/User';
import StorageHelper from './Storage.helper';

export default class Utils {
  public static decodeToken(): TokenData {
    try {
      return jwt_decode(StorageHelper.getToken());
    } catch (Error) {
      return null;
    }
  }

  public static navigate(url: string, location: string = '_blank'): void {
    let urlEditted = '';
    if (!/^http[s]?:\/\//.test(url)) {
      urlEditted += 'http://';
    }
    urlEditted += url;
    window.open(urlEditted, location);
  }

  public static sortBy(array: Array<any>, field: string, numberInString: boolean = false): any[] {
    return array.sort((a: any, b: any) =>
      numberInString ? Number(a[field].split('-').shift()) - Number(b[field].split('-').shift()) : a[field].localeCompare(b[field])
    );
  }

  // NOTE: The order of items in addressComponentType matters
  public static getGooglePlacesData(addressComponents: AddressComponent[], addressComponentType: AddressComponentTypes[]): AddressComponent {
    if (!addressComponents) {
      return;
    }
    let addressComponent: AddressComponent;
    addressComponentType.every((component) => {
      addressComponent = addressComponents.find((address) => {
        return address.types.indexOf(component) > -1;
      });
      return !addressComponent;
    });
    return addressComponent;
  }

  public static getGooglePlacesCountryOrStateObjectLongName(addressComponents: AddressComponent[]) {
    const country = this.getGooglePlacesData(addressComponents, [AddressComponentTypes.COUNTRY])?.long_name;
    const stateProvince = this.getGooglePlacesData(addressComponents, [AddressComponentTypes.ADMINISTRATIVE_AREA_LEVEL_1])?.long_name;
    const stateProvinceMainlands = ['United States', 'Canada', 'Australia'];
    const UKMembers = ['Scotland', 'Wales', 'England', 'Northern Ireland'];
    if (stateProvinceMainlands.includes(country)) return stateProvince;
    if (UKMembers.includes(stateProvince)) return stateProvince;
    return country;
  }

  public static getGooglePlacesCountryOrStateObjectShortName(addressComponents: AddressComponent[]) {
    const country = this.getGooglePlacesData(addressComponents, [AddressComponentTypes.COUNTRY])?.short_name;
    const stateProvince = this.getGooglePlacesData(addressComponents, [AddressComponentTypes.ADMINISTRATIVE_AREA_LEVEL_1])?.short_name;
    const stateProvinceMainlands = ['US', 'CA', 'AU'];
    return stateProvinceMainlands.includes(country) ? stateProvince : country;
  }

  public static getCityLongName(addressComponents: AddressComponent[]): string {
    return Utils.getGooglePlacesData(addressComponents, [
      AddressComponentTypes.LOCALITY,
      AddressComponentTypes.POSTAL_TOWN,
      AddressComponentTypes.SUBLOCALITY_LEVEL_1,
      AddressComponentTypes.ADMINISTRATIVE_AREA_LEVEL_1,
    ])?.long_name;
  }

  public static extractFiltersUuidsFromFilter(filter: Filter, selected: boolean = true): string[] {
    return filter.filters?.length === 0
      ? null
      : filter.filters?.filter((subFilter: FilterOption) => subFilter.selected === selected).map((subFilter) => subFilter.uuid) || [];
  }

  public static extractFiltersNamesFromFilter(filter: Filter, selected: boolean = true): string[] {
    return filter.filters?.length === 0
      ? null
      : filter.filters?.filter((subFilter: FilterOption) => subFilter.selected === selected).map((subFilter) => subFilter.name) || [];
  }

  public static extractFiltersDaysFromFilter(filter: Filter, selected: boolean = true): number {
    const days = filter.filters?.filter((subFilter: FilterOption) => subFilter.selected === selected).map((subFilter) => parseInt(subFilter.uuid));
    return days.length > 0 ? Math.max(...days) : 0;
  }

  public static changeSortByOption(sortOptions: SortOption[], sort: Sort): void {
    const index: number = +sort.active;
    sortOptions.forEach((option: SortOption) => {
      if (option.field !== sortOptions[index].field) {
        option.checked = false;
        option.order = null;
      }
    });
    sortOptions[index].order = sort.direction || null;
    sortOptions[index].checked = !!sortOptions[index].order;
  }

  public static checkValueAndValidityOfFormGroup(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach((key) => {
      form.controls[key].updateValueAndValidity();
    });
  }

  public static unsetValidatorsForFormGroup(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach((key) => {
      form.controls[key].clearValidators();
    });
    form.clearValidators();
  }

  public static clearErrorsOfFormGroup(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach((key) => {
      form.controls[key].setErrors(null);
    });
  }

  public static parseEmbed(text: string): string {
    let link: string = null;
    const textArray = text?.split('src="');
    if (textArray?.length) {
      const linkArray = textArray[1]?.split('"');
      link = linkArray?.length ? linkArray[0] : null;
    }
    return link;
  }

  public static extractFieldOfCertainItems(array: any[], fieldToSearch: string, valueToSearch: any, fieldToReturn: string): string[] {
    const selectedArray: string[] = [];
    for (const item of array) {
      if (item[fieldToSearch] == valueToSearch) {
        selectedArray.push(item[fieldToReturn]);
      }
    }
    return selectedArray;
  }

  public static extractFieldArray(array: Array<any>, field: string): string[] {
    return array.length > 0 ? array.map((item) => item[field]) : [];
  }

  public static extractSelectedFieldArray(array: Array<any>, field: string): string[] {
    if (!array) return [];

    return array.filter((item) => item.selected).map((item) => item[field]);
  }

  public static makeArray(param: any): string[] {
    return !Array.isArray(param) ? [param] : param;
  }

  public static computeSelectAllForPayload(filters: GroupsFilters): boolean {
    const filtersSelected = this.extractFiltersUuidsFromFilter(filters.selectedFilter)?.length;
    if (filtersSelected === 0 && filters.startDate === '') {
      return true;
    } else if (filtersSelected > 0 && filters.selectedFilter.name !== FilterType.FACILITATOR && filters.selectedFilter.name !== FilterType.STUDY) {
      return false;
    } else {
      return filters.selectedFilter.selectAll;
    }
  }

  public static b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public static getCompanySizeMessage(group: Group): string {
    if (group.companySize.indexOf('10-50 Employees') > -1 && group.companySize.indexOf('More than 50 Employees') > -1) {
      return 'Any Company Size';
    } else {
      return group.companySize[group.companySize.length - 1];
    }
  }

  public static getEventStructuredMeetings(meetings: Meeting[]) {
    const structuredMeetings = {};
    const finalStructuredMeetings: string[] = [];
    meetings?.forEach((meeting: Meeting) => {
      const newDate = new Date(meeting.meetingStartTime);
      if (!structuredMeetings[newDate.getFullYear()]) structuredMeetings[newDate.getFullYear()] = {};
      if (!structuredMeetings[newDate.getFullYear()][newDate.toLocaleString('default', { month: 'long' })])
        structuredMeetings[newDate.getFullYear()][newDate.toLocaleString('default', { month: 'long' })] = [];
      structuredMeetings[newDate.getFullYear()][newDate.toLocaleString('default', { month: 'long' })].push(newDate.getDate());
    });

    for (const yearKey in structuredMeetings) {
      let meetingLineString = '';
      for (const monthKey in structuredMeetings[yearKey]) {
        meetingLineString += monthKey + ' ' + structuredMeetings[yearKey][monthKey].join(',') + ', ' + yearKey;
        finalStructuredMeetings.push(meetingLineString);
        meetingLineString = '';
      }
    }
    return finalStructuredMeetings;
  }

  public static objectHasValue(obj: any): boolean {
    if (!obj || typeof obj !== 'object') {
      return false;
    }
    return !!Object.keys(obj).length && !!Object.keys(obj).find((child) => obj[child]);
  }

  public static getEnumKeyByValue(enumObject, value: string): string {
    return Object.keys(enumObject).find((key) => enumObject[key] === value) || null;
  }

  public static convertKmToMiles(km: number): number {
    return km * 0.621371;
  }

  public static checkEmailValidity(value: string): boolean {
    const regExp: RegExp =
      /^[a-zA-Z0-9]+([._-]*[a-zA-Z0-9])*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    return regExp.test(value?.trim());
  }

  public static deepCloneArray<T>(array: T[]): T[] {
    return JSON.parse(JSON.stringify(array));
  }
}
