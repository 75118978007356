import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdminUserService } from 'src/app/_core/api/admin-user.service';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import ComputePayload from 'src/app/_core/helpers/ComputePayload';
import { AdminUser } from 'src/app/_core/models/AdminUsers';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { BasicFilters, BasicPaginatedRequest } from 'src/app/_core/models/Shared';
import { User } from 'src/app/_core/models/User';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-merge-accounts',
  templateUrl: './merge-accounts.component.html',
  styleUrls: ['./merge-accounts.component.scss'],
})
export class MergeAccountsModalComponent implements OnInit {
  mainAccount: AdminUser;
  mainAccountUser: User;
  users: AdminUser[];
  toBeRemovedAccountUser: User;
  selectedUser: AdminUser;
  filters: BasicFilters = new BasicFilters();
  searchText: string;
  isLoading: boolean = false;
  isLoadingMerge: boolean = false;
  mergeForm: FormGroup;

  constructor(
    private modalsService: ModalsService,
    private adminUserService: AdminUserService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.mergeForm = this.fb.group({
      role: [true],
      userType: [true],
      firstName: [true],
      lastName: [true],
      phone: [true],
      timezone: [true],
      location: [true],
      linkedinProfile: [true],
      bio: [true],
      title: [true],
    });
  }

  async ngOnInit(): Promise<void> {
    this.mainAccount = this.modalsService.params.user;
    this.mainAccountUser = await this.getUserInfo(this.mainAccount.uuid);
  }

  getUserInfo(uuid: string): Promise<User> {
    return new Promise<User>((resolve) => {
      this.adminUserService.getUserInfo(uuid).subscribe({
        next: (res: ResponseObject<User>) => {
          resolve(res.response);
        },
        error: () => {
          this.toastr.error(...ToastrMessages.BASIC_ERROR);
        },
      });
    });
  }

  searchUsers(): void {
    if (!this.searchText && !this.isLoading) return;
    this.isLoading = true;
    this.filters.searchText = this.searchText.trim();
    const payload: BasicPaginatedRequest = ComputePayload.getSearchAccountPayload(this.filters);
    this.adminUserService.searchAccount(payload).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.users = res;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  async onSelectionChange(user: AdminUser): Promise<void> {
    if (!user) return;
    if (this.mainAccount.uuid === user.uuid) {
      this.toastr.error('You cannot merge with your own account.', 'Error!');
      return;
    }
    this.selectedUser = user;
    this.toBeRemovedAccountUser = await this.getUserInfo(this.selectedUser.uuid);
  }

  onMergeSubmit() {
    this.isLoadingMerge = true;
    const payload = { mainAccount: this.mainAccount.uuid, toBeRemoved: this.selectedUser.uuid, ...this.mergeForm.value };

    this.adminUserService.mergeAccounts(payload).subscribe({
      next: () => {
        this.toastr.success(
          'Your account is currently in the process of merging. You will receive an email once the process is complete.',
          'Success!'
        );
        this.isLoadingMerge = false;
        this.close();
      },
      error: (error) => {
        this.isLoadingMerge = false;
        console.error('error', error);
        this.toastr.error('Failed to merge accounts.', 'Error!');
      },
    });
  }

  close(): void {
    this.modalsService.closeModal();
  }

  displayFn(user?: AdminUser): string | undefined {
    return user ? `${user.firstName} ${user.lastName}` : undefined;
  }
}
