import { Directive, OnDestroy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Directive({
  selector: 'input[debounceInput]',
})
export class DebounceInputDirective implements OnDestroy {
  @Input() public debounceTime: number;
  @Output() public debouncedInput: EventEmitter<any>;

  protected emitEvent$: Subject<any>;
  protected subscription$: Subject<void>;

  constructor() {
    this.debounceTime = 500;
    this.debouncedInput = new EventEmitter<any>();
    this.emitEvent$ = new Subject<any>();
    this.subscription$ = new Subject<void>();
  }

  @HostListener('keyup', ['$event'])
  public onInput(event: any): void {
    if (!event.target.value) return;
    event.preventDefault();
    this.emitEvent$.next(event);
  }

  ngOnInit(): void {
    this.emitEvent$
      .pipe(
        takeUntil(this.subscription$),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap((value) => this.emitChange(value))
      )
      .subscribe();
  }

  public emitChange(value: any): void {
    this.debouncedInput.emit(value);
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }
}
