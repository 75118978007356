import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FacilitatorsService } from 'src/app/_core/api/facilitators.service';
import { EntrepreneurTypes, InvestorTypes } from 'src/app/_core/constants/AdminGroups';
import { ServerError } from 'src/app/_core/constants/ServerErrors';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import { Urls } from 'src/app/_core/constants/Urls';
import Utils from 'src/app/_core/helpers/Utils';
import { AdminFacilitatorGetDetails, AdminGroupResume } from 'src/app/_core/models/AdminFacilitators';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalActions, ModalEmitActions, Modals } from '../../../../../_core/constants/Modals';
import DateUtils from '../../../../../_core/helpers/DateUtils';
import { ModalsService } from '../../../../../_core/services/modals.service';

@Component({
  selector: 'app-view-facilitator',
  templateUrl: './view-facilitator.component.html',
  styleUrls: ['./view-facilitator.component.scss'],
})
export class ViewFacilitatorComponent implements OnInit, OnDestroy {
  modals = Modals;
  modalActions = ModalActions;
  modalEmitActions = ModalEmitActions;
  facilitatorDetails: AdminFacilitatorGetDetails;
  modalRequest: ModalRequest;
  breakpointSubscription: Subscription;
  fromManageUsers: boolean;
  areGroups: boolean;
  userRoles = { ...EntrepreneurTypes, ...InvestorTypes };

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private facilitatorsService: FacilitatorsService,
    private toastr: ToastrService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<ViewFacilitatorComponent>,
    private router: Router,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vh')
        : this.dialogRef.updateSize('75vw', '100vh');
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.fromManageUsers = this.router.url.indexOf(Urls.MANAGE_USERS) > -1;
    this.getFacilitatorDetails();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  getFacilitatorDetails(): void {
    this.facilitatorsService.getFacilitatorDetails(this.modalRequest.uuid).subscribe(
      (res: ResponseObject<AdminFacilitatorGetDetails>) => {
        this.facilitatorDetails = res.response;
        this.facilitatorDetails.groups.approved.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.facilitatorDetails.groups.pending.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.facilitatorDetails.groups.declined.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.facilitatorDetails.groups.leading.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.facilitatorDetails.groups.withdrawn.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.areGroups = Object.values(this.facilitatorDetails.groups).some((val) => Array.isArray(val) && val.length !== 0);
      },
      (error) => {
        switch (error) {
          case ServerError.NO_USER:
            this.toastr.error(...ToastrMessages.FACILITATOR_NOT_FOUND);
            break;
          default:
            this.toastr.error(...ToastrMessages.BASIC_ERROR);
            break;
        }
      }
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  openModal(modalName: Modals, modalAction: ModalActions, payload: any = null): void {
    this.modalService.openModal(modalName, { ...this.modalRequest, scope: modalAction, payload });
  }

  navigate(url: string): void {
    Utils.navigate(url);
  }

  emit(modalEmitAction: ModalEmitActions): void {
    const response = new ModalResponse(true, this.modalRequest, modalEmitAction);
    this.modalService.emitResponse(response);
  }

  getAddressString(): string {
    return [this.facilitatorDetails.userInfo?.city, this.facilitatorDetails.userInfo?.stateProvince, this.facilitatorDetails.userInfo?.country]
      .filter((el) => {
        return el != null;
      })
      .join(', ');
  }
}
