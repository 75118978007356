import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GroupTypes } from 'src/app/_core/constants/AdminGroups';
import { ModalActions, ModalEmitActions } from 'src/app/_core/constants/Modals';
import { GroupDetailsUnit } from 'src/app/_core/models/AdminGroups';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-apply-to-in-person-group',
  templateUrl: './apply-to-in-person-group.component.html',
  styleUrls: ['./apply-to-in-person-group.component.scss'],
})
export class ApplyToInPersonGroupComponent implements OnInit {
  modalRequest: ModalRequest<GroupDetailsUnit>;
  breakpointSubscription: Subscription;
  modalActions = ModalActions;
  cityStateCountryString: string;
  isAuth: boolean;
  groupTypes = GroupTypes;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<ApplyToInPersonGroupComponent>,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalsService
  ) {
    this.isAuth = !!this.activatedRoute.snapshot.queryParams.reason;
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      this.updateSize(result.matches);
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.updateSize(this.breakpointObserver.isMatched('(max-width: 767px)'));
    this.buildLocationString();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  updateSize(isMobile: boolean): void {
    if (this.isAuth) {
      this.dialogRef.updateSize('430px', 'auto');
    } else {
      isMobile ? this.dialogRef.updateSize('90vw', 'auto') : this.dialogRef.updateSize('580px', 'auto');
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  emit(confirm: boolean): void {
    this.modalService.emitResponse(
      new ModalResponse<GroupDetailsUnit>(
        confirm,
        this.modalRequest,
        this.modalRequest.scope === ModalActions.APPLY_TO_IN_PERSON_GROUP
          ? ModalEmitActions.APPLY_TO_IN_PERSON_GROUP
          : ModalEmitActions.REGISTER_APPLY_TO_IN_PERSON_GROUP
      )
    );
    this.close();
  }

  buildLocationString(): void {
    let region = null;
    if (this.modalRequest.payload.mainlands?.length > 0) {
      region = this.modalRequest.payload.mainlands[0]?.region;
    }
    this.cityStateCountryString = [this.modalRequest.payload.location?.city, region].filter(Boolean).join(', ');
  }
}
