<div class="d-flex flex-column justify-content-start m-2">
  <a class="text-right" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center mb-5 mt-2 mx-3">
    <h2>The requested facilitator is already registered as a user on the platform.</h2>
    <div>
      Please select if you want to overwrite the existing profile data in the process of upgrading or to keep the user's current data. In case of
      keeping the user's data, only the facilitator description will be added.
    </div>
  </div>

  <div class="d-flex justify-content-between my-3 mx-4">
    <button mat-flat-button class="mat-medium-button" color="primary" (click)="close()">Cancel</button>
    <div class="d-flex">
      <button mat-flat-button class="mat-medium-button mr-3" color="main" (click)="emit(true)">Overwrite data</button>
      <button mat-flat-button class="mat-medium-button" color="accent" (click)="emit(false)">Keep user's data</button>
    </div>
  </div>
</div>
