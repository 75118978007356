<div class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
  <h1 class="mb-0 modal-header-name">
    <ng-container *ngIf="modalRequest.scope === modalActions.MY_PROFILE; else noProfile">My profile</ng-container>
    <ng-template #noProfile>
      <ng-container [ngSwitch]="modalRequest.scope">
        <span *ngSwitchCase="modalActions.PROMOTE_TO_FACILITATOR">Promote User To Facilitator</span>
        <span *ngSwitchCase="modalActions.ADD_USER">Add User</span>
        <span *ngSwitchCase="modalActions.ADD_FACILITATOR">Add Facilitator</span>
        <span *ngSwitchCase="modalActions.ADD_FACILITATOR_REMOTELY">Add Facilitator</span>
        <span *ngSwitchCase="modalActions.ADD_CO_FACILITATOR_REMOTELY">Add Co-Facilitator</span>
        <span *ngSwitchCase="modalActions.EDIT_USER">Edit User</span>
        <span *ngSwitchCase="modalActions.EDIT_FACILITATOR">Edit Facilitator</span>
        <span class="smaller-header" *ngSwitchCase="modalActions.MY_PROFILE_THEN_TRY_ENROLL"
          >Please fill in just a few more details to join your group.</span
        >
        <span *ngSwitchCase="modalActions.MY_PROFILE">My Profile</span>
      </ng-container>
    </ng-template>
  </h1>
  <a (click)="emit(false, modalEmitActions.TRY_ENROLL)"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>

<div class="px-3 px-md-5 mt-4">
  <div class="bg-gray overflow-auto mb-3" *ngIf="modalRequest?.scope === modalActions.PROMOTE_TO_FACILITATOR">
    <div class="px-3 py-2 mt-3">
      <span class="label">Please fill in the remaining fields in order to promote the user to a facilitator</span>
    </div>
  </div>
  <div class="d-flex gap-2">
    <div>
      <img
        class="profilePic"
        [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
        [lazyLoad]="this.userPhoto?.path"
      />
      <h5 class="profile-pic-label primary-color pointer mb-0 mt-1 font-weight-bold" (click)="handleImageUpload()">
        <i class="fas fa-upload mr-1"></i> {{ userPhoto?.path ? 'DELETE PICTURE' : 'UPLOAD PICTURE' }}
      </h5>
    </div>
    <div
      *ngIf="
        modalRequest?.scope !== modalActions.ADD_USER &&
        (user?.userType || user?.userSubType || user?.secondaryUserType || user?.secondaryUserSubType)
      "
    >
      <div class="label">What best describes you?</div>
      <div>
        <span *ngIf="user.userType">{{ userRoles[user.userType] }}</span
        ><span *ngIf="user.userSubType"> ({{ userRoles[user.userSubType] }})</span>
        <span *ngIf="(user.userType || user.userSubType) && (user.secondaryUserType || user.secondaryUserSubType)">, </span>
        <span *ngIf="user.secondaryUserType">{{ userRoles[user.secondaryUserType] }}</span
        ><span *ngIf="user.secondaryUserSubType"> ({{ userRoles[user.secondaryUserSubType] }})</span>
      </div>
    </div>
  </div>

  <form [formGroup]="addFacilitatorForm" autocomplete="do-not-autofill">
    <div class="row">
      <!-- First Name-->
      <div class="admin-input col-sm-6">
        <label for="firstName" class="label">First name <span class="primary-color">*</span></label>
        <mat-form-field appearance="outline" class="w-100">
          <input id="firstName" matInput formControlName="firstName" maxlength="50" autocomplete="do-not-autofill" />
        </mat-form-field>
        <mat-error *ngIf="firstName.invalid && (firstName.dirty || firstName.touched || user)" class="error">
          <app-validation-message [control]="firstName" [controlName]="'firstName'"></app-validation-message>
        </mat-error>
      </div>

      <!-- Last Name-->
      <div class="admin-input col-sm-6">
        <label for="lastName" class="label">Last name <span class="primary-color">*</span></label>
        <mat-form-field appearance="outline" class="w-100">
          <input id="lastName" matInput formControlName="lastName" maxlength="50" autocomplete="do-not-autofill" />
        </mat-form-field>
        <mat-error *ngIf="lastName.invalid && (lastName.dirty || lastName.touched || user)" class="error">
          <app-validation-message [control]="lastName" [controlName]="'lastName'"></app-validation-message>
        </mat-error>
      </div>
    </div>

    <!--Email-->
    <div class="admin-input">
      <label for="email" class="label">Email <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline" class="w-100">
        <input id="email" type="email" matInput formControlName="email" autocomplete="do-not-autofill" [maxlength]="255" />
      </mat-form-field>
      <mat-error *ngIf="email.invalid && (email.dirty || email.touched || user)" class="error">
        <app-validation-message [control]="email" [controlName]="'email'"></app-validation-message>
      </mat-error>
    </div>

    <ng-container *ngIf="facilitator">
      <!--Job Title-->
      <div class="admin-input">
        <label for="jobTitle" class="label mb-0">Job Title <span class="primary-color">*</span></label>
        <div>Short bio similar to a LinkedIn profile</div>
        <mat-form-field appearance="outline" class="w-100">
          <input id="jobTitle" matInput formControlName="jobTitle" autocomplete="do-not-autofill" [maxlength]="255" />
        </mat-form-field>
        <mat-error *ngIf="!jobTitle.value && jobTitle.invalid && (jobTitle.dirty || jobTitle.touched || user)" class="error">
          <app-validation-message [control]="jobTitle" [controlName]="'jobTitle'"></app-validation-message>
        </mat-error>
      </div>

      <!--Description-->
      <div class="admin-textarea">
        <label for="description" class="label">Description <span class="primary-color">*</span></label>
        <mat-form-field appearance="outline" class="w-100">
          <textarea rows="4" id="description" matInput formControlName="description" maxlength="500"></textarea>
        </mat-form-field>
        <mat-error *ngIf="description.invalid && (description.dirty || description.touched || user)" class="error">
          <app-validation-message [control]="description" [controlName]="'description'"></app-validation-message>
        </mat-error>
      </div>
    </ng-container>

    <!--Phone Number-->
    <div class="admin-input">
      <label for="phoneNumber" class="label"
        >Phone Number <span class="primary-color">* </span> <span class="optional">(International Format)</span></label
      >
      <div class="w-100">
        <ngx-intl-tel-input
          [cssClass]="'phone-input'"
          [enableAutoCountrySelect]="true"
          [enablePlaceholder]="true"
          [searchCountryFlag]="true"
          [selectFirstCountry]="false"
          [maxLength]="15"
          [phoneValidation]="true"
          [inputId]="'phoneNumber'"
          name="phoneNumber"
          formControlName="phoneNumber"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [numberFormat]="PhoneNumberFormat.National"
          [selectedCountryISO]="selectedCountryCode"
          [separateDialCode]="true"
        ></ngx-intl-tel-input>
      </div>
      <mat-error *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched || user)" class="error">
        <app-validation-message [control]="phoneNumber" [controlName]="'phoneNumber'"></app-validation-message>
      </mat-error>
    </div>

    <!-- Timezone -->
    <div class="admin-input">
      <label for="name" class="label">Timezone <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="timezone" [matAutocomplete]="autoTimezone" [maxlength]="255" />
        <mat-autocomplete #autoTimezone="matAutocomplete" [displayWith]="displayTimezone">
          <mat-option *ngFor="let item of timezonesFiltered | async" [value]="item">
            {{ item.displayName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-error *ngIf="timezone.invalid && (timezone.dirty || timezone.touched || user)" class="error">
        <app-validation-message [control]="timezone" [controlName]="'timezone'"></app-validation-message>
      </mat-error>
    </div>

    <!--Address-->
    <div class="admin-input">
      <label for="address" class="label"
        >Search your city <span *ngIf="country.invalid || stateProvince.invalid || city.invalid" class="primary-color">*</span></label
      >
      <div *ngIf="modalRequest.scope === modalActions.MY_PROFILE || modalRequest.scope === modalActions.MY_PROFILE_THEN_TRY_ENROLL">
        To best serve you and help you find local groups and events in your area, please enter the information below. Your information will never be
        shared or used for anything else.
      </div>
      <mat-form-field appearance="outline">
        <input
          id="address"
          matInput
          ngx-google-places-autocomplete
          #placesRef
          (onAddressChange)="handleAddressChange($event)"
          placeholder="Search by address, city, or zip code"
          autocomplete="do-not-autofill"
        />
      </mat-form-field>
      <mat-error
        class="error pt-2"
        *ngIf="
          (country.invalid && (country.dirty || country.touched || user)) ||
          (stateProvince.invalid && (stateProvince.dirty || stateProvince.touched || user)) ||
          (city.invalid && (city.dirty || city.touched || user))
        "
      >
        <div>Search for an address.</div>
      </mat-error>
      <mat-error class="error pt-2" *ngIf="addressNotFound">
        <div>Address couldn't provide enough data. Please try again.</div>
      </mat-error>
    </div>

    <div class="row" formGroupName="addressInfo">
      <div class="admin-input col-sm-4">
        <label for="city" class="label">City <span class="primary-color">*</span></label>
        <div>{{ city.value ? city.value : 'Auto-fill from above' }}</div>
      </div>
      <div class="admin-input col-sm-4">
        <label for="stateProvince" class="label">State/Province <span class="primary-color">*</span></label>
        <div>{{ stateProvince.value ? stateProvince.value : 'Auto-fill from above' }}</div>
      </div>
      <div class="admin-input col-sm-4">
        <label for="country" class="label">Country <span class="primary-color">*</span></label>
        <div>{{ country.value ? country.value : 'Auto-fill from above' }}</div>
      </div>
    </div>

    <!-- Linkedin profile-->
    <div class="admin-input">
      <label for="linkedinProfile" class="label d-flex"
        >LinkedIn Profile (optional)
        <span class="additional-label">
          If you do not have a LinkedIn profile, you can add any link in URL format (company bio profile, Facebook profile, etc.)</span
        ></label
      >
      <mat-form-field appearance="outline" class="w-100">
        <input id="linkedinProfile" matInput formControlName="linkedinProfile" maxlength="300" />
      </mat-form-field>
      <mat-error *ngIf="linkedinProfile.invalid && (linkedinProfile.dirty || linkedinProfile.touched || user)" class="error">
        <app-validation-message [control]="linkedinProfile" [controlName]="'linkedinProfile'"></app-validation-message>
      </mat-error>
    </div>

    <!-- Visible -->
    <div
      class="admin-input"
      *ngIf="facilitator && (modalRequest?.scope === modalActions.ADD_FACILITATOR || modalRequest?.scope === modalActions.EDIT_FACILITATOR)"
    >
      <div class="d-flex flex-nowrap justify-content-end">
        <div class="d-flex justify-content-end label mr-2" [class.opacity-4]="user?.facilitatesGroup" [class.disabled-text]="user?.facilitatesGroup">
          Invisible
        </div>
        <mat-slide-toggle formControlName="visible" color="primary" class="visible label" [disabled]="user?.facilitatesGroup"
          >Visible
        </mat-slide-toggle>
      </div>
    </div>
  </form>

  <!-- Delete Section  -->
  <div
    class="bg-gray overflow-auto"
    *ngIf="(modalRequest?.scope === modalActions.EDIT_FACILITATOR || modalRequest?.scope === modalActions.EDIT_USER) && !fromManageUsers"
  >
    <div class="px-3 pt-2 mt-3">
      <div class="label mt-0">
        {{
          modalRequest?.scope === modalActions.EDIT_FACILITATOR
            ? 'Delete or downgrade facilitator'
            : modalRequest?.scope === modalActions.EDIT_USER
            ? 'Promote to facilitator or delete'
            : 'Delete User'
        }}
      </div>
    </div>
    <button mat-button color="primary" *ngIf="modalRequest?.scope === modalActions.EDIT_FACILITATOR" (click)="downgradeFacilitator()">
      <span class="d-flex align-items-center"><i class="far fa-trash-alt mr-2"></i>Downgrade to user</span>
    </button>
    <button
      mat-button
      color="primary"
      *ngIf="modalRequest?.scope === modalActions.EDIT_USER"
      (click)="emit(true, modalEmitActions.PROMOTE_TO_FACILITATOR)"
    >
      Promote to facilitator
    </button>
    <button
      mat-button
      color="primary"
      *ngIf="modalRequest?.scope === modalActions.EDIT_USER || modalRequest?.scope === modalActions.EDIT_FACILITATOR"
      (click)="promoteToLevel2()"
    >
      {{ user?.fdeTeamMember ? 'Level 2 User' : 'Promote to level 2' }}
    </button>
    <button mat-button color="warn" (click)="deleteFacilitator()">
      <span class="d-flex align-items-center"><i class="far fa-trash-alt mr-2"></i>Delete</span>
    </button>
  </div>

  <!-- Change user Role  -->
  <div
    class="bg-gray overflow-auto mb-5"
    *ngIf="
      isSuperAdmin &&
      modalRequest?.uuid !== currentUserUuid &&
      (modalRequest?.scope === modalActions.EDIT_FACILITATOR || modalRequest?.scope === modalActions.EDIT_USER)
    "
  >
    <div class="px-3 pt-2 mt-3">
      <div class="label mt-0">Change user Role</div>
    </div>
    <button mat-button color="primary" *ngIf="user?.role !== Role.SUPER_ADMIN" (click)="changeUserRole(UserRole.ADMIN, true)">
      Promote to Admin
    </button>
    <button mat-button color="primary" *ngIf="user?.specialUser === false" (click)="changeUserRole(UserRole.SUPER_ADMIN, true)">
      Promote to SUPER Admin
    </button>
    <button mat-button color="primary" *ngIf="user?.role === Role.SUPER_ADMIN && user?.specialUser === true" (click)="changeUserRole(UserRole.ADMIN)">
      Downgrade to Admin
    </button>
    <button mat-button color="primary" *ngIf="user?.role === Role.SUPER_ADMIN" (click)="changeUserRole(UserRole.USER)">Downgrade to User</button>
  </div>

  <div class="d-flex justify-content-between my-4">
    <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="emit(false, modalEmitActions.TRY_ENROLL)">Cancel</button>
    <button mat-flat-button class="filter-button uppercase" color="accent" [disabled]="tryToSubmit" (click)="submit()">
      <ng-container [ngSwitch]="modalRequest.scope">
        <span *ngSwitchCase="modalActions.PROMOTE_TO_FACILITATOR">Promote</span>
        <span *ngSwitchCase="modalActions.ADD_USER">Add User</span>
        <span *ngSwitchCase="modalActions.ADD_FACILITATOR">Add Facilitator</span>
        <span *ngSwitchDefault>Save Changes</span>
      </ng-container>
    </button>
  </div>
</div>
