import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AdminCohort } from 'src/app/internal-app/admin/tags/tags.model';
import { environment } from '../../../environments/environment';
import { COHORT_SCHEDULE_DATA, FilterType } from '../constants/Home';
import ComputePayload from '../helpers/ComputePayload';
import { Filter, FilterOption } from '../models/Filters';
import { ResponseObject, UUIDName } from '../models/GenericObject';
import { Church, ChurchDetails, Dropdowns, GroupsFilters, Industry, Language, Owner, Study, StudyDetails } from '../models/Home';
import { BasicPaginatedRequestMain, PaginatedResponse } from '../models/Shared';
import { RegionType } from './../constants/RegionType';
import { ChurchesPaginatedRequest } from './../models/Shared';

export const InterceptorSkipBearer = 'X-Skip-Authorization-Bearer';

@Injectable({
  providedIn: 'root',
})
export class GroupDropdownService {
  headers = new HttpHeaders().set(InterceptorSkipBearer, '');
  filtersDropdowns$ = new ReplaySubject<GroupsFilters>(1);
  dropdownsData$ = new ReplaySubject<Dropdowns>(1);
  private filters: GroupsFilters;
  private dropdownsData: Dropdowns;

  // prettier-ignore
  constructor(
    private http: HttpClient
  ) {
    if (this.filters === undefined) {
      this.getFilterDropdowns();
    }
  }

  public getFilterDropdowns(): void {
    this.http.get(`${environment.server}/v1/groups/dropdowns`, { headers: this.headers }).subscribe((response: ResponseObject<Dropdowns>) => {
      this.dropdownsData = response.response;
      this.filters = new GroupsFilters(this.dropdownsData);
      this.setDropdownsData(this.dropdownsData);
      this.setFiltersDropdowns(this.filters);
    });
  }

  private setFiltersDropdowns(filters: GroupsFilters): void {
    this.filtersDropdowns$.next(filters);
  }

  private setDropdownsData(dropdowns: Dropdowns): void {
    this.dropdownsData$.next(dropdowns);
  }

  loadStudies(groupRole: string = null): Observable<any> {
    const payload: BasicPaginatedRequestMain = ComputePayload.basicPaginatedRequestStudies(this.filters.study);
    return this.http
      .post(`${environment.server}/v1/study/get-studies-main`, payload, {
        headers: this.headers,
        params: { groupRole: groupRole ? groupRole?.toUpperCase() : 'NONE' },
      })
      .pipe(
        tap((res: ResponseObject<PaginatedResponse<StudyDetails>>) => {
          this.filters.study.filters = [
            ...this.filters.study.filters,
            ...res.response.objects.map((study: StudyDetails, index: number) => {
              const selected = this.filters.study.shouldBeSelected(index, study.studyUuid);
              return new Study(study, selected);
            }),
          ];
          this.filters.study.page++;
          this.filters.study.pagination.setPagination(res.response.totalObjects, res.response.to);
        })
      );
  }

  reloadStudies(groupRole: string = null): Observable<any> {
    this.filters.study.filters = [];
    this.filters.study.clearFilter();
    return this.loadStudies(groupRole);
  }

  loadChurches(region?: RegionType, groupRole: string = null): Observable<any> {
    const payload: ChurchesPaginatedRequest = ComputePayload.basicPaginatedRequestChurches(this.filters.churches, groupRole);
    if (region !== RegionType.ALL) {
      payload.region = region;
    }
    return this.http.post(`${environment.server}/v1/church/get-churches`, payload, { headers: this.headers }).pipe(
      tap((res: ResponseObject<PaginatedResponse<ChurchDetails>>) => {
        this.filters.churches.filters = [
          ...this.filters.churches.filters,
          ...res.response.objects.map((church: ChurchDetails, index: number) => {
            const selected = this.filters.churches.shouldBeSelected(index, church.uuid);
            return new Church(church, selected);
          }),
        ];
        this.filters.churches.page++;
        this.filters.churches.pagination.setPagination(res.response.totalObjects, res.response.to);
      })
    );
  }

  reloadChurches(region?: RegionType, groupRole: string = null, resetSearch: boolean = true) {
    this.filters.churches.filters = [];
    if (resetSearch) {
      this.filters.churches.clearFilter();
    } else {
      const searchText = this.filters.churches.searchText;
      this.filters.churches.clearFilter();
      this.filters.churches.searchText = searchText;
    }
    return this.loadChurches(region, groupRole);
  }

  reloadCohorts(cohortList: AdminCohort[]): void {
    this.dropdownsData.cohortList = cohortList;
    this.filters.cohorts = new Filter(
      FilterType.SCHEDULE,
      cohortList.map((item: AdminCohort) => new FilterOption(item.uuid, item.name, false)),
      COHORT_SCHEDULE_DATA
    );
    this.setDropdownsData(this.dropdownsData);
    this.setFiltersDropdowns(this.filters);
  }

  reloadAudiences(audiencesList: UUIDName[]): void {
    this.dropdownsData.groupAudienceList = audiencesList;
    this.filters.audience = new Filter(
      FilterType.GROUP_AUDIENCE,
      audiencesList.map((item: UUIDName) => new FilterOption(item.uuid, item.name, false))
    );
    this.setDropdownsData(this.dropdownsData);
    this.setFiltersDropdowns(this.filters);
  }

  reloadOwners(ownersList: Owner[]): void {
    this.dropdownsData.groupOwnerList = ownersList;
    this.filters.owners = new Filter(
      FilterType.GROUP_OWNERS,
      ownersList.map((item: Owner) => new FilterOption(item.uuid, `${item.firstName} ${item.lastName}`, false))
    );
    this.setDropdownsData(this.dropdownsData);
    this.setFiltersDropdowns(this.filters);
  }

  reloadLanguages(languagesList: Language[]): void {
    this.dropdownsData.languageList = languagesList;
    this.setDropdownsData(this.dropdownsData);
  }

  reloadIndustryCategories(industryCategoriesList: Industry[]): void {
    this.dropdownsData.industryCategoriesList = industryCategoriesList;
    this.setDropdownsData(this.dropdownsData);
  }
}
