import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalEmitActions } from 'src/app/_core/constants/Modals';
import { AlreadyApprovedGroups } from 'src/app/_core/models/Groups';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-already-approved-for-group',
  templateUrl: './already-approved-for-group.component.html',
  styleUrls: ['./already-approved-for-group.component.scss'],
})
export class AlreadyApprovedForGroupComponent implements OnInit, OnDestroy {
  modalRequest: ModalRequest<AlreadyApprovedGroups[]>;
  breakpointSubscription: Subscription;
  isAuth: boolean;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private dialogRef: MatDialogRef<AlreadyApprovedForGroupComponent>,
    private breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isAuth = !!this.activatedRoute.snapshot.queryParams.reason;
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      this.updateSize(result.matches);
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.updateSize(this.breakpointObserver.isMatched('(max-width: 767px)'));
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  updateSize(isMobile: boolean): void {
    if (this.isAuth) {
      this.dialogRef.updateSize('430px', 'auto');
    } else {
      isMobile ? this.dialogRef.updateSize('90vw', 'auto') : this.dialogRef.updateSize('580px', 'auto');
    }
  }

  emit(confirm: boolean): ModalResponse<AlreadyApprovedGroups[]> {
    return new ModalResponse<AlreadyApprovedGroups[]>(confirm, this.modalRequest, ModalEmitActions.ALREADY_APPROVED_FOR_ANOTHER_GROUP);
  }

  close(): void {
    this.dialogRef.close();
  }
}
