import { UserLink } from '../models/Links';
import { Urls } from './Urls';
import { Role } from './UserEnums';

export enum UserMenu {
  GROUPS = 'GROUPS',
  FACILITATORS = 'FACILITATORS',
  USERS = 'USERS',
  STUDIES = 'STUDIES',
  GROUP_REQUESTS = 'GROUP_REQUESTS',
  MY_PROFILE = 'MY_PROFILE',
  GROUPS_I_JOIN = 'GROUPS_I_JOIN',
  GROUPS_I_LEAD = 'GROUPS_I_LEAD',
  SIGN_OUT = 'SIGN_OUT',
  LOCATIONS = 'LOCATIONS',
  CHURCHES = 'CHURCHES',
  PARTNER_ORGANIZATIONS = 'PARTNER_ORGANIZATIONS',
  TAGS = 'TAGS',
  FOR_FOUNDATION_STUDY = 'FOR_FOUNDATION_STUDY',
  FOR_FAITH_DRIVEN_ENTREPRENEUR_STUDY = 'FOR_FAITH_DRIVEN_ENTREPRENEUR_STUDY',
  PROMOTIONAL_GRAPHICS = 'PROMOTIONAL_GRAPHICS',
  FOR_FAITH_DRIVEN_INVESTING_STUDY = 'FOR_FAITH_DRIVEN_INVESTING_STUDY',
}

export enum UserMenuSection {
  ADMINISTRATION = 'ADMINISTRATION',
  ACCOUNT = 'ACCOUNT',
  FACILITATOR_RESOURCES = 'FACILITATOR_RESOURCES',
}

export const UserMenuLinks: UserLink[] = [
  {
    name: UserMenu.FACILITATORS,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_FACILITATORS}`,
    section: UserMenuSection.ADMINISTRATION,
    userRole: [Role.SUPER_ADMIN],
    facilitator: false,
  },
  {
    name: UserMenu.STUDIES,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_STUDIES}`,
    section: UserMenuSection.ADMINISTRATION,
    userRole: [Role.SUPER_ADMIN],
    facilitator: false,
  },
  {
    name: UserMenu.GROUPS,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_GROUPS}`,
    section: UserMenuSection.ADMINISTRATION,
    userRole: [Role.SUPER_ADMIN],
    facilitator: false,
  },
  {
    name: UserMenu.LOCATIONS,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_LOCATIONS}`,
    section: UserMenuSection.ADMINISTRATION,
    userRole: [Role.SUPER_ADMIN],
    facilitator: false,
  },
  {
    name: UserMenu.CHURCHES,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_CHURCHES}`,
    section: UserMenuSection.ADMINISTRATION,
    userRole: [Role.SUPER_ADMIN],
    facilitator: false,
  },
  {
    name: UserMenu.PARTNER_ORGANIZATIONS,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_PARTNER_ORGANIZATIONS}`,
    section: UserMenuSection.ADMINISTRATION,
    userRole: [Role.SUPER_ADMIN],
    facilitator: false,
  },
  {
    name: UserMenu.USERS,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_USERS}`,
    section: UserMenuSection.ADMINISTRATION,
    userRole: [Role.SUPER_ADMIN],
    facilitator: false,
  },
  {
    name: UserMenu.TAGS,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_TAGS}`,
    section: UserMenuSection.ADMINISTRATION,
    userRole: [Role.SUPER_ADMIN],
    facilitator: false,
  },
  {
    name: UserMenu.GROUP_REQUESTS,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.GROUPS_REQUESTS}`,
    section: UserMenuSection.ADMINISTRATION,
    userRole: [Role.SUPER_ADMIN],
    facilitator: false,
  },
  {
    name: UserMenu.MY_PROFILE,
    url: '',
    section: UserMenuSection.ACCOUNT,
    userRole: [Role.USER, Role.SUPER_ADMIN],
    facilitator: true,
  },
  {
    name: UserMenu.GROUPS_I_JOIN,
    url: `${Urls.APP}/${Urls.MY_GROUPS}`,
    section: UserMenuSection.ACCOUNT,
    userRole: [Role.USER, Role.SUPER_ADMIN],
    facilitator: true,
  },
  {
    name: UserMenu.GROUPS_I_LEAD,
    url: `${Urls.APP}/${Urls.ADMIN}/${Urls.GROUPS_I_LEAD}`,
    section: UserMenuSection.ACCOUNT,
    userRole: [Role.FACILITATOR],
    facilitator: true,
  },
  {
    name: UserMenu.FOR_FOUNDATION_STUDY,
    url: 'https://www.faithdrivenentrepreneur.org/foundation-facilitator',
    section: UserMenuSection.FACILITATOR_RESOURCES,
    userRole: [Role.FACILITATOR, Role.SUPER_ADMIN],
    facilitator: true,
    description: '(8-Session Series)',
  },
  {
    name: UserMenu.FOR_FAITH_DRIVEN_ENTREPRENEUR_STUDY,
    url: 'https://www.faithdrivenentrepreneur.org/entrepreneur-group-facilitator',
    section: UserMenuSection.FACILITATOR_RESOURCES,
    userRole: [Role.FACILITATOR, Role.SUPER_ADMIN],
    facilitator: true,
  },
  // {
  //   name: UserMenu.PROMOTIONAL_GRAPHICS,
  //   url: 'https://www.faithdrivenentrepreneur.org/group-resources',
  //   section: UserMenuSection.FACILITATOR_RESOURCES,
  //   userRole: [Role.FACILITATOR, Role.SUPER_ADMIN],
  //   facilitator: true,
  // },
  {
    name: UserMenu.FOR_FAITH_DRIVEN_INVESTING_STUDY,
    url: 'https://www.faithdriveninvestor.org/facilitator-hub',
    section: UserMenuSection.FACILITATOR_RESOURCES,
    userRole: [Role.FACILITATOR, Role.SUPER_ADMIN],
    facilitator: true,
    description: '(6-Session Series)',
    divider: true,
  },
  {
    name: UserMenu.SIGN_OUT,
    url: '',
    section: UserMenuSection.ACCOUNT,
    userRole: [Role.USER, Role.SUPER_ADMIN],
    facilitator: true,
  },
];

export enum APP_LINKS {
  MEDIA_LINK_USER = 'https://fde-group-finder-tool.s3.amazonaws.com/videos/FDE+Groups+Trailer+CBR+UHD+FINAL+v7.mp4',
  MEDIA_LINK_FACILITATOR = 'https://fde-group-finder-tool.s3.amazonaws.com/videos/What+are+Faith+Driven+Entrepreneur+Groups_.mp4',
  LEAD_A_GROUP = 'https://www.faithdrivenentrepreneur.org/lead-a-group',
  LEARN_MORE_ABOUT_GROUPS = 'https://www.faithdrivenentrepreneur.org/groups',
}
