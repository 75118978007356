import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, from } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { GroupRoles, MainGroupRoles } from '../constants/AdminGroups';
import { Urls } from '../constants/Urls';

export declare type QueryParams = { [key: string]: string };

@Injectable({ providedIn: 'root' })
export class QueryParamsService {
  private _queryParams: QueryParams = {};
  private _storedParams: QueryParams = {};
  private queryParamsSource: ReplaySubject<any> = new ReplaySubject<any>();
  $queryParams: Observable<any> = this.queryParamsSource.asObservable();

  constructor(private viewportScroller: ViewportScroller, private router: Router, private route: ActivatedRoute) {}

  add(qparams: QueryParams): void {
    this._queryParams = { ...this._queryParams, ...qparams };
    this.queryParamsSource.next(this._queryParams);
  }

  remove(qparamName: string): void {
    if (this._queryParams[qparamName]) delete this._queryParams[qparamName];
    this.queryParamsSource.next(this._queryParams);
  }

  getParams(): QueryParams {
    return { ...this._queryParams };
  }

  initEntrepreneurGroupsParams(): void {
    this._queryParams = {
      userRole: GroupRoles.ENTREPRENEUR,
    };
  }

  initInvestorGroupsParams(investorType: string = null): void {
    const investorTypes = investorType ? { investorTypes: investorType } : {};
    this._queryParams = {
      ...this._queryParams,
      userRole: MainGroupRoles.INVESTOR,
      ...investorTypes,
    };
  }

  initQueryParams(groupRole: string, investorType: string = null): void {
    if (groupRole === GroupRoles.ENTREPRENEUR) {
      this.initEntrepreneurGroupsParams();
    } else {
      this.initInvestorGroupsParams(investorType);
    }
    this.queryParamsSource.next(this._queryParams);
  }

  resetParams(filter: QueryParams = {}): void {
    this._queryParams = filter;
    this.queryParamsSource.next(this._queryParams);
  }

  updateUrl(): Observable<any> {
    const ScrollPosition = this.viewportScroller.getScrollPosition();
    return from(this.router.navigate([], { queryParams: this.getParams(), replaceUrl: true })).pipe(
      first(),
      tap(() => this.viewportScroller.scrollToPosition(ScrollPosition))
    );
  }

  storeParams(): void {
    this._storedParams = this._queryParams;
  }

  clearStoredParams(): void {
    this._storedParams = {};
  }

  retrieveStoredParams(): QueryParams {
    return this._storedParams;
  }

  hasEntries(): boolean {
    return Object.entries(this._queryParams).length > 0;
  }

  setUrlToQPService(): void {
    if (!this.hasEntries()) {
      if (Object.entries(this.route.snapshot.queryParams).length > 0) {
        this.add(this.route.snapshot.queryParams);
      } else {
        this.router.navigate([Urls.WIZARD_HOME]);
      }
    }
  }

  setAdminUrlToQPService(): void {
    if (Object.entries(this.route.snapshot.queryParams).length > 0) {
      this.add(this.route.snapshot.queryParams);
    }
  }
}
