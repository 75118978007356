<div class="d-flex flex-column justify-content-between h-100">
  <a class="text-right" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center">
    <h2>Your account has been successfully created!</h2>
    <div class="description">
      Please check your email inbox for a confirmation email. If you don't see it in your inbox, please check your spam or junk folder.
    </div>
    <div class="description">Thank you!</div>
  </div>

  <div class="button-container">
    <button mat-flat-button class="confirm-button" (click)="close()">Got it!</button>
  </div>
</div>
