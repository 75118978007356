import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { Subscription, of } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { AdminChurchService } from 'src/app/_core/api/admin-church.service';
import { UploaderApiService } from 'src/app/_core/api/uploader-api.service';
import { FileType } from 'src/app/_core/constants/FileType';
import { ModalActions, ModalEmitActions, Modals } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import ComputePayload from 'src/app/_core/helpers/ComputePayload';
import { CustomValidators } from 'src/app/_core/helpers/CustomValidators.helpers';
import FileUtils from 'src/app/_core/helpers/FileUtils';
import Utils from 'src/app/_core/helpers/Utils';
import { AdminChurchDetails } from 'src/app/_core/models/AdminChurches';
import { FileDetails } from 'src/app/_core/models/FileInfo';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { Location } from 'src/app/_core/models/Location';
import { ModalFileResponse, ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { Region, RegionDto } from 'src/app/_core/models/Region';
import { UploadPromiseResponse } from 'src/app/_core/models/Upload';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UploadService } from 'src/app/_core/services/upload.service';

@Component({
  selector: 'app-add-church',
  templateUrl: './add-church.component.html',
  styleUrls: ['./add-church.component.scss'],
})
export class AddChurchComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  uploader: FileUploader;
  modalRequest: ModalRequest;
  modals = Modals;
  modalActions = ModalActions;
  modalEmitActions = ModalEmitActions;
  addChurchForm: UntypedFormGroup;
  churchDetails: AdminChurchDetails;
  churchPhoto: FileDetails = new FileDetails(null, null, null, null);
  liveSearchMainlandResults: Location[] = [];
  submitted: boolean;
  loading: boolean;
  selectedTabIndex: number = 0;

  // prettier-ignore
  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private uploadService: UploadService,
    private modalService: ModalsService,
    private uploaderApiService: UploaderApiService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<AddChurchComponent>,
    private adminChurchService: AdminChurchService,
  ) {
    this.subscription.add(this.modalService.modalResponse$.subscribe((response: ModalFileResponse) => {
      this.handleModalResponse(response);
    }));

    this.subscription.add(this.uploadService.uploaderObservable.subscribe(data =>
      data.progress ? this.setProgress(data.result) : this.setFileFields(data.result)
    ));
  }

  ngOnInit(): void {
    this.uploader = this.uploadService.uploaderInstance;
    this.modalRequest = this.modalService?.params;
    this.createForm();
    if ([ModalActions.EDIT_CHURCH, ModalActions.EDIT_CHURCH_REMOTELY].includes(this.modalRequest.scope)) {
      this.getChurchDetails();
    }
    if ([ModalActions.EDIT_CHURCH, ModalActions.ADD_CHURCH].includes(this.modalRequest.scope)) {
      this.subscription.add(
        this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
          result.matches ? this.dialogRef.updateSize('100vw', '100vh') : this.dialogRef.updateSize('50vw', '100vh');
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getChurchDetails(): void {
    this.loading = true;
    this.adminChurchService.getChurch(this.modalRequest.uuid).subscribe(
      (res: ResponseObject<AdminChurchDetails>) => {
        this.churchDetails = res.response;
        this.prefillForm();
        this.setChurchImage();
        this.loading = false;
      },
      () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      }
    );
  }

  createForm(): void {
    this.addChurchForm = this.formBuilder.group({
      churchUuid: [null],
      churchName: [null, [Validators.required]],
      mainland: [null, [Validators.required, CustomValidators.mainland]],
      website: [null, [Validators.required, CustomValidators.website]],
      churchPictureUrl: [null, [Validators.required]],
      visible: [true, [Validators.required]],
      description: [null, [Validators.maxLength(500)]],
      headline: [null, [Validators.maxLength(150)]],
    });

    this.mainlandLiveSearch();
  }

  prefillForm(): void {
    this.addChurchForm.patchValue({
      churchUuid: this.churchDetails.uuid,
      churchName: this.churchDetails.name,
      mainland: new Location(this.churchDetails.mainland),
      website: this.churchDetails.websiteUrl,
      churchPictureUrl: this.churchDetails.imageUrl,
      visible: this.churchDetails.visible,
      description: this.churchDetails.description,
      headline: this.churchDetails.headline,
    });
  }

  smallModalSubmit(): void {
    if (this.selectedTabIndex === 0) {
      if (this.addChurchForm.pristine && this.addChurchForm.untouched) {
        this.toastr.info(...ToastrMessages.NO_CHANGES);
        return;
      }
      this.addChurchForm.markAllAsTouched();
      this.submitted = true;
      if (this.addChurchForm.invalid) {
        this.toastr.error(...ToastrMessages.MISSING_REQUIRED_FIELDS);
        return;
      }
      this.selectedTabIndex++;
    } else {
      this.submit();
    }
  }

  submit(): void {
    if (this.addChurchForm.pristine && this.addChurchForm.untouched) {
      Utils.clearErrorsOfFormGroup(this.addChurchForm);
      this.toastr.info(...ToastrMessages.NO_CHANGES);
      return;
    }

    this.addChurchForm.markAllAsTouched();
    this.submitted = true;
    Utils.checkValueAndValidityOfFormGroup(this.addChurchForm);

    if (this.addChurchForm.invalid) {
      this.toastr.error(...ToastrMessages.MISSING_REQUIRED_FIELDS);
      return;
    }

    this.loading = true;
    const payload = ComputePayload.createUpdateChurch(this.addChurchForm.value, this.churchDetails?.verified);
    this.adminChurchService.createUpdateChurch(payload).subscribe({
      next: (res) => {
        if ([ModalActions.EDIT_CHURCH, ModalActions.EDIT_CHURCH_REMOTELY].includes(this.modalRequest.scope)) {
          this.toastr.success(...ToastrMessages.CHURCH_SUCCESSFULLY_EDITED);
        } else {
          this.toastr.success(...ToastrMessages.CHURCH_SUCCESSFULLY_ADDED);
        }
        if (!this.modalRequest.uuid) this.modalRequest.uuid = res.response;
        this.modalRequest.payload = this.churchName.value;
        this.loading = false;
        this.emit(ModalEmitActions.CHURCH_ADDED);
      },
      error: () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      },
    });
  }

  mainlandLiveSearch(): void {
    this.mainland.valueChanges
      .pipe(
        filter((value) => typeof value === 'string' && value.trim().length > 0),
        debounceTime(500),
        tap(() => (this.liveSearchMainlandResults = [])),
        switchMap((value: string) => this.adminChurchService.searchMainland(value).pipe(catchError((err) => of(err))))
      )
      .subscribe((res: ResponseObject<RegionDto[]>) => {
        const regions = res.response.map((regionData) => new Region(regionData));
        regions.forEach((region) => {
          region.subRegions.forEach((subRegion) => (this.liveSearchMainlandResults = [...this.liveSearchMainlandResults, ...subRegion.locations]));
        });
      });
  }

  displayFn(item: Location): string | undefined {
    if (!item) return '';
    return `${item.subRegion}, ${item.name}`;
  }

  emit(modalEmitAction: ModalEmitActions): void {
    const response = new ModalResponse(true, this.modalRequest, modalEmitAction);
    this.modalService.emitResponse(response);
    this.close();
  }

  setChurchImage(): void {
    if (this.churchDetails.imageUrl) {
      this.churchPhoto.path = this.churchDetails.imageUrl;
    }
  }

  handleImageUpload(): void {
    if (this.churchPhoto?.path) {
      this.uploaderApiService.deleteImage({ fileType: FileType.CHURCH_IMAGE, fileUuid: this.churchUuid.value }).subscribe(() => {
        this.churchPhoto = new FileDetails(null, null, null, null);
        this.churchPictureUrl.setValue(null);
        this.churchPictureUrl.markAsDirty();
      });
    } else {
      this.modalService.openModal(Modals.CROP_IMAGE, {
        aspectRatio: 1,
        fileType: FileType.CHURCH_IMAGE,
        idx: null,
      });
    }
  }

  // openCropModal(): void {
  //   this.modalService.openModal(Modals.CROP_IMAGE, { aspectRatio: 1, fileType: FileType.CHURCH_IMAGE });
  // }

  uploadImageAfterCrop(payload: string, fileType: FileType, target: string): void {
    const fileToUpload: FileItem = this.uploader.queue[this.uploader.queue.length - 1];
    fileToUpload.formData.fileType = fileType;
    fileToUpload.formData.entityUuid = this.modalRequest?.uuid;
    fileToUpload.formData.formControlName = target;
    fileToUpload._file = FileUtils.convertB64ToImage(payload, fileToUpload);
    this.churchPhoto.pendingUpload += 1;
    this.uploadService.uploadFile();
  }

  setProgress(response: UploadPromiseResponse): void {
    this.churchPhoto.progress = +response.response;
  }

  setFileFields(response: UploadPromiseResponse): void {
    const responseData = JSON.parse(response.response);
    const parsedData = responseData.response;
    const fileName = FileUtils.computeFileName(response.fileItem.file.name);

    if (responseData.hasOwnProperty('error')) {
      this.toastr.error(...ToastrMessages.BASIC_ERROR);
      this.churchPhoto.pendingUpload -= 1;
      return;
    }

    this.churchUuid.patchValue(parsedData.objectUuid);
    this.churchPictureUrl.patchValue(parsedData.url);
    this.churchPhoto.uuid = parsedData.objectUuid;
    this.churchPhoto.name = fileName;
    this.churchPhoto.path = parsedData.url;
    this.churchPhoto.fileType = FileType.CHURCH_IMAGE;
    this.churchPhoto.isFormatValid = true;
    this.churchPhoto.pendingUpload -= 1;
  }

  handleModalResponse(response: ModalFileResponse): void {
    if (response?.confirmed) {
      switch (response.modalRequest.scope) {
        case ModalActions.CROP:
          this.uploadImageAfterCrop(response.payload, response.fileType, response.target);
          break;
        default:
          break;
      }
    }
  }

  smallModalBack(): void {
    if (this.selectedTabIndex === 1) this.selectedTabIndex--;
    else this.close();
  }

  navigate(url: string): void {
    Utils.navigate(url);
  }

  close(): void {
    this.dialogRef.close();
  }

  get isSidePanel(): boolean {
    return [ModalActions.ADD_CHURCH, ModalActions.EDIT_CHURCH, ModalActions.ADD_CHURCH_REMOTELY].includes(this.modalRequest.scope);
  }

  get churchUuid(): AbstractControl {
    return this.addChurchForm.get('churchUuid');
  }
  get churchName(): AbstractControl {
    return this.addChurchForm.get('churchName');
  }
  get mainland(): AbstractControl {
    return this.addChurchForm.get('mainland');
  }
  get website(): AbstractControl {
    return this.addChurchForm.get('website');
  }
  get churchPictureUrl(): AbstractControl {
    return this.addChurchForm.get('churchPictureUrl');
  }
  get visible(): AbstractControl {
    return this.addChurchForm.get('visible');
  }
  get description(): AbstractControl {
    return this.addChurchForm.get('description');
  }
  get headline(): AbstractControl {
    return this.addChurchForm.get('headline');
  }
}
