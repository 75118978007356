import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalActions, ModalEmitActions, Modals } from 'src/app/_core/constants/Modals';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { REGION_TYPES, RegionType } from '../../../_core/constants/RegionType';
import { RegionsTabService } from '../../../_core/services/regions-tab.service';

@Component({
  selector: 'app-region-filters',
  templateUrl: './region-filters.component.html',
  styleUrls: ['./region-filters.component.scss'],
})
export class RegionFiltersComponent implements OnInit, OnChanges {
  @Output() selectedRegion = new EventEmitter<RegionType>();
  @Input() searchedRegion: RegionType | string;

  regionFilters = REGION_TYPES;
  region: RegionType;
  subscription: Subscription = new Subscription();
  selectedFilter: RegionType | string;
  isMobile: boolean;

  constructor(
    private regionTabService: RegionsTabService,
    private analyticsService: AnalyticsService,
    private breakpointObserver: BreakpointObserver,
    private modalsService: ModalsService
  ) {
    this.subscription.add(
      this.breakpointObserver.observe('(max-width: 575px)').subscribe((result: BreakpointState) => {
        this.isMobile = result.matches;
      })
    );
    this.subscription.add(
      this.modalsService.modalResponse$.subscribe((response: ModalResponse<any>) => {
        if (response) this.handleModalResponse(response);
      })
    );
  }

  ngOnInit(): void {
    this.selectFilter(RegionType.ALL);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.searchedRegion.previousValue || this.searchedRegion) &&
      changes.searchedRegion.currentValue !== changes.searchedRegion.previousValue
    ) {
      this.selectedFilter = this.searchedRegion || RegionType.ALL;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectFilter(filter: RegionType): void {
    if (filter === this.selectedFilter) {
      this.selectedFilter = null;
      this.regionTabService.changeRegion(RegionType.ALL);
      this.selectedRegion.emit(RegionType.ALL);
      return;
    }
    this.changeToSelectedRegion(filter);
  }

  changeToSelectedRegion(filter: RegionType): void {
    this.selectedFilter = filter;
    this.analyticsService.logClickEvent(this.selectedFilter);
    this.regionTabService.changeRegion(filter);
    this.selectedRegion.emit(filter);
  }

  handleModalResponse(response: ModalResponse<any>): void {
    if (response.confirmed && response.modalEmitAction === ModalEmitActions.REGION_MOBILE_FILTER_SELECTED) {
      this.selectFilter(response.modalRequest.payload);
    }
  }

  openRegionsModal(): void {
    this.modalsService.openModal(Modals.REGION_FILTER_MOBILE, new ModalRequest(ModalActions.SELECT_MOBILE_REGION_FILTER));
  }
}
