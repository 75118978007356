import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../../../_core/services/modals.service';
import { Modals } from '../../../_core/constants/Modals';

@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss'],
})
export class StylesComponent implements OnInit {
  // prettier-ignore
  constructor(
    private modalService: ModalsService,
  ) {
  }

  ngOnInit(): void {
    this.modalService.openModal(Modals.SELECT_FILTER);
  }
}
