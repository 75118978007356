<mat-accordion>
  <mat-expansion-panel hideToggle [(expanded)]="panelExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span *ngIf="panelExpanded" class="uppercase mr-1">Hide</span>
        <span *ngIf="!panelExpanded && fieldName === 'name'" class="uppercase">Group Name Example</span>
        <span *ngIf="!panelExpanded && fieldName === 'audience'" class="uppercase">Audience Descriptions</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="fieldName === 'name'">
      <div class="name-format">
        <p class="font-weight-bold group-type">Group Name Examples:</p>
        <div class="pl-4">
          <p>Victory Church Entrepreneurs</p>
          <p>London Small Business Owners</p>
          <p>Female Founders in New England</p>
        </div>
      </div>
      <div class="name-format">
        <p class="font-weight-bold">For <span class="group-type">invite-only groups</span>:</p>
        <div class="pl-4">
          <p class="my-2">Please, include "<span class="font-weight-bold">Invite-Only</span>" in the Group Name.</p>
        </div>
      </div>
    </div>
    <div *ngIf="fieldName === 'audience'">
      <div class="name-format">
        <p class="font-weight-bold group-type">Audience Descriptions:</p>
        <div class="pl-4">
          <p><b>Regional</b>: In-person or online. Intended for a particular audience, such as a specific location or region.</p>
          <p>
            <b>Industry or Affinity</b>: In-person or online. Intended for a particular audience, such as a specific industry or demographic (ex.
            Aspiring Entrepreneurs, Female Founders)
          </p>
          <p><b>Church</b>: This group is connected to a local church.</p>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
