import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { memoize } from '@angular/cli/src/utilities/memoize';
import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminPartnerOrganizationService } from 'src/app/_core/api/admin-partner-organization.service';
import { ModalActions, ModalEmitActions, Modals } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import Utils from 'src/app/_core/helpers/Utils';
import { AdminPartnerOrganizationDetails, History } from 'src/app/_core/models/AdminChurches';
import { AdminGroupResume } from 'src/app/_core/models/AdminFacilitators';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UserService } from 'src/app/_core/services/user.service';

@Component({
  selector: 'app-view-partner-organization',
  templateUrl: './view-partner-organization.component.html',
  styleUrls: ['./view-partner-organization.component.scss'],
})
export class ViewPartnerOrganizationComponent {
  modalRequest: ModalRequest;
  partnerOrganizationDetails: AdminPartnerOrganizationDetails;
  modals = Modals;
  modalActions = ModalActions;
  breakpointSubscription: Subscription;
  isMobile: boolean;
  loading: boolean;
  isSuperAdmin = false;

  // prettier-ignore
  constructor(
    private toastr: ToastrService,
    private modalService: ModalsService,
    private adminPartnerOrganizationService: AdminPartnerOrganizationService,
    private dialogRef: MatDialogRef<ViewPartnerOrganizationComponent>,
    private breakpointObserver: BreakpointObserver,
    private currentUser: UserService,
  ) {
    this.isSuperAdmin = this.currentUser.isSuperAdmin();
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.dialogRef.updateSize('100vw', '100vh');
        this.isMobile = true;
      } else {
        this.dialogRef.updateSize('75vw', '100vh');
      }
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService?.params;
    this.getPartnerOrganizationDetails();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  getPartnerOrganizationDetails(): void {
    this.loading = true;
    this.adminPartnerOrganizationService.getPartnerOrganization(this.modalRequest.uuid).subscribe({
      next: (res: ResponseObject<AdminPartnerOrganizationDetails>) => {
        this.partnerOrganizationDetails = res.response;
        this.partnerOrganizationDetails.groups?.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.partnerOrganizationDetails.historyList?.forEach((item: History) => (item.date = DateUtils.UTCtoLocalNoOffset(parseInt(item.date))));
        this.loading = false;
      },
      error: () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      },
    });
  }

  confirmPartnerOrganization() {
    this.loading = true;
    this.adminPartnerOrganizationService.approvePartnerOrganization(this.modalRequest.uuid).subscribe({
      next: () => {
        this.partnerOrganizationDetails.verified = true;
        this.toastr.success(...ToastrMessages.PARTNER_ORGANIZATION_APPROVED);
        this.emit(ModalEmitActions.PARTNER_ORGANIZATION_VERIFIED);
        this.loading = false;
      },
      error: () => {
        this.toastr.error(...ToastrMessages.CHURCH_APPROVAL_FAILED);
        this.loading = false;
      },
    });
  }

  //Helper methods
  close(): void {
    this.dialogRef.close();
  }

  externalNavigate(value: string): void {
    Utils.navigate(value);
  }

  openModal(modalName: Modals, modalAction: ModalActions, payload: any = null): void {
    this.modalService.openModal(modalName, { ...this.modalRequest, scope: modalAction, payload });
  }

  emit(modalEmitAction: ModalEmitActions): void {
    const response = new ModalResponse(true, this.modalRequest, modalEmitAction);
    this.modalService.emitResponse(response);
    this.close();
  }

  @memoize
  get addressString(): string {
    return [
      this.partnerOrganizationDetails?.mainland?.region,
      this.partnerOrganizationDetails?.mainland?.subRegion,
      this.partnerOrganizationDetails?.mainland?.state,
    ]
      .filter((el) => {
        return el != null;
      })
      .join(', ');
  }
}
