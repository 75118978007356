import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RegionType } from '../constants/RegionType';

@Injectable({
  providedIn: 'root',
})
export class RegionsTabService {
  private regionSource = new BehaviorSubject(RegionType.ALL);
  private regionSearchSource = new BehaviorSubject('');
  currentRegion$ = this.regionSource.asObservable();
  searchRegion$ = this.regionSearchSource.asObservable();

  constructor() {}

  changeRegion(region: RegionType) {
    this.regionSource.next(region);
  }

  searchRegion(searchText: string) {
    this.regionSearchSource.next(searchText);
  }
}
