import DateUtils from 'src/app/_core/helpers/DateUtils';
import {
  Frequencies,
  GroupFormating,
  GroupStates,
  GroupTypes,
  MeetingType,
  MonthlyGroupRecurrence,
} from '../../../../../_core/constants/AdminGroups';
import { AdminGroupDetails, Participant, UnitStudy, ViewGroupUnit } from '../../../../../_core/models/AdminGroups';
import { UUIDName } from '../../../../../_core/models/GenericObject';
import { GroupChurchPartnerOrganization, GroupFacilitator } from '../../../../../_core/models/Groups';
import { GroupStatus } from '../../../_helpers/add-group/add-group.const';

export class SmartAdminGroup {
  activeUnit: SmartAdminGroupUnit;
  churches: GroupChurchPartnerOrganization[];
  name: string;
  companySizes: UUIDName[];
  units: SmartAdminGroupUnit[];
  uuid: string;
  status: GroupStatus;
  state: GroupStates;
  partners: GroupChurchPartnerOrganization[];

  constructor(adminGroupDto: AdminGroupDetails) {
    this.units = adminGroupDto.units.map((unit, i, units) => new SmartAdminGroupUnit(unit, adminGroupDto.uuid, i + 1 === units.length));
    this.activeUnit = this.units.find((unit) => unit.active);
    this.churches = adminGroupDto.churchesList;
    this.companySizes = adminGroupDto.companySizes;
    this.name = adminGroupDto.name;
    this.uuid = adminGroupDto.uuid;
    this.status = adminGroupDto.groupStatus;
    this.state = adminGroupDto.groupState;
    this.partners = adminGroupDto.partnerOrganizationsList;
  }
}

export class SmartAdminGroupUnit {
  active: boolean;
  groupUuid: string;
  uuid: string;
  startsOn: number;
  endsOn: number;
  localStartDate: string;
  localEndDate: string;
  timezone: string;
  timezoneName: string;
  startTimeWithDaylightSavingTime: string;
  facilitators: GroupFacilitator[];
  meetingAddress: string;
  meetingDate: string;
  frequencyType: GroupTypes;
  meetingFrequency: Frequencies;
  meetingLink: string;
  meetings: AdminGroupMeeting[];
  neverEnding: boolean;
  spotsLeft: number;
  study: UnitStudy;
  groupFormat: GroupFormating;
  approvedParticipants: Participant[];
  declinedParticipants: Participant[];
  pendingParticipants: Participant[];
  withdrawnParticipants: Participant[];
  isLast: boolean;
  isMonthly: boolean;
  isNew: boolean;
  isHybrid: boolean;
  isInPerson: boolean;
  isVirtual: boolean;
  areConfirmationEmailsOn: boolean;
  approvalNotes: string;

  constructor(unitDto: ViewGroupUnit, groupUuid: string, isLast: boolean) {
    this.active = unitDto.active;
    this.groupUuid = groupUuid;
    this.uuid = unitDto.uuid;
    this.startsOn = unitDto.startsOn;
    this.endsOn = unitDto.endsOn;
    this.localStartDate = DateUtils.formatDateTimeToLocalTimezoneLuxon(unitDto.startsOn);
    this.localEndDate = DateUtils.formatDateTimeToLocalTimezoneLuxon(unitDto.endsOn);
    this.timezone = unitDto.timezone;
    this.timezoneName = unitDto.timezoneName;
    this.startTimeWithDaylightSavingTime = DateUtils.getStartDateTimeWithDaylightSavingTime(this.startsOn, this.timezone);
    this.facilitators = unitDto.facilitatorsList;
    this.meetingFrequency = unitDto.frequencyType;
    this.meetingAddress = this.setMeetingAddress(unitDto);
    this.meetingLink = unitDto.meetingLink;
    this.neverEnding = unitDto.endDateNever;
    this.frequencyType = unitDto.meetingPlaceType;
    this.groupFormat = unitDto.groupFormat;
    this.meetings = unitDto.meetings.map((meeting) => new AdminGroupMeeting(meeting));
    this.meetingDate = this.setMeetingDate(unitDto);
    this.spotsLeft = unitDto.spotsLeft;
    this.study = unitDto.study;
    this.approvedParticipants = unitDto.approvedParticipants;
    this.declinedParticipants = unitDto.declinedParticipants || [];
    this.pendingParticipants = unitDto.pendingParticipants;
    this.withdrawnParticipants = unitDto.withdrawnParticipants;
    this.isLast = isLast;
    this.isMonthly = this.meetingFrequency === Frequencies.MONTHLY;
    this.isNew = unitDto.study.isOnboardingStudy;
    this.isHybrid = this.frequencyType === GroupTypes.HYBRID;
    this.isInPerson = this.frequencyType === GroupTypes.IN_PERSON;
    this.isVirtual = this.frequencyType === GroupTypes.VIRTUAL;
    this.areConfirmationEmailsOn = unitDto.emailNotifications.confirmation;
    this.approvalNotes = unitDto.approvalNotes;
  }

  private setMeetingAddress(unitDto: ViewGroupUnit): string {
    if (!unitDto.location) return null;
    const addressArray = [];
    if (unitDto.location.locationDetails) addressArray.push(unitDto.location.locationDetails);
    if (unitDto.location.address) addressArray.push(unitDto.location.address);
    else {
      if (unitDto.location.city) addressArray.push(unitDto.location.city);
      if (unitDto.location.stateProvince) addressArray.push(unitDto.location.stateProvince);
    }
    return addressArray.join(', ');
  }

  private setMeetingDate(unitDto: ViewGroupUnit): string {
    const recurency = this.isMonthly && unitDto.dayIndex ? `${MonthlyGroupRecurrence[unitDto.dayIndex]}` : '';
    const getDays: () => string = () => {
      // switch (true) {
      //   case !!unitDto.days?.length:
      //     const adjustedDays = DateUtils.adjustMeetingDayToUserTimezone(unitDto.startsOn, unitDto.days);
      //     console.log('adjustedDays :', adjustedDays);
      //     return adjustedDays.join(', ');
      //   default:
      //     return `${DateUtils.getDayNameOutOfMilliseconds(unitDto.startsOn)}`;
      // }
      // const luxonDate = DateUtils.formatDateTimeToLocalTimezoneLuxon(unitDto.startsOn);

      return DateUtils.getDayNameOutOfMilliseconds(unitDto.startsOn);
    };

    const getFrequency: () => string = () => {
      switch (this.meetingFrequency) {
        case Frequencies.MONTHLY:
        case Frequencies.WEEKLY:
          return this.meetingFrequency;
        case Frequencies.EVERY_2_WEEKS:
          return this.meetingFrequency.split('_').join(' ');
        case Frequencies.CUSTOM:
          return parseInt(unitDto.interval) === 1 ? 'Weekly' : `Every ${unitDto.interval} weeks`;
      }
    };
    return `${getFrequency()} ON ${recurency} ${getDays()}`;
  }
}

export class AdminGroupMeeting {
  completed: boolean;
  skipped: boolean;
  started: boolean;
  startTime: number;
  endTime: number;
  submitted: boolean;
  type: MeetingType;
  uuid: string;

  constructor(meetingDto) {
    this.completed = meetingDto.completed;
    this.skipped = meetingDto.skipped;
    this.startTime = meetingDto.startTime;
    this.endTime = meetingDto.endTime;
    this.submitted = meetingDto.submitted;
    this.type = meetingDto.type;
    this.uuid = meetingDto.uuid;
    this.started = DateUtils.compareDates(this.startTime);
  }
}
