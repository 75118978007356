import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import DateUtils from './DateUtils';
import Utils from './Utils';

export class CustomValidators {
  public static required(control: UntypedFormControl): { [key: string]: any } {
    return control.value &&
      (typeof control.value === 'number' || typeof control.value === 'object' || (typeof control.value === 'string' && control.value.trim()))
      ? null
      : { required: true };
  }

  public static requiredObject(control: UntypedFormControl): { [key: string]: any } {
    return Utils.objectHasValue(control.value) ? null : { required: true };
  }

  public static email(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp =
      /^[a-zA-Z0-9]+([._-]*[a-zA-Z0-9])*(?:\+[a-zA-Z0-9]+)?@([a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zAZ0-9])?)+)$/;
    return regExp.test(c.value?.trim()) ? null : { email: true };
  }

  public static linkedin(c: UntypedFormControl): { [key: string]: any } {
    if (c.value === null || c.value === '') {
      return null;
    }
    const regExp: RegExp =
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9À-ž@:%._\+~#=\u00C0-\u024F\u1E00-\u1EFF\u00C0-\u024F]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9À-ž@:%_\+.~#?&//=\u00C0-\u024F\u1E00-\u1EFF\u00C0-\u024F]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9À-ž@:%._\+~#=\u00C0-\u024F\u1E00-\u1EFF\u00C0-\u024F]{2,256}\.[a-z]{2,15}\b([-a-zA-Z0-9À-ž@:%_\+.~#?&//=\u00C0-\u024F\u1E00-\u1EFF\u00C0-\u024F]*)/;
    const regExpArray = regExp.exec(c.value);
    return regExpArray && regExpArray[0] === regExpArray.input ? null : { invalidWebsite: true };
  }

  public static website(c: UntypedFormControl): { [key: string]: any } {
    if (c.value === null || c.value === '') {
      return null;
    }
    const regExp: RegExp =
      /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,15}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    const regExpArray = regExp.exec(c.value);
    return regExpArray && regExpArray[0] === regExpArray.input ? null : { invalidWebsite: true };
  }

  public static minLength(value: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      return c.value && c.value.trim().length >= value ? null : { minlength: true };
    };
  }

  public static maxLength(value: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      return c.value && c.value.trim().length > value ? { maxlength: true } : null;
    };
  }

  public static lettersAndSomeSpecialCharacters(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /^([ \u00c0-\u01ffa-zA-Z'\-])+$/;
    return regExp.test(c.value) ? null : { noNameFormat: true };
  }

  public static passwordMatchValidator(control: AbstractControl): { [key: string]: any } {
    return control.get('password').value !== control.get('repeatPassword').value ? { confPass: true } : null;
  }

  public static emailMatchValidator(control: AbstractControl): { [key: string]: any } {
    return control.get('email').value?.trim() !== control.get('repeatEmail').value?.trim() ? { confEmail: true } : null;
  }

  public static lettersAndNumber(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return regExp.test(c.value) ? null : { noNumbers: true };
  }

  public static number(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /^[1-9][0-9]*$/;
    return !c.value || regExp.test(c.value) ? null : { invalid: true };
  }

  public static endTimeAfterStartTime(control: AbstractControl): { [key: string]: any } {
    if (!control.get('endTime').value) {
      return null;
    }
    return DateUtils.isTimeBefore(control.get('startTime').value, control.get('endTime').value) ? null : { endTime: true };
  }

  public static eventEndTimeAfterStartTime(control: AbstractControl): { [key: string]: any } {
    return DateUtils.isTimeBefore(control.get('eventStartTime').value, control.get('eventEndTime').value) ? null : { endTime: true };
  }

  public static startTimeAfterCurrentTime(control: AbstractControl): { [key: string]: any } {
    return moment(DateUtils.addTimeToDate(control.parent.parent.get('date').get('startDate').value, control?.value)).isBefore(moment())
      ? { startTimeBefore: true }
      : null;
  }

  public static startTimeEventAfterCurrentTime(control: AbstractControl): { [key: string]: any } {
    return moment(DateUtils.addTimeToDate(control.get('eventStartDate').value, control.get('eventStartTime').value)).isBefore(moment())
      ? { startTimeBefore: true }
      : null;
  }

  public static password(control: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /^.{8,}$/;
    return regExp.test(control.value) ? null : { password: true };
  }

  public static requiredOnlyOne(formGroup: UntypedFormGroup): { [key: string]: any } {
    const controls = formGroup.controls;
    const values = Object.values(controls);
    const selectedValues = values.filter((control) => control.value !== null && control.value !== '');
    return selectedValues.length === 1 ? null : { requiredOnlyOne: true };
  }

  public static duplicateControlValueInArray(controlName: string): ValidatorFn {
    return (formArray: UntypedFormArray): { [key: string]: any } | null => {
      const values = {};

      for (let i = 0; i < formArray.length; i++) {
        const formGroup = formArray.at(i);

        if (!formGroup.get(controlName)) {
          throw new Error(`Control '${controlName}' not found in form group at index ${i}`);
        }

        const value = formGroup.get(controlName)?.value;
        if (value === null) {
          return null;
        }

        if (values[value]) {
          return { duplicateControlValueInArray: true };
        } else {
          values[value] = true;
        }
      }

      return null;
    };
  }

  public static mainland(control: AbstractControl): { [key: string]: any } | null {
    return typeof control.value === 'object' && control.value?.uuid ? null : { invalidMainland: true };
  }
}
