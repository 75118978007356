<div class="d-flex flex-column justify-content-between h-100">
  <a class="text-right d-block" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center">
    <h3 class="font-weight-bold">{{ text }}</h3>
  </div>

  <div class="d-flex justify-content-between mt-5">
    <button mat-flat-button class="mat-medium-button" color="primary" (click)="close()">{{ cancelButtonText || 'Cancel' }}</button>
    <button mat-flat-button class="mat-medium-button" color="accent" (click)="confirm()">{{ confirmButtonText || 'Confirm' }}</button>
  </div>
</div>
