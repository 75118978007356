export class UsersImport {
  columnErrors: string[];
  users: UserImportData[];
  submitted: boolean;

  constructor(importData: any) {
    this.columnErrors = importData.columnErrors;
    this.users = importData.users.map((user) => new UserImportData(user));
    this.submitted = this.users.some((user) => user.submitted);
  }

  get hasInvalidEntries(): boolean {
    return this.users.some((user) => user.listOfErrorFlags.length) || !!this.columnErrors.length;
  }
}

export class UserImportData {
  name: string;
  email: string;
  phoneNumber: string;
  linkedinProfile: string;
  address: string;
  countryCode: string;
  timezone: string;
  timezoneName: string;
  coordinates: string;
  learnedAbout: string;
  groupUuid: string;
  groupName: string;
  errorMessages: ImportErrors;
  infoMessages: ImportInfo;
  listOfErrorFlags: string[];
  listOfInfoFlags: string[];
  status: ImportStatus;
  expanded: boolean;
  submitted: boolean;

  constructor(importData: any) {
    this.errorMessages = new ImportErrors(importData);
    this.listOfErrorFlags = Object.keys(this.errorMessages).filter((key) => this.errorMessages[key].show);
    this.infoMessages = new ImportInfo(importData);
    this.listOfInfoFlags = Object.keys(this.infoMessages).filter((key) => this.infoMessages[key].show);

    this.groupUuid = importData.rawData.groupUuid;
    this.groupName = importData.groupName;
    this.phoneNumber = this.setPhoneNumber(importData);
    this.address = this.setAddress(importData);
    this.linkedinProfile = this.setLinkedin(importData);
    const userData = importData.computedData;
    this.name = `${userData.firstName} ${userData.lastName}`;
    this.email = userData.email;
    this.countryCode = userData.countryCode || '(empty)';
    this.timezone = userData.timezone || '(empty)';
    this.timezoneName = userData.timezoneName || '(empty)';
    this.coordinates = userData.location ? `latitude ${userData.location.latitude}, longitude ${userData.location.longitude}` : '(empty)';
    this.expanded = false;
    this.submitted = !importData.checkOnly;
    if (this.submitted) this.setStatus(importData);
  }

  setLinkedin(importData: any): string {
    if (importData.computedData.linkedinProfile) return importData.computedData.linkedinProfile;
    if (this.errorMessages.invalidLinkedinLink) return importData.rawData.linkedin;
    return '(empty)';
  }

  setPhoneNumber(importData: any): string {
    if (this.errorMessages.missingPhoneNumber.show || this.errorMessages.invalidFormatPhoneNumber.show) {
      return importData.rawData.completePhoneNumber || '(empty)';
    }
    const phoneNumberInfo = importData.computedData.phoneNumber;
    return `${phoneNumberInfo.countryCode.toUpperCase()} ${phoneNumberInfo.completeNumber}`;
  }

  setAddress(importData: any): string {
    if (this.errorMessages.countryNotFoundInList.show || this.errorMessages.invalidCity.show || this.errorMessages.missingCity.show) {
      if (importData.rawData.city && importData.rawData.country) return `${importData.rawData.city}, ${importData.rawData.country}`;
      if (importData.rawData.country) return importData.rawData.country;
      return importData.rawData.city;
    }
    const locationData = importData.computedData.location;
    let address = locationData.address;
    if (locationData.stateProvince) address = `${locationData.stateProvince}, ${address}`;
    if (locationData.city !== locationData.stateProvince) address = `${locationData.city}, ${address}`;
    return address;
  }

  setStatus(importData: any): void {
    if (this.listOfErrorFlags.length) {
      this.status = new ImportStatus('Import errors occured', 'red');
    } else if (this.infoMessages.userAlreadyRegistered.show) {
      this.status = new ImportStatus('Already exists', 'blue');
    } else if (this.infoMessages.userAlreadyInvited.show) {
      this.status = new ImportStatus('Already invited', 'blue');
    } else if (importData.userWasSuccessfullyAddedToGroup) {
      this.status = new ImportStatus('Invited and added to group', 'green');
    } else {
      this.status = new ImportStatus('Invited', 'green');
    }
  }
}

class ImportInfo {
  userAlreadyInvited: ImportFlag;
  userAlreadyRegistered: ImportFlag;

  constructor(userData: any) {
    this.userAlreadyRegistered = new ImportFlag(
      userData.userAlreadyRegistered || userData.facilitatorAlreadyRegistered,
      'There is already an existing account with this email'
    );
    if (!this.userAlreadyRegistered.show) {
      this.userAlreadyInvited = new ImportFlag(userData.userAlreadyInvited, 'The user was already invited');
    }
  }
}

class ImportErrors {
  countryNotFoundInList: ImportFlag;
  groupAlreadyFull: ImportFlag;
  groupEnded: ImportFlag;
  groupNotActive: ImportFlag;
  groupNotFound: ImportFlag;
  invalidEmail: ImportFlag;
  invalidFormatPhoneNumber: ImportFlag;
  invalidLinkedinLink: ImportFlag;
  invalidNameFormat: ImportFlag;
  invalidNameLength: ImportFlag;
  inviteUserFailed: ImportFlag;
  maximumApplicationsReached: ImportFlag;
  invalidCity: ImportFlag;
  missingCity: ImportFlag;
  missingCountry: ImportFlag;
  missingPhoneNumber: ImportFlag;
  userAlreadyPartOfGroup: ImportFlag;
  duplicatedEntry: ImportFlag;

  constructor(userData: any) {
    this.countryNotFoundInList = new ImportFlag(userData.countryNotFoundInList, 'The country is invalid');
    this.invalidEmail = new ImportFlag(userData.invalidEmail, 'The email address is invalid');
    this.missingPhoneNumber = new ImportFlag(userData.missingPhoneNumber, 'The phone number is missing');
    if (!userData.missingPhoneNumber) {
      this.invalidFormatPhoneNumber = new ImportFlag(userData.invalidFormatPhoneNumber, 'The phone number format is invalid');
    }
    this.invalidLinkedinLink = new ImportFlag(userData.invalidLinkedinLink, 'The linkedin link is invalid');
    this.invalidNameFormat = new ImportFlag(userData.invalidNameFormat, 'The name format is invalid');
    this.invalidNameLength = new ImportFlag(userData.invalidNameLength, 'The name is too long');
    this.inviteUserFailed = new ImportFlag(userData.inviteUserFailed, 'The user could not be invited');
    this.missingCity = new ImportFlag(userData.missingCity, 'The city is missing from the address');
    this.invalidCity = new ImportFlag(userData.invalidCity, 'The city is not valid');
    this.missingCountry = new ImportFlag(userData.missingCountry, 'The country is missing from the address');
    this.duplicatedEntry = new ImportFlag(userData.duplicatedEntry, 'Duplicate email detected');
    if (userData.shouldAddToGroup) {
      this.maximumApplicationsReached = new ImportFlag(
        userData.maximumApplicationsReached,
        'The user reached the maximum applications count and could not be added to the group'
      ); //
      this.userAlreadyPartOfGroup = new ImportFlag(userData.userAlreadyPartOfGroup, 'The user is already a participant in the group');
      this.groupAlreadyFull = new ImportFlag(userData.groupAlreadyFull, 'The specified group is already full');
      this.groupEnded = new ImportFlag(userData.groupEnded, 'The speicified group has already ended');
      this.groupNotActive = new ImportFlag(userData.groupNotActive, 'The speicified group is not active');
      this.groupNotFound = new ImportFlag(userData.groupNotFound || userData.invalidGroupUuid, 'There is no group with the speicified ID');
    }
  }
}

class ImportFlag {
  show: boolean;
  message: string;

  constructor(show: boolean, message: string) {
    this.show = show;
    this.message = message;
  }
}

class ImportStatus {
  name: string;
  color: string;

  constructor(name: string, color: string) {
    this.name = name;
    this.color = color;
  }
}
