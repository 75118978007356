<div class="d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
  <h1 class="mb-0">{{ tag.name || tag.firstName ? 'Edit ' + tagTypeName : 'Add new ' + tagTypeName }}</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>

<div class="h-computed px-3 px-md-5 mt-4 d-flex flex-column justify-content-between">
  <div>
    <!--Image Language-->
    <div class="admin-input" *ngIf="tagType === filterTypes.GROUP_LANGUAGE">
      <img class="profilePic" [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/image-placeholder.png'" [lazyLoad]="languagePhoto?.path" />
      <h5 class="accent-color pointer mb-0 mt-1" (click)="handleImageUpload()">
        <i class="fas fa-upload"></i> {{ languagePhoto?.path ? 'DELETE PICTURE' : 'UPLOAD PICTURE' }}
      </h5>
      <mat-error *ngIf="tagImage.invalid && (tagImage.dirty || tagImage.touched || tag?.languagePictureUuid)" class="error">
        <app-validation-message [control]="tagImage" [controlName]="'tagImage'"></app-validation-message>
      </mat-error>
    </div>

    <!-- First Name -->
    <div class="admin-input">
      <label for="firstName" class="label">
        {{
          tagTypeName +
            ' ' +
            (tagType === filterTypes.GROUP_AUDIENCE || tagType === filterTypes.GROUP_LANGUAGE || tagType === filterTypes.GROUP_INDUSTRY
              ? 'name'
              : 'first name')
        }}
        <span class="primary-color">*</span>
      </label>
      <mat-form-field appearance="outline">
        <input matInput [formControl]="tagFirstName" [maxlength]="255" />
      </mat-form-field>
      <mat-error *ngIf="tagFirstName.touched && tagFirstName.invalid" class="error">
        <app-validation-message [control]="tagFirstName" [controlName]="'tagFirstName'"></app-validation-message>
      </mat-error>
    </div>

    <!-- Last Name -->
    <div class="admin-input" *ngIf="tagType === filterTypes.GROUP_OWNERS">
      <label for="firstName" class="label"> Owner last name <span class="primary-color">*</span> </label>
      <mat-form-field appearance="outline">
        <input matInput [formControl]="tagLastName" [maxlength]="255" />
      </mat-form-field>
      <mat-error *ngIf="tagLastName.touched && tagLastName.invalid" class="error">
        <app-validation-message [control]="tagLastName" [controlName]="'tagLastName'"></app-validation-message>
      </mat-error>
    </div>

    <!-- Email -->
    <div class="admin-input" *ngIf="tagType === filterTypes.GROUP_OWNERS">
      <label for="tagEmail" class="label"> Owner E-mail Address</label>
      <mat-form-field appearance="outline">
        <input matInput [formControl]="tagEmail" [maxlength]="255" />
      </mat-form-field>
      <mat-error *ngIf="tagEmail.touched && tagEmail.invalid" class="error">
        <app-validation-message [control]="tagEmail" [controlName]="'email'"></app-validation-message>
      </mat-error>
    </div>

    <!-- Industry Type -->
    <div class="admin-input" *ngIf="tagType === filterTypes.GROUP_INDUSTRY">
      <label for="tagIndustryType" class="label"> Industry/Affinity Type <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline">
        <mat-select [formControl]="tagIndustryType">
          <mat-option *ngFor="let type of industryTypes | objectIteration" [value]="type">{{ industryTypes[type] }} </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="tagIndustryType.invalid && (tagIndustryType.dirty || tagIndustryType.touched)" class="error">
        <app-validation-message [control]="tagIndustryType" [controlName]="'tagIndustryType'"></app-validation-message>
      </mat-error>
    </div>
  </div>

  <div class="d-flex justify-content-between my-4 pb-4">
    <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="close()">Cancel</button>
    <button mat-flat-button class="filter-button uppercase" color="accent" (click)="submit()">Save</button>
  </div>
</div>
