import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import * as moment from 'moment-timezone';
import { AdminGroupMeeting } from 'src/app/internal-app/admin/groups/_helpers/view-group/view-group.model';
import { AdminGroupResume } from '../models/AdminFacilitators';
import { GroupDetailsUnit, Meeting, UnitMeeting } from '../models/AdminGroups';
import { Group, GroupCardUnit } from '../models/Groups';
import { GroupRequestCard } from '../models/GroupsRequests';

export default class DateUtils {
  public static localToUTCNoOffset(date: any): number | null {
    return date ? moment(date).utc().valueOf() : null;
  }

  public static timezoneToUTCNoOffset(date: any, timezone: string): number | null {
    return date ? moment(date).tz(timezone).utc().valueOf() : null;
  }

  public static UTCtoLocalNoOffset(date: number): string | null {
    return date ? moment(date).toLocaleString() : null;
  }

  public static UTCtoTimezone(date: number, timezone: string): string | null {
    return date ? moment(date).tz(timezone)?.format('LL') : null;
  }

  public static localStartTime(startsOn: number): string {
    return moment(startsOn).format('LT');
  }

  public static localEndTime(startsOn: number, durationMinutes: number): string {
    return moment(startsOn).add(durationMinutes, 'minutes').format('LT');
  }

  public static timezoneStartTime(startsOn: number, timezone: string): string {
    return moment(startsOn).tz(timezone)?.format('LT');
  }

  public static timezoneEndTime(startsOn: number, durationMinutes: number, timezone: string): string {
    return moment(startsOn).add(durationMinutes, 'minutes').tz(timezone).format('LT');
  }

  public static extractMeetingTime(startsOn: number | Date, utc: boolean): string {
    return utc ? moment(startsOn).utc().format('LT') : moment(startsOn).format('LT');
  }

  public static extractMeetingTimeTimezone(startsOn: number | Date, utc: boolean, timezone: string): string {
    return utc ? moment(startsOn).tz(timezone).utc().format('LT') : moment(startsOn).tz(timezone).format('LT');
  }

  public static extractMeetingDay(startsOn: number | Date, utc: boolean): string {
    return utc ? moment(startsOn).utc().format('dddd') : moment(startsOn).format('dddd');
  }

  public static computeDuration(startTime: string, endTime: string): number {
    return moment(this.addTimeToDate(null, endTime)).diff(moment(this.addTimeToDate(null, startTime)), 'minutes');
  }

  public static extractTimeZone(): number {
    return +moment().format('ZZ') / 100;
  }

  public static addTimeToDate(date, time): string {
    return date ? moment(date).format('LL') + ' ' + time : moment().format('LL') + ' ' + time;
  }

  public static addTimeToDateTimezone(date, time, timezone): string {
    return date ? moment(date).tz(timezone).format('LL') + ' ' + time : moment().tz(timezone).format('LL') + ' ' + time;
  }

  public static compareDates(startsOn: string | number): boolean {
    return moment(startsOn).isBefore(moment());
  }

  public static compareDatesTimezone(startsOn: string | number, timezone: string): boolean {
    return moment(startsOn).tz(timezone).isBefore(moment().tz(timezone));
  }

  public static formatDateString(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  public static setLocalTime(group: Group | GroupRequestCard | AdminGroupResume | GroupCardUnit | GroupDetailsUnit): void {
    group.startsOn = DateUtils.UTCtoLocalNoOffset(group.startsOn);
    group.endsOn = DateUtils.UTCtoLocalNoOffset(group.endsOn);
    group.meetingTime = DateUtils.extractMeetingTime(group.startsOn, false);
    group.meetingDay = DateUtils.extractMeetingDay(group.startsOn, false);
  }

  public static soonerThan(startDate: Date | number, daysBefore: number) {
    const twoWeeks = new Date(startDate);
    twoWeeks.setDate(twoWeeks.getDate() - daysBefore);
    return moment(moment()).isAfter(twoWeeks);
  }

  public static isDateWithoutTimeSoonerThanCurrentDateWithAddedDays(startDate: Date | number, daysBefore: number) {
    const twoWeeks = new Date(startDate);
    twoWeeks.setHours(0, 0, 0, 0);
    twoWeeks.setDate(twoWeeks.getDate() - daysBefore);
    return moment(moment()).isAfter(twoWeeks);
  }

  public static soonerThanWithTimezone(startDate: Date, daysBefore: number, timezone: string): boolean {
    return moment().tz(timezone).isAfter(moment(startDate).tz(timezone).subtract(daysBefore, 'd'));
  }

  public static createDateAtSpecificTimezone(dateParam, timeParam, timezone): number {
    const date = new DatePipe('en-US').transform(dateParam, 'yyyy-MM-dd');
    const time = timeParam.toString();
    const fullDate = date + ' ' + time;
    const fullDateUTC = DateTime.fromFormat(fullDate, 'yyyy-MM-dd h:mm a', { zone: timezone }).valueOf();
    return fullDateUTC;
  }

  public static getStartDateTimeWithDaylightSavingTime(startOn, timezone) {
    const date = new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd');
    const time = moment(startOn).tz(timezone)?.format('LT');
    const fullDate = date + ' ' + time;
    const resultWithTimezone = DateTime.fromFormat(fullDate, 'yyyy-MM-dd h:mm a', { zone: timezone });
    return resultWithTimezone.toLocal().toFormat('yyyy-MM-dd HH:mm:ss');
  }

  public static formatDateTimeToLocalTimezoneLuxon(millis: number) {
    const dateTime = DateTime.fromMillis(millis);

    const localDateTime = dateTime.setZone('local').toLocal().toFormat('yyyy-MM-dd HH:mm:ss');
    // const localDateTimeString = dateTime.setZone('local').toLocaleString(DateTime.DATETIME_FULL);
    return localDateTime;
  }

  public static getNextMeeting(meetings: Meeting[] | UnitMeeting[] | AdminGroupMeeting[]): Meeting | UnitMeeting {
    if (!meetings) return null;
    const fullMeeting = (meetings as Meeting[]).find((meeting: Meeting) => meeting.meetingStartTime > moment().valueOf());
    const unitMeeting = (meetings as UnitMeeting[]).find((meeting: UnitMeeting) => meeting.startTime > moment().valueOf());

    return fullMeeting || unitMeeting;
  }

  public static isTimeBefore(startTime: string, endTime): boolean {
    let startTimeMoment = moment(this.addTimeToDate(null, startTime));
    let endTimeMoment = moment(this.addTimeToDate(null, endTime));

    if (startTimeMoment.format('hh:mm A').indexOf('PM') > -1 && endTimeMoment.format('hh:mm A').indexOf('AM') > -1) {
      endTimeMoment = moment(moment(endTimeMoment).add(1, 'days').format('MMMM D, YYYY hh:mm A'));
    }
    return startTimeMoment.isBefore(endTimeMoment);
  }

  public static getDayNameOutOfMilliseconds(milliseconds: number) {
    return new Date(milliseconds).toLocaleDateString('en-US', { weekday: 'long' });
  }

  public static getDayName(date: Date) {
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  }

  public static addMinutesToTimeString(time: string, minutes: number): string {
    const date = new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd');
    const fullDate = date + ' ' + time;
    return moment(DateTime.fromFormat(fullDate, 'yyyy-MM-dd h:mm a').toJSDate()).add(minutes, 'minutes').format('hh:mm A').toString();
  }

  public static getWeekdaysFloorDifference(date1: Date, date2: Date): number {
    if (date1.getDay() < date2.getDay()) {
      return 7 + date1.getDay() - date2.getDay();
    } else {
      return date1.getDay() - date2.getDay();
    }
  }

  public static adjustMeetingDayToUserTimezone(startsOn: number, scheduledDays: string[]) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const utcStart = moment.utc(startsOn).startOf('day');

    return scheduledDays.map((day) => {
      const dayIndex = daysOfWeek.indexOf(day);
      const utcDay = utcStart.clone().add(dayIndex - utcStart.day(), 'days');
      const localDay = utcDay.tz(userTimezone);
      return daysOfWeek[localDay.day()];
    });
  }
}
