import { WeekDay } from '../models/Groups';

export enum AdminAction {
  FILTER_BY = 'FILTER_BY',
  SEARCH_GROUP = 'SEARCH_GROUP',
  SEARCH_USER = 'SEARCH_USER',
}

export enum ParticipantType {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  WITHDRAWN = 'WITHDRAWN',
}

export enum GroupTypes {
  IN_PERSON = 'IN_PERSON',
  VIRTUAL = 'VIRTUAL',
  HYBRID = 'HYBRID',
}

export enum EntrepreneurTypes {
  ENTREPRENEUR = 'Entrepreneur',
  SOLOPRENEUR = 'Solopreneur',
  ASPIRING = 'Aspiring',
  INTRAPRENEUR = 'Intrapreneur',
  STUDENT = 'Student',
  PASTOR_MINISTRY_LEADER = 'Pastor/Ministry Leader',
  OTHER = 'Other',
}

export enum InvestorTypes {
  RETAIL_INVESTOR = 'Retail Investor',
  ACCREDITED_INVESTOR = 'Accredited Investor',
  QUALIFIED_PURCHASER = 'Qualified Purchaser',
  FINANCIAL_ADVISOR = 'Financial Advisor',
  FUND_MANAGER = 'Fund Manager',
  MINISTRY_LEADER_IN_FINANCE = 'Ministry Leader in Finance',
  OTHER = 'Other',
}

export enum GroupRoles {
  ENTREPRENEUR = 'ENTREPRENEUR',
  ACCREDITED_INVESTOR = 'ACCREDITED_INVESTOR',
  FUND_MANAGER = 'FUND_MANAGER',
  FINANCIAL_ADVISOR = 'FINANCIAL_ADVISOR',
  NON_ACCREDITED_INVESTOR = 'NON_ACCREDITED_INVESTOR',
  QUALIFIED_PURCHASER = 'QUALIFIED_PURCHASER',
}

export enum Frequencies {
  WEEKLY = 'WEEKLY',
  EVERY_2_WEEKS = 'EVERY_2_WEEKS',
  MONTHLY = 'MONTHLY',
  CUSTOM = 'CUSTOM',
}

export enum MainGroupRoles {
  ENTREPRENEUR = 'ENTREPRENEUR',
  INVESTOR = 'INVESTOR',
}

export enum GroupStates {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum SearchObject {
  FACILITATOR = 'FACILITATOR',
  STUDY = 'STUDY',
  CHURCH = 'CHURCH',
  PARTNER_ORGANIZATION = 'PARTNER_ORGANIZATION',
}

export enum GroupFormating {
  RECURRING = 'RECURRING',
  SINGLE = 'SINGLE',
}

export enum GroupFormat {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  SINGLE = 'SINGLE',
}

export enum UserFormatType {
  NEW = 'NEW',
  RETURNING = 'RETURNING',
}

export enum UnitStatusName {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum GroupUnitsCounter {
  ONE_UNIT = 'ONE_UNIT',
  TWO_UNITS = 'TWO_UNITS',
  MORE_THAN_TWO_UNITS = 'MORE_THAN_TWO_UNITS',
}

export enum MeetingType {
  COMPLETED,
  WAITING_COMPLETION,
  PENDING,
}

export enum MonthlyGroupRecurrence {
  FIRST = 1,
  SECOND,
  THIRD,
  FOURTH,
  LAST = -1,
}

export enum DECLINED_EMAIL {
  NO_SPOTS_LEFT = 'NO_SPOTS_LEFT',
  DIFFERENT_LOCATION = 'DIFFERENT_LOCATION',
  TOO_MANY_GROUPS = 'TOO_MANY_GROUPS',
  NO_EMAIL = 'NO_EMAIL',
}

export enum ENDS {
  NEVER = 'NEVER',
  END_DATE = 'END_DATE',
  OCCURRENCES = 'OCCURRENCES',
}

export const WEEK_DAYS: WeekDay[] = [
  { name: 'SUNDAY', selected: false, disabled: false },
  { name: 'MONDAY', selected: false, disabled: false },
  { name: 'TUESDAY', selected: false, disabled: false },
  { name: 'WEDNESDAY', selected: false, disabled: false },
  { name: 'THURSDAY', selected: false, disabled: false },
  { name: 'FRIDAY', selected: false, disabled: false },
  { name: 'SATURDAY', selected: false, disabled: false },
];
