import { Component, OnInit } from '@angular/core';
import { ModalEmitActions } from 'src/app/_core/constants/Modals';
import { CustomFormGroup } from 'src/app/_core/models/CustomForms';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UnitForm } from '../../../_helpers/add-group/group-form.model';
import { Frequencies, GroupTypes } from 'src/app/_core/constants/AdminGroups';
import { EmailNotifications } from 'src/app/_core/models/AdminGroups';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-group-update-notes',
  templateUrl: './group-update-notes.component.html',
  styleUrls: ['./group-update-notes.component.scss'],
})
export class GroupUpdateNotesComponent implements OnInit {
  meetingType = GroupTypes;
  frequency = Frequencies;
  modalRequest: ModalRequest<any>;
  unitsFormGroups: CustomFormGroup<UnitForm>[];

  constructor(private modalService: ModalsService) {}

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.unitsFormGroups = this.modalRequest.payload.controls.units.controls.filter((unitForm) => unitForm.dirty);
    this.unitsFormGroups.forEach((unitForm) => {
      const emailNotifications: EmailNotifications = unitForm.controls.emailNotifications.getRawValue();
      const updateEmailsTurnedOn = emailNotifications.updateEmails || emailNotifications.calendarInvites;
      const updateEmailFields: FormControl[] = [
        unitForm.controls.frequency,
        unitForm.controls.startDate,
        unitForm.controls.startTime,
        unitForm.controls.endTime,
        unitForm.controls.meetingDaysNames,
        unitForm.controls.groupType,
        unitForm.controls.timezone,
        unitForm.controls.occurrences,
        unitForm.controls.meetingLink,
        unitForm.controls.location,
        unitForm.controls.locationDetails,
      ];
      unitForm.helperData = {
        ...unitForm.helperData,
        showUpdateNotes: updateEmailsTurnedOn && updateEmailFields.some((control) => control.dirty),
        coFacilitators: unitForm.controls.coFacilitators.value.map((coFacilitator) => coFacilitator.name).join(', '),
      };
    });
  }

  submit(): void {
    this.modalService.emitResponse(new ModalResponse<any>(true, this.modalRequest, ModalEmitActions.ADD_GROUP_UPDATE_NOTES));
    this.close();
  }

  close(): void {
    this.modalService.emitResponse(new ModalResponse<any>(false, this.modalRequest, ModalEmitActions.ADD_GROUP_UPDATE_NOTES));
    this.modalService.closeModal();
  }
}
