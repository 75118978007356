import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subscription, catchError, map, of, tap } from 'rxjs';
import { RedirectReason } from 'src/app/auth/auth.model';
import { GroupService } from '../api/group.service';
import { GroupRoles, GroupTypes, MainGroupRoles, ParticipantType } from '../constants/AdminGroups';
import { AnalyticsEvent } from '../constants/AnalyticsEvents';
import { ModalActions, ModalEmitActions, Modals } from '../constants/Modals';
import { ServerError } from '../constants/ServerErrors';
import { ToastrMessages } from '../constants/ToastrMessages';
import { Urls } from '../constants/Urls';
import StorageHelper from '../helpers/Storage.helper';
import { GroupDetails, GroupDetailsUnit } from '../models/AdminGroups';
import { ResponseObject } from '../models/GenericObject';
import { AlreadyApprovedGroups } from '../models/Groups';
import { ModalRequest, ModalResponse } from '../models/ModalEvent';
import { AnalyticsService } from './analytics.service';
import { ModalsService } from './modals.service';
import { QueryParams } from './query-params.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class GroupApplicationService {
  private _modalSubscription = new Subscription();
  private _groupDetails: GroupDetails;
  private _activeUnit: GroupDetailsUnit;
  private _alreadyApprovedGroups: AlreadyApprovedGroups[];
  private _case: RedirectReason;
  private _queryParams: QueryParams;
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $loading: Observable<boolean> = this._loading.asObservable();

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private groupService: GroupService,
    private router: Router,
    private userService: UserService,
    private analytics: AnalyticsService,
    private toastr: ToastrService
  ) {}

  private watchModalResponse(): void {
    this._modalSubscription = this.modalService.modalResponse$.subscribe((response: ModalResponse) => {
      if (response && this._groupDetails) {
        this.handleModalResponse(response);
      }
    });
  }

  handleModalResponse(response: ModalResponse): void {
    if (response.modalRequest.uuid === this._groupDetails.uuid) return;
    if (!response.confirmed) return;

    switch (response.modalEmitAction) {
      case ModalEmitActions.TRY_ENROLL:
        this.analytics.logCustomEvent(AnalyticsEvent.APPLY_JOURNEY_UPDATE_INCOMPLETE_PROFILE);
        this.confirmAndApply();
        break;
      case ModalEmitActions.APPLY_TO_IN_PERSON_GROUP:
        if (response.modalRequest.uuid) return;
        this.analytics.logCustomEvent(AnalyticsEvent.APPLY_JOURNEY_CONFIRM_PARTICIPATION_IN_PERSON);
        if (this._alreadyApprovedGroups.length) this.checkForAlreadyApprovedGroups();
        else this.apply();
        break;
      case ModalEmitActions.ALREADY_APPROVED_FOR_ANOTHER_GROUP:
        this.analytics.logCustomEvent(AnalyticsEvent.APPLY_JOURNEY_CONFIRM_ALREADY_APPROVED_FOR_OTHER_GROUP);
        this.apply();
        break;
      default:
        break;
    }
  }

  getGroupDetails(uuid: string): Observable<any> {
    let group: GroupDetails;
    let unit: GroupDetailsUnit;
    return this.groupService.getGroupDetails(uuid).pipe(
      map((res: ResponseObject<GroupDetails>) => {
        group = res.response;
        unit = group.units.find((unit) => unit.active);
        this.setGroupData(group, unit);
        return group.uuid;
      }),
      catchError((error) => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        return of(new Error(error));
      })
    );
  }

  setGroupData(group: GroupDetails, unit: GroupDetailsUnit): void {
    this.clearData();
    this._groupDetails = group;
    this._activeUnit = unit;
    const groupUserType = this._groupDetails.groupRole === GroupRoles.ENTREPRENEUR ? MainGroupRoles.ENTREPRENEUR : MainGroupRoles.INVESTOR;
    this._queryParams = {
      groupUuid: this._groupDetails.uuid,
      groupRole: this._groupDetails.groupRole,
      userType: groupUserType,
      onboardingStudy: this._activeUnit.study.isOnboardingStudy.toString(),
    };
    StorageHelper.saveSelectedGroupUuid(this._groupDetails.uuid);
    this.watchModalResponse();
  }

  attemptApplication(reason: RedirectReason): void {
    this._case = reason;
    this._queryParams.reason = this._case;
    this.checkUserGroups().subscribe(() => this.confirmAndApply());
  }

  private confirmAndApply(): void {
    if (!this.userService.currentUser.completedProfile) {
      this.modalService.openModal(Modals.ADD_USER, new ModalRequest(ModalActions.MY_PROFILE_THEN_TRY_ENROLL, null, null, this._groupDetails.uuid));
    } else if (this._activeUnit.meetingPlaceType === GroupTypes.IN_PERSON || this._activeUnit.meetingPlaceType === GroupTypes.HYBRID) {
      this.confirmParticipationInPersonHybrid();
    } else if (this._alreadyApprovedGroups.length) {
      this.checkForAlreadyApprovedGroups();
    } else {
      this.apply();
    }
  }

  private checkUserGroups(): Observable<any> {
    return this.groupService.checkUserGroups(this.activeUnit.uuid).pipe(
      tap((res: ResponseObject<AlreadyApprovedGroups[]>) => {
        this._alreadyApprovedGroups = res.response || [];
      }),
      catchError((err) => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        return of(new Error(err.message));
      })
    );
  }

  confirmParticipationInPersonHybrid(): void {
    const modalRequest = new ModalRequest(ModalActions.APPLY_TO_IN_PERSON_GROUP, null, null, this._activeUnit);
    this.modalService.openModal(Modals.APPLY_TO_IN_PERSON_GROUP, modalRequest);
  }

  private checkForAlreadyApprovedGroups(): void {
    const modalRequest = new ModalRequest(ModalActions.ALREADY_APPROVED_FOR_ANOTHER_GROUP, null, null, this._alreadyApprovedGroups);
    this.modalService.openModal(Modals.ALREADY_APPROVED, modalRequest);
  }

  private apply(): void {
    this._loading.next(true);
    this.groupService.applyForGroup(this._groupDetails.uuid).subscribe({
      next: () => {
        this.handleSuccess();
        this._loading.next(false);
        this.clearData();
      },
      error: (error: ServerError) => {
        this.analytics.logCustomEvent(AnalyticsEvent.APPLY_JOURNEY_APPLICATION_ERROR);
        this.handleError(error);
        this._loading.next(false);
        this.clearData();
      },
    });
  }

  clearData(): void {
    this._groupDetails = null;
    this._activeUnit = null;
    this._alreadyApprovedGroups = [];
    this._case = null;
    this._queryParams = null;
    this._modalSubscription.unsubscribe();
    StorageHelper.clearSelectedGroupUuid();
  }

  private handleSuccess(): void {
    this._activeUnit.userStatus = ParticipantType.PENDING;
    this.modalService.closeAll();
    this.goToConfirmation();
  }

  private handleError(error: ServerError): void {
    let serverErrorKeyName: string;
    switch (error) {
      case ServerError.ALREADY_EXISTS:
        serverErrorKeyName = 'GROUP_ALREADY_APPLIED';
        break;
      case ServerError.ALREADY_FACILITATOR:
        serverErrorKeyName = 'FACILITATOR_APPLY_FOR_HIS_GROUP';
        break;
      case ServerError.GROUP_ENDED:
        serverErrorKeyName = 'GROUP_ENDED';
        break;
      case ServerError.TIMEZONE_NOT_FOUND:
        serverErrorKeyName = 'TIMEZONES_NOT_FOUND_ERROR';
        break;
      case ServerError.GROUP_ALREADY_FULL:
        serverErrorKeyName = 'GROUP_ALREADY_FULL_ERROR';
        break;
      case ServerError.MAXIMUM_APPLICATIONS_REACHED:
        serverErrorKeyName = 'MAXIMUM_APPLICATIONS_REACHED_ERROR';
        break;
      default:
        serverErrorKeyName = 'BASIC_ERROR';
        break;
    }

    this.router.navigate([`${Urls.APP}/${Urls.HOME}`]);
    if (this._case !== RedirectReason.APPLICATION_ALLOWED) {
      this.modalService.openModal(Modals.GROUP_DETAILS, new ModalRequest(ModalActions.VIEW_GROUP, this._groupDetails.uuid));
    }
    this.toastr.error(ToastrMessages[serverErrorKeyName]);
  }

  goToConfirmation(): void {
    this.router.navigate([`${Urls.AUTH}/${Urls.CONFIRM}`], { queryParams: this._queryParams, queryParamsHandling: 'merge' });
  }

  addQueryParams(queryParams: QueryParams): void {
    this._queryParams = { ...this._queryParams, ...queryParams };
  }

  get selectedGroupDetails(): GroupDetails {
    return this._groupDetails;
  }

  get activeUnit(): GroupDetailsUnit {
    return this._activeUnit;
  }

  get selectedGroupUserType(): MainGroupRoles {
    if (!this._groupDetails) return null;
    const groupUserType = this._groupDetails.groupRole === GroupRoles.ENTREPRENEUR ? MainGroupRoles.ENTREPRENEUR : MainGroupRoles.INVESTOR;
    return groupUserType;
  }
}
