<div class="dark-background">
  <div class="container">
    <div class="text-center">
      <h1 class="text-white error-code">{{ errorStatus }}</h1>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center align-items-center flex-column">
  <h2 class="error-headline">{{ errorTitle }}</h2>
  <p class="text-center font-weight-bold gray-color error-message">{{ errorMessage }}</p>
  <button mat-flat-button color="primary" class="mat-longer-button mt-5" (click)="goHome()">Back to homepage</button>
</div>
