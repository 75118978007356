import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountService } from '../api/account.service';
import { MainGroupRoles } from '../constants/AdminGroups';
import { ToastrMessages } from '../constants/ToastrMessages';
import { Urls } from '../constants/Urls';
import { Role } from '../constants/UserEnums';
import StorageHelper from '../helpers/Storage.helper';
import { ResponseObject } from '../models/GenericObject';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _currentUser: User = null;
  private currentUserSource = new BehaviorSubject<User>(null);

  currentUser$ = this.currentUserSource.asObservable();

  // prettier-ignore
  constructor(
    private router: Router,
    private accountService: AccountService,
    private toastrService: ToastrService
  ) {
  }

  getUserInfo(): Observable<any> {
    return this.accountService.getUserInfo().pipe(
      tap((response: ResponseObject<User>) => {
        this.setCurrentUser(response.response);
        StorageHelper.saveFacilitator(response.response.facilitator);
      }),
      catchError((err) => {
        this.toastrService.error(...ToastrMessages.BASIC_ERROR);
        StorageHelper.killSession();
        return of(err);
      })
    );
  }

  get currentUser(): User {
    return this._currentUser;
  }

  setCurrentUser(currentUser: User): void {
    this._currentUser = currentUser;
    this.currentUserSource.next(this._currentUser);
  }

  setPrimaryTypeAndSubtype(type: MainGroupRoles, subType: string): void {
    this._currentUser.userType = type;
    this._currentUser.userSubType = subType;
  }

  setSecondaryTypeAndSubtype(type: MainGroupRoles, subType: string): void {
    this._currentUser.secondaryUserType = type;
    this._currentUser.secondaryUserSubType = subType;
  }

  isLoggedIn(): boolean {
    return !!this._currentUser;
  }

  isAdmin(): boolean {
    return this._currentUser?.role === Role.SUPER_ADMIN;
  }

  isNormalAdmin(): boolean {
    return this._currentUser?.role === Role.SUPER_ADMIN && !this._currentUser.specialUser;
  }

  isSuperAdmin(): boolean {
    return this._currentUser?.role === Role.SUPER_ADMIN && this._currentUser.specialUser;
  }

  isSpecialUser(): boolean {
    return this._currentUser.specialUser;
  }

  isMattermostEnabled(): boolean {
    return this._currentUser.mattermostEnabled;
  }

  isFacilitator(): boolean {
    return this._currentUser?.facilitator;
  }

  isOnlyFacilitator(): boolean {
    return this._currentUser?.facilitator && !this.isAdmin();
  }

  logout(): void {
    this.accountService.logout().subscribe(() => {
      this.killSession();
    });
  }

  killSession(): void {
    StorageHelper.killSession();
    this.setCurrentUser(null);
    this.router.navigate([Urls.APP]);
  }
}
