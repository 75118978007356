import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AlphabetizedGroupListPayload,
  GroupsListPayload,
  OldProximityGroupsListPayload,
  ProximityGroupListPayload,
} from 'src/app/internal-app/groups/groups/groups.model';
import { MultiFilterGroups } from '../models/AdminChurches';
import { GroupUnitHistoryRequest } from '../models/GroupHistory';
import { AdminApplyUserToGroup, GroupSurveyAnswersRequest, RunningOrPastGroupsRequest } from '../models/Groups';
import { BasicPaginatedRequest } from '../models/Shared';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  private readonly resourceUrl: string = '/v1/groups';
  private readonly InterceptorSkipBearer = 'X-Skip-Authorization-Bearer';

  constructor(private apiService: ApiService) {}

  getGroupDetails(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/details/${uuid}`);
  }

  applyForGroup(groupUuid: string): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/apply-to-group`, { groupUuid });
  }

  adminApplyUserForGroup(body: AdminApplyUserToGroup): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/apply-user-to-group`, body);
  }

  getRunningGroups(body: RunningOrPastGroupsRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/running-groups`, body);
  }

  getPastGroups(body: RunningOrPastGroupsRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/past-groups`, body);
  }

  getPendingGroups(body: BasicPaginatedRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/pending-groups`, body);
  }

  getSurveyAnswers(body: GroupSurveyAnswersRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/participant-message`, body);
  }

  checkUserGroups(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/check-user-attending-group/${uuid}`);
  }

  getGroupsProximityOrdered(body: MultiFilterGroups | OldProximityGroupsListPayload | ProximityGroupListPayload): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipBearer, '');
    return this.apiService.post(`${this.resourceUrl}/multi-filter-proximity-groups`, body, null, headers);
  }

  getGroupsAphabeticallyOrdered(body: MultiFilterGroups | GroupsListPayload | AlphabetizedGroupListPayload): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipBearer, '');
    return this.apiService.post(`${this.resourceUrl}/multi-filter-alphabetized-by-city-name-groups`, body, null, headers);
  }

  getMultiFilterLeadingGroups(body: MultiFilterGroups): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/groups-leading`, body);
  }

  getMultiFilterValueByUuid(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/multi-filter-values`, body);
  }

  synchronizeHubspot(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/hubspot/sync`);
  }

  getGroupHistory(body: GroupUnitHistoryRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/history`, body);
  }

  getUnitHistory(body: GroupUnitHistoryRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/unit/history`, body);
  }
}
