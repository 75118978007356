import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Urls } from './_core/constants/Urls';
import { NoAuthGuard } from './_core/guards/no-auth.guard';
import { ServerErrorPageComponent } from './_shared/components/server-error-page/server-error-page.component';

const routes: Routes = [
  {path: '', redirectTo: Urls.APP, pathMatch: 'full'},
  {
    path: Urls.APP,
    loadChildren: () => import('./internal-app/internal-app.module').then(m=>m.InternalAppModule),
  },
  {
    path: Urls.AUTH,
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m=>m.AuthModule),
  },
  {
    path: Urls.SERVER_ERROR,
    component: ServerErrorPageComponent
  },
  {
    path: '**',
    loadChildren: () => import('./internal-app/internal-app.module').then(m=>m.InternalAppModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    // enableTracing: true,
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
