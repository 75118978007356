<div class="d-flex justify-content-between align-items-center px-4 py-3 bg-gray">
  <h1 class="mb-0">Filter by date</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>
<form [formGroup]="scheduleForm">
  <div class="d-flex justify-content-end mr-3 mt-3">
    <u class="primary-color font-weight-bold pointer" (click)="reset()">Reset</u>
  </div>
  <div class="admin-input mx-3 mb-3">
    <label for="cohort" class="label">Cohort <span class="primary-color">*</span></label>
    <mat-form-field appearance="outline">
      <mat-select multiple formControlName="cohort">
        <mat-option *ngFor="let item of groupsFilters?.cohorts.filters" [value]="item.uuid">{{ item.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex mx-3 my-3">
    <h2 class="gray-color m-0">OR</h2>
    <mat-divider class="ml-3 w-100 align-self-center"></mat-divider>
  </div>
  <div class="admin-input mx-3 mb-3">
    <label for="startDate" class="label">Starting Date</label>
    <mat-form-field appearance="outline" class="w-100 date-input">
      <input
        id="startDate"
        placeholder="MM/DD/YYYY"
        matInput
        required
        readonly
        [matDatepicker]="startDatePicker"
        (click)="startDatePicker.open()"
        formControlName="startDate"
      />
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #startDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="d-flex mx-3 my-3">
    <h2 class="gray-color m-0">OR</h2>
    <mat-divider class="ml-3 w-100 align-self-center"></mat-divider>
  </div>
  <div class="row mx-3" formGroupName="dayMonth">
    <!--Day-->
    <div class="admin-input col-sm-4 pl-0">
      <label for="endTime" class="label">DAY</label>
      <mat-form-field appearance="outline">
        <mat-select multiple formControlName="day">
          <mat-option *ngFor="let item of groupsFilters.days.filters" [value]="item.uuid">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--Month-->
    <div class="admin-input col-sm-4 p-0">
      <label for="endTime" class="label">MONTH</label>
      <mat-form-field appearance="outline">
        <mat-select multiple formControlName="month">
          <mat-option *ngFor="let item of groupsFilters?.months.filters" [value]="item.uuid">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--Year-->
    <div class="admin-input col-sm-4 pr-0">
      <label for="endTime" class="label">YEAR</label>
      <mat-form-field appearance="outline">
        <mat-select multiple formControlName="year">
          <mat-option *ngFor="let item of groupsFilters?.years.filters" [value]="item.uuid">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-error
    *ngIf="
      (startDate.value !== null && (day.value?.length > 0 || month.value?.length > 0 || year.value?.length > 0)) ||
      (startDate.value !== null && cohort.value?.length > 0) ||
      (cohort.value?.length && (day.value?.length > 0 || month.value?.length > 0 || year.value?.length > 0))
    "
    class="error ml-3 mt-3"
  >
    <div>Please chose only the cohort, starting date or the day(s) and/or month(s) and/or year(s)</div>
  </mat-error>
</form>
<div class="sticky-div position-sticky mt-3">
  <mat-divider></mat-divider>
  <div class="py-3 px-3">
    <button mat-flat-button color="primary" class="mat-medium-button" (click)="applyFilter()">Apply Filter</button>
  </div>
</div>
