<div class="d-flex justify-content-between align-items-center px-4 py-3 bg-gray">
  <h1 class="mb-0">Attendance - Session {{ meetingIndex }}</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>
<div class="bg-warning px-1 px-sm-3 py-2">
  <ng-container *ngIf="canSubmit; else cannotSubmit">
    <div class="container main-text">
      Use the buttons to mark if the participant was <span class="font-weight-bold uppercase text-success">present</span> or
      <span class="font-weight-bold uppercase text-danger">absent </span>
    </div>
    <div class="container pt-1">
      <span
        >If you started your attendance and believe you will need to edit it before finalizing, click '<span class="font-weight-bold text-danger"
          >Save Draft.</span
        >'</span
      >
      <span>
        If you have finalized your attendance and are confident it is marked correctly, click '<span class="font-weight-bold text-danger"
          >Submit.</span
        >' Once you click Submit, we will automatically send an email to any group members who missed your session.
      </span>
    </div>
  </ng-container>
  <ng-template #cannotSubmit>
    <div class="container main-text py-3">
      If you need to make changes to your attendance, please email
      <a class="font-weight-bold text-dark" href="mailto:groups@faithdrivenentrepreneur.org">groups@faithdrivenentrepreneur.org</a>.
    </div>
  </ng-template>
</div>
<div *ngIf="canSubmit" class="d-flex justify-content-end pr-4 mt-3">
  <u class="primary-color font-weight-bold pointer" (click)="selectAll()">{{ selectedAll ? 'Deselect All' : 'Select All' }}</u>
</div>

<div class="px-1 px-sm-3 pt-2 mt-2" *ngIf="!loading">
  <div class="container d-flex flex-column">
    <div *ngFor="let participant of attendanceInfo.payload.participants; let i = index">
      <div class="d-flex justify-content-between">
        <div class="ml-2 p-1">
          <h2 class="mb-0 truncated-single-line">{{ participant.firstName + ' ' + participant.lastName | titlecase }}</h2>
        </div>
        <button
          mat-button
          [disabled]="!canSubmit"
          class="attendance-button"
          [ngClass]="participant.selected ? 'red-border' : 'green-border'"
          [color]="participant.selected ? 'warn' : 'accent'"
          (click)="setAttendance(participant)"
        >
          <div class="d-flex align-items-center">
            <i class="fas fa-circle mr-2"></i>
            <div class="f-16 text-dark">{{ participant.selected ? 'Absent' : 'Present' }}</div>
          </div>
        </button>
      </div>
      <mat-divider class="my-3" *ngIf="i < attendanceInfo.payload.participants.length - 1"></mat-divider>
    </div>
  </div>
  <div class="sticky-div">
    <mat-divider class="my-3"></mat-divider>
    <div class="action-btns">
      <div class="d-flex justify-content-end flex-md-row flex-column-reverse">
        <button mat-flat-button color="primary" class="mat-medium-button px-5 mx-2" (click)="close()">
          {{ canSubmit ? 'Cancel' : 'Close' }}
        </button>
        <button
          *ngIf="(attendanceInfo.payload.attendanceSubmitted || attendanceInfo.payload.attendanceSaved) && isAdmin"
          mat-flat-button
          color="warn"
          class="mat-medium-button px-5 mx-2"
          (click)="reset()"
        >
          Reset
        </button>
        <button
          *ngIf="!attendanceInfo.payload.attendanceSubmitted"
          mat-flat-button
          color="main"
          class="mat-medium-button px-5 mx-2"
          (click)="submit(false)"
        >
          Save Draft
        </button>
        <button *ngIf="canSubmit" mat-flat-button color="accent" class="mat-medium-button px-5 mx-2" (click)="submit(true)">Submit</button>
      </div>
    </div>
  </div>
</div>
