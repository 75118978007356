import { SortValue } from '../constants/SortByOptions';
import { AdminGroupResume } from './AdminFacilitators';
import { SortOption } from './Filters';
import { BasicPaginatedRequest, BasicPaginatedRequestWithSort } from './Shared';

export class AdminStudiesFilters implements BasicPaginatedRequestWithSort {
  page: number;
  searchText: string;
  size: number;
  sortBy: SortOption[];

  constructor() {
    this.page = 0;
    this.searchText = '';
    this.size = 10;
    this.sortBy = [
      new SortOption(SortValue.NAME, null, false),
      new SortOption(SortValue.AUTHOR, null, false),
      new SortOption(SortValue.GROUPS, 'desc', false),
    ];
  }
}

export interface AdminStudyCard {
  author: string;
  imgUrl: string;
  name: string;
  studyUuid: string;
  groupsCount: number;
}

export interface AdminStudyGetDetails {
  groupFinderDto: AdminGroupResume[];
  study: AdminStudyDetails;
}

export interface AdminStudyDetails {
  author: string;
  description: string;
  name: string;
  studyName?: string;
  studyPictureUuid?: string;
  studyPictureUrl?: string;
  studyUuid: string;
  trailerLink: string;
  onBoardingStudy?: boolean;
  visible: boolean;
  groupRoleTypes?: string[];
  type?: string;
  nrOfSession: number;
  nrOfUnits?: number;
}

export class ViewStudyGroupBody {
  uuid: string;
  page: number;
  size: number;

  constructor() {
    this.page = 0;
    this.size = 50;
  }
}

export interface StudyTypeList {
  type: string;
  groupRoleTypeList: string[];
}

export class StudiesFilter implements BasicPaginatedRequest {
  page: number;
  searchText: string;
  size: number;

  constructor() {
    this.page = 0;
    this.searchText = '';
    this.size = 10;
  }
}
