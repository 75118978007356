<ng-container *ngIf="filters">
  <div class="dark-background">
    <div class="container pt-3 pb-75">
      <div class="text-center">
        <h1 class="text-white title mb-2">Join the perfect group for you</h1>
        <p *ngIf="addressInfo || locationName || resultsFor" class="text-white m-0">
          Results for:
          <span class="primary-color font-weight-bold">
            {{ cityState || addressInfo?.address || locationName || resultsFor | titlecase }}
          </span>
        </p>
      </div>
    </div>
  </div>

  <div class="off-150">
    <div class="d-flex containerGap container">
      <div class="multi-filter-container" [style]="!showGroupsInYourAreaHeader ? 'margin-top: 73px' : 'margin-top: 99px'" [hidden]="isMobile">
        <app-multi-filter *ngIf="this.readyToLoadGroups" [dynamicFilters]="dynamicFilters"></app-multi-filter>
      </div>
      <div class="results-container" infiniteScroll [infiniteScrollDistance]="5" (scrolled)="onScrollDown()">
        <ng-container *ngIf="multiFilter">
          <ng-container>
            <div class="d-flex justify-content-between align-items-center mb-2 h-34p">
              <p class="text-white mb-0">
                Sorted by:
                <span class="primary-color font-weight-bold">
                  {{ cityState ? "Proximity to Facilitator's Location" : addressInfo ? 'Proximity' : 'Start Date' }}
                </span>
              </p>
              <button *ngIf="showJumpButton" class="transparent-button" (click)="scrollToOnlineGroups()">
                Jump to <span class="font-weight-bold">Online Affinity Groups</span>
                <i class="fas fa-long-arrow-alt-down ml-2"></i>
              </button>
            </div>
            <!-- <div *ngIf="!showGroupsInYourAreaHeader" class="group-card mt-40p font-weight-bold p-3 mb-3">
              <p>
                {{
                  'Currently1, no groups are meeting' +
                    (groups.onlineRegional.items.length ? ' in-person ' : ' ') +
                    'in your area. Would you like to launch a group locally?'
                }}
                <a class="primary-color" target="_blank" [href]="contactLink"> Contact us. </a>
              </p>
              <p class="mb-0">You can also join one of the online groups listed below.</p>
            </div> -->
            <div *ngIf="showGroupsInYourAreaHeader" class="h-25p mb-3 mt-4">
              <div class="row bold-text">
                <div class="col-7 col-xl-8 font-weight-bold text-gray col-header uppercase font-18 lh-18">
                  <span class="ml-35p">{{ addressInfo ? 'GROUPS IN YOUR AREA' : 'GROUP NAME' }}</span>
                </div>
                <ng-container *ngIf="!isTablet">
                  <div class="col-3 col-xl-2 font-weight-bold text-gray col-header uppercase font-18 lh-18 d-flex flex-column">
                    <span [class.truncated-single-line]="isLarge">MEETING DAY & TIME</span>
                    <span class="font-12">(IN YOUR LOCAL TIME)</span>
                  </div>
                  <div class="col-2 col-xl-2 font-weight-bold text-gray col-header uppercase font-18 lh-18 text-right">
                    <span class="mr-2">FREQUENCY</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="mb-3">
              <!-- Alphabetized -->
              <app-group-card *ngFor="let group of groups.alphabetized" [group]="group" (selected)="openGroup($event)"></app-group-card>

              <ng-container *ngIf="groups.alphabetizedOnline.length">
                <hr *ngIf="groups.alphabetized.length" class="my-5 mx-4 thick" />
                <!-- <div class="row mb-2 bold-text">
                  <div class="col-7 col-xl-8 font-weight-bold text-gray col-header uppercase font-18">
                    <span class="ml-35p">ONLINE GROUP NAME</span>
                  </div>
                </div> -->

                <div class="h-25p mb-3 mt-5">
                  <div class="row bold-text">
                    <div class="col-7 col-xl-8 font-weight-bold text-gray col-header uppercase font-18 lh-18">
                      <span class="ml-35p">ONLINE GROUP NAME</span>
                    </div>
                    <ng-container *ngIf="!isTablet">
                      <div class="col-3 col-xl-2 font-weight-bold text-gray col-header uppercase font-18 lh-18 d-flex flex-column">
                        <span [class.truncated-single-line]="isLarge">MEETING DAY & TIME</span>
                        <span class="font-12">(IN YOUR LOCAL TIME)</span>
                      </div>
                      <div class="col-2 col-xl-2 font-weight-bold text-gray col-header uppercase font-18 lh-18 text-right">
                        <span class="mr-2">FREQUENCY</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <app-group-card *ngFor="let group of groups.alphabetizedOnline" [group]="group" (selected)="openGroup($event)"></app-group-card>
              </ng-container>

              <!-- In-Person Regional (Proximity In-Person)-->
              <app-group-card *ngFor="let group of groups.inPersonRegional.items" [group]="group" (selected)="openGroup($event)"></app-group-card>

              <!-- Online Regional (Proximity Online)-->
              <ng-container *ngIf="groups.onlineRegional.items.length">
                <hr *ngIf="showGroupsInYourAreaHeader" class="my-5 mx-4 thick" />
                <!-- <div class="row mb-2 bold-text">
                  <div class="col-7 col-xl-8 font-weight-bold text-gray col-header uppercase font-18">
                    <span class="ml-35p">ONLINE GROUPS IN YOUR AREA</span>
                  </div>
                </div> -->

                <div class="h-25p mb-3 mt-5">
                  <div class="row bold-text">
                    <div class="col-7 col-xl-8 font-weight-bold text-gray col-header uppercase font-18 lh-18">
                      <span class="ml-35p">ONLINE GROUPS IN YOUR AREA</span>
                    </div>
                    <ng-container *ngIf="!isTablet">
                      <div class="col-3 col-xl-2 font-weight-bold text-gray col-header uppercase font-18 lh-18 d-flex flex-column">
                        <span [class.truncated-single-line]="isLarge">MEETING DAY & TIME</span>
                        <span class="font-12">(IN YOUR LOCAL TIME)</span>
                      </div>
                      <div class="col-2 col-xl-2 font-weight-bold text-gray col-header uppercase font-18 lh-18 text-right">
                        <span class="mr-2">FREQUENCY</span>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <app-group-card *ngFor="let group of groups.onlineRegional.items" [group]="group" (selected)="openGroup($event)"></app-group-card>
              </ng-container>
            </div>

            <!-- Industry or Global Audience Groups -->
            <div>
              <ng-container *ngIf="groups.industry.items.length || groups.global.items.length">
                <hr *ngIf="groups.hasRegionalGroups" class="my-5 mx-4 thick" />
                <div class="row mb-2 bold-text">
                  <div class="col-7 col-xl-8 font-weight-bold text-gray col-header uppercase font-18">
                    <span class="ml-35p">ONLINE AFFINITY GROUPS</span>
                  </div>
                </div>
              </ng-container>
              <div #onlineAudience class="mb-3">
                <app-group-card *ngFor="let group of groups.industry.items" [group]="group" (selected)="openGroup($event)"></app-group-card>
                <app-group-card *ngFor="let group of groups.global.items" [group]="group" (selected)="openGroup($event)"></app-group-card>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!groups.totalProximityItems.length && !groups.alphabetized.length && !groups.hasOpenGroups">
            <div *ngIf="!loading && multiFilter">
              <div class="group-card mt-40p font-weight-bold p-3 mb-3">
                <p class="mb-0">
                  Currently, no groups are meeting based on your filter selection. Would you like to launch a group locally?
                  <a class="primary-color" target="_blank" [href]="contactLink"> Contact us. </a>
                </p>
              </div>
            </div>

            <div *ngIf="groups.otherOnlineGroups.length" infiniteScroll [infiniteScrollDistance]="5" (scrolled)="onScrollOtherOnlineGroups()">
              <div class="h-25p mb-3 mt-5">
                <div class="row bold-text">
                  <div class="col-7 col-xl-8 font-weight-bold text-gray col-header uppercase font-18 lh-18">
                    <span class="ml-35p">OTHER ONLINE GROUPS</span>
                  </div>
                  <ng-container *ngIf="!isTablet">
                    <div class="col-3 col-xl-2 font-weight-bold text-gray col-header uppercase font-18 lh-18 d-flex flex-column">
                      <span [class.truncated-single-line]="isLarge">MEETING DAY & TIME</span>
                      <span class="font-12">(IN YOUR LOCAL TIME)</span>
                    </div>
                    <div class="col-2 col-xl-2 font-weight-bold text-gray col-header uppercase font-18 lh-18 text-right">
                      <span class="mr-2">FREQUENCY</span>
                    </div>
                  </ng-container>
                </div>
              </div>

              <!-- Other Online Groups-->
              <app-group-card *ngFor="let group of groups.otherOnlineGroups" [group]="group" (selected)="openGroup($event)"></app-group-card>
            </div>
          </ng-container>
        </ng-container>
        <div class="d-flex align-items-center justify-content-center mt-200p">
          <app-loading-spinner></app-loading-spinner>
        </div>
      </div>
    </div>
  </div>

  <div class="fab" *ngIf="isMobile && multiFilter">
    <button mat-fab color="gray" [color]="multiFilter.isExpanded() ? 'primary' : 'gray'" (click)="openMultiFilter()">
      <i class="fas fa-filter"></i>
    </button>
  </div>
</ng-container>
