import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FileUploader } from 'ng2-file-upload';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UploadService } from 'src/app/_core/services/upload.service';
import { FileType } from '../../../_core/constants/FileType';
import { ModalActions, ModalEmitActions, Modals, MODALS_STYLES } from '../../../_core/constants/Modals';
import { ToastrMessages } from '../../../_core/constants/ToastrMessages';
import FileUtils from '../../../_core/helpers/FileUtils';
import { ModalFileResponse, ModalRequest } from '../../../_core/models/ModalEvent';

@Component({
  selector: 'app-crop-modal',
  templateUrl: './crop-modal.component.html',
  styleUrls: ['./crop-modal.component.scss'],
})
export class CropModalComponent implements OnInit, OnDestroy {
  @ViewChild('cropper') cropper: ImageCropperComponent;
  uploader: FileUploader;
  modalStyle;
  hasBaseDropZoneOver: boolean;
  editingFile: boolean;
  scale = 1;
  transform: ImageTransform = {};

  fileType: FileType;
  target: string;
  image: any = '';
  aspectRatio: number;
  croppedImages: string;
  isMobile = false;
  fileIndex: number;

  breakpointSubscription: Subscription;

  // prettier-ignore
  constructor(
    private modalsService: ModalsService,
    private uploadService: UploadService,
    private dialogRef: MatDialogRef<any>,
    private toastr: ToastrService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      this.isMobile = result.matches;
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vh')
        : this.dialogRef.updateSize('750px', '460px');
    });
  }

  ngOnInit(): void {
    this.uploader = this.uploadService.uploaderInstance;
    this.aspectRatio = this.modalsService.params.aspectRatio;
    this.modalStyle = MODALS_STYLES.find((modal) => modal.name === Modals.CROP_IMAGE).style;
    this.fileType = this.modalsService.params.fileType;
    this.fileIndex = this.modalsService.params.idx;
    this.target = this.modalsService.params.target;
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  fileChangeEvent(event: any): void {
    if (event) {
      if (!FileUtils.checkFileType(event[0].name, event[0].type, FileType.IMG)) {
        this.toastr.error(...ToastrMessages.INVALID_FORMAT);
        this.uploader.removeFromQueue(this.uploader.queue[this.uploader.queue.length - 1]);
        return;
      }
      this.image = this.uploader.queue[this.uploader.queue.length - 1].file.rawFile;
      this.editingFile = true;
      if (this.isMobile) {
        this.dialogRef.updateSize(this.modalStyle.width, '570px');
      }
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImages = event.base64;
  }

  loadImageFailed(): void {
    this.toastr.error(...ToastrMessages.LOAD_IMG_IN_CROPPER_ERROR);
  }

  changeFileOver(event: any): void {
    this.hasBaseDropZoneOver = event;
  }

  zoomOut(): void {
    this.scale -= 0.1;
    if (this.scale < 0.1) {
      this.scale = 0.1;
      return;
    }
    this.transform = { ...this.transform, scale: this.scale };
  }

  zoomIn(): void {
    this.scale += 0.1;
    this.transform = { ...this.transform, scale: this.scale };
  }

  close(): void {
    this.uploader.removeFromQueue(this.uploader.queue[this.uploader.queue.length - 1]);
    this.dialogRef.close();
  }

  uploadImages(): ModalFileResponse {
    return new ModalFileResponse(
      true,
      new ModalRequest(ModalActions.CROP),
      ModalEmitActions.CROPPED,
      this.croppedImages,
      this.fileType,
      this.target,
      this.fileIndex
    );
  }
}
