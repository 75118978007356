import { Injectable } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { UploaderApiService } from '../api/uploader-api.service';
import { FileType } from '../constants/FileType';
import { ToastrMessages } from '../constants/ToastrMessages';
import StorageHelper from '../helpers/Storage.helper';
import { UploadPromiseResponse } from '../models/Upload';

@Injectable({ providedIn: 'root' })
export class UploadService {
  private readonly uploader: FileUploader;
  public readonly maxFileSize = 2 * 1024 * 1024 * 1024; // 2GB

  public uploaderObservable = new Subject<any>();

  // prettier-ignore
  constructor(
    private toastrService: ToastrService,
    private uploaderApiService: UploaderApiService
  ) {
    this.uploader = this.setAssetUploaderInstance();
    this.setUploaderMethods();
  }

  get uploaderInstance(): FileUploader {
    return this.uploader;
  }

  setAssetUploaderInstance(): FileUploader {
    return new FileUploader({
      url: this.uploaderApiService.getUploadImageUrl(),
      authToken: `Bearer ${StorageHelper.getToken()}`,
      autoUpload: false,
      itemAlias: 'file',
      removeAfterUpload: false,
      maxFileSize: this.maxFileSize,
    });
  }

  setUploaderMethods(): void {
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => this.emitResponse(fileItem, progress.toString(), true);
    this.uploader.onSuccessItem = (fileItem: FileItem, response: any) => this.emitResponse(fileItem, response, false);
    this.uploader.onErrorItem = (fileItem: FileItem, response: any) => this.handleError(fileItem, response);
    this.uploader.onBuildItemForm = (fileItem: FileItem, form) => this.computeBuildItem(fileItem, form);
    this.uploader.onWhenAddingFileFailed = (_, filter, __) => this.addingFileFailed(filter);
  }

  uploadFile(): void {
    this.setOptions();
    this.computeFileNames();
    this.uploader.uploadAll();
  }

  private setOptions(): void {
    this.uploader.setOptions({ url: this.uploaderApiService.getUploadImageUrl(), authToken: `Bearer ${StorageHelper.getToken()}` });
  }

  private computeFileNames(): void {
    this.uploader.queue.forEach(
      (file: FileItem) => (file.file.name = new Date().getTime() + '_' + file.file.name.replace(/[^0-9a-zA-z._&\-\s]+/g, ''))
    );
  }

  private computeBuildItem(fileItem: FileItem, form): any {
    switch (fileItem.formData.fileType) {
      case FileType.PROFILE_IMAGE:
      case FileType.STUDY_IMAGE:
      case FileType.LOCATION_IMAGE:
      case FileType.CHURCH_IMAGE:
      case FileType.LANGUAGE_IMAGE:
      case FileType.PARTNER_ORGANIZATION_IMAGE:
        form.append('fileType', fileItem.formData.fileType);
        if (fileItem.formData?.entityUuid) {
          form.append('entityUuid', fileItem.formData.entityUuid);
        }
        if (fileItem.formData?.uploadForLogedinUser) {
          form.append('uploadForLogedinUser', fileItem.formData?.uploadForLogedinUser);
        }
        break;
      default:
        break;
    }
    return { fileItem, form };
  }

  private emitResponse(fileItem: FileItem, response: string, progress: boolean): void {
    const result: UploadPromiseResponse = new UploadPromiseResponse(fileItem, response);
    this.uploaderObservable.next({ result, progress });
    if (!progress) {
      this.uploader.progress = 0;
      this.uploader.removeFromQueue(fileItem);
    }
  }

  private handleError(fileItem: FileItem, response: string): void {
    JSON.parse(response).error === 'invalid_token'
      ? this.toastrService.error(...ToastrMessages.EXPIRED_SESSION)
      : this.emitResponse(fileItem, response, false);
  }

  private addingFileFailed(filter: any): void {
    switch (filter.name) {
      case 'fileSize':
        this.toastrService.error(...ToastrMessages.ERROR_MAX_FILE_SIZE);
        break;
      default:
        break;
    }
  }
}
