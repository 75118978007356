<div class="d-flex">
  <div class="image-container">
    <img class="study-image" [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'" [lazyLoad]="study.pictureUrl" />
  </div>
  <div class="ml-3 ml-xl-4">
    <h2 class="mb-0 truncated-2-lines">{{ study.name + ' ' }}</h2>
    <p class="smaller-text primary-color uppercase font-weight-bold mb-0 truncated-3-lines">{{ study.author }}</p>
    <div class="d-none d-xl-block">
      {{ study.description }}
    </div>
  </div>
</div>
<div class="d-block d-xl-none mt-2">
  {{ study.description }}
</div>
