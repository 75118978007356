import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { Configuration } from '../services/app.constants';

@Injectable({
  providedIn: 'root',
})
export class UploaderApiService {
  private readonly resourceUrl: string = '/v1/general';
  private readonly resourceUsersUrl: string = '/v1/users';

  // prettier-ignore
  constructor(
      private apiService: ApiService,
      private config: Configuration
   ) {
   }

  getUploadImageUrl(): string {
    return `${this.config.server}${this.resourceUrl}/upload-file`;
  }

  deleteImage(params): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/delete-file`, params);
  }

  getImportUsersUrl(): string {
    return `${this.config.server}${this.resourceUsersUrl}/import-via-file`;
  }
}
