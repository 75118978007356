import { Component, Input } from '@angular/core';
import { ValidationMessages } from '../../../_core/constants/ValidationMessages';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent {
  @Input() control: AbstractControl = null;
  @Input() controlName: string = '';

  validationMessages = ValidationMessages;

  showValidationError(vmType: string): boolean {
    if (this.control.hasError(vmType) && vmType === 'required') {
      return true;
    }
    if (this.control.hasError(vmType)) {
      return !(this.control.hasError('required') && this.validationMessages[this.controlName].length > 1);
    }
    return false;
  }
}
