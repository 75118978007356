<div class="row no-gutters justify-content-center flex-wrap">
  <ng-container *ngFor="let filter of filters.filtersArray; let i = index">
    <ng-container *ngIf="filter.name !== filterType.REGION">
      <div class="col" [ngClass]="{ 'mr-3': i < filters.filtersArray.length - 1 }" *ngIf="computeCondition(filter)">
        <button mat-button class="w-100" (click)="filterSelected.emit(filter)" [ngClass]="filter.misc.selected ? 'white-button' : 'black-button'">
          {{ filter.misc.text }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
