import { Component, OnInit } from '@angular/core';
import { ModalActions, ModalEmitActions } from 'src/app/_core/constants/Modals';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  text: string;
  cancelButtonText: string;
  confirmButtonText: string;

  // prettier-ignore
  constructor(private modalService: ModalsService) {}

  ngOnInit(): void {
    this.text = this.modalService.params.text;
    this.confirmButtonText = this.modalService.params.confirmButtonText;
  }

  confirm(): void {
    const payload = this.modalService.params.payload;
    this.modalService.emitResponse(
      new ModalResponse(
        true,
        new ModalRequest(ModalActions.RESEND_CONFIRMATION_EMAIL, null, null, payload),
        ModalEmitActions.RESEND_CONFIRMATION_EMAIL
      )
    );
    this.close();
  }

  close(): void {
    this.modalService.closeModal();
  }
}
