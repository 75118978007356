export enum RegionType {
  ALL = 'ALL',
  UNITED_STATES = 'United States',
  CANADA = 'Canada',
  LATIN_AMERICA = 'Latin America',
  EUROPE = 'Europe',
  AFRICA = 'Africa',
  MENA = 'MENA',
  ASIA_PACIFIC = 'Asia Pacific',
}

export const REGION_TYPES: RegionType[] = [
  RegionType.UNITED_STATES,
  RegionType.CANADA,
  RegionType.LATIN_AMERICA,
  RegionType.EUROPE,
  RegionType.AFRICA,
  RegionType.MENA,
  RegionType.ASIA_PACIFIC,
];
