<button
  [hidden]="!notificationsReceived"
  type="button"
  class="messages-icon-container"
  data-toggle="modal"
  data-target="#mattermostModal"
  (click)="openMattermost()"
>
  <div *ngIf="unreadMessagesCount" class="messages-unread-wrapper">
    <span class="font-weight-bold text-white">{{ this.unreadMessagesCount }}</span>
  </div>
  <img class="messages-icon" src="./assets/images/chat-icon.png" />
</button>

<!-- Modal -->
<div class="modal fade pr-0" id="mattermostModal" tabindex="-1" role="dialog" aria-labelledby="mattermostModalLabel" aria-hidden="true">
  <div class="modal-dialog mattermost-modal" role="document">
    <div class="modal-content">
      <div class="h-100">
        <div class="iframe-container" *ngIf="mattermostUrl">
          <div class="mattermost-close-icon-wrapper" data-toggle="modal" data-target="#mattermostModal">
            <div class="mattermost-close-icon"><i class="fas fa-2x fa-times"></i></div>
          </div>
          <iframe
            width="100%"
            height="100%"
            class="mattermost-iframe"
            [src]="mattermostUrl | sanitizeUrl"
            title="Chat"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading-spinner *ngIf="!notificationsReceived && !chatLoginFailed" [forceLoading]="!notificationsReceived" [small]="true"> </app-loading-spinner>
