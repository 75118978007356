import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { AdminChurchService } from 'src/app/_core/api/admin-church.service';
import { GroupService } from 'src/app/_core/api/group.service';
import { RegionsService } from 'src/app/_core/api/regions.service';
import { GroupRoles, GroupTypes, MainGroupRoles } from 'src/app/_core/constants/AdminGroups';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { FilterType } from 'src/app/_core/constants/Home';
import { RegionType } from 'src/app/_core/constants/RegionType';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import { Urls } from 'src/app/_core/constants/Urls';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import { CustomAddress } from 'src/app/_core/models/Address';
import { ResponseObject, UUIDName } from 'src/app/_core/models/GenericObject';
import { GroupsFilters } from 'src/app/_core/models/Home';
import { Location, LocationDto } from 'src/app/_core/models/Location';
import { Region, RegionDto } from 'src/app/_core/models/Region';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { GroupDropdownService } from 'src/app/_core/services/group-dropdown.service';
import { MultiFilterService } from 'src/app/_core/services/multi-filter.service';
import { QueryParams, QueryParamsService } from 'src/app/_core/services/query-params.service';
import { FILTER_NAME, INPUT_TYPE } from './multi-filter.const';
import { MultiFilterFormManagerService } from './multi-filter.manager';
import { MultiFilter, MultiFilterItem, MultiFilterOption } from './multi-filter.model';

@Component({
  selector: 'app-multi-filter',
  templateUrl: './multi-filter.component.html',
  styleUrls: ['./multi-filter.component.scss'],
  providers: [MultiFilterFormManagerService],
})
export class MultiFilterComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('placesRef') placesRef: ElementRef<HTMLInputElement>;
  @ViewChildren('regionPanels') regionPanels: MatExpansionPanel[];
  @ViewChild('subRegionsPanel') subRegionsPanel: MatExpansionPanel;
  @ViewChild('advanceSearchSection') advanceSearchSection: ElementRef<HTMLDivElement>;
  @Input() fromAdmin: boolean;
  @Input() dynamicFilters: QueryParams;
  // @ts-ignore
  placesOptions: Options = { types: ['(regions)'] };
  userRoles = MainGroupRoles;
  inputTypes = INPUT_TYPE;
  regionTypes = RegionType;
  groupRoles = GroupRoles;
  subscription: Subscription = new Subscription();
  dropdowns: GroupsFilters;
  multiFilter: MultiFilter;

  isAdminGroups: boolean;

  constructor(
    private groupDropdownsService: GroupDropdownService,
    private toastr: ToastrService,
    private adminChurchService: AdminChurchService,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private router: Router,
    private regionsService: RegionsService,
    private QPService: QueryParamsService,
    private multiFilterService: MultiFilterService,
    private analyticsService: AnalyticsService,
    public manager: MultiFilterFormManagerService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription.add(this.multiFilterService.userRoleChange$.subscribe((newRole) => this.filterChanged(newRole, this.multiFilter.userRole)));
  }

  ngOnInit(): void {
    this.isAdminGroups = this.router.url.indexOf(Urls.MANAGE_GROUPS) > 0;
    this.getFilterOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dynamicFilters?.currentValue !== changes.dynamicFilters?.previousValue) {
      this.multiFilter.resetAll();
      this.manager.advancedSearch.patchValue(false);
      this.handleQueryParams();
    }
  }

  ngOnDestroy(): void {
    this.QPService.resetParams();
    this.subscription.unsubscribe();
  }

  getFilterOptions(): void {
    forkJoin({
      filtersDropdowns: this.groupDropdownsService.filtersDropdowns$.pipe(first()),
      regions: this.getRegions(),
    }).subscribe((filters) => {
      this.dropdowns = filters.filtersDropdowns;
      this.multiFilter = new MultiFilter(this.dropdowns, filters.regions, this.isAdminGroups);
      if (this.QPService.getParams().locations) {
        filters.regions.forEach((region) => {
          const location = region.allLocations.find((location) => location.uuid === this.QPService.getParams().locations);
          if (location) this.multiFilterService.setLocation(location.name);
        });
      }
      this.manager.initForm(this.multiFilter, this.dropdowns);
      this.liveSearchListener();
      this.handleQueryParams();
    });
  }

  handleQueryParams(): void {
    const qParams = this.route.snapshot.queryParams;
    if (qParams.userRole) this.multiFilter.userRole.setSelected(qParams.userRole.toUpperCase());
    if (qParams.investorTypes) qParams.investorTypes.split(',').forEach((typeUuid) => this.multiFilter.investorTypes.setSelected(typeUuid));
    if (qParams.cohort) this.multiFilter.cohort.setSelected(qParams.cohort);
    if (qParams.startDate) this.multiFilter.startDate.setSelected(DateUtils.UTCtoLocalNoOffset(parseInt(qParams.startDate)));
    if (qParams.userType) qParams.userType.split(',').forEach((typeEnum) => this.multiFilter.userType.setSelected(typeEnum));
    if (qParams.unitStatuses) qParams.unitStatuses.split(',').forEach((statusUuid) => this.multiFilter.unitStatuses.setSelected(statusUuid));
    if (qParams.weekDays) qParams.weekDays.split(',').forEach((dayUuid) => this.multiFilter.weekDays.setSelected(dayUuid));
    if (qParams.timeIntervals) qParams.timeIntervals.split(',').forEach((intervalUuid) => this.multiFilter.timeIntervals.setSelected(intervalUuid));
    if (qParams.meetingType) qParams.meetingType.split(',').forEach((typeUuid) => this.multiFilter.meetingType.setSelected(typeUuid));
    if (qParams.groupState) qParams.groupState.split(',').forEach((state) => this.multiFilter.groupState.setSelected(state));
    if (qParams.unitsCounter) qParams.unitsCounter.split(',').forEach((unitUuid) => this.multiFilter.unitsCounter.setSelected(unitUuid));
    if (qParams.archived) this.multiFilter.archived.setSelected(qParams.archived);
    if (qParams.groupAudience)
      qParams.groupAudience.split(',').forEach((audienceUuid) => {
        const audienceData = this.dropdowns.audience.filters.find((audience) => audience.uuid === audienceUuid);
        this.multiFilter.groupAudience.setSelected(audienceUuid, audienceData.name);
      });
    if (qParams.groupLanguage)
      qParams.groupLanguage.split(',').forEach((languageUuid) => {
        const languageData = this.dropdowns.language.filters.find((language) => language.uuid === languageUuid);
        this.multiFilter.groupLanguage.setSelected(languageUuid, languageData.name);
      });
    if (qParams.groupOwners)
      qParams.groupOwners.split(',').forEach((ownerUuid) => {
        const ownerData = this.dropdowns.owners.filters.find((owner) => owner.uuid === ownerUuid);
        this.multiFilter.groupOwners.setSelected(ownerUuid, ownerData.name);
      });
    if (qParams.regions)
      qParams.regions.split(',').forEach((regionUuid) => {
        const SelectedSubRegionOption = this.multiFilter.mainlands.options.find((subRegion) => subRegion.name === regionUuid);
        if (SelectedSubRegionOption) this.multiFilter.mainlands.setSelected(regionUuid);
      });
    if (qParams.locations && this.isAdminGroups)
      qParams.locations.split(',').forEach((locationUuid) => {
        const SelectedLocationOption = this.multiFilter.mainlands.getChildOptionByUuid(locationUuid);
        if (SelectedLocationOption) this.multiFilter.mainlands.setChildSelected(SelectedLocationOption, true);
      });
    if (qParams.industry) {
      this.multiFilter.industryAffinity.setSelected(qParams.industry);
      this.manager.industryAffinity.setValue(qParams.industry);
    }
    if (qParams.companySize) {
      this.multiFilter.companySize.setSelected(qParams.companySize);
      this.manager.companySize.setValue(qParams.companySize);
    }
    if (this.multiFilter.mainlands.getSelectedChildrenUuids().length) {
      this.multiFilter.mainlands.helperData.subRegionsExpanded = true;
    }
    if (qParams.address) {
      const customAddress = {
        address: qParams.address,
        latitude: qParams.lat,
        longitude: qParams.long,
      } as CustomAddress;
      this.multiFilterService.setAddress(customAddress);
    }
    const commonAdvancedSearchParams =
      qParams.churches ||
      qParams.facilitators ||
      qParams.studies ||
      qParams.groupAudience ||
      qParams.groupOwners ||
      qParams.groupLanguage ||
      qParams.archived ||
      qParams.industry ||
      qParams.companySize;
    const publicAdvancedSearchParams = qParams.weekDays || qParams.timeIntervals;
    const adminAdvancedSearchParams = qParams.unitsCounter;
    this.manager.advancedSearch.patchValue(commonAdvancedSearchParams || publicAdvancedSearchParams || adminAdvancedSearchParams);
    this.selectAdvancedSearchFilters(qParams).subscribe(() => {
      this.updateUrl();
    });
  }

  globalGroupsChange(event): void {
    this.multiFilter.meetingType.reset();
    if (event.checked) {
      this.multiFilter.meetingType.setSelected(GroupTypes.VIRTUAL);
      this.QPService.add({ meetingType: this.multiFilter.meetingType.getQueryParamValue() });
      this.multiFilter.mainlands.reset();
      this.multiFilter.mainlands.setSelected(RegionType.ALL);
      this.multiFilter.mainlands.toggleDisable();
      this.regionPanels.forEach((panel) => panel.close());
    } else {
      this.QPService.remove(FILTER_NAME.MEETING_TYPE);
      this.multiFilter.mainlands.setSelected(RegionType.ALL);
      this.multiFilter.mainlands.toggleDisable();
    }
    this.multiFilter.meetingType.toggleDisable(GroupTypes.IN_PERSON);
    this.QPService.remove(FILTER_NAME.LOCATIONS);
    this.QPService.remove(FILTER_NAME.REGIONS);
    this.updateUrl();
  }

  resetAllFilters(): void {
    this.multiFilter.resetAll();
    let addressParams = {},
      locationParam = {};
    if (this.QPService.getParams().address) {
      addressParams = {
        address: this.QPService.getParams().address,
        lat: this.QPService.getParams().lat,
        long: this.QPService.getParams().long,
        mainland: this.QPService.getParams().mainland,
      };
    }
    if (!this.isAdminGroups && this.QPService.getParams().locations) locationParam = { mainland: this.QPService.getParams().mainland };
    this.QPService.resetParams({ ...addressParams, ...locationParam });
    this.manager.advancedSearch.patchValue(false);
    this.updateUrl();

    if (this.subRegionsPanel) {
      this.subRegionsPanel.close();
    }
  }

  filterChanged(event, filter: MultiFilterItem): void {
    const Value = event.source?.value || event.value || event;
    filter.setSelected(Value);

    if (filter.name === FILTER_NAME.USER_ROLE && filter.options[0].selected && (this.multiFilter.investorTypes.getValue() as string[]).length > 0) {
      this.multiFilter.investorTypes.reset();
      this.QPService.remove(FILTER_NAME.INVESTOR_TYPES);
    }

    if (filter.name === FILTER_NAME.REGIONS) {
      const queryParamsLocations =
        this.QPService.getParams()
          .locations?.split(',')
          .map((uuid) => this.multiFilter.mainlands.getChildByUuid(uuid)) || [];
      const selectedLocationsInOtherRegions = queryParamsLocations.filter((l) => l && l.parentUuid !== event.source.value);
      if (selectedLocationsInOtherRegions.length) {
        this.QPService.add({ locations: selectedLocationsInOtherRegions.map((l) => l.uuid).join(',') });
      } else {
        this.QPService.remove(FILTER_NAME.LOCATIONS);
      }
    }
    if (filter.expanded) {
      if (!this.isAdminGroups) {
        this.analyticsService.logClickEvent(AnalyticsEvent[filter.name]);
      }
      this.QPService.add({ [filter.name]: filter.getQueryParamValue() });
    } else {
      this.QPService.remove(filter.name);
    }
    this.updateUrl();
  }

  liveSearchListener(): void {
    this.manager.liveSearchEmitor.subscribe((filter: MultiFilterItem) => {
      if (filter.expanded) {
        this.QPService.add({ [filter.name]: filter.getQueryParamValue() });
      } else {
        this.QPService.remove(filter.name);
      }
      this.updateUrl();
    });
  }

  locationChanged(location: MultiFilterOption): void {
    const willUnselectSubRegion = this.multiFilter.mainlands.isSelected(location.parentUuid);
    this.multiFilter.mainlands.setChildSelected(location);
    const willSelectSubRegion = this.multiFilter.mainlands.isSelected(location.parentUuid);
    const selectedLocationsInCurrentSubRegion = this.multiFilter.mainlands.getSelectedChildrenByParent(location.parentUuid) || [];
    const queryParamsLocations =
      this.QPService.getParams()
        .locations?.split(',')
        .map((uuid) => this.multiFilter.mainlands.getChildByUuid(uuid)) || [];
    const selectedLocationsInOtherSubRegions = queryParamsLocations.filter((l) => {
      return l && l.parentUuid !== location.parentUuid;
    });
    if (willUnselectSubRegion) {
      const selectedSubRegions = this.multiFilter.mainlands.getQueryParamValue();
      if (selectedSubRegions) {
        this.QPService.add({ regions: selectedSubRegions });
      } else {
        this.QPService.remove(FILTER_NAME.REGIONS);
      }
    }
    let locationsUuids: string;
    if (willSelectSubRegion) {
      this.QPService.add({ regions: this.multiFilter.mainlands.getQueryParamValue() });
      locationsUuids = selectedLocationsInOtherSubRegions.map((l) => l.uuid).join(',');
    } else {
      locationsUuids = [...selectedLocationsInOtherSubRegions, ...selectedLocationsInCurrentSubRegion].map((l) => l.uuid).join(',');
    }
    if (locationsUuids) {
      this.QPService.add({ locations: locationsUuids });
    } else {
      this.QPService.remove(FILTER_NAME.LOCATIONS);
    }
    this.updateUrl();
  }

  resetFilter(filter: MultiFilterItem): void {
    filter.reset();
    if (filter.name === FILTER_NAME.USER_ROLE) this.multiFilter.investorTypes.reset();
    this.QPService.remove(filter.name);
    if (filter.name === FILTER_NAME.REGIONS && this.QPService.getParams().locations) this.QPService.remove(FILTER_NAME.LOCATIONS);
    this.updateUrl();
  }

  //Google Places location search
  handleAddressChange(address: Address): void {
    const customAddress = new CustomAddress(address);
    this.placesRef.nativeElement.value = null;
    if (!customAddress.stateProvince && !customAddress.city) {
      this.toastr.error(...ToastrMessages.GOOGLE_PLACES_NOT_FOUND);
      return;
    }
    if (!this.isAdminGroups) {
      this.multiFilterService.setAddress(customAddress);
      this.QPService.add({
        lat: customAddress.latitude.toString(),
        long: customAddress.longitude.toString(),
        address: customAddress.country,
      });
    }
    this.adminChurchService.getMainlandForPrefill(customAddress.stateProvince, customAddress.stateProvinceShort).subscribe({
      next: (res: ResponseObject<LocationDto>) => {
        if (!res.response) return;
        if (this.isAdminGroups) {
          this.multiFilter.mainlands.addSelectedLocation(new Location(res.response));
          this.locationChanged(this.multiFilter.mainlands.getChildByUuid(res.response.uuid));
        } else {
          this.QPService.add({ mainland: res.response.uuid });
        }
        this.updateUrl();
      },
      error: () => this.toastr.error(...ToastrMessages.BASIC_ERROR),
    });
  }

  closeRegionPanels(): void {
    this.multiFilter.mainlands.options.forEach((option) => (option.expanded = false));
    this.multiFilter.mainlands.helperData.subRegionsExpanded = false;

    if (this.subRegionsPanel) {
      this.subRegionsPanel.close();
    }
  }

  getFilterByUuid(filter: string, qParamsList: string): Observable<any> {
    if (!qParamsList) return of(null);
    const uuids = qParamsList.split(',');
    return this.groupService.getMultiFilterValueByUuid({ filter, uuids }).pipe(
      map((res: ResponseObject<UUIDName[]>) => {
        return res.response;
      }),
      catchError((err) => of(err))
    );
  }

  selectAdvancedSearchFilters(qParams): Observable<any> {
    return this.getFilterByUuid(FilterType.CHURCH, qParams.churches).pipe(
      tap((churches: UUIDName[]) => {
        if (churches) churches.forEach((church) => this.multiFilter.churches.setSelected(church.uuid, church.name));
      }),
      switchMap(() =>
        this.getFilterByUuid(FilterType.FACILITATOR, qParams.facilitators).pipe(
          tap((facilitators: UUIDName[]) => {
            if (facilitators) facilitators.forEach((facilitator) => this.multiFilter.facilitators.setSelected(facilitator.uuid, facilitator.name));
          }),
          switchMap(() =>
            this.getFilterByUuid(FilterType.STUDY, qParams.studies).pipe(
              tap((studies: UUIDName[]) => {
                if (studies) studies.forEach((study) => this.multiFilter.studies.setSelected(study.uuid, study.name));
              })
            )
          )
        )
      )
    );
  }

  advancedSearchClicked(event: MatLegacySlideToggleChange): void {
    if (event.checked) {
      this.scrollToAdvancedSearchSection();
    } else {
      this.scrollToTopOfFilters();
    }
    this.analyticsService.logClickEvent(AnalyticsEvent.ADVANCED_SEARCH);
  }

  disableOption(type, selected): boolean {
    return !!(selected && selected.find((item) => item.uuid === type.uuid));
  }

  getRegions(): Observable<Region[]> {
    const groupRole = this.QPService.getParams().groupRole as string;
    return this.regionsService.getRegions(groupRole).pipe(
      map((res: ResponseObject<RegionDto[]>) => {
        return res.response.map((region) => new Region(region));
      }),
      catchError((err) => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        return of(err);
      })
    );
  }

  updateRegionsFn(_index: number, subRegion: MultiFilterOption): boolean {
    return subRegion.expanded;
  }

  updateUrl(): void {
    this.QPService.updateUrl().subscribe(() => {
      this.multiFilterService.setMultiFilter(this.multiFilter);
    });
  }

  scrollToAdvancedSearchSection(): void {
    this.cdr.detectChanges();
    const container: HTMLDivElement = this.advanceSearchSection.nativeElement;
    const advancedSearchDiv: Element = container.querySelector('#advancedSearchSection');
    if (advancedSearchDiv) {
      advancedSearchDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  scrollToTopOfFilters(): void {
    const container: HTMLDivElement = this.advanceSearchSection.nativeElement;
    container.scrollTop = 0;
  }
}
