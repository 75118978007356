import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { LOADER_TYPE } from '../constants/Loader';
import { LoaderData } from '../models/Loader';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public loading$ = new ReplaySubject<LoaderData>(1);
  private _loaderData: LoaderData;

  show(type: LOADER_TYPE) {
    this._loaderData = {
      type,
      visible: true,
    };
    this.loading$.next(this._loaderData);
  }

  hide(type: LOADER_TYPE) {
    this._loaderData = {
      type,
      visible: false,
    };
    this.loading$.next(this._loaderData);
  }
}
