import { Clipboard } from '@angular/cdk/clipboard';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminUserService } from 'src/app/_core/api/admin-user.service';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { Environment } from 'src/app/_core/constants/Environments';
import { ServerError } from 'src/app/_core/constants/ServerErrors';
import { SocialMedia } from 'src/app/_core/constants/SocialMedia';
import { Urls } from 'src/app/_core/constants/Urls';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { QueryParamsService } from 'src/app/_core/services/query-params.service';
import { UserService } from 'src/app/_core/services/user.service';
import { environment } from 'src/environments/environment';
import { AdminGroupService } from '../../../../../_core/api/admin-group.service';
import { GroupFormat, GroupStates, GroupTypes, MeetingType, ParticipantType } from '../../../../../_core/constants/AdminGroups';
import { ModalActions, ModalEmitActions, Modals } from '../../../../../_core/constants/Modals';
import { ToastrMessages } from '../../../../../_core/constants/ToastrMessages';
import ComputePayload from '../../../../../_core/helpers/ComputePayload';
import { AdminGroupDetails, ChangeType, HandleParticipantRequest, Participant } from '../../../../../_core/models/AdminGroups';
import { SkipMeetingRequest } from '../../../../../_core/models/Groups';
import { ModalsService } from '../../../../../_core/services/modals.service';
import { GroupStatus } from '../../../_helpers/add-group/add-group.const';
import { AdminGroupMeeting, SmartAdminGroup, SmartAdminGroupUnit } from './view-group.model';

@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.scss'],
})
export class ViewGroupComponent implements OnInit, OnDestroy {
  urls = Urls;
  environment = environment;
  environmentName = Environment;
  statuses = GroupStatus;
  groupState = GroupStates;

  breakpointSubscription: Subscription;
  modalSubscription: Subscription;
  modalRequest: ModalRequest;
  group: SmartAdminGroup;
  socialMedia = SocialMedia;
  modals = Modals;
  modalActions = ModalActions;
  types = ParticipantType;
  meetingType = MeetingType;
  groupType = GroupFormat;
  groupTypes = GroupTypes;
  selectedUnit: SmartAdminGroupUnit;

  isLoading: boolean = false;
  isSkipped: boolean = false;
  showPopConfirm: boolean = false;
  isFacilitator: boolean;
  isAdmin: boolean;
  showCarousels: boolean = true;
  shareLink: string;
  currentUrl: string;
  selectedTab: number;

  // prettier-ignore
  constructor(
    private toastr: ToastrService,
    private adminGroupService: AdminGroupService,
    private adminUserService: AdminUserService,
    private modalService: ModalsService,
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    private dialogRef: MatDialogRef<ViewGroupComponent>,
    private clipboard: Clipboard,
    private analytics: AnalyticsService,
    private router: Router,
    private QPService: QueryParamsService,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 840px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vh')
        : this.dialogRef.updateSize('75vw', '100vh');
    });

    this.modalSubscription = this.modalService.modalResponse$.subscribe((response: ModalResponse) => {
      if (response) {
        this.handleModalResponse(response);
      }
    });
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.modalRequest = this.modalService.params;
    this.isFacilitator = this.userService.isFacilitator();
    this.isAdmin = this.userService.isAdmin();
    this.shareLink = `${environment.url}/${Urls.APP}/${Urls.GROUPS}/${this.modalRequest.uuid}`;
    this.getGroupDetails();
  }

  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
    this.breakpointSubscription.unsubscribe();
    this.modalSubscription.unsubscribe();
  }

  getGroupDetails(): void {
    this.adminGroupService.getGroupDetails(this.modalRequest.uuid).subscribe({
      next: (res: ResponseObject<AdminGroupDetails>) => {
        this.group = new SmartAdminGroup(res.response);
        this.selectedTab = this.group.units.findIndex((unit) => unit.active);
        this.changeTab();
      },
      error: () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
      },
    });
  }

  areSpotsLeft(): boolean {
    return this.selectedUnit.spotsLeft > 0;
  }

  toggleArchive(): void {
    const isArchived = this.group.status === GroupStatus.ARCHIVED;
    const modalAction = isArchived ? ModalActions.RESTORE_GROUP_FROM_VIEW_MODAL : ModalActions.ARCHIVE_GROUP_FROM_VIEW_MODAL;
    const payload = {
      actionButtonColor: isArchived ? 'accent' : 'warn',
      actionButtonText: isArchived ? 'Restore' : 'Archive',
      message: `Are you sure you want to ${isArchived ? 'restore' : 'archive'} this group?`,
    };
    this.openModal(Modals.CONFIRM_DELETE, modalAction, payload);
  }

  changeParticipantType(changeType: ChangeType, removeFromParticipants: Participant[], previousStatus: ParticipantType): void {
    if (this.isLoading) return;
    if (!this.areSpotsLeft() && changeType.newType === ParticipantType.APPROVED) {
      this.toastr.error(...ToastrMessages.ADMIN_GROUP_NO_SPOTS_LEFT);
      return;
    }
    const params: HandleParticipantRequest = ComputePayload.handleParticipant(this.selectedUnit.uuid, this.modalRequest.uuid, changeType);
    this.isLoading = true;
    this.adminGroupService.handleParticipant(params).subscribe({
      next: () => {
        const selectedUnitParticipant = removeFromParticipants.splice(changeType.index, 1)[0];
        if (previousStatus !== ParticipantType.PENDING || changeType.newType !== ParticipantType.WITHDRAWN) {
          this.getParticipantsListByStatus(changeType.newType, this.selectedUnit).unshift(selectedUnitParticipant);
        }
        if (changeType.newType === ParticipantType.APPROVED) this.selectedUnit.spotsLeft--;
        if (previousStatus === ParticipantType.APPROVED) this.selectedUnit.spotsLeft++;
        if (!this.selectedUnit.isLast) {
          this.group.units.forEach((unit, i) => {
            if (i <= this.selectedTab) return;
            const selectedList = this.getParticipantsListByStatus(previousStatus, unit);
            const movedParticipantIndex = selectedList.findIndex((participant) => participant.uuid === changeType.uuid);
            if (movedParticipantIndex >= 0) selectedList.splice(movedParticipantIndex, 1);
            if (previousStatus !== ParticipantType.PENDING || changeType.newType !== ParticipantType.WITHDRAWN) {
              this.getParticipantsListByStatus(changeType.newType, unit).unshift(selectedUnitParticipant);
            }
            if (changeType.newType === ParticipantType.APPROVED) unit.spotsLeft--;
            if (previousStatus === ParticipantType.APPROVED) unit.spotsLeft++;
          });
        }
        this.toastr.success(...ToastrMessages.HANDLE_PARTICIPANT);
        this.isLoading = false;
      },
      error: (error) => {
        if (error === ServerError.NO_MAINLAND_FOR_GROUP) {
          this.toastr.error(...ToastrMessages.NO_MAINLAND_FOR_GROUP);
        } else {
          this.toastr.error(...ToastrMessages.HANDLE_PARTICIPANT_ERROR);
        }
        this.isLoading = false;
      },
    });
  }

  getParticipantsListByStatus(status: ParticipantType, unit: SmartAdminGroupUnit): Participant[] {
    switch (status) {
      case ParticipantType.APPROVED:
        return unit.approvedParticipants;
      case ParticipantType.PENDING:
        return unit.pendingParticipants;
      case ParticipantType.DECLINED:
        return unit.declinedParticipants;
      case ParticipantType.WITHDRAWN:
        return unit.withdrawnParticipants;
      default:
    }
  }

  handleModalResponse(response: ModalResponse<any>): void {
    if (response?.confirmed) {
      switch (response.modalEmitAction) {
        case ModalEmitActions.ADMIN_ADDED_USER_TO_GROUP:
          this.handleParticipantAdded(response.modalRequest.payload);
          break;
        case ModalEmitActions.ATTENDANCE_SAVED:
          this.handleAttendanceEdited(response.modalRequest, false);
          break;
        case ModalEmitActions.ATTENDANCE_SUBMITTED:
          this.handleAttendanceEdited(response.modalRequest, true);
          break;
        case ModalEmitActions.ATTENDANCE_RESET:
          this.handleAttendanceReset(response.modalRequest);
          break;
        case ModalEmitActions.PARTICIPANT_DECLINED_SEND_EMAIL:
          this.participantDeclined(response);
          break;
        case ModalEmitActions.PARTICIPANT_APPROVED_SEND_EMAIL:
          this.participantApproved(response);
          break;
        case ModalEmitActions.PARTICIPANT_PENDING:
          this.participantPending(response);
          break;
        case ModalEmitActions.PARTICIPANT_WITHDRAWN:
          this.participantWithdrawn(response);
          break;
        case ModalEmitActions.SKIP_MEETING:
          this.skipMeeting(response.modalRequest.payload.meetingId, response.modalRequest.uuid);
          break;
        case ModalEmitActions.ARCHIVE_GROUP_FROM_VIEW_MODAL:
          this.archiveGroup(response.modalRequest);
          break;
        case ModalEmitActions.RESTORE_GROUP_FROM_VIEW_MODAL:
          this.restoreGroup(response.modalRequest);
          break;
        case ModalEmitActions.RESEND_CONFIRMATION_EMAIL:
          this.resendConfirmation(response.modalRequest.payload);
          break;
        default:
          break;
      }
    }
  }

  handleParticipantAdded(participant: Participant): void {
    this.selectedUnit.approvedParticipants.push(participant);
    if (!this.selectedUnit.isLast) {
      this.group.units.forEach((unit, i) => {
        if (i <= this.selectedTab) return;
        unit.approvedParticipants.push(participant);
      });
    }
  }

  resendConfirmation(userPayload: any): void {
    const payload = {
      userUuid: userPayload.userData.uuid,
      groupUuid: this.group.uuid,
      unitUuid: this.selectedUnit.uuid,
      isFacilitator: userPayload.facilitator,
    };
    this.adminUserService.resendConfirmation(payload).subscribe({
      next: () => this.toastr.success(...ToastrMessages.EMAIL_RESENT),
      error: () => this.toastr.error(...ToastrMessages.BASIC_ERROR),
    });
  }

  skipMeeting(meetingId: string, groupId: string): void {
    this.isLoading = true;
    const skipMeetingPayload: SkipMeetingRequest = { meetingUuid: meetingId, groupUuid: groupId };
    this.adminGroupService.skipMeeting(skipMeetingPayload).subscribe({
      next: (res) => {
        this.selectedUnit.meetings = res.response.units[this.selectedTab].meetings.map((meeting) => new AdminGroupMeeting(meeting));
        this.toastr.success(...ToastrMessages.SKIP_MEETING_SUCCESS);
        this.isLoading = false;
      },
      error: (error) => {
        if (error === ServerError.UNIT1_CANNOT_END_AFTER_UNIT2_STARTS) {
          this.toastr.error(...ToastrMessages.UNIT1_CANNOT_END_AFTER_UNIT2_STARTS);
        } else {
          this.toastr.error(...ToastrMessages.SKIP_MEETING_ERROR);
        }
        this.isLoading = false;
      },
    });
  }

  archiveGroup(modalRequest: ModalRequest): void {
    this.adminGroupService.archiveGroup(modalRequest.uuid).subscribe({
      next: () => {
        this.toastr.success(...ToastrMessages.GROUP_ARCHIVED);
        this.analytics.logClickEvent(AnalyticsEvent.ARCHIVE_GROUP);
        this.close();
      },
      error: () => {
        this.toastr.error(...ToastrMessages.GROUP_ARCHIVE_ERROR);
      },
    });
  }

  restoreGroup(modalRequest: ModalRequest): void {
    this.adminGroupService.restoreGroup(modalRequest.uuid).subscribe({
      next: () => {
        this.toastr.success(...ToastrMessages.GROUP_RESTORED);
        this.analytics.logClickEvent(AnalyticsEvent.RESTORE_GROUP);
        this.close();
      },
      error: () => {
        this.toastr.error(...ToastrMessages.GROUP_RESTORE_ERROR);
      },
    });
  }

  openModal(modalName: Modals, modalAction: ModalActions, payload: any = null): void {
    this.modalService.openModal(modalName, { ...this.modalRequest, scope: modalAction, payload });
  }

  close(): void {
    this.dialogRef.close();
  }

  handleAttendanceEdited(modalRequest: ModalRequest, submitted: boolean): void {
    for (const meeting of this.selectedUnit.meetings) {
      if (meeting.uuid === modalRequest.uuid) {
        meeting.completed = true;
        meeting.type = MeetingType.COMPLETED;
        meeting.submitted = submitted;
        break;
      }
    }
  }

  handleAttendanceReset(res): void {
    this.selectedUnit.meetings = res.response.units[this.selectedTab].meetings.map((meeting) => new AdminGroupMeeting(meeting));
  }

  participantDeclined(response: ModalResponse<any>): void {
    const declinedChangeType = new ChangeType(
      response.modalRequest.payload.uuid,
      response.modalRequest.index,
      this.types.DECLINED,
      response.modalRequest.payload.sendEmail,
      false,
      false,
      response.modalRequest.payload.freeBooks,
      response.modalRequest.payload.declinedEmailOption
    );
    if (response.modalRequest.payload.prevType === this.types.APPROVED) {
      this.changeParticipantType(declinedChangeType, this.selectedUnit.approvedParticipants, this.types.APPROVED);
    } else {
      this.changeParticipantType(declinedChangeType, this.selectedUnit.pendingParticipants, this.types.PENDING);
    }
  }

  participantApproved(response: ModalResponse<any>): void {
    const approvedChangeType = new ChangeType(
      response.modalRequest.payload.uuid,
      response.modalRequest.index,
      this.types.APPROVED,
      false,
      response.modalRequest.payload.hasOwnProperty('before7days') ? response.modalRequest.payload.before7days : false,
      response.modalRequest.payload.hasOwnProperty('before3days') ? response.modalRequest.payload.before3days : false,
      response.modalRequest.payload.hasOwnProperty('freeBooks') ? response.modalRequest.payload.freeBooks : false
    );

    switch (response.modalRequest.payload.prevType) {
      case this.types.PENDING:
        this.changeParticipantType(approvedChangeType, this.selectedUnit.pendingParticipants, this.types.PENDING);
        break;
      case this.types.DECLINED:
        this.changeParticipantType(approvedChangeType, this.selectedUnit.declinedParticipants, this.types.DECLINED);
        break;
      case this.types.WITHDRAWN:
        this.changeParticipantType(approvedChangeType, this.selectedUnit.withdrawnParticipants, this.types.WITHDRAWN);
        break;
    }
  }

  participantPending(response: ModalResponse<any>): void {
    const pendingChangeType = new ChangeType(response.modalRequest.payload.uuid, response.modalRequest.index, this.types.PENDING);

    switch (response.modalRequest.payload.prevType) {
      case this.types.APPROVED:
        this.changeParticipantType(pendingChangeType, this.selectedUnit.approvedParticipants, this.types.APPROVED);
        break;
      case this.types.DECLINED:
        this.changeParticipantType(pendingChangeType, this.selectedUnit.declinedParticipants, this.types.DECLINED);
        break;
      case this.types.WITHDRAWN:
        this.changeParticipantType(pendingChangeType, this.selectedUnit.withdrawnParticipants, this.types.WITHDRAWN);
        break;
    }
  }

  participantWithdrawn(response: ModalResponse<any>): void {
    const withdrawnChangeType = new ChangeType(response.modalRequest.payload.uuid, response.modalRequest.index, this.types.WITHDRAWN);

    switch (response.modalRequest.payload.prevType) {
      case this.types.APPROVED:
        this.changeParticipantType(withdrawnChangeType, this.selectedUnit.approvedParticipants, this.types.APPROVED);
        break;
      case this.types.DECLINED:
        this.changeParticipantType(withdrawnChangeType, this.selectedUnit.declinedParticipants, this.types.DECLINED);
        break;
      case this.types.PENDING:
        this.changeParticipantType(withdrawnChangeType, this.selectedUnit.pendingParticipants, this.types.PENDING);
        break;
    }
  }

  openWindow(socialMedia: SocialMedia): void {
    let url: string;
    switch (socialMedia) {
      case SocialMedia.FACEBOOK:
        url = `http://www.facebook.com/sharer/sharer.php?u=${this.shareLink}&title=${this.group.name}`;
        break;
      case SocialMedia.LINKEDIN:
        url = `http://www.linkedin.com/shareArticle?mini=true&url=${this.shareLink}&title==${this.group.name}&source=${environment.url}`;
        break;
      case SocialMedia.TWITTER:
        url = `http://twitter.com/intent/tweet?original_referer=${environment.url}&text=${this.group.name}&url=${this.shareLink}`;
        break;
      default:
        break;
    }
    window.open(url, 'newwindow', 'width: 300, height: 500');
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.shareLink);
    this.toastr.success(...ToastrMessages.COPIED_TO_CLIPBOARD);
  }

  changeTab(): void {
    this.selectedUnit = this.group.units[this.selectedTab];
    this.showCarousels = false;
  }

  showCarousel(): void {
    this.showCarousels = true;
  }

  goToGroupSetup(): void {
    this.QPService.storeParams();
    const source = this.currentUrl.indexOf(Urls.GROUPS_I_LEAD) < 0 ? Urls.MANAGE_GROUPS : Urls.GROUPS_I_LEAD;
    this.router.navigate([`${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_GROUPS}/${this.group.uuid}`], { queryParams: { source } });
    this.modalService.closeAll();
  }
}
