import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Urls } from 'src/app/_core/constants/Urls';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss', '../../../../assets/styles/shared-styles.scss'],
})
export class ErrorPageComponent implements OnInit {
  @Input() errorStatus = 404;
  breakpointSubscription: Subscription;

  errorTitle: string;
  errorMessage: string;

  // prettier-ignore
  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
    switch (this.errorStatus) {
      case 404:
        this.errorTitle = 'Seems like you are lost!';
        this.errorMessage = `This page was not found. \nYou may have mistyped the address or the page may have moved.`;
        break;
      case 401:
        this.errorTitle = 'No authorization found.';
        this.errorMessage = 'This page is not publicly available. \nTo access please log in first.';
        break;
      case 500:
        this.errorTitle = 'Sorry, unexpected error.';
        this.errorMessage = 'We are working on fixing the problem. \nTry again soon.';
        break;
    }
  }

  goHome(): void {
    this.router.navigate([Urls.BASE]);
  }
}
