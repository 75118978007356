import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map, startWith } from 'rxjs/operators';
import { AdminUserService } from 'src/app/_core/api/admin-user.service';
import { UploaderApiService } from 'src/app/_core/api/uploader-api.service';
import { EntrepreneurTypes, InvestorTypes } from 'src/app/_core/constants/AdminGroups';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { ServerError } from 'src/app/_core/constants/ServerErrors';
import { Urls } from 'src/app/_core/constants/Urls';
import ComputePayload from 'src/app/_core/helpers/ComputePayload';
import { CustomAddress } from 'src/app/_core/models/Address';
import { Timezone } from 'src/app/_core/models/AdminGroups';
import { CreateUpdateUserRequest } from 'src/app/_core/models/AdminUsers';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { ModalFileResponse, ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { InvitedUserStatus, User } from 'src/app/_core/models/User';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { AccountService } from '../../../_core/api/account.service';
import { FileType } from '../../../_core/constants/FileType';
import { ModalActions, ModalEmitActions, Modals } from '../../../_core/constants/Modals';
import { ToastrMessages } from '../../../_core/constants/ToastrMessages';
import { Role, UserRole } from '../../../_core/constants/UserEnums';
import { CustomValidators } from '../../../_core/helpers/CustomValidators.helpers';
import FileUtils from '../../../_core/helpers/FileUtils';
import Utils from '../../../_core/helpers/Utils';
import { FileDetails } from '../../../_core/models/FileInfo';
import { UploadPromiseResponse } from '../../../_core/models/Upload';
import { ModalsService } from '../../../_core/services/modals.service';
import { UploadService } from '../../../_core/services/upload.service';
import { UserService } from '../../../_core/services/user.service';

@Component({
  selector: 'app-add-user-facilitator',
  templateUrl: './add-user-facilitator.component.html',
  styleUrls: ['./add-user-facilitator.component.scss'],
})
export class AddUserFacilitatorComponent implements OnInit, OnDestroy {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  modalActions = ModalActions;
  modalEmitActions = ModalEmitActions;

  selectedCountryCode: CountryISO;

  user: User;
  uploader: FileUploader;
  modalRequest: ModalRequest<any>;
  addFacilitatorForm: UntypedFormGroup;
  addressNotFound: boolean;
  userPhoto: FileDetails = new FileDetails(null, null, null, null);
  facilitator = false;
  isSuperAdmin = false;
  currentUserUuid: String;
  enitityUuid: string = null;
  timezones: Timezone[];
  timezonesFiltered: Observable<any[]>;
  tryToSubmit: boolean = false;
  fromManageUsers: boolean;
  currentUserRole: Role;
  userRoles = { ...EntrepreneurTypes, ...InvestorTypes };
  subscription: Subscription = new Subscription();
  emailVal: string = null;

  protected readonly Role = Role;
  protected readonly UserRole = UserRole;

  // prettier-ignore
  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private uploadService: UploadService,
    private userService: UserService,
    private modalService: ModalsService,
    private adminUserService: AdminUserService,
    private uploaderApiService: UploaderApiService,
    private accountService: AccountService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<AddUserFacilitatorComponent>,
    private analytics: AnalyticsService,
    private router: Router,
  ) {
    this.subscription.add(this.modalService.modalResponse$.subscribe((response: ModalFileResponse) => {
      this.handleModalResponse(response);
    }));

    this.subscription.add(this.uploadService.uploaderObservable.subscribe(data =>
      data.progress ? this.setProgress(data.result) : this.setFileFields(data.result)));
  }

  ngOnInit(): void {
    this.currentUserUuid = this.userService.currentUser.userUuid;
    this.currentUserRole = this.userService.currentUser.role;
    this.uploader = this.uploadService.uploaderInstance;
    this.modalRequest = this.modalService.params;
    this.isSuperAdmin = this.userService.isSuperAdmin();
    this.emailVal = this.modalRequest.payload?.formData?.email ?? null;
    if (
      [
        ModalActions.ADD_FACILITATOR,
        ModalActions.EDIT_FACILITATOR,
        ModalActions.EDIT_USER,
        ModalActions.MY_PROFILE,
        ModalActions.MY_PROFILE_THEN_TRY_ENROLL,
        ModalActions.PROMOTE_TO_FACILITATOR,
      ].includes(this.modalRequest.scope)
    ) {
      this.subscription.add(
        this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
          result.matches ? this.dialogRef.updateSize('95vw', '100vh') : this.dialogRef.updateSize('50vw', '100vh');
        })
      );
    }
    this.fromManageUsers = this.router.url.indexOf(Urls.MANAGE_USERS) > -1 && this.modalRequest.scope === ModalActions.EDIT_FACILITATOR;
    this.createForm();
    this.isFacilitator();
    this.isEdit();
    this.getTimezones();
    this.phoneNumber.valueChanges.pipe(first()).subscribe(() => this.phoneNumber.markAsPristine());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm(): void {
    this.addFacilitatorForm = this.formBuilder.group({
      photo: [null],
      firstName: [null, [Validators.required, Validators.maxLength(50), CustomValidators.lettersAndSomeSpecialCharacters]],
      lastName: [null, [Validators.required, Validators.maxLength(50), CustomValidators.lettersAndSomeSpecialCharacters]],
      email: [this.emailVal, [Validators.required, Validators.maxLength(255), CustomValidators.email]],
      jobTitle: [null, [Validators.maxLength(255)]],
      description: [null],
      phoneNumber: [null, [Validators.required]],
      addressInfo: this.formBuilder.group({
        country: [null, [CustomValidators.required]],
        countryCode: [null],
        lat: [null],
        lng: [null],
        stateProvince: [null],
        city: [null, [CustomValidators.required]],
      }),
      linkedinProfile: [null, [Validators.maxLength(1000), CustomValidators.linkedin]],
      profilePictureUUID: [null],
      visible: [true],
      timezone: [null, [CustomValidators.requiredObject]],
    });
  }

  getUserDetails(): void {
    this.adminUserService.getUserInfo(this.modalRequest.uuid).subscribe(
      (res: ResponseObject<User>) => {
        this.user = res.response;
        this.setUserPhoto();
        this.patchForm();
      },
      () => {
        this.modalService.closeAll();
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
      }
    );
  }

  getTimezones(): void {
    this.adminUserService.getTimezones().subscribe(
      (res: ResponseObject<Timezone[]>) => {
        this.timezones = res.response;
        this.timezoneSearch();
      },
      () => {
        this.toastr.error(...ToastrMessages.TIMEZONES_REQUEST_ERROR);
      }
    );
  }

  timezoneSearch(): void {
    this.timezonesFiltered = this.timezone.valueChanges.pipe(
      startWith(''),
      filter((value) => typeof value === 'string'),
      map((value: string) => this.filterTimezone(value))
    );
  }

  filterTimezone(value: string): Timezone[] {
    const filterValue = value?.toLowerCase();
    return this.timezones.filter((option) => option.displayName.toLowerCase().indexOf(filterValue) > -1);
  }

  patchForm(): void {
    this.addFacilitatorForm.patchValue({
      profilePictureUUID: this.userPhoto?.uuid,
      firstName: this.user?.firstName,
      lastName: this.user?.lastName,
      email: this.user?.email,
      jobTitle: this.user?.title,
      description: this.user?.description,
      linkedinProfile: this.user?.linkedinProfile,
      phoneNumber: this.user?.phoneNumber?.nationalNumber,
      addressInfo: {
        country: this.user?.country,
        countryCode: this.user?.countryCode,
        stateProvince: this.user?.stateProvince,
        lat: this.user?.latitude,
        lng: this.user?.longitude,
        city: this.user?.city,
      },
      visible: this.user?.visible,
      timezone: this.pupulateTimezone(),
    });
    this.selectedCountryCode = CountryISO[Utils.getEnumKeyByValue(CountryISO, this.user?.phoneNumber?.countryCode)];
  }

  isAddInviteFlow(): boolean {
    return [ModalActions.ADD_FACILITATOR, ModalActions.ADD_USER, ModalActions.PROMOTE_TO_FACILITATOR].indexOf(this.modalRequest.scope) > -1;
  }

  isEdit(): void {
    if (
      this.modalRequest.scope === ModalActions.EDIT_FACILITATOR ||
      this.modalRequest.scope === ModalActions.EDIT_USER ||
      this.modalRequest.scope === ModalActions.PROMOTE_TO_FACILITATOR
    ) {
      this.getUserDetails();
    } else if (this.modalRequest.scope === ModalActions.MY_PROFILE || this.modalRequest.scope === ModalActions.MY_PROFILE_THEN_TRY_ENROLL) {
      this.user = this.userService.currentUser;
      this.setUserPhoto();
      this.patchForm();
    }
  }

  isFacilitator(): void {
    if (
      this.modalRequest.scope === ModalActions.ADD_FACILITATOR ||
      this.modalRequest.scope === ModalActions.ADD_FACILITATOR_REMOTELY ||
      this.modalRequest.scope === ModalActions.ADD_CO_FACILITATOR_REMOTELY ||
      this.modalRequest.scope === ModalActions.EDIT_FACILITATOR ||
      this.modalRequest.scope === ModalActions.PROMOTE_TO_FACILITATOR ||
      ((this.modalRequest.scope === ModalActions.MY_PROFILE || this.modalRequest.scope === ModalActions.MY_PROFILE_THEN_TRY_ENROLL) &&
        this.userService.currentUser.facilitator)
    ) {
      this.facilitator = true;
      this.description.setValidators([Validators.required, Validators.maxLength(500)]);
      this.jobTitle.setValidators([Validators.required]);
      this.visible.setValidators([Validators.required]);
    }
  }

  setUserPhoto(): void {
    if (this.user?.profileImage) {
      this.userPhoto.uuid = this.user.profileImage.uuid;
      this.userPhoto.path = this.user.profileImage.url;
    }
  }

  handleImageUpload(): void {
    if (this.userPhoto?.path) {
      const payload = ComputePayload.deleteUserImageRequest(FileType.PROFILE_IMAGE, this.userPhoto.uuid, this.modalRequest?.uuid || this.enitityUuid);
      this.uploaderApiService.deleteImage(payload).subscribe(() => {
        this.userPhoto = new FileDetails(null, null, null, null);
        if (this.userService.currentUser?.userUuid === this.user?.userUuid) {
          this.user.profileImage = null;
          this.userService.setCurrentUser(this.user);
        }
      });
    } else {
      this.openCropModal();
    }
  }

  openCropModal(): void {
    this.modalService.openModal(Modals.CROP_IMAGE, { aspectRatio: 1, fileType: FileType.PROFILE_IMAGE });
  }

  handleModalResponse(response: ModalFileResponse<any>): void {
    if (response?.confirmed) {
      switch (response.modalEmitAction) {
        case ModalEmitActions.CROPPED:
          this.uploadImageAfterCrop(response.payload, response.fileType);
          break;
        case ModalEmitActions.UPGRADE_USER_CONFIRMATION:
          this.inviteFacilitator(response.modalRequest.payload);
          break;
        default:
          break;
      }
    }
  }

  downgradeFacilitator(): void {
    const response = new ModalResponse(true, this.modalRequest, ModalEmitActions.DOWNGRADE_FACILITATOR_SPECIAL_CASE_REQUEST);
    this.modalService.emitResponse(response);
  }

  deleteFacilitator(): void {
    const response = new ModalResponse(true, this.modalRequest, ModalEmitActions.DELETE_FACILITATOR_SPECIAL_CASE_REQUEST);
    this.modalService.emitResponse(response);
  }

  emit(confirmed: boolean = true, modalEmitAction: ModalEmitActions): void {
    const response = new ModalResponse(confirmed, this.modalRequest, modalEmitAction);
    this.modalService.emitResponse(response);
    this.dialogRef.close();
  }

  uploadImageAfterCrop(payload: string, fileType: FileType): void {
    const fileToUpload: FileItem = this.uploader.queue[this.uploader.queue.length - 1];
    fileToUpload.formData.fileType = fileType;
    fileToUpload.formData.entityUuid = this.modalRequest?.uuid;
    fileToUpload.formData.uploadForLogedinUser =
      this.modalRequest.scope === ModalActions.MY_PROFILE || this.modalRequest.scope === ModalActions.MY_PROFILE_THEN_TRY_ENROLL ? true : undefined;
    fileToUpload._file = FileUtils.convertB64ToImage(payload, fileToUpload);
    this.userPhoto.pendingUpload += 1;
    this.uploadService.uploadFile();
  }

  setProgress(response: UploadPromiseResponse): void {
    this.userPhoto.progress = +response.response;
  }

  setFileFields(response: UploadPromiseResponse): void {
    const responseData = JSON.parse(response.response);
    const parsedData = responseData.response;
    const fileName = FileUtils.computeFileName(response.fileItem.file.name);

    if (responseData.hasOwnProperty('error')) {
      this.toastr.error(...ToastrMessages.BASIC_ERROR);
      this.userPhoto.pendingUpload -= 1;
      return;
    }

    this.profilePictureUUID.patchValue(parsedData.uuid);
    if (parsedData?.objectUuid) {
      this.enitityUuid = parsedData.objectUuid;
    }

    this.userPhoto.name = fileName;
    this.userPhoto.uuid = parsedData.uuid;
    this.userPhoto.path = parsedData.url;
    this.userPhoto.fileType = FileType.PROFILE_IMAGE;
    this.userPhoto.isFormatValid = true;
    this.userPhoto.pendingUpload -= 1;

    if (this.userService.currentUser?.userUuid === this.user?.userUuid) {
      this.user.profileImage = { uuid: this.userPhoto.uuid, url: this.userPhoto.path, filename: this.userPhoto.name, type: this.userPhoto.fileType };
      this.userService.setCurrentUser(this.user);
    }
  }

  handleAddressChange(address: Address): void {
    const customAddress = new CustomAddress(address);
    this.address.patchValue({
      address: customAddress.address,
      country: customAddress.country,
      countryCode: customAddress.countryCode || customAddress.stateProvinceShort,
      stateProvince: customAddress.stateProvince,
      city: customAddress.city,
      lat: customAddress.latitude,
      lng: customAddress.longitude,
    });

    this.addressInfo.markAsDirty();
    this.addressNotFound = !this.country.value || !this.city.value;
  }

  submit(): void {
    this.setTryToSubmit(true);
    this.addFacilitatorForm.markAllAsTouched();
    this.checkPhoneNumberChanges();
    if (this.addFacilitatorForm.pristine) {
      this.toastr.info(...ToastrMessages.NO_CHANGES);
      this.setTryToSubmit(false);
      return;
    }

    if (this.addFacilitatorForm.invalid) {
      this.toastr.error(...ToastrMessages.INCOMPLETE_FORM);
      this.setTryToSubmit(false);
      return;
    }

    const payload: CreateUpdateUserRequest = ComputePayload.createUpdateUser(this.modalRequest?.uuid, this.addFacilitatorForm.value);

    switch (this.modalRequest.scope) {
      case ModalActions.MY_PROFILE_THEN_TRY_ENROLL:
      case ModalActions.MY_PROFILE:
        this.updateAccount(payload);
        break;
      case ModalActions.EDIT_USER:
      case ModalActions.EDIT_FACILITATOR:
        this.updateUser(payload);
        break;
      case ModalActions.ADD_USER:
      case ModalActions.ADD_FACILITATOR_REMOTELY:
      case ModalActions.ADD_CO_FACILITATOR_REMOTELY:
      case ModalActions.ADD_FACILITATOR:
        this.inviteNewUser(payload);
        break;
      case ModalActions.PROMOTE_TO_FACILITATOR:
        this.inviteFacilitator({ ...payload, overrideUserInfo: true, facilitator: true });
        break;
      default:
        break;
    }
  }

  updateAccount(payload: CreateUpdateUserRequest): void {
    this.accountService
      .updateAccount(payload)
      .subscribe({
        next: (res: ResponseObject<User>) => {
          this.userService.setCurrentUser(res.response);
          this.toastr.success(...ToastrMessages.ACCOUNT_UPDATED);
          if (this.modalRequest.scope === ModalActions.MY_PROFILE_THEN_TRY_ENROLL) {
            this.emit(true, this.modalEmitActions.TRY_ENROLL);
          }
        },
        error: (error) => {
          switch (error) {
            case ServerError.EMAIL_USED:
              this.toastr.error(...ToastrMessages.EMAIL_USED);
              break;
            default:
              this.toastr.error(...ToastrMessages.BASIC_ERROR);
          }
        },
      })
      .add(() => {
        this.setTryToSubmit(false);
      });
  }

  updateUser(payload: CreateUpdateUserRequest): void {
    this.adminUserService
      .updateUser(payload)
      .subscribe(
        () => {
          this.facilitator ? this.inviteFacilitatorActionDone(true) : this.inviteUserActionDone(true);
          this.modalRequest.payload = payload;
          this.emit(true, ModalEmitActions.FACILITATOR_UPDATED);
        },
        (error) => {
          switch (error) {
            case ServerError.EMAIL_USED:
              this.toastr.error(...ToastrMessages.EMAIL_USED);
              break;
            default:
              this.toastr.error(...ToastrMessages.BASIC_ERROR);
          }
        }
      )
      .add(() => {
        this.setTryToSubmit(false);
      });
  }

  checkPhoneNumberChanges(): void {
    if (!this.phoneNumber.touched) {
      this.phoneNumber.markAsPristine();
      this.phoneNumber.setErrors(null);
    }
  }

  inviteFacilitator(payload: any): void {
    this.adminUserService
      .inviteFacilitator(payload)
      .subscribe(
        (res: ResponseObject<InvitedUserStatus[] | string>) => {
          if (Array.isArray(res.response)) {
            if (res.response[0].invalidNameFormat) {
              this.toastr.error(...ToastrMessages.INVITE_INVALID_NAME);
            } else if (res.response[0].invalidNameLength) {
              this.toastr.error(...ToastrMessages.INVITE_INVALID_NAME_LENGTH);
            } else if (res.response[0].invalidFacilitatorPhoneNumber) {
              this.toastr.error(...ToastrMessages.INVITE_INVALID_PHONE);
            } else if (res.response[0].alreadyInvited) {
              this.toastr.error(...ToastrMessages.INVITE_ALREADY_INVITED);
            } else if (res.response[0].existingFacilitator) {
              this.toastr.error(...ToastrMessages.INVITE_EXISTING_USER);
            }
          } else {
            if (!this.modalRequest.uuid) this.modalRequest.uuid = res.response;
            this.modalRequest.payload = this.firstName.value + ' ' + this.lastName.value;
            this.toastr.success(...ToastrMessages.FACILITATOR_ADDED);
            this.emit(true, ModalEmitActions.PROMOTE_TO_FACILITATOR_SUCCESS);
          }
        },
        () => this.toastr.error(...ToastrMessages.BASIC_ERROR)
      )
      .add(() => {
        this.setTryToSubmit(false);
      });
  }

  inviteNewUser(payload: CreateUpdateUserRequest): void {
    payload = { ...payload, facilitator: this.facilitator };
    this.adminUserService
      .inviteNewUser(payload)
      .subscribe(
        (res: ResponseObject<InvitedUserStatus[] | string>) => {
          if (Array.isArray(res.response)) {
            if (res.response[0].invalidNameFormat) {
              this.toastr.error(...ToastrMessages.INVITE_INVALID_NAME);
            } else if (res.response[0].invalidNameLength) {
              this.toastr.error(...ToastrMessages.INVITE_INVALID_NAME_LENGTH);
            } else if (res.response[0].invalidFacilitatorPhoneNumber) {
              this.toastr.error(...ToastrMessages.INVITE_INVALID_PHONE);
            } else if (res.response[0].alreadyInvited) {
              this.toastr.error(...ToastrMessages.INVITE_ALREADY_INVITED);
            } else if (res.response[0].existingFacilitator) {
              this.toastr.error(...ToastrMessages.INVITE_EXISTING_FACILITATOR);
            } else if (res.response[0].existingUser) {
              if (this.modalRequest.scope === ModalActions.ADD_USER) {
                this.toastr.error(...ToastrMessages.INVITE_EXISTING_USER);
              } else {
                this.modalService.openModal(
                  Modals.UPGRADE_USER_CONFIRMATION,
                  new ModalRequest(ModalActions.UPGRADE_TO_FACILITATOR, null, null, payload)
                );
              }
            }
          } else {
            if (
              this.modalRequest.scope === ModalActions.ADD_FACILITATOR_REMOTELY ||
              this.modalRequest.scope === ModalActions.ADD_CO_FACILITATOR_REMOTELY
            ) {
              if (!this.modalRequest.uuid) this.modalRequest.uuid = res.response;
              this.modalRequest.payload = this.firstName.value + ' ' + this.lastName.value;
              this.emit(true, ModalEmitActions.ADD_FACILITATOR_REMOTELY);
            } else {
              this.facilitator ? this.inviteFacilitatorActionDone(false) : this.inviteUserActionDone(false);
              this.modalService.closeAll();
              if (this.emailVal) this.modalRequest.payload.formData.email = this.email.value;
              this.emit(true, ModalEmitActions.ADD_USER_SUCCESS);
            }
          }
        },
        () => {
          this.toastr.error(...ToastrMessages.BASIC_ERROR);
        }
      )
      .add(() => {
        this.setTryToSubmit(false);
      });
  }

  promoteToLevel2(): void {
    if (this.user.fdeTeamMember) {
      this.toastr.info(...ToastrMessages.USER_LEVEL_2);
    } else {
      this.adminUserService.promoteToLevel2(this.email.value).subscribe(
        () => {
          this.user.fdeTeamMember = true;
          this.toastr.success(...ToastrMessages.USER_PROMOTED_TO_LEVEL_2);
        },
        () => this.toastr.error(...ToastrMessages.BASIC_ERROR)
      );
    }
  }

  changeUserRole(role: UserRole, upgrade: boolean = false): void {
    const payload = {
      userUuid: this.modalRequest.uuid,
      role: role,
    };

    this.adminUserService.changeUserRole(payload).subscribe(
      () => {
        const action = upgrade ? 'promoted' : 'downgraded';
        this.toastr.success(`User successfully ${action} to '${role}' role`);
        this.getUserDetails();
      },
      () => this.toastr.error(...ToastrMessages.BASIC_ERROR)
    );
  }

  displayTimezone(timezone?: Timezone): string | undefined {
    return timezone?.displayName;
  }

  inviteFacilitatorActionDone(edit: boolean): void {
    if (edit) {
      this.toastr.success(...ToastrMessages.FACILITATOR_UPDATED);
      this.analytics.logClickEvent(AnalyticsEvent.EDIT_FACILITATOR);
    } else {
      this.toastr.success(...ToastrMessages.FACILITATOR_INVITED);
      this.analytics.logClickEvent(AnalyticsEvent.SUBMIT_FACILITATOR);
    }
  }

  inviteUserActionDone(edit: boolean): void {
    if (edit) {
      this.toastr.success(...ToastrMessages.USER_UPDATED);
      this.analytics.logClickEvent(AnalyticsEvent.EDIT_USER);
    } else {
      this.toastr.success(...ToastrMessages.USER_INVITED);
      this.analytics.logClickEvent(AnalyticsEvent.SUBMIT_USER);
    }
  }

  pupulateTimezone(): Timezone | null {
    return this.user?.timezone && this.user?.timezoneName ? new Timezone(this.user?.timezone, this.user?.timezoneName) : null;
  }

  setTryToSubmit(submit: boolean) {
    this.tryToSubmit = submit;
  }

  get firstName(): AbstractControl {
    return this.addFacilitatorForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.addFacilitatorForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.addFacilitatorForm.get('email');
  }

  get jobTitle(): AbstractControl {
    return this.addFacilitatorForm.get('jobTitle');
  }

  get description(): AbstractControl {
    return this.addFacilitatorForm.get('description');
  }

  get phoneNumber(): AbstractControl {
    return this.addFacilitatorForm.get('phoneNumber');
  }

  get addressInfo(): AbstractControl {
    return this.addFacilitatorForm.get('addressInfo');
  }

  get address(): AbstractControl {
    return this.addFacilitatorForm.get('addressInfo');
  }

  get country(): AbstractControl {
    return this.addFacilitatorForm.get('addressInfo').get('country');
  }

  get countryCode(): AbstractControl {
    return this.addFacilitatorForm.get('addressInfo').get('countryCode');
  }

  get lat(): AbstractControl {
    return this.addFacilitatorForm.get('addressInfo').get('lat');
  }

  get lng(): AbstractControl {
    return this.addFacilitatorForm.get('addressInfo').get('lng');
  }

  get city(): AbstractControl {
    return this.addFacilitatorForm.get('addressInfo').get('city');
  }

  get stateProvince(): AbstractControl {
    return this.addFacilitatorForm.get('addressInfo').get('stateProvince');
  }

  get linkedinProfile(): AbstractControl {
    return this.addFacilitatorForm.get('linkedinProfile');
  }

  get profilePictureUUID(): AbstractControl {
    return this.addFacilitatorForm.get('profilePictureUUID');
  }

  get visible(): AbstractControl {
    return this.addFacilitatorForm.get('visible');
  }

  get timezone(): AbstractControl {
    return this.addFacilitatorForm.get('timezone');
  }
}
