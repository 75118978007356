<div *ngIf="selectedUnit" class="custom-container">
  <h1 class="mb-1 primary-color">Participants</h1>
  <div class="d-flex">
    <div class="menu-tab py-2 px-3 mr-2" [class.selected]="selectedTabIndex === 0" (click)="selectedTabIndex = 0">Manage Participants</div>
    <div
      class="menu-tab py-2 px-3"
      *ngAccess-admin
      [class.selected]="selectedTabIndex === 1"
      (click)="selectedTabIndex = 1; getParticipantsHistory()"
    >
      See History
    </div>
  </div>
  <div class="bordered-box py-3">
    <div *ngIf="selectedTabIndex === 0; else history" class="px-3">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="font-weight-bold mt-1">{{ selectedUnit.spotsLeft }} spots left</div>
          <div class="d-flex justify-content-end">
            <button
              [hidden]="group.status === statuses.ARCHIVED"
              mat-button
              color="primary"
              class="filter-button mat-long-button mr-2"
              (click)="openAddParticipantModal()"
              *ngAccess-admin
            >
              <span class="d-flex align-items-center"><i class="fas fa-plus mr-2"></i>Add Participants</span>
            </button>
            <button
              *ngIf="selectedUnit.approvedParticipants.length"
              mat-flat-button
              color="primary"
              class="filter-button"
              (click)="downloadGroupMembers()"
            >
              <i class="fas fa-download mr-2"></i>Export
            </button>
          </div>
        </div>
        <div class="mt-1" *ngIf="selectedUnit.approvalNotes">
          <span class="font-weight-bold primary-color mr-1"><i class="fas fa-comment mr-1"></i>Notes for Approval: </span>
          <span>{{ selectedUnit.approvalNotes }}</span>
        </div>
      </div>

      <div class="row mt-3">
        <mat-tab-group class="w-100 px-4">
          <mat-tab [label]="'Approved (' + selectedUnit.approvedParticipants.length + ')'" class="primary-color">
            <ng-container
              *ngTemplateOutlet="usersList; context: { participants: selectedUnit.approvedParticipants, type: types.APPROVED }"
            ></ng-container>
          </mat-tab>

          <mat-tab [label]="'Pending (' + selectedUnit.pendingParticipants.length + ')'">
            <ng-container
              *ngTemplateOutlet="usersList; context: { participants: selectedUnit.pendingParticipants, type: types.PENDING }"
            ></ng-container>
          </mat-tab>

          <mat-tab [label]="'Withdrawn (' + selectedUnit.withdrawnParticipants.length + ')'">
            <ng-container
              *ngTemplateOutlet="usersList; context: { participants: selectedUnit.withdrawnParticipants, type: types.WITHDRAWN }"
            ></ng-container>
          </mat-tab>

          <mat-tab *ngAccess-admin [label]="'Declined (' + selectedUnit.declinedParticipants.length + ')'">
            <ng-container
              *ngTemplateOutlet="usersList; context: { participants: selectedUnit.declinedParticipants, type: types.DECLINED }"
            ></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <ng-template #history>
      <ng-container *ngIf="!loading; else isLoading">
        <div *ngFor="let change of participantsHistory; index as i">
          <div class="d-flex justify-content-between history-card py-2 px-3">
            <span *ngIf="change.fieldValueList[0].oldValue" class="d-flex users">
              <b
                class="user truncated-single-line"
                [matTooltip]="change.user.firstName + ' ' + change.user.lastName + ' (' + change.user.email + ')'"
                matTooltipPosition="above"
                >{{ change.user.firstName + ' ' + change.user.lastName }}</b
              >
              <span class="mx-1">moved</span>
              <b
                class="user truncated-single-line"
                [matTooltip]="change.participant.firstName + ' ' + change.participant.lastName + ' (' + change.participant.email + ')'"
                matTooltipPosition="above"
                >{{ change.participant.firstName + ' ' + change.participant.lastName }}</b
              >
            </span>
            <span *ngIf="!change.fieldValueList[0].oldValue" class="d-flex users">
              <b
                class="user truncated-single-line"
                [matTooltip]="change.participant.firstName + ' ' + change.participant.lastName + ' (' + change.participant.email + ')'"
                matTooltipPosition="above"
                >{{ change.participant.firstName + ' ' + change.participant.lastName }}
              </b>
              <span class="ml-1">joined the group</span>
            </span>
            <span *ngIf="change.fieldValueList[0].oldValue" class="statuses">
              <b>{{ change.fieldValueList[0].oldValue | lowercase | titlecase }}</b>
              <i class="fas fa-long-arrow-alt-right text-gray mx-2"></i>
              <b>{{ change.fieldValueList[0].newValue | lowercase | titlecase }}</b>
            </span>
            <span class="text-gray date">{{ change.date | date : 'MMM d, y, h:mm a' }}</span>
          </div>
          <hr *ngIf="i !== participantsHistory.length - 1" class="my-0" />
        </div>
        <div *ngIf="!participantsHistory.length" class="no-participants">
          <app-empty [text]="'No history to display yet'"></app-empty>
        </div>
      </ng-container>
      <ng-template #isLoading>
        <div class="d-flex justify-content-center align-items-center">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>

<ng-template #usersList let-participants="participants" let-type="type">
  <ng-container *ngIf="participants.length; else noParticipantsContainer">
    <div class="d-flex flex-column">
      <div class="mb-0 fit-content-width mt-4">
        <ng-container *ngFor="let participant of participants; let i = index">
          <div class="participants-grid align-items-center" *ngIf="i < 8 || seeAll">
            <div>
              <i
                *ngIf="type === types.PENDING && !participant.completedWeeklyFoundation && !selectedUnit.isNew"
                class="fas fa-exclamation-triangle text-warning mr-1"
                matTooltip="This user has not completed a Foundation Group."
              >
              </i>
              <i
                *ngIf="type === types.PENDING && participant.foundationGroupParticipant && selectedUnit.study.isOnboardingStudy"
                class="fas fa-clone text-warning mr-1"
                matTooltip="This user is already approved for another Foundation Group."
              >
              </i>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <i
                  *ngAccess-admin
                  [hidden]="type !== types.APPROVED || !selectedUnit.areConfirmationEmailsOn || group.status === statuses.ARCHIVED"
                  class="fas fa-envelope primary-color pointer"
                  (click)="openConfirmationEmailModal(participant)"
                >
                </i>
                <div class="pointer ml-2 mr-5" (click)="openUserDetailsModal(participant)">
                  <div class="truncated-single-line" data-title="name" [title]="participant.firstName + participant.lastName">
                    {{ i + 1 }}. {{ participant.firstName + ' ' + participant.lastName }}
                  </div>
                </div>
              </div>
              <div [ngClass]="group.status === statuses.ARCHIVED ? 'd-none' : 'd-flex'" class="align-items-center flex-nowrap" *ngAccess-admin>
                <div
                  *ngIf="type !== types.APPROVED"
                  class="green-color pointer"
                  [ngClass]="isMobile ? 'mr-2' : 'mr-4'"
                  (click)="handleChangeType(participant, i, type, types.APPROVED)"
                >
                  <i class="fas fa-check mr-2"></i>
                  <span class="font-12" *ngIf="!isMobile">Approve</span>
                </div>

                <div
                  class="primary-color pointer"
                  [ngClass]="isMobile ? 'mr-2' : 'mr-4'"
                  (click)="handleChangeType(participant, i, type, types.PENDING)"
                  *ngIf="type !== types.PENDING"
                >
                  <i class="far fa-clock mr-2"></i>
                  <span class="font-12" *ngIf="!isMobile">Move to pending</span>
                </div>

                <div
                  class="red-color pointer"
                  [ngClass]="isMobile ? 'mr-2' : 'mr-4'"
                  *ngIf="type === types.APPROVED || type === types.PENDING"
                  (click)="handleChangeType(participant, i, type, types.DECLINED)"
                >
                  <i class="fas fa-times mr-2"></i>
                  <span class="font-12" *ngIf="!isMobile">Decline</span>
                </div>

                <div
                  class="gray-color pointer"
                  [ngClass]="isMobile ? 'mr-2' : 'mr-4'"
                  (click)="handleChangeType(participant, i, type, types.WITHDRAWN)"
                  *ngIf="type !== types.WITHDRAWN"
                >
                  <i class="fas fa-user-slash mr-2"></i>
                  <span class="font-12" *ngIf="!isMobile">{{ type !== types.PENDING ? 'Withdraw' : 'Remove application' }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="primary-color pointer font-weight-bold mt-2 d-flex" (click)="seeAll = true" *ngIf="participants.length >= 8 && !seeAll">
        <u>SEE ALL</u>
      </div>
    </div>
  </ng-container>

  <ng-template #noParticipantsContainer>
    <div class="no-participants">
      <app-empty [text]="'No ' + type.toLowerCase() + ' applicants'"></app-empty>
    </div>
  </ng-template>
</ng-template>
