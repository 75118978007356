import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SubscriptionLike } from 'rxjs';
import { ModalActions, ModalEmitActions } from 'src/app/_core/constants/Modals';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-delete-special-case',
  templateUrl: './delete-special-case.component.html',
  styleUrls: ['./delete-special-case.component.scss'],
})
export class DeleteSpecialCaseComponent implements OnInit, OnDestroy {
  modalRequest: ModalRequest;
  modalActions = ModalActions;
  breakpointSubscription: SubscriptionLike;
  item: string;

  // prettier-ignore
  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<DeleteSpecialCaseComponent>,
    private modalService: ModalsService,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', 'auto')
        : this.dialogRef.updateSize('580px', '280px');
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.extractItem();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  extractItem(): void {
    switch (this.modalRequest.scope) {
      case ModalActions.DELETE_STUDY_SPECIAL_CASE:
        this.item = 'study';
        break;
      case ModalActions.DELETE_COHORT_SPECIAL_CASE:
        this.item = 'cohort';
        break;
      case ModalActions.DELETE_AUDIENCE_SPECIAL_CASE:
        this.item = 'audience';
        break;
      case ModalActions.DELETE_CHURCH_SPECIAL_CASE:
        this.item = 'church';
        break;
      case ModalActions.DELETE_FACILITATOR_SPECIAL_CASE:
      case ModalActions.DOWNGRADE_FACILITATOR_SPECIAL_CASE:
        this.item = 'facilitator';
        break;
    }
  }

  close(): void {
    this.modalService.closeModal();
  }

  emit(confirmed: boolean): void {
    let modalRes;
    switch (this.modalRequest.scope) {
      case ModalActions.DELETE_STUDY_SPECIAL_CASE:
        modalRes = new ModalResponse(confirmed, this.modalRequest, ModalEmitActions.DELETE_STUDY_GO_TO_GROUPS);
        break;
      case ModalActions.DELETE_CHURCH_SPECIAL_CASE:
        modalRes = new ModalResponse(confirmed, this.modalRequest, ModalEmitActions.DELETE_CHURCH_SPECIAL_CASE);
        break;
      case ModalActions.DOWNGRADE_FACILITATOR_SPECIAL_CASE:
      case ModalActions.DELETE_FACILITATOR_SPECIAL_CASE:
        modalRes = new ModalResponse(confirmed, this.modalRequest, ModalEmitActions.DELETE_FACILITATOR_GO_TO_GROUPS);
        break;
      case ModalActions.DELETE_COHORT_SPECIAL_CASE:
      case ModalActions.DELETE_AUDIENCE_SPECIAL_CASE:
      case ModalActions.DELETE_OWNER_SPECIAL_CASE:
      case ModalActions.DELETE_LANGUAGE_SPECIAL_CASE:
        modalRes = new ModalResponse(confirmed, this.modalRequest);
        break;
    }
    this.modalService.emitResponse(modalRes);
    this.close();
  }
}
