import { Component } from '@angular/core';
import { ModalEmitActions } from 'src/app/_core/constants/Modals';
import { ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-confirm-accredited',
  templateUrl: './confirm-accredited.component.html',
  styleUrls: ['./confirm-accredited.component.scss', './../../auth.scss'],
})
export class ConfirmAccreditedComponent {
  constructor(private modalsService: ModalsService) {}

  confirm(): void {
    this.modalsService.emitResponse(new ModalResponse(true, this.modalsService.params, ModalEmitActions.CONFIRM_ACCREDITED));
    this.modalsService.closeModal();
  }
}
