<div class="d-flex flex-column justify-content-between h-100">
  <div
    *ngIf="[modalActions.ADD_PARTNER_ORGANIZATION, modalActions.EDIT_PARTNER_ORGANIZATION].includes(modalRequest.scope); else smallModalHeader"
    class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray"
  >
    <h1 class="mb-0">{{ partnerOrganizationDetails ? partnerOrganizationDetails.name : 'Add new partner organization' }}</h1>
    <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
  </div>
  <ng-template #smallModalHeader>
    <div class="d-flex flex-column align-items-end">
      <a class="text-right width-fit" (click)="close()"><i class="fas fa-times pointer"></i></a>
      <h2 class="text-center w-100">{{ partnerOrganizationDetails ? 'Edit Partner Organization Details' : 'Add New Partner Organization' }}</h2>
    </div>
  </ng-template>

  <mat-tab-group [selectedIndex]="selectedTabIndex" class="hide-tabs h-100">
    <mat-tab>
      <form [formGroup]="addPartnerOrganizationForm" [ngClass]="isSidePanel ? 'px-3 px-md-5 mt-3 mt-md-5' : ''">
        <div class="d-flex" class="flex-column">
          <!-- Partner organization picture -->
          <div class="d-flex flex-column">
            <img
              class="profilePic"
              [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/image-placeholder.png'"
              [lazyLoad]="partnerOrganizationPhoto?.path"
            />
            <a class="accent-color pointer mb-0 mt-1 text-nowrap width-fit" (click)="handleImageUpload()">
              <i class="fas fa-upload"></i> {{ (partnerOrganizationPhoto?.path ? 'DELETE' : 'UPLOAD') + ' PARTNER ORGANIZATION LOGO' }}
              <span *ngIf="!partnerOrganizationPhoto?.path" class="primary-color font-weight-bold">*</span>
            </a>
            <mat-error
              *ngIf="
                (partnerOrganizationPictureUrl.invalid && (partnerOrganizationPictureUrl.dirty || partnerOrganizationPictureUrl.touched)) ||
                (partnerOrganizationPictureUrl.invalid && submitted)
              "
              class="error"
            >
              <app-validation-message
                [control]="partnerOrganizationPictureUrl"
                [controlName]="'partnerOrganizationPictureUrl'"
              ></app-validation-message>
            </mat-error>
          </div>
          <!-- Partner Organization Name-->
          <div class="admin-input w-100">
            <label for="partnerOrganizationName" class="label">Partner organization name <span class="primary-color">*</span></label>
            <mat-form-field appearance="outline" class="w-100">
              <input id="partnerOrganizationName" matInput formControlName="partnerOrganizationName" maxlength="255" />
            </mat-form-field>
            <mat-error
              *ngIf="
                (partnerOrganizationName.invalid && (partnerOrganizationName.dirty || partnerOrganizationName.touched)) ||
                (partnerOrganizationName.invalid && submitted)
              "
              class="error"
            >
              <app-validation-message [control]="partnerOrganizationName" [controlName]="'partnerOrganizationName'"></app-validation-message>
            </mat-error>
          </div>
        </div>
        <!-- Headline -->
        <div class="admin-input">
          <label for="website" class="label">Headline</label>
          <mat-form-field appearance="outline" class="w-100">
            <input id="website" matInput formControlName="headline" maxlength="150" />
          </mat-form-field>
        </div>

        <!--Description-->
        <div class="admin-textarea">
          <label for="description" class="label">Description</label>
          <mat-form-field appearance="outline" class="w-100">
            <textarea rows="4" id="description" matInput formControlName="description" maxlength="500"></textarea>
          </mat-form-field>
        </div>

        <div class="d-flex flex-column" [ngClass]="{ 'flex-sm-row gap-1': !isSidePanel }">
          <!-- Website -->
          <div class="admin-input w-100">
            <label for="website" class="label">Website <span class="primary-color">*</span></label>
            <mat-form-field appearance="outline" class="w-100">
              <input id="website" matInput formControlName="website" maxlength="255" />
            </mat-form-field>
            <mat-error *ngIf="(website.invalid && (website.dirty || website.touched)) || (website.invalid && submitted)" class="error">
              <app-validation-message [control]="website" [controlName]="'partnerOrganizationWebsite'"></app-validation-message>
            </mat-error>
          </div>

          <!-- Mainland -->
          <div class="admin-input w-100">
            <label class="label">Region & Location <span class="primary-color">*</span></label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="mainland" (focus)="liveSearchMainlandResults = []" [matAutocomplete]="auto" />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayFn"
                (optionSelected)="liveSearchMainlandResults = []"
                (closed)="liveSearchMainlandResults = []"
              >
                <mat-option *ngFor="let item of liveSearchMainlandResults" [value]="item"
                  >{{ item.regionName }}, {{ item.subRegion }}, {{ item.name }}</mat-option
                >
              </mat-autocomplete>
            </mat-form-field>
            <mat-error *ngIf="(mainland.invalid && (mainland.dirty || mainland.touched)) || (mainland.invalid && submitted)" class="error">
              <app-validation-message [control]="mainland" [controlName]="'partnerOrganizationMainland'"></app-validation-message>
            </mat-error>
          </div>
        </div>
        <!-- Visible -->
        <!-- <div class="d-flex justify-content-end admin-input" *ngIf="isSidePanel">
          <div class="d-flex flex-nowrap mt-2">
            <div
              class="label mr-2 mt-0 width-fit titlecase"
              [class.opacity-4]="partnerOrganizationDetails?.noOfGroups > 0"
              [class.disabled-text]="partnerOrganizationDetails?.noOfGroups > 0"
            >
              Invisible
            </div>
            <mat-slide-toggle
              formControlName="visible"
              color="primary"
              class="visible label mt-0 titlecase"
              [disabled]="partnerOrganizationDetails?.noOfGroups > 0"
              >Visible
            </mat-slide-toggle>
          </div>
        </div> -->
      </form>
    </mat-tab>
    <mat-tab>
      <div class="d-flex flex-column">
        <h3 class="mt-3 mb-5 text-center font-20">
          <ng-container *ngIf="partnerOrganizationDetails; else newPartnerOrganization">
            Are you sure you want to make changes to the<br /><b>{{ partnerOrganizationName.value }}</b> details?<br />
            These changes may affect other groups affiliated with this partner organization.
          </ng-container>
          <ng-template #newPartnerOrganization>
            Review the details of <b>{{ partnerOrganizationName.value }}</b> before creating it.
          </ng-template>
        </h3>
        <h3 class="mb-3">This is how the new partner organization details would look like:</h3>
        <div class="d-flex" [ngClass]="isSidePanel ? '' : 'flex-column'">
          <div class="mr-4 mr-xs-0">
            <img
              class="logo-image"
              [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
              [lazyLoad]="partnerOrganizationPictureUrl.value"
            />
          </div>
          <div>
            <h2 class="mt-2 mb-1 truncated-2-lines">{{ partnerOrganizationName.value }}</h2>
            <div class="smaller-text primary-color uppercase font-weight-bold mb-0">{{ headline.value }}</div>
            <div class="smaller-text mb-0">{{ description.value }}</div>
            <div class="d-flex align-items-center my-2 pointer" *ngIf="website.value" (click)="navigate(website.value)">
              <i class="fas fa-link mr-2"></i> (link to {{ website.value }})
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="d-flex justify-content-between" [ngClass]="isSidePanel ? 'my-4 pb-4 px-3 px-md-5' : 'mt-4'">
    <ng-container *ngIf="isSidePanel; else smallModalButtons">
      <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="close()">Cancel</button>
      <button mat-flat-button class="filter-button uppercase" color="accent" (click)="submit()">Save</button>
    </ng-container>
    <ng-template #smallModalButtons>
      <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="smallModalBack()">
        {{ selectedTabIndex === 0 ? 'Cancel' : 'Back' }}
      </button>
      <button mat-flat-button class="filter-button uppercase" color="accent" (click)="smallModalSubmit()">
        {{ selectedTabIndex === 0 ? 'Review' : 'Save' }}
      </button>
    </ng-template>
  </div>
</div>
<div class="modal-loading-spinner" *ngIf="loading && isSidePanel">
  <app-loading-spinner [forceLoading]="loading"></app-loading-spinner>
</div>
