import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MultiFilter } from 'src/app/_shared/components/multi-filter/multi-filter.model';
import { MainGroupRoles } from '../constants/AdminGroups';
import Utils from '../helpers/Utils';
import { CustomMatSort } from '../models/CustomMatSort';
import { Sort } from '@angular/material/sort';
import { CustomAddress } from '../models/Address';
@Injectable({
  providedIn: 'root',
})
export class MultiFilterService {
  private _multiFilter: MultiFilter = null;
  private multiFilterSource = new BehaviorSubject<MultiFilter>(null);
  private address: Subject<CustomAddress> = new Subject<CustomAddress>();
  private locationName: Subject<string> = new Subject<string>();
  private userRoleChange = new Subject<MainGroupRoles>();

  multiFilter$ = this.multiFilterSource.asObservable();
  address$ = this.address.asObservable();
  locationName$ = this.locationName.asObservable();
  userRoleChange$ = this.userRoleChange.asObservable();

  // prettier-ignore
  constructor() {}

  get multiFilter(): MultiFilter {
    return this._multiFilter;
  }

  setMultiFilter(multiFilter: MultiFilter): void {
    this._multiFilter = multiFilter;
    this.multiFilterSource.next(this._multiFilter);
  }

  setAddress(address: CustomAddress): void {
    this.address.next(address);
  }

  setLocation(locationName: string): void {
    this.locationName.next(locationName);
  }

  setSearchText(searchText: string): void {
    this._multiFilter.searchText = searchText;
    this.multiFilterSource.next(this._multiFilter);
  }

  setSortBy(sortInfo: CustomMatSort | Sort): void {
    Utils.changeSortByOption(this._multiFilter.sortBy, sortInfo);
    this.multiFilterSource.next(this._multiFilter);
  }

  changeUserRole(newRole: MainGroupRoles): void {
    this.userRoleChange.next(newRole);
  }
}
