import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponentTypes } from './../constants/GooglePlaces';
import Utils from './../helpers/Utils';

export class CustomAddress {
  stateProvince: string;
  city: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  address: string;
  stateProvinceShort?: string;
  locationDetails?: string;
  distanceInKm?: number;
  countryCode?: string;
  country: string;

  constructor(address: Address) {
    this.stateProvince = Utils.getGooglePlacesCountryOrStateObjectLongName(address.address_components);
    this.stateProvinceShort = Utils.getGooglePlacesCountryOrStateObjectShortName(address.address_components);
    this.city = Utils.getCityLongName(address.address_components);
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    this.postalCode = Utils.getGooglePlacesData(address.address_components, [AddressComponentTypes.POSTAL_CODE])?.long_name;
    this.country = Utils.getGooglePlacesData(address.address_components, [AddressComponentTypes.COUNTRY])?.long_name;
    this.countryCode = Utils.getGooglePlacesData(address.address_components, [AddressComponentTypes.COUNTRY])?.short_name;
    this.address = address.formatted_address;
  }

  get addressIsIncomplete(): boolean {
    return (
      !this.stateProvince ||
      !this.stateProvinceShort ||
      !this.city ||
      !this.latitude ||
      !this.longitude ||
      !this.country ||
      !this.countryCode ||
      !this.address
    );
  }
}
