<app-facilitators-slider
  [facilitators]="facilitators"
  [churches]="churches"
  [readOnly]="false"
  (facilitatorEmitter)="setSelectedFacilitator($event)"
  (churchEmitter)="setSelectedChurch($event)"
></app-facilitators-slider>
<div class="d-flex flex-column" *ngIf="selectedFacilitator">
  <div class="d-flex">
    <div class="mr-4 mr-xs-0">
      <img
        class="facilitator-image"
        [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
        [lazyLoad]="selectedFacilitator?.imgUrl"
      />
    </div>
    <div class="ml-3 ml-xl-4 w-100">
      <h2 class="mb-0 truncated-2-lines">{{ selectedFacilitator.firstName + ' ' + selectedFacilitator.lastName + ' ' }}</h2>
      <div class="smaller-text primary-color uppercase font-weight-bold mb-0">{{ selectedFacilitator.title }}</div>
      <div class="smaller-text mb-0" *ngIf="!isMobile">{{ selectedFacilitator.bio }}</div>
      <div class="d-flex justify-content-between align-items-center my-2">
        <i *ngIf="selectedFacilitator.linkedinUrl" class="fas fa-link mr-2 pointer" (click)="navigate(selectedFacilitator.linkedinUrl)"></i>
        <div
          *ngIf="isAdmin && emailsTurnedOn && !isArchived"
          class="d-flex align-items-center primary-color pointer hover-bold"
          (click)="resendConfirmation()"
        >
          <i class="fas fa-envelope mr-2"></i>
          <span>Resend confirmation to facilitator</span>
        </div>
      </div>
    </div>
  </div>
  <div class="smaller-text mb-0" *ngIf="isMobile">{{ selectedFacilitator.bio }}</div>
</div>
<div class="d-flex flex-column" *ngIf="selectedChurch">
  <div class="d-flex">
    <div class="mr-4 mr-xs-0">
      <img
        class="facilitator-image"
        [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
        [lazyLoad]="selectedChurch.imageUrl"
      />
    </div>
    <div class="ml-3 ml-xl-4">
      <h2 class="mb-0 truncated-2-lines">{{ selectedChurch.name }}</h2>
      <div class="smaller-text primary-color uppercase font-weight-bold mb-0">{{ selectedChurch.headline }}</div>
      <div class="smaller-text mb-0" *ngIf="!isMobile">{{ selectedChurch?.description }}</div>
      <div class="d-flex my-2" *ngIf="selectedChurch.websiteUrl">
        <i class="fas fa-link mr-2 pointer" (click)="navigate(selectedChurch.websiteUrl)"></i>
      </div>
    </div>
  </div>
  <div class="smaller-text mb-0" *ngIf="isMobile">{{ selectedChurch.description }}</div>
</div>
