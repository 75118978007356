import { FilterType } from '../constants/Home';
import { LastDays, UserStatus } from '../models/AdminUsers';

export enum AdminUsersAction {
  FILTER_BY = 'FILTER_BY',
  SEARCH_USER = 'SEARCH_USER',
}

export const USER_STATUS_FILTER_DATA: UserStatus[] = [
  {
    text: 'Approved status',
    code: FilterType.USER_STATUS,
    status: 'APPROVED',
  },
  {
    text: 'Pending status',
    code: FilterType.USER_STATUS,
    status: 'PENDING',
  },
  {
    text: 'Declined status',
    code: FilterType.USER_STATUS,
    status: 'DECLINED',
  },
];

export const LAST_MODIFIED_FILTER_DATA: LastDays[] = [
  {
    text: 'Last day',
    code: FilterType.LAST_MODIFIED,
    days: 1,
  },
  {
    text: 'Last 3 days',
    code: FilterType.LAST_MODIFIED,
    days: 3,
  },
  {
    text: 'Last 7 days',
    code: FilterType.LAST_MODIFIED,
    days: 7,
  },
  {
    text: 'Last month',
    code: FilterType.LAST_MODIFIED,
    days: 31,
  },
];
