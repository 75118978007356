import { DOC_FORMATS, DOC_MIME_TYPES, FileType, IMG_FORMATS, IMG_MIME_TYPES, VIDEO_FORMATS, VIDEO_MIME_TYPES } from '../constants/FileType';
import { FileItem } from 'ng2-file-upload';

export default class FileUtils {
  public static computeFileName(fileName: string): string {
    const fileNameSplit: string[] = fileName.split('.');
    if (fileNameSplit[fileNameSplit.length - 1] !== 'mp4') {
      fileNameSplit[fileNameSplit.length - 1] = 'mp4';
    }
    return fileNameSplit.join('.');
  }

  public static checkFileType(fileName: string, mimeType: string, fileType: FileType): boolean {
    let mimeTypes: Array<string>;
    let fileFormats: Array<string>;
    switch (fileType) {
      case FileType.DOC:
        mimeTypes = DOC_MIME_TYPES;
        fileFormats = DOC_FORMATS;
        break;
      case FileType.VIDEO:
        mimeTypes = VIDEO_MIME_TYPES;
        fileFormats = VIDEO_FORMATS;
        break;
      case FileType.IMG:
        mimeTypes = IMG_MIME_TYPES;
        fileFormats = IMG_FORMATS;
        break;
    }
    return mimeType ? mimeTypes.indexOf(mimeType) > -1 : fileFormats.indexOf(this.extractFileExtension(fileName)) > -1;
  }

  public static extractFileExtension(fileName: string): string {
    return fileName ? fileName.split('.').pop() : null;
  }

  public static convertB64ToImage(b64string: string, fileToUpload: FileItem): File {
    const contentType = b64string.split('data:')[0];
    const data = b64string.split('base64,')[1];
    const blob: Blob = this.b64toBlob(data, contentType);
    return new File([blob], fileToUpload.file.name, { type: fileToUpload.file.type });
  }

  public static b64toBlob(b64Data, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
}
