import { FileItem } from 'ng2-file-upload';

export class UploadPromiseResponse {
  constructor(public fileItem: FileItem, public response: string) {}
}

export interface UploadFileResponse {
  objectUuid: string;
  type: string;
  url: string;
  uuid: string;
}
