import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AccountService } from 'src/app/_core/api/account.service';
import { AuthService } from 'src/app/_core/api/auth.service';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { ChatLoginResponse } from 'src/app/_core/models/Mattermost';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { UserService } from 'src/app/_core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy {
  unreadMessagesSub: Subscription = new Subscription();
  mattermostUrl: string;
  unreadMessagesCount: number;
  notificationsReceived: boolean;
  chatLoginFailed: boolean = true;

  // prettier-ignore
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private toastrService: ToastrService,
    private accountService: AccountService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    // if (this.userService.isMattermostEnabled()) {
    //   this.loginMattermost();
    // }
  }

  ngOnDestroy(): void {
    this.unreadMessagesSub?.unsubscribe();
  }

  loginMattermost() {
    const email = this.userService.currentUser.email;
    this.authService.getMattermostLogin(email).subscribe({
      next: (res: ChatLoginResponse) => {
        if (!res) {
          this.toastrService.error(...ToastrMessages.CHAT_LOGIN_FAILED);
          this.chatLoginFailed = true;
          return;
        }
        this.mattermostUrl = `${environment.mattermostUrl}/?userId=${res.userId}&authToken=${res.token}`;
        this.getUnreadMessages();
      },
      error: () => {
        this.toastrService.error(...ToastrMessages.CHAT_LOGIN_FAILED);
        this.chatLoginFailed = true;
      },
    });
  }

  getUnreadMessages(): void {
    this.accountService.getChatNotifications().subscribe({
      next: (response: ResponseObject<any>) => {
        this.notificationsReceived = true;
        this.unreadMessagesCount = response.response.numberOfUnreadNotifications;
        this.unreadMessagesSub = interval(10000)
          .pipe(mergeMap(() => this.accountService.getChatNotifications()))
          .subscribe((response: ResponseObject<any>) => {
            if (this.unreadMessagesCount !== response.response.numberOfUnreadNotifications) {
              this.unreadMessagesCount = response.response.numberOfUnreadNotifications;
            }
          });
      },
      error: () => this.toastrService.error(...ToastrMessages.NOTIFICATIONS_ERROR),
    });
  }

  openMattermost(): void {
    this.analyticsService.logClickEvent(AnalyticsEvent.OPEN_MATTERMOST);
  }
}
