import { Industry } from '../models/Home';
import { INDUSTRY_TYPE } from '../../internal-app/admin/tags/tags.const';

export class IndustryHelper {
  private static sortIndustries(industries: Industry[]): Industry[] {
    return industries.sort((a, b) => {
      if (a.type === INDUSTRY_TYPE.INDUSTRY && b.type !== INDUSTRY_TYPE.INDUSTRY) {
        return -1;
      } else if (a.type !== INDUSTRY_TYPE.INDUSTRY && b.type === INDUSTRY_TYPE.INDUSTRY) {
        return 1;
      }
    });
  }

  static getIndustriesWithHeaders(industryList: Industry[]): Industry[] {
    const industries: Industry[] = [];
    let lastType = null;

    this.sortIndustries(industryList).forEach((item) => {
      if (item.type !== lastType) {
        if (lastType !== null) {
          industries.push({
            name: 'DIVIDER',
            type: 'DIVIDER',
            uuid: 'DIVIDER_' + item.type,
            selected: false,
          });
        }
        industries.push({
          name: 'HEADER_' + item.type,
          type: 'HEADER',
          uuid: 'HEADER_' + item.type,
          selected: false,
        });
        lastType = item.type;
      }
      industries.push(item);
    });
    return industries;
  }
}
