<div class="d-flex flex-column justify-content-between h-100">
  <a class="text-right" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center">
    <h2>{{ skipMeetingTitle }}</h2>
    <div>{{ skipMeetingDescription }}</div>
  </div>

  <div class="d-flex justify-content-between mt-5">
    <button mat-flat-button class="mat-medium-button" color="primary" (click)="close()">Cancel</button>
    <button mat-flat-button class="mat-medium-button" color="warn" [mat-dialog-close]="emit(true)">{{ actionButtonText }}</button>
  </div>
</div>
