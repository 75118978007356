import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GroupService } from 'src/app/_core/api/group.service';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import ComputePayload from 'src/app/_core/helpers/ComputePayload';
import { GroupUnit } from 'src/app/_core/models/AdminGroups';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { GroupUnitHistoryRequest, HistoryResponse } from 'src/app/_core/models/GroupHistory';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { Pagination } from 'src/app/_core/models/Pagination';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-group-history',
  templateUrl: './group-history.component.html',
  styleUrls: ['./group-history.component.scss'],
})
export class GroupHistoryComponent implements OnInit {
  modalRequest: ModalRequest<{ groupUuid: string; units: GroupUnit[] }>;
  loading: boolean;
  groupPagination: Pagination = new Pagination();
  unitsPagination: Pagination = new Pagination();

  groupHistory: HistoryResponse[] = [];
  unitsHistory: [HistoryResponse[], HistoryResponse[]] = [[], []];

  isGroupHistoryEmpty: boolean = false;
  isUnitOneHistoryEmpty: boolean = false;
  isUnitTwoHistoryEmpty: boolean = false;

  // prettier-ignore
  constructor(
    private modalService: ModalsService, 
    private groupService: GroupService, 
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.getGroupHistory();
    this.getUnitsHistory();
  }

  close(): void {
    this.modalService.closeModal();
  }

  getGroupHistory(): void {
    this.loading = true;
    const payload: GroupUnitHistoryRequest = ComputePayload.computeGroupUnitHistoryPayload(this.groupPagination, this.modalRequest.payload.groupUuid);
    this.groupService.getGroupHistory(payload).subscribe({
      next: (response: ResponseObject<HistoryResponse[]>) => {
        if (response.response.length > 0) {
          this.groupHistory.push(...response.response);
        } else {
          this.isGroupHistoryEmpty = true;
        }
      },

      error: () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      },
    });
  }

  getUnitsHistory() {
    for (const [index, value] of this.modalRequest.payload.units.entries()) {
      this.getUnitHistory(index, value.uuid);
    }
  }

  getUnitHistory(index: number, uuid: string): void {
    this.loading = true;
    const payload: GroupUnitHistoryRequest = ComputePayload.computeGroupUnitHistoryPayload(this.groupPagination, uuid);
    this.groupService.getUnitHistory(payload).subscribe({
      next: (response: ResponseObject<HistoryResponse[]>) => {
        if (response.response.length > 0) {
          this.unitsHistory[index].push(...response.response);
        } else {
          index === 0 ? (this.isUnitOneHistoryEmpty = true) : (this.isUnitTwoHistoryEmpty = true);
        }
      },

      error: () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      },
    });
  }
}
