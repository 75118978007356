<ng-container *ngIf="facilitatorDetails">
  <div class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
    <h1 class="mb-0">
      <i class="fas fa-medal primary-color mr-1"></i>{{ facilitatorDetails.userInfo.firstName + ' ' + facilitatorDetails.userInfo.lastName }}
    </h1>
    <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
  </div>

  <div class="px-3 px-md-5 mt-2">
    <div class="custom-container">
      <h1 class="primary-color">About</h1>
      <div class="d-flex flex-column flex-md-row">
        <div class="d-flex flex-column mr-4 mr-xs-0">
          <img
            class="facilitator-image"
            [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
            [lazyLoad]="facilitatorDetails.userInfo?.profileImageUrl"
          />
          <div class="d-flex my-2 ml-2">
            <i class="fab fa-linkedin-in mr-2 pointer" (click)="navigate(facilitatorDetails.userInfo?.linkedIn)"></i>
          </div>
        </div>
        <div>
          <h2 class="mb-0">{{ facilitatorDetails.userInfo.firstName + ' ' + facilitatorDetails.userInfo.lastName }}</h2>
          <p class="smaller-text primary-color uppercase font-weight-bold mb-0">{{ facilitatorDetails.userInfo.title }}</p>
          <div class="mt-2">
            {{ facilitatorDetails.userInfo.description }}
          </div>
          <h3 class="mt-3 mb-0">Email</h3>
          <div class="bold">{{ facilitatorDetails.userInfo?.email }}</div>
          <h3 class="mt-3 mb-0">Address</h3>
          <div class="bold">{{ getAddressString() }}</div>
          <h3
            class="mt-3 mb-0"
            *ngIf="
              facilitatorDetails.userInfo?.userType ||
              facilitatorDetails.userInfo?.userSubType ||
              facilitatorDetails.userInfo?.secondaryUserType ||
              facilitatorDetails.userInfo?.secondaryUserSubType
            "
          >
            Primary User Type and Secondary User Type
          </h3>
          <div class="bold">
            <span *ngIf="facilitatorDetails.userInfo?.userType">{{ userRoles[facilitatorDetails.userInfo?.userType] }}</span
            ><span *ngIf="facilitatorDetails.userInfo?.userSubType"> ({{ userRoles[facilitatorDetails.userInfo?.userSubType] }})</span>
            <span
              *ngIf="
                (facilitatorDetails.userInfo?.userType || facilitatorDetails.userInfo?.userSubType) &&
                (facilitatorDetails.userInfo?.secondaryUserType || facilitatorDetails.userInfo?.secondaryUserSubType)
              "
              >,
            </span>
            <span *ngIf="facilitatorDetails.userInfo?.secondaryUserType">{{ userRoles[facilitatorDetails.userInfo?.secondaryUserType] }}</span
            ><span *ngIf="facilitatorDetails.userInfo?.secondaryUserSubType">
              ({{ userRoles[facilitatorDetails.userInfo?.secondaryUserSubType] }})</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="custom-container" *ngIf="areGroups">
      <h1 class="primary-color">Current Groups</h1>
      <ng-container *ngIf="facilitatorDetails.groups?.leading?.length > 0">
        <h2 class="text-center primary-color">LEADING</h2>
        <div class="mb-4" *ngFor="let group of facilitatorDetails.groups.leading">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </ng-container>
      <ng-container *ngIf="facilitatorDetails.groups?.approved?.length > 0">
        <h2 class="text-center primary-color">APPROVED</h2>
        <div class="mb-4" *ngFor="let group of facilitatorDetails.groups.approved">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </ng-container>
      <ng-container *ngIf="facilitatorDetails.groups?.pending?.length > 0">
        <h2 class="text-center primary-color">PENDING</h2>
        <div class="mb-4" *ngFor="let group of facilitatorDetails.groups.pending">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </ng-container>
      <ng-container *ngIf="facilitatorDetails.groups?.withdrawn?.length > 0">
        <h2 class="text-center primary-color">WITHDRAWN</h2>
        <div class="mb-4" *ngFor="let group of facilitatorDetails.groups.withdrawn">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </ng-container>
      <ng-container *ngIf="facilitatorDetails.groups?.declined?.length > 0">
        <h2 class="text-center primary-color">DECLINED</h2>
        <div class="mb-4" *ngFor="let group of facilitatorDetails.groups.declined">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </ng-container>
    </div>

    <div class="custom-container py-1 mb-4" *ngIf="modalRequest.scope !== modalActions.VIEW_PARTICIPANT_FACILITATOR">
      <button mat-button class="uppercase" color="primary" (click)="openModal(modals.ADD_USER, modalActions.EDIT_FACILITATOR)">
        <i class="fas fa-pencil-alt mr-2"></i>Edit
      </button>
      <button
        *ngIf="!fromManageUsers"
        mat-button
        class="uppercase"
        color="warn"
        (click)="emit(modalEmitActions.DELETE_FACILITATOR_SPECIAL_CASE_REQUEST)"
      >
        <i class="far fa-trash-alt mr-2"></i>Delete
      </button>
    </div>
  </div>
</ng-container>
