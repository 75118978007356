import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Urls } from '../constants/Urls';
import StorageHelper from '../helpers/Storage.helper';

export const NoAuthGuard: (...args: any) => boolean = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const isSelectingTypeWithoutAccount = !StorageHelper.getToken() && state.url.includes(Urls.REGISTER) && route.queryParams.reason;
  const isLoggedInWithNoReason = StorageHelper.getToken() && !route.queryParams.reason;
  if (isSelectingTypeWithoutAccount || isLoggedInWithNoReason) {
    router.navigate([Urls.BASE]);
    return false;
  }
  return true;
};
