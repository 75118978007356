import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguagePayload } from '../models/AdminTags';
import { ApiService } from '../services/api.service';
@Injectable({
  providedIn: 'root',
})
export class AdminTagsService {
  private readonly audienceUrl: string = '/v1/group-audiences';
  private readonly ownersUrl: string = '/v1/group-owners';
  private readonly languageUrl: string = '/v1/language';
  private readonly industryUrl: string = '/v1/industry';

  constructor(private apiService: ApiService) {}

  addAudience(name: string): Observable<any> {
    return this.apiService.post(`${this.audienceUrl}/add/${name}`);
  }

  editAudience(uuid: string, name: string): Observable<any> {
    return this.apiService.put(`${this.audienceUrl}/${uuid}`, {}, { name });
  }

  deleteAudience(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.audienceUrl}/${uuid}`);
  }

  addOwner(firstName: string, lastName: string, email: string): Observable<any> {
    return this.apiService.post(`${this.ownersUrl}/add/`, { firstName, lastName, email });
  }

  editOwner(uuid: string, firstName: string, lastName: string, email: string): Observable<any> {
    return this.apiService.put(`${this.ownersUrl}/${uuid}`, { firstName, lastName, email });
  }

  deleteOwner(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.ownersUrl}/${uuid}`);
  }

  createUpdateLanguage(body: LanguagePayload): Observable<any> {
    return this.apiService.post(`${this.languageUrl}/create-update`, body);
  }

  deleteLanguage(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.languageUrl}/${uuid}`);
  }

  addIndustryCategory(name: string, type: string): Observable<any> {
    return this.apiService.post(`${this.industryUrl}/create-update-category`, { name, type });
  }

  editIndustryCategory(uuid: string, name: string, type: string): Observable<any> {
    return this.apiService.post(`${this.industryUrl}/create-update-category`, { uuid, name, type });
  }

  deleteIndustryCategory(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.industryUrl}/category/${uuid}`);
  }
}
