import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchObject } from '../constants/AdminGroups';
import { MultiFilterGroups } from '../models/AdminChurches';
import { GetAdminGroupsRequest, HandleParticipantRequest } from '../models/AdminGroups';
import { SkipMeetingRequest } from '../models/Groups';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGroupService {
  private readonly resourceUrl: string = '/v1/groups';
  private readonly resourceUsersUrl: string = '/v1/users';

  constructor(private apiService: ApiService) {}

  searchStudyFacilitatorOrChurch(studyFacilitatorChurchOptions: SearchObject, name: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/search-study-facilitator-church`, { name, studyFacilitatorChurchOptions });
  }

  createUpdateGroup(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/create-update-group`, body);
  }

  getGroups(body: GetAdminGroupsRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/admin/get-groups`, body);
  }

  getMultiFilterGroups(body: MultiFilterGroups): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/admin/multi-filter-groups`, body);
  }

  getGroupDetails(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/details/${uuid}`);
  }

  handleParticipant(params: HandleParticipantRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/handle-participant`, null, params);
  }

  updateMeeting(body, submit): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/meeting`, body, { submit });
  }

  resetAttendance(meetingUuid): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/reset-meeting-attendance/${meetingUuid}`);
  }

  getAttendants(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/meeting/${uuid}/attendants`);
  }

  deleteGroup(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/delete-group/${uuid}`);
  }

  archiveGroup(uuid: string): Observable<any> {
    return this.apiService.put(`${this.resourceUrl}/archive/${uuid}`);
  }

  restoreGroup(uuid: string): Observable<any> {
    return this.apiService.put(`${this.resourceUrl}/unarchive/${uuid}`);
  }

  publishPendingGroup(uuid: string, publish: boolean, body: any): Observable<any> {
    return this.apiService.put(`${this.resourceUrl}/group/${uuid}/publish/${publish}`, body);
  }

  getLocations(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/admin/get-mainlands`, body);
  }

  downloadGroupMembersFile(uuid: string): Observable<any> {
    return this.apiService.post(`${this.resourceUsersUrl}/download-group-members-file/${uuid}`);
  }

  downloadAllGroupsFile(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/download-all-groups-file`);
  }

  getCohorts(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/admin/get-cohorts`);
  }

  skipMeeting(body: SkipMeetingRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/skip-meeting`, body);
  }

  updateEmailNotifications(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/turn-automated-emails`, body);
  }

  calculateEndDate(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/calculate-end-date`, body);
  }

  calculateEndDateAndSecondSesion(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/calculate-dates`, body);
  }

  getParticipantsHistory(payload: any): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/admin/participants-history`, payload);
  }
}
