<div class="d-flex flex-column justify-content-center align-items-center error-page-container">
  <div class="d-flex justify-content-center w-50 mb-3">
    <img class="logo-image" src="https://fde-group-finder-tool.s3.amazonaws.com/logos/FDEFDI_logo_horizontal_FDEFDI_white_horizontal.png" />
  </div>
  <div class="d-flex flex-column content-container">
    <div class="big-text text-center text-white mb-5">We'll be back soon!</div>
    <div class="main-text text-center text-white">
      We apologize for the inconvenience. Our <span class="primary-color font-weight-bold">Faith Driven Group Finder</span> site is currently under
      maintenance. We are working to improve your experience! We'll be back online shortly!
    </div>
  </div>
</div>
