import { GroupCardUnit, GroupChurchPartnerOrganization } from 'src/app/_core/models/Groups';
import { GroupStatus } from 'src/app/internal-app/admin/_helpers/add-group/add-group.const';
import { ExtractFormValueType, UnitForm } from 'src/app/internal-app/admin/_helpers/add-group/group-form.model';
import { Frequencies, GroupFormating, GroupStates, GroupTypes, MeetingType, ParticipantType } from '../constants/AdminGroups';
import DateUtils from '../helpers/DateUtils';
import { DECLINED_EMAIL, GroupFormat, GroupRoles } from './../constants/AdminGroups';
import { CustomAddress } from './Address';
import { Mainland } from './AdminChurches';
import { CustomFormGroup } from './CustomForms';
import { SortByOption } from './Filters';
import { UUIDName } from './GenericObject';
import { GetGroupsRequest, GroupFacilitator } from './Groups';
import { Industry, Language, Owner } from './Home';
import { FacilitatorSendEmail } from './User';

export interface AdminGroup {
  activeUnit: GroupCardUnit;
  churches: string[];
  cohort: UUIDName;
  groupState: string;
  name: string;
  groupStatus: GroupStatus;
  openUntilDate: number;
  uuid: string;
  hasHistory: boolean;
  units: GroupUnit[];
  isEnded?: boolean;
}

export interface GroupDetails {
  churchesList: GroupChurchPartnerOrganization[];
  partnerOrganizationsList: GroupChurchPartnerOrganization[];
  cohort: GroupCohort;
  groupAudiences: UUIDName[];
  groupIndustryCategory: Industry[];
  groupLanguage: Language[];
  groupOwners: Owner[];
  groupRole: GroupRoles;
  groupState: GroupStates;
  name: string;
  openUntilDate: number;
  pending: boolean;
  companySizes: UUIDName[];
  units: GroupDetailsUnit[];
  uuid: string;
  visible: boolean;
}

export interface GroupDetailsUnit {
  uuid: string;
  active: boolean;
  applyToUnitAllowed: boolean;
  approvedParticipants: Participant[];
  dayIndex: number;
  dayName: string;
  days: string[];
  durationMinutes: number;
  endsOn: number;
  endDateNever: boolean;
  occurrences: number;
  facilitatorsList: GroupFacilitator[];
  frequencyType: Frequencies;
  frequencyText: string;
  interval: string;
  location: CustomAddress;
  mainlands: Mainland[];
  meetingDay: string;
  meetingLink: string;
  meetingPlaceType: GroupTypes;
  meetings: Meeting[];
  meetingTime: string;
  primaryFacilitatorLocation: CustomAddress;
  spotsLeft: number;
  startsOn: number;
  study: UnitStudy;
  timezone: string;
  timezoneName: string;
  userStatus: string;
  groupFormat: GroupFormating;
  startTimeWithDaylightSavingTime: string;
  approvalNotes: string;
}

export interface UnitStudy {
  author: string;
  description: string;
  name: string;
  pictureUrl: string;
  uuid: string;
  isOnboardingStudy: boolean;
  onboardingStudy: boolean;
  groupRoleTypes: GroupRoles[];
  nrOfSession: number;
}

export interface AdminGroupDetails {
  uuid: string;
  name: string;
  groupFormat: GroupFormating;
  churchesList: GroupChurchPartnerOrganization[];
  partnerOrganizationsList: GroupChurchPartnerOrganization[];
  cohort: GroupCohort;
  groupAudiences: UUIDName[];
  groupIndustryCategory: Industry[];
  groupLanguage: Language[];
  groupOwners: Owner[];
  groupRole: GroupRoles;
  groupState: GroupStates;
  openUntilDate: number;
  openUntilAlways: boolean;
  groupStatus: GroupStatus;
  companySizes: UUIDName[];
  visible: boolean;
  units: ViewGroupUnit[];
}

export interface GroupUnit {
  uuid: string;
  onboardingStudy: boolean;
}

export interface ViewGroupUnit extends GroupDetailsUnit {
  dayName: string;
  declinedParticipants: Participant[];
  emailNotifications: EmailNotifications;
  maxParticipants: number;
  pendingParticipants: Participant[];
  spotsTaken: number;
  withdrawnParticipants: Participant[];
}

export interface ParticipantHistory {
  date: string;
  user: UserInfo;
  fieldValueList: FieldValue[];
  participant: UserInfo;
}

export interface FieldValue {
  fieldName: string;
  oldValue: string;
  newValue: string;
}

export interface EmailNotifications {
  priming: boolean;
  weeksBefore: boolean;
  confirmation: boolean;
  calendarInvites: boolean;
  updateEmails: boolean;
}

export interface GroupCohort {
  name: string;
  uuid: string;
}

export interface UserInfo {
  uuid: string;
  userUuid: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Participant extends UserInfo {
  facilitator: boolean;
  selected: boolean;
  completedWeeklyFoundation: boolean;
  foundationGroupParticipant: boolean;
}

export interface ParticipantStatus extends Participant {
  prevType: string;
  sendEmail?: boolean;
  before7days?: boolean;
  before3days?: boolean;
  freeBooks?: boolean;
  groupFormat?: GroupFormat;
  meetingType?: GroupTypes;
  declinedEmailOption?: DECLINED_EMAIL;
}

export class SendEmailFacilitator {
  uuid: string;
  sendEmail: boolean;
  sendFreeBookEmail?: boolean;
  isPrimary?: boolean;

  constructor(facilitatorData: FacilitatorSendEmail) {
    this.uuid = facilitatorData.uuid;
    this.sendEmail = facilitatorData.sendEmail;
    this.sendFreeBookEmail = facilitatorData.sendFreeBookEmail;
    this.isPrimary = facilitatorData.isPrimary;
  }
}

export interface GetAdminGroupsRequest extends GetGroupsRequest {
  sortBy: SortByOption<'asc' | 'desc'>;
  searchText: string;
}

export interface HandleParticipantRequest {
  userStatusInGroup: ParticipantType;
  unitUuid: string;
  groupUuid: string;
  userUuid: string;
  sendEmail?: boolean;
  before7days?: boolean;
  before3days?: boolean;
  freeBooks?: boolean;
  declinedEnum?: DECLINED_EMAIL;
}

export class ChangeType {
  uuid: string;
  index: number;
  newType: ParticipantType;
  sendEmail: boolean;
  before7days: boolean;
  before3days: boolean;
  freeBooks?: boolean;
  declinedEmailOption?: DECLINED_EMAIL;

  constructor(
    userUuid: string,
    index: number,
    newType: ParticipantType,
    sendEmail: boolean = false,
    before7days: boolean = false,
    before3days: boolean = false,
    freeBooks: boolean = false,
    declinedEmailOption: DECLINED_EMAIL = DECLINED_EMAIL.NO_EMAIL
  ) {
    this.uuid = userUuid;
    this.index = index;
    this.newType = newType;
    this.sendEmail = sendEmail;
    this.before7days = before7days;
    this.before3days = before3days;
    this.freeBooks = freeBooks;
    this.declinedEmailOption = declinedEmailOption;
  }
}

export interface CreateUpdateGroupRequest {
  uuid: string;
  groupFormat: GroupFormating;
  name: string;
  groupRole: string;
  groupAudiencesUuids: string[];
  groupIndustryCategoriesUuids: string[];
  language: string[];
  cohortUuid: string;
  churchesUuids: string[];
  partnerOrganizations: string[];
  companySizeUuids: string[];
  groupOwnersUuids: string[];
  groupState: string;
  openUntilDate: number;
  openUntilAlways: boolean;
  visible: boolean;
  groupStatus: GroupStatus;
  units: GroupUnitRequest[];
}

export class GroupUnitRequest {
  uuid: string;
  studyUuid: string;
  startsOn: number;
  endsOn: number;
  endDateNever: boolean;
  unitFrequencyType: string; //WEEKLY, EVERY_2_WEEKS etc
  meetingTime: string;
  durationMinutes: number;
  timezone: string;
  timezoneName: string;
  facilitators: SendEmailFacilitator[];
  meetingPlaceType: string;
  maxParticipants: number;
  meetingDaysNames: string[];
  interval: number;
  occurrences: number;
  // meetingDayUuid: string;
  meetingLink: string;
  location: CustomAddress;
  locationDetails: string;
  mainlandsUuid: string[];
  dayName?: string;
  dayIndex?: number;
  emailNotifications: EmailNotifications;
  meetings: EventMeeting[];
  approvalNotes: string;
  emailNote: string;

  constructor(unit: CustomFormGroup<UnitForm>, groupFormat: GroupFormating) {
    const unitFormValue: ExtractFormValueType<UnitForm> = unit.getRawValue();
    this.uuid = unitFormValue.uuid;
    this.studyUuid = unitFormValue.study.uuid;
    this.timezone = unitFormValue.timezone.timezone;
    this.timezoneName = unitFormValue.timezone.timezoneName;
    this.startsOn = this.createDateForRecurringGroup(groupFormat, unitFormValue.startDate, unitFormValue.startTime, this.timezone);
    this.endsOn = this.createDateForRecurringGroup(groupFormat, unitFormValue.endDate, unitFormValue.endTime, this.timezone);
    this.endDateNever = unitFormValue.endDateNever;
    this.unitFrequencyType = unitFormValue.frequency;
    this.meetingTime = DateUtils.extractMeetingTimeTimezone(this.startsOn, true, this.timezone);
    this.durationMinutes = DateUtils.computeDuration(unitFormValue.startTime, unitFormValue.endTime);
    this.facilitators = this.extractFacilitatorsList(unitFormValue);
    this.meetingPlaceType = unitFormValue.groupType;
    this.maxParticipants = unitFormValue.participantsAllowed;
    this.meetingDaysNames = this.extractWeekDays(unitFormValue);
    this.interval = unitFormValue.interval;
    this.occurrences = unitFormValue.frequency === Frequencies.CUSTOM && unitFormValue.occurrences ? unitFormValue.occurrences : 0;
    this.meetingLink = unitFormValue.meetingLink;
    this.location = unitFormValue.location;
    this.locationDetails = unitFormValue.locationDetails;
    this.mainlandsUuid = unitFormValue.mainlands.map((mainland) => mainland.uuid);
    // this.dayName = unitFormValue.frequency === Frequencies.MONTHLY ? this.meetingDaysNames[0] : null;
    this.dayName = unitFormValue.frequency === Frequencies.MONTHLY ? DateUtils.getDayName(unitFormValue.startDate).toUpperCase() : null;
    this.dayIndex = unitFormValue.frequency === Frequencies.MONTHLY ? unitFormValue.recurring : null;
    this.emailNotifications = unitFormValue.emailNotifications;
    this.meetings = this.extractEvents(unitFormValue);
    this.approvalNotes = unitFormValue.approvalNotes;
    this.emailNote = unit.helperData.updateEmailNotes;
  }

  createDateForRecurringGroup(groupFormat: GroupFormating, date: Date, time: string, timezone: string): number {
    if (groupFormat === GroupFormating.RECURRING) {
      return DateUtils.createDateAtSpecificTimezone(date, time, timezone);
    }
    return null;
  }

  extractFacilitatorsList(unit: ExtractFormValueType<UnitForm>): SendEmailFacilitator[] {
    return [unit.primaryFacilitator, ...unit.coFacilitators].map((facilitator) => new SendEmailFacilitator(facilitator));
  }

  extractWeekDays(unit: ExtractFormValueType<UnitForm>): string[] {
    return unit.meetingDaysNames.filter((day) => day.selected).map((day) => day.name);
  }

  extractEvents(unit: ExtractFormValueType<UnitForm>): EventMeeting[] {
    const orderedEventsData = unit.events.sort((a, b) => a.eventStartDate - b.eventStartDate);
    return orderedEventsData.map((event) => {
      const meetingStartTime: number = DateUtils.createDateAtSpecificTimezone(event.eventStartDate, event.eventStartTime, unit.timezone);
      const meetingEndTime: number = DateUtils.createDateAtSpecificTimezone(event.eventStartDate, event.eventEndTime, unit.timezone);
      return { meetingUuid: event.eventUuid, meetingStartTime, meetingEndTime };
    });
  }
}

export interface EventMeeting {
  meetingEndTime: number;
  meetingStartTime: number;
  meetingUuid: string;
}

export interface Meeting {
  attendants: any[];
  completed: boolean;
  passed: boolean;
  meetingTime?: number;
  meetingTimeLocal?: string;
  meetingUuid: string;
  type: MeetingType;
  meetingStartTime?: number;
  meetingEndTime?: number;
  durationMinutes?: number;
  submitted: boolean;
  skipped: boolean;
}

export interface UnitMeeting {
  startTime: number;
  endTime: number;
  durationMinutes: number;
}

export interface Timezone {
  timezone: string;
  timezoneName: string;
  displayName: string;
}

export class Timezone {
  timezone: string;
  timezoneName: string;
  displayName: string;

  constructor(timezone: string, timezoneName: string) {
    this.timezone = timezone;
    this.timezoneName = timezoneName;
    this.displayName = timezone && timezoneName ? timezoneName + '(' + timezone + ')' : null;
  }
}
