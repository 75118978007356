import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminStudiesService } from 'src/app/_core/api/admin-studies.service';
import { UploaderApiService } from 'src/app/_core/api/uploader-api.service';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { FileType } from 'src/app/_core/constants/FileType';
import { ModalActions, ModalEmitActions, Modals } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import ComputePayload from 'src/app/_core/helpers/ComputePayload';
import { CustomValidators } from 'src/app/_core/helpers/CustomValidators.helpers';
import FileUtils from 'src/app/_core/helpers/FileUtils';
import FormUtils from 'src/app/_core/helpers/FormUtils';
import Utils from 'src/app/_core/helpers/Utils';
import { AdminStudyDetails, StudyTypeList } from 'src/app/_core/models/AdminStudies';
import { FileDetails } from 'src/app/_core/models/FileInfo';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { MediaModel, ModalFileResponse, ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { UploadPromiseResponse } from 'src/app/_core/models/Upload';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { GroupDropdownService } from 'src/app/_core/services/group-dropdown.service';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UploadService } from 'src/app/_core/services/upload.service';
import { GroupRoles } from '../../../../../_core/constants/AdminGroups';
import { Dropdowns } from '../../../../../_core/models/Home';

@Component({
  selector: 'app-add-study',
  templateUrl: './add-study.component.html',
  styleUrls: ['./add-study.component.scss'],
})
export class AddStudyComponent implements OnInit, OnDestroy {
  modalSubscription: Subscription;
  uploaderSubscription: Subscription;
  breakpointSubscription: Subscription;
  dropDownSubscription: Subscription;
  uploader: FileUploader;
  modalRequest: ModalRequest;
  modals = Modals;
  modalActions = ModalActions;
  modalEmitActions = ModalEmitActions;
  addStudyForm: UntypedFormGroup;
  studyDetails: AdminStudyDetails;
  studyPhoto: FileDetails = new FileDetails(null, null, null, null);
  tryToSubmit: boolean = false;
  groupRoles: string[];
  disabledGroupRoles: { [key: string]: boolean } = {};
  studyTypes: StudyTypeList[];
  studyTypeOptions: string[];

  // prettier-ignore
  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private uploadService: UploadService,
    private modalService: ModalsService,
    private adminStudiesService: AdminStudiesService,
    private uploaderApiService: UploaderApiService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<AddStudyComponent>,
    private analytics: AnalyticsService,
    private dropdownsService: GroupDropdownService,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 875px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vh')
        : this.dialogRef.updateSize('874px', '100vh');
    });

    this.modalSubscription = this.modalService.modalResponse$.subscribe((response: ModalFileResponse) => {
      this.handleModalResponse(response);
    });

    this.uploaderSubscription = this.uploadService.uploaderObservable.subscribe(data =>
      data.progress ? this.setProgress(data.result) : this.setFileFields(data.result)
    );
  }

  async ngOnInit(): Promise<void> {
    this.uploader = this.uploadService.uploaderInstance;
    this.modalRequest = this.modalService?.params;
    this.createForm();
    await this.setDropdownData();
    if (this.modalRequest.scope === ModalActions.EDIT_STUDY) {
      this.getStudyDetails();
    }
  }

  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
    this.uploaderSubscription.unsubscribe();
    this.breakpointSubscription.unsubscribe();
    this.dropDownSubscription.unsubscribe();
  }

  private setDropdownData(): Promise<void> {
    return new Promise((resolve) => {
      this.dropDownSubscription = this.dropdownsService.dropdownsData$.subscribe((dropdowns: Dropdowns) => {
        this.studyTypes = dropdowns.studyTypeList;
        this.groupRoles = dropdowns.groupRoleTypesList.filter((role) => role !== 'INVESTOR');
        resolve();
      });
    });
  }

  private updateDisabledGroupRoles(selectedRoles: string[]) {
    const isEntrepreneurSelected = selectedRoles.includes(GroupRoles.ENTREPRENEUR);
    const isAnyOtherRoleSelected = selectedRoles.some((role) => role !== GroupRoles.ENTREPRENEUR);

    this.groupRoles.forEach((role) => {
      this.disabledGroupRoles[role] = role === GroupRoles.ENTREPRENEUR ? isAnyOtherRoleSelected : isEntrepreneurSelected;
    });
  }

  filterStudyTypes(selectedRoles: string[]): void {
    this.studyTypeOptions =
      selectedRoles.length === 0
        ? []
        : this.studyTypes
            .filter((studyType) => selectedRoles.some((role) => studyType.groupRoleTypeList.includes(role)))
            .map((studyType) => studyType.type);

    if (!this.studyTypeOptions.includes(this.studyType.value)) {
      this.studyType.setValue([]);
    }
  }

  getStudyDetails(): void {
    this.adminStudiesService.getStudyDetailsForm(this.modalRequest.uuid).subscribe(
      (res: ResponseObject<AdminStudyDetails>) => {
        this.studyDetails = res.response;
        this.prefillForm();
        this.setStudyImage();
      },
      () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
      }
    );
  }

  createForm(): void {
    this.addStudyForm = this.formBuilder.group({
      studyUuid: [null, [Validators.required]],
      studyName: [null, [Validators.required, Validators.maxLength(100)]],
      author: [null, [Validators.required, Validators.maxLength(100)]],
      groupRoleTypes: [[], [Validators.required]],
      studyType: [null, [Validators.required]],
      nrOfSession: [null, [CustomValidators.number, Validators.max(100)]],
      studyDescription: [null, [Validators.required, Validators.maxLength(700)]],
      studyPictureUuid: [null, [Validators.required]],
      trailerEmbed: [null],
      trailerLink: [null, [CustomValidators.website, Validators.maxLength(500)]],
      onboarding: [false, Validators.required],
      visible: [true, Validators.required],
    });

    this.valueChanges();
  }

  handleTrailerEmbed(): void {
    this.trailerEmbed.valueChanges.subscribe((value) => {
      value ? this.trailerLink.patchValue(Utils.parseEmbed(value)) : this.trailerLink.patchValue(null);
    });
  }

  handleOnboardingStudy() {
    this.onboarding.valueChanges.subscribe((value) => {
      value
        ? FormUtils.clearAndUpdateValueAndValidator(this.nrOfSession, [Validators.required, CustomValidators.number, Validators.max(100)])
        : FormUtils.clearAndUpdateValueAndValidator(this.nrOfSession, [CustomValidators.number, Validators.max(100)]);
    });
  }

  private handleGroupRoleTypes(): void {
    this.groupRoleTypes.valueChanges.subscribe((selectedRoles: string[]) => {
      this.updateDisabledGroupRoles(selectedRoles);
      this.filterStudyTypes(selectedRoles);
    });
  }

  valueChanges(): void {
    this.handleTrailerEmbed();
    this.handleOnboardingStudy();
    this.handleGroupRoleTypes();
  }

  prefillForm(): void {
    this.addStudyForm.patchValue({
      studyName: this.studyDetails.name,
      author: this.studyDetails.author,
      groupRoleTypes: this.studyDetails.groupRoleTypes,
      studyType: this.studyDetails.type,
      nrOfSession: this.studyDetails.nrOfSession,
      studyDescription: this.studyDetails.description,
      studyUuid: this.studyDetails.studyUuid,
      studyPictureUuid: this.studyDetails?.studyPictureUuid,
      trailerLink: this.studyDetails?.trailerLink,
      onboarding: this.studyDetails.onBoardingStudy,
      visible: this.studyDetails.visible,
    });
  }

  setStudyImage(): void {
    if (this.studyDetails.studyPictureUrl && this.studyDetails.studyPictureUuid) {
      this.studyPhoto.uuid = this.studyDetails.studyPictureUuid;
      this.studyPhoto.path = this.studyDetails.studyPictureUrl;
    }
  }

  handleImageUpload(): void {
    this.studyPictureUuid.reset();
    if (this.studyPhoto?.path) {
      this.uploaderApiService
        .deleteImage({ entityUuid: this.studyUuid.value, fileType: FileType.STUDY_IMAGE, fileUuid: this.studyPhoto.uuid })
        .subscribe(() => (this.studyPhoto = new FileDetails(null, null, null, null)));
    } else {
      this.modalService.openModal(Modals.CROP_IMAGE, {
        aspectRatio: 3 / 4,
        fileType: FileType.STUDY_IMAGE,
        idx: null,
        target: 'studyPictureUuid',
      });
    }
  }

  openModal(modalName: Modals, modalAction: ModalActions = null, payload: any = null): void {
    modalAction
      ? this.modalService.openModal(modalName, { ...this.modalRequest, scope: modalAction, payload })
      : this.modalService.openModal(Modals.MEDIA, new MediaModel(this.trailerLink.value, this.studyName.value, null));
  }

  handleModalResponse(response: ModalFileResponse): void {
    if (response?.confirmed) {
      switch (response.modalRequest.scope) {
        case ModalActions.CROP:
          this.uploadImageAfterCrop(response.payload, response.fileType, response.target);
          break;
        default:
          break;
      }
    }
  }

  uploadImageAfterCrop(payload: string, fileType: FileType, target: string): void {
    const fileToUpload: FileItem = this.uploader.queue[this.uploader.queue.length - 1];
    fileToUpload.formData.fileType = fileType;
    fileToUpload.formData.entityUuid = this.modalRequest?.uuid;
    fileToUpload.formData.formControlName = target;
    fileToUpload._file = FileUtils.convertB64ToImage(payload, fileToUpload);
    this[target].pendingUpload += 1;
    this.uploadService.uploadFile();
  }

  setProgress(response: UploadPromiseResponse): void {
    this.studyPhoto.progress = +response.response;
  }

  setFileFields(response: UploadPromiseResponse): void {
    const responseData = JSON.parse(response.response);
    const parsedData = responseData.response;
    const fileName = FileUtils.computeFileName(response.fileItem.file.name);

    if (responseData.hasOwnProperty('error')) {
      this.toastr.error(...ToastrMessages.BASIC_ERROR);
      this.studyPhoto.pendingUpload -= 1;
      return;
    }

    this.studyPictureUuid.patchValue(parsedData.uuid);
    if (parsedData.objectUuid) {
      this.studyUuid.patchValue(parsedData.objectUuid);
    }

    this.studyPhoto.name = fileName;
    this.studyPhoto.uuid = parsedData.uuid;
    this.studyPhoto.path = parsedData.url;
    this.studyPhoto.fileType = FileType.STUDY_IMAGE;
    this.studyPhoto.isFormatValid = true;
    this.studyPhoto.pendingUpload -= 1;
  }

  submit(): void {
    if (this.addStudyForm.pristine && this.addStudyForm.untouched) {
      Utils.clearErrorsOfFormGroup(this.addStudyForm);
      this.toastr.info(...ToastrMessages.NO_CHANGES);
      this.tryToSubmit = false;
      return;
    }

    this.addStudyForm.markAllAsTouched();
    this.tryToSubmit = true;
    Utils.checkValueAndValidityOfFormGroup(this.addStudyForm);

    if (this.addStudyForm.invalid) {
      this.toastr.error(...ToastrMessages.MISSING_REQUIRED_FIELDS);
      this.tryToSubmit = false;
      return;
    }

    const payload = ComputePayload.createUpdateStudy(this.addStudyForm.getRawValue());
    this.adminStudiesService
      .createUpdateStudy(payload)
      .subscribe(
        () => {
          this.modalRequest ? this.editActionDone() : this.saveActionDone();
          this.dropdownsService.getFilterDropdowns();
          this.emit(ModalEmitActions.STUDY_ADDED);
        },
        () => {
          this.toastr.error(...ToastrMessages.BASIC_ERROR);
        }
      )
      .add(() => {
        this.tryToSubmit = false;
      });
  }

  emit(modalEmitAction: ModalEmitActions): void {
    const response = new ModalResponse(true, this.modalRequest, modalEmitAction);
    this.modalService.emitResponse(response);
    if (modalEmitAction !== ModalEmitActions.DELETE_STUDY_SPECIAL_CASE_REQUEST) {
      this.close();
    }
  }

  close(): void {
    this.modalService.closeAll();
  }

  editActionDone(): void {
    this.toastr.success(...ToastrMessages.STUDY_SUCCESSFULLY_EDITED);
    this.analytics.logClickEvent(AnalyticsEvent.EDIT_STUDY);
  }

  saveActionDone(): void {
    this.toastr.success(...ToastrMessages.STUDY_SUCCESSFULLY_ADDED);
    this.analytics.logClickEvent(AnalyticsEvent.SUBMIT_STUDY);
  }

  get studyPictureUuid(): AbstractControl {
    return this.addStudyForm.get('studyPictureUuid');
  }

  get studyUuid(): AbstractControl {
    return this.addStudyForm.get('studyUuid');
  }

  get studyName(): AbstractControl {
    return this.addStudyForm.get('studyName');
  }

  get author(): AbstractControl {
    return this.addStudyForm.get('author');
  }

  get groupRoleTypes(): AbstractControl {
    return this.addStudyForm.get('groupRoleTypes');
  }

  get studyType(): AbstractControl {
    return this.addStudyForm.get('studyType');
  }

  get nrOfSession(): AbstractControl {
    return this.addStudyForm.get('nrOfSession');
  }

  get onboarding(): AbstractControl {
    return this.addStudyForm.get('onboarding');
  }

  get studyDescription(): AbstractControl {
    return this.addStudyForm.get('studyDescription');
  }

  get trailerEmbed(): AbstractControl {
    return this.addStudyForm.get('trailerEmbed');
  }

  get trailerLink(): AbstractControl {
    return this.addStudyForm.get('trailerLink');
  }
}
