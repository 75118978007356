import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ServerError } from 'src/app/_core/constants/ServerErrors';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { UserService } from 'src/app/_core/services/user.service';
import { AdminGroupService } from '../../../../../_core/api/admin-group.service';
import { ModalEmitActions } from '../../../../../_core/constants/Modals';
import { ToastrMessages } from '../../../../../_core/constants/ToastrMessages';
import ComputePayload from '../../../../../_core/helpers/ComputePayload';
import { Participant, UserInfo } from '../../../../../_core/models/AdminGroups';
import { GroupParticipants } from '../../../../../_core/models/Groups';
import { ModalRequest, ModalResponse } from '../../../../../_core/models/ModalEvent';
import { ModalsService } from '../../../../../_core/services/modals.service';

@Component({
  selector: 'app-edit-attendance',
  templateUrl: './edit-attendance.component.html',
  styleUrls: ['./edit-attendance.component.scss'],
})
export class EditAttendanceComponent implements OnInit {
  attendanceInfo: ModalRequest<GroupParticipants>;
  meetingIndex: number;
  selectedAll = false;
  loading = true;
  isAdmin: boolean;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private adminGroupService: AdminGroupService,
    private toastrService: ToastrService,
    private userService: UserService,
  ) {

  }

  ngOnInit(): void {
    this.isAdmin = this.userService.isAdmin();
    this.attendanceInfo = this.modalService.params;
    this.meetingIndex = this.modalService.params?.index;
    this.getAttendants();
  }

  close(): void {
    this.modalService.closeModal();
  }

  getAttendants(): void {
    this.adminGroupService.getAttendants(this.attendanceInfo.uuid).subscribe(
      (response: ResponseObject<UserInfo[]>) => {
        const presetUsers: UserInfo[] = response.response;

        if (this.attendanceInfo.payload.attendanceSaved || this.attendanceInfo.payload.attendanceSubmitted) {
          this.attendanceInfo.payload.participants.forEach((attendant) => {
            if (presetUsers.find((preset) => preset.userUuid === attendant.uuid)) {
              attendant.selected = false;
            } else {
              attendant.selected = true;
            }
          });
        } else {
          this.attendanceInfo.payload.participants.forEach((attendant) => {
            attendant.selected = false;
          });
        }
      },
      () => this.toastrService.error(...ToastrMessages.BASIC_ERROR),
      () => (this.loading = false)
    );
  }

  submit(submit: boolean): void {
    const payload = ComputePayload.updateMeeting(this.attendanceInfo.uuid, this.attendanceInfo.payload);
    this.adminGroupService.updateMeeting(payload, submit).subscribe(
      () => {
        this.toastrService.success(...ToastrMessages.ATTENDANCE);
        this.modalService.emitResponse(
          new ModalResponse<GroupParticipants>(
            true,
            this.attendanceInfo,
            submit ? ModalEmitActions.ATTENDANCE_SUBMITTED : ModalEmitActions.ATTENDANCE_SAVED
          )
        );
        this.close();
      },
      (error) => {
        switch (error) {
          case ServerError.PREVIOUS_MEETING_NOT_SUBMITTED:
            this.toastrService.error(...ToastrMessages.PREVIOUS_MEETING_NOT_SUBMITTED);
            break;
          case ServerError.MEETING_ALREADY_SUBMITTED:
            this.toastrService.error(...ToastrMessages.MEETING_ALREADY_SUBMITTED);
            break;
          default:
            this.toastrService.error(...ToastrMessages.BASIC_ERROR);
            break;
        }
      }
    );
  }

  selectAll(): void {
    this.selectedAll = !this.selectedAll;
    this.attendanceInfo.payload.participants.forEach((participant: Participant) => (participant.selected = this.selectedAll));
  }

  setAttendance(participant: Participant) {
    participant.selected = !participant.selected;
  }

  reset(): void {
    this.adminGroupService.resetAttendance(this.attendanceInfo.uuid).subscribe({
      next: (res) => {
        this.toastrService.success(...ToastrMessages.ATTENDANCE_RESET);
        this.modalService.emitResponse(new ModalResponse<GroupParticipants>(true, res, ModalEmitActions.ATTENDANCE_RESET));
        this.close();
      },
      error: (error) => {
        switch (error) {
          case ServerError.MEETING_NOT_SUBMITTED_OR_SAVED:
            this.toastrService.error(...ToastrMessages.MEETING_NOT_SUBMITTED_OR_SAVED);
            break;
          default:
            this.toastrService.error(...ToastrMessages.BASIC_ERROR);
            break;
        }
      },
    });
  }

  get canSubmit(): boolean {
    return !this.attendanceInfo.payload.attendanceSubmitted || this.isAdmin;
  }
}
