import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminUserService } from 'src/app/_core/api/admin-user.service';
import { GroupService } from 'src/app/_core/api/group.service';
import { Frequencies } from 'src/app/_core/constants/AdminGroups';
import { ModalActions, ModalEmitActions, Modals } from 'src/app/_core/constants/Modals';
import { ServerError } from 'src/app/_core/constants/ServerErrors';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import ComputePayload from 'src/app/_core/helpers/ComputePayload';
import { CustomValidators } from 'src/app/_core/helpers/CustomValidators.helpers';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import { Participant } from 'src/app/_core/models/AdminGroups';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { GroupCardUnit } from 'src/app/_core/models/Groups';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { User } from 'src/app/_core/models/User';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { SmartAdminGroupUnit } from 'src/app/internal-app/admin/groups/_helpers/view-group/view-group.model';
import { AddUserFacilitatorComponent } from '../add-user-facilitator/add-user-facilitator.component';
import { ParticipantFormData } from './add-participant-modal-form-data.model';

@Component({
  selector: 'app-add-participant-modal',
  templateUrl: './add-participant-modal.component.html',
  styleUrls: ['./add-participant-modal.component.scss'],
})
export class AddParticipantModalComponent implements OnInit, OnDestroy {
  frequencies = Frequencies;
  modalRequest: ModalRequest<{ formData: ParticipantFormData; payload: GroupCardUnit | SmartAdminGroupUnit }>;
  addParticipantForm: UntypedFormGroup;
  submited: boolean = false;
  isLoading: boolean = false;
  breakpointSubscription: Subscription;
  periodOfTime: string;
  timezoneNotFound: boolean = false;
  investorQuestionsNotAnswered: boolean = false;
  alreadyApprovedToFoundationGroup: boolean = false;
  user: User;
  subscription: Subscription;
  isReadyToUnsubscribe: boolean = true;
  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private formBuilder: UntypedFormBuilder,
    private groupService: GroupService,
    private toastr: ToastrService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<AddUserFacilitatorComponent>,
    private adminUserService: AdminUserService,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('95vw')
        : this.dialogRef.updateSize('580px');
    });

    this.subscription = this.modalService.modalResponse$.subscribe(response => {
      this.handleModalResponse(response);
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.createForm();
    this.getPeriodOfTime();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  createForm(): void {
    this.addParticipantForm = this.formBuilder.group({
      email: [null, [Validators.required, CustomValidators.email]],
      before3days: [false],
      before7days: [false],
      freeBooks: [false],
      anotherFoundationConfirm: [false],
    });

    if (this.modalRequest.payload?.formData) {
      this.addParticipantForm.patchValue(this.modalRequest.payload.formData);
    }
  }

  addNewParticipant(): void {
    if (!this.modalRequest.payload.formData && this.addParticipantForm.pristine && this.addParticipantForm.untouched) {
      this.toastr.info(...ToastrMessages.NO_CHANGES);
      return;
    }

    if (this.addParticipantForm.invalid) {
      this.toastr.error(...ToastrMessages.MISSING_REQUIRED_FIELDS);
      return;
    }

    this.submited = true;
    this.isLoading = true;
    this.timezoneNotFound = false;

    const payload = ComputePayload.adminApplyUserToGroupRequest(this.addParticipantForm.value, this.modalRequest);

    this.groupService
      .adminApplyUserForGroup(payload)
      .subscribe(
        (res: ResponseObject<Participant>) => {
          this.modalRequest.payload.payload.spotsLeft--;
          this.modalService.emitResponse(
            new ModalResponse<Participant>(
              true,
              new ModalRequest<Participant>(ModalActions.ADD_NEW_PARTICIPANT, null, null, res.response),
              ModalEmitActions.ADMIN_ADDED_USER_TO_GROUP
            )
          );
          this.toastr.success(...ToastrMessages.ADMIN_APPLY_USER_FOR_GROUP_SUCCESS);
          this.close();
        },
        (error: ServerError) => {
          switch (error) {
            case ServerError.ALREADY_EXISTS:
              this.email.setErrors({ alreadyExists: true });
              break;
            case ServerError.GROUP_NOT_FOUND:
              this.email.setErrors({ groupNotFound: true });
              break;
            case ServerError.MAXIMUM_APPLICATIONS_REACHED:
              this.email.setErrors({ maximumApplicationsReached: true });
              break;
            case ServerError.GROUP_ALREADY_FULL:
              this.email.setErrors({ groupAlreadyFull: true });
              break;
            case ServerError.ALREADY_APPROVED_TO_FOUNDATION_GROUP:
              this.alreadyApprovedToFoundationGroup = true;
              this.anotherFoundationConfirm.patchValue(true);
              break;
            case ServerError.ALREADY_FACILITATOR:
              this.email.setErrors({ alreadyFacilitator: true });
              break;
            case ServerError.INVALID_EMAIL_ADDRESS:
              this.email.setErrors({ doesNotExists: true });
              break;
            case ServerError.GROUP_ENDED:
              this.email.setErrors({ groupEnded: true });
              break;
            case ServerError.TIMEZONE_NOT_FOUND:
              this.email.setErrors({ timezoneNotFound: true });
              this.timezoneNotFound = true;
              this.getUserByEmail();
              break;
            case ServerError.INVESTOR_QUESTIONS_NOT_ANSWERED:
              this.email.setErrors({ investorQuestionsNotAnswered: true });
              this.investorQuestionsNotAnswered = true;
              this.getUserByEmail();
              break;
            default:
              this.toastr.error(...ToastrMessages.BASIC_ERROR);
              break;
          }
        }
      )
      .add(() => {
        this.isLoading = false;
      });
  }

  openAddNewUserModal() {
    this.isReadyToUnsubscribe = false;
    const payload = { formData: this.addParticipantForm.value, groupUuid: this.modalRequest.uuid };
    this.modalService.openModal(Modals.ADD_USER, new ModalRequest(ModalActions.ADD_USER, null, null, payload));
  }

  handleModalResponse(response: ModalResponse<{ email: string; formData: ParticipantFormData; groupUuid: string }>): void {
    const shouldOpenAddParticipantModal =
      response?.modalRequest.scope === ModalActions.ADD_USER &&
      (response?.modalEmitAction === ModalEmitActions.ADD_USER_SUCCESS || response?.modalEmitAction === ModalEmitActions.TRY_ENROLL);

    if (shouldOpenAddParticipantModal) {
      this.isReadyToUnsubscribe = true;
      this.unsubscribe();

      const { formData, groupUuid } = response.modalRequest.payload;
      const payload = this.modalRequest.payload?.payload;
      const combinedPayload = { formData, payload };

      this.modalService.openModal(Modals.ADD_NEW_PARTICIPANT, new ModalRequest(ModalActions.ADD_NEW_PARTICIPANT, groupUuid, null, combinedPayload));
    }
  }

  close(): void {
    this.modalService.closeModal();
  }

  soonerThan(daysBefore: number): boolean {
    return DateUtils.isDateWithoutTimeSoonerThanCurrentDateWithAddedDays(this.modalRequest.payload.payload.startsOn, daysBefore);
  }

  getPeriodOfTime() {
    this.periodOfTime = this.soonerThan(3) ? '3 days' : this.soonerThan(7) ? '1 week' : '3 weeks';
  }

  openEdit(): void {
    this.modalService.openModal(
      Modals.ADD_USER,
      new ModalRequest(this.user?.facilitator ? ModalActions.EDIT_FACILITATOR : ModalActions.EDIT_USER, this.user?.userUuid)
    );
  }

  getUserByEmail(): void {
    this.adminUserService.getUSerOrFacilitatorByEmail(this.email.value).subscribe(
      (res: ResponseObject<User>) => {
        this.user = res.response;
      },
      () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
      }
    );
  }

  get email(): AbstractControl {
    return this.addParticipantForm.get('email');
  }

  get before3days(): AbstractControl {
    return this.addParticipantForm.get('before3days');
  }

  get before7days(): AbstractControl {
    return this.addParticipantForm.get('before7days');
  }

  get freeBooks(): AbstractControl {
    return this.addParticipantForm.get('freeBooks');
  }

  get anotherFoundationConfirm(): AbstractControl {
    return this.addParticipantForm.get('anotherFoundationConfirm');
  }

  private unsubscribe() {
    this.breakpointSubscription.unsubscribe();
    if (this.isReadyToUnsubscribe) {
      this.subscription.unsubscribe();
    }
  }
}
