export enum AnalyticsEvent {
  // Custom Events for Group Application Journey
  APPLY_JOURNEY_CLICK_REGISTER_OR_LOGIN = 'j_click_register_or_login',
  APPLY_JOURNEY_CLICK_APPLY = 'j_click_apply',
  APPLY_JOURNEY_LOGIN_PAGE = 'j_land_on_login_page',
  APPLY_JOURNEY_CLICK_REGISTER_NOW = 'j_click_register_now',
  APPLY_JOURNEY_CLICK_FORGOT_PASSWORD = 'j_click_forgot_password',
  APPLY_JOURNEY_CLICK_WEBSITE_LOGO = 'j_click_website_logo',
  APPLY_JOURNEY_CLICK_LOGIN = 'j_click_login',
  APPLY_JOURNEY_LOGIN_ERROR = 'j_login_error',
  APPLY_JOURNEY_LAND_ON_PRIMARY_TYPE_SELECTION = 'j_land_on_primary_type_selection',
  APPLY_JOURNEY_LAND_ON_SECONDARY_TYPE_SELECTION = 'j_land_on_secondary_type_selection',
  APPLY_JOURNEY_NEXT_TO_SUBTYPE_SELECTION = 'j_next_to_subtype_selection',
  APPLY_JOURNEY_SUBMIT_TYPE_AND_SUBTYPE = 'j_submit_type_and_subtype',
  APPLY_JOURNEY_LAND_ON_REGISTER_PAGE = 'j_land_on_register_page',
  APPLY_JOURNEY_LAND_ON_REGISTER_FROM_INVITATION = 'j_land_on_registration_from_invitation',
  APPLY_JOURNEY_NEXT_TO_REGISTER_STEP_2 = 'j_next_to_register_step_2',
  APPLY_JOURNEY_NEXT_TO_REGISTER_STEP_3 = 'j_next_to_register_step_3',
  APPLY_JOURNEY_NEXT_TO_REGISTER_STEP_4 = 'j_next_to_register_step_4',
  APPLY_JOURNEY_SUBMIT_REGISTRATION = 'j_submit_registration',
  APPLY_JOURNEY_UPDATE_INCOMPLETE_PROFILE = 'j_update_incomplete_profile',
  APPLY_JOURNEY_CONFIRM_PARTICIPATION_IN_PERSON = 'j_confirm_participation_in_person',
  APPLY_JOURNEY_CONFIRM_ALREADY_APPROVED_FOR_OTHER_GROUP = 'j_confirm_approved_to_other_groups',
  APPLY_JOURNEY_APPLICATION_ERROR = 'j_application_error',
  APPLY_JOURNEY_LAND_ON_CONFIRMATION_PAGE = 'j_land_on_confirmation_page',
  APPLY_JOURNEY_CLICK_COMPLETE_PROFILE = 'j_click_complete_profile',
  APPLY_JOURNEY_CLICK_FIND_GROUPS = 'j_click_find_groups',

  //register
  LOGIN = 'login',
  LOGIN_FROM_REGISTER_PAGE = 'login_from_register_page',
  SIGN_OUT = 'sign_out',
  REGISTER_NOW = 'click_on_register_now',
  CLICK_REGISTER_OR_LOGIN = 'click_register_or_login',
  CLICK_ON_FORGOT_PASSWORD = 'click_on_forgot_password',
  FORGOT_PASSWORD = 'forgot_password',
  CLICK_NEXT_ON_STEP_1 = 'click_next_on_step_1',
  CLICK_NEXT_ON_STEP_1_SET_USER_TYPE = 'click_next_on_step_1_set_user_type',
  CLICK_NEXT_ON_STEP_2 = 'click_next_on_step_2',
  CLICK_APPLY_ON_STEP_2_SET_USER_TYPE = 'click_apply_on_step_2_SET_USER_TYPE',
  CLICK_SUBMIT_ON_STEP_2_SET_USER_TYPE = 'click_submit_on_step_2_set_user_type',
  CLICK_NEXT_ON_STEP_3 = 'click_next_on_step_3',
  CLICK_REGISTER_ON_STEP_4_ATTEMPT = 'click_register_on_step_4_attempt',
  CLICK_REGISTER_ON_STEP_4_SUCCESS = 'click_register_on_step_4_success',
  CLICK_APPLY_ON_STEP_4_ATTEMPT = 'click_apply_on_step_4_attempt',
  CLICK_APPLY_ON_STEP_4_SUCCESS = 'click_apply_on_step_4_success',
  CLICK_ON_ENTREPRENEUR_TYPE = 'click_on_entrepreneur_type',
  CLICK_ON_INVESTOR_TYPE = 'click_on_investor_type',
  CLICK_ON_ENTREPRENEUR_SUBTYPE = 'click_on_entrepreneur_subtype',
  CLICK_ON_SOLOPRENEUR_SUBTYPE = 'click_on_solopreneur_subtype',
  CLICK_ON_ASPIRING_SUBTYPE = 'click_on_aspiring_subtype',
  CLICK_ON_INTRAPRENEUR_SUBTYPE = 'click_on_intrapreneur_subtype',
  CLICK_ON_STUDENT_SUBTYPE = 'click_on_student_subtype',
  CLICK_ON_PASTOR_MINISTRY_LEADER_SUBTYPE = 'click_on_pastor_ministry_leader_subtype',
  CLICK_ON_OTHER_ENTREPRENEUR_SUBTYPE = 'click_on_other_entrepreneur_subtype',
  CLICK_ON_RETAIL_INVESTOR_SUBTYPE = 'click_on_retail_investor_subtype',
  CLICK_ON_ACCREDITED_INVESTOR_SUBTYPE = 'click_on_accredited_investor_subtype',
  CLICK_ON_QUALIFIED_PURCHASER_SUBTYPE = 'click_on_qualified_purchaser_subtype',
  CLICK_ON_FINANCIAL_ADVISOR_SUBTYPE = 'click_on_financial_advisor_subtype',
  CLICK_ON_FUND_MANAGER_SUBTYPE = 'click_on_fund_manager_subtype',
  CLICK_ON_MINISTRY_LEADER_IN_FINANCE_SUBTYPE = 'click_on_ministry_leader_in_finance_subtype',
  CLICK_ON_OTHER_INVESTOR_SUBTYPE = 'click_on_other_investor_subtype',
  CLICK_INVITE_A_FRIEND = 'click_invite_a_friend',
  CLICK_FIND_A_GROUP_NEAR_YOU = 'click_find_a_group_near_you',
  CLICK_GO_TO_VIDEO_SERIES = 'click_go_to_video_series',
  CLICK_COMPLETE_PROFILE = 'click_complete_profile',
  CLICK_BACK_ON_STEP_2 = 'click_back_on_step_2',
  CLICK_BACK_ON_STEP_2_SET_USER_TYPE = 'click_back_on_step_2_set_user_type',
  CLICK_BACK_ON_STEP_3 = 'click_back_on_step_3',
  CLICK_BACK_ON_STEP_4 = 'click_back_on_step_4',
  TERMS_AND_CONDITIONS = 'click_terms_and_conditions',

  CLICK_APPLY_TO_GROUP = 'click_apply_to_group',

  //WIZARD
  CLICK_ON_INVESTOR_WIZARD = 'click_on_investor_wizard',
  CLICK_ON_ENTREPRENEUR_WIZARD = 'click_on_entrepreneur_wizard',
  CLICK_ON_NEW_ENTREPRENEUR_WIZARD = 'click_on_new_entrepreneur_wizard',
  CLICK_ON_RETURNING_ENTREPRENEUR_WIZARD = 'click_on_returning_entrepreneur_wizard',
  CLICK_ON_NEW_INVESTOR_WIZARD = 'click_on_new_investor_wizard',
  CLICK_ON_RETURNING_INVESTOR_WIZARD = 'click_on_returning_investor_wizard',
  CLICK_ON_VIRTUAL_WIZARD = 'click_on_online_wizard',
  CLICK_ON_IN_PERSON_WIZARD = 'click_on_in_person_wizard',
  CLICK_ON_LOCATIONS_WIZARD = 'click_on_in_your_region_wizard',
  CLICK_ON_DATE_TIME_WIZARD = 'click_on_date_time_wizard',
  CLICK_ON_COMPANY_SIZE_WIZARD = 'click_on_company_size_wizard',
  CLICK_ON_COMPANY_SIZE_WIZARD_OPTION = 'click_on_company_size_',
  CLICK_ON_INDUSTRY_WIZARD_ = 'click_on_industry_wizard',
  CLICK_ON_INDUSTRY_WIZARD_OPTION = 'click_on_industry_',
  CLICK_ON_AFFINITY_WIZARD = 'click_on_affinity_wizard',
  CLICK_ON_AFFINITY_WIZARD_OPTION = 'click_on_affinity_',
  CLICK_ON_DAY_WIZARD_OPTION = 'click_on_day_',
  CLICK_ON_TIME_WIZARD_OPTION = 'click_on_time_',
  CLICK_ON_SEARCH_ALL_WIZARD = 'click_on_affinity_wizard',

  FUND_MANAGER = 'click_on_fund_manager_filter',
  ACCREDITED_INVESTOR = 'click_on_accredited_investor_filter',
  FINANCIAL_ADVISOR = 'click_on_financial_advisor_filter',
  UNITED_STATES = 'region_selected_united_states',
  CANADA = 'region_selected_canada',
  LATIN_AMERICA = 'region_selected_latin_america',
  AFRICA = 'region_selected_africa',
  EUROPE = 'region_selected_europe',
  ASIA = 'region_selected_asia ',
  AUSTRALIA = 'region_selected_australia',
  CARIBBEAN = 'region_selected_caribbean',
  ADD_FACILITATOR = 'add_facilitator',
  ADD_PARTNER_ORGANIZATION = 'add_partner_organization',
  ADD_STUDY = 'add_study',
  ADD_GROUP = 'add_group',
  ADD_USER = 'add_user',
  SUBMIT_FACILITATOR = 'submit_facilitator',
  SUBMIT_STUDY = 'submit_study',
  SUBMIT_GROUP = 'submit_group',
  SUBMIT_USER = 'submit_user',
  EDIT_FACILITATOR = 'edit_facilitator',
  EDIT_STUDY = 'edit_study',
  EDIT_GROUP = 'edit_group',
  GROUP_REQUEST_APPROVED = 'group_request_approved',
  GROUP_REQUEST_UPDATED = 'group_request_updated',
  GROUP_REQUEST_DECLINED = 'group_request_declined',
  EDIT_USER = 'edit_user',
  DELETE_FACILITATOR = 'delete_facilitator',
  DELETE_STUDY = 'delete_study',
  ARCHIVE_GROUP = 'archive_group',
  RESTORE_GROUP = 'restore_group',
  DELETE_USER = 'delete_user',
  ACCEPT_GROUP_REQUEST = 'accept_group_request',
  DELETE_GROUP_REQUEST = 'delete_group_request',
  ADD_COHORT = 'add_cohort',
  EDIT_COHORT = 'edit_cohort',
  DELETE_COHORT = 'delete_cohort',
  OPEN_MATTERMOST = 'open_matermost',
  DIRECT_NAVIGATION_TO_GROUP_DETAILS = 'direct_navigation_to_group_details',

  MY_PROFILE = 'my-profile',
  GO_TO_INVESTOR_GROUPS_BTN = 'go-to-investor-groups',
  GO_TO_ENTREPRENEUR_GROUPS_BTN = 'go-to-entrepreneur-groups',

  //filters
  DATE_AND_TIME_FILTER = 'click_on_date_time_filter',
  LOCATION_FILTER = 'click_on_location_filter',
  GOOGLE_PLACES_SEARCH = 'search-by-google-places',
  COUNTRY_CLICKED = 'country-clicked-on-location-page',
  ONLINE_GROUPS_FILTER = 'click_on_online_groups_filter',
  FACILITATOR_FILTER = 'facilitator-filter',
  CHURCH_FILTER = 'church-filter',
  STUDY_FILTER = 'study-filter',
  CLICK_NEXT_LOCATION_PAGE = 'click-next-location-page',

  EDIT_AUDIENCE = 'edit-audience',
  DELETE_AUDIENCE = 'delete-audience',
  EDIT_OWNER = 'edit-owner',
  DELETE_OWNER = 'delete-owner',
  EDIT_LANGUAGE = 'edit-language',
  DELETE_LANGUAGE = 'delete-language',
  EDIT_INDUSTRY = 'edit-industry',
  DELETE_INDUSTRY = 'delete-industry',

  //user menu
  FACILITATORS = 'click_on_user_menu_manage_facilitators',
  STUDIES = 'click_on_user_menu_manage_studies',
  GROUPS = 'click_on_user_menu_manage_groups',
  LOCATIONS = 'click_on_user_menu_manage_locations',
  CHURCHES = 'click_on_user_menu_manage_churches',
  USERS = 'click_on_user_menu_manage_users',
  TAGS = 'click_on_user_menu_manage_tags',
  GROUP_REQUESTS = 'click_on_user_menu_group_requests',
  GROUPS_I_JOIN = 'click_on_user_menu_groups_i_join',
  GROUPS_I_LEAD = 'click_on_user_menu_groups_i_lead',
  FOR_FOUNDATION_STUDY = 'click_on_user_menu_for_foundation_study',
  FOR_FAITH_DRIVEN_ENTREPRENEUR_STUDY = 'click_on_user_menu_for_faith_driven_entrepreneur_study',
  PROMOTIONAL_GRAPHICS = 'click_on_user_menu_promotional_graphics',
  FOR_FAITH_DRIVEN_INVESTING_STUDY = 'click_on_user_menu_for_faith_driven_investing_study',

  //multi filter
  userRole = 'multi_filter_selected_userRole',
  investorTypes = 'multi_filter_selected_investor_userRole',
  userType = 'multi_filter_selected_userType',
  industry = 'multi_filter_selected_industry',
  companySize = 'multi_filter_selected_company_size',
  weekDays = 'multi_filter_selected_week_days',
  timeIntervals = 'multi_filter_selected_time_intervals',
  meetingType = 'multi_filter_selected_time_meeting_type',
  regions = 'multi_filter_selected_regions',
  churches = 'multi_filter_selected_church',
  facilitator = 'multi_filter_selected_facilitator',
  groupLanguage = 'multi_filter_selected_language',
  study = 'multi_filter_selected_study',
  ADVANCED_SEARCH = 'multi-filter-advanced-search',
}
