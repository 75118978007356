import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalActions, Modals } from 'src/app/_core/constants/Modals';
import { GroupParticipants } from 'src/app/_core/models/Groups';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UserService } from 'src/app/_core/services/user.service';
import { GroupStatus } from '../../../../../_helpers/add-group/add-group.const';
import { AdminGroupMeeting, SmartAdminGroup, SmartAdminGroupUnit } from '../../view-group.model';

@Component({
  selector: 'app-view-group-attendance',
  templateUrl: './view-group-attendance.component.html',
  styleUrls: ['./view-group-attendance.component.scss'],
})
export class ViewGroupAttendanceComponent implements OnInit {
  @Input() group: SmartAdminGroup;
  @Input() selectedUnit: SmartAdminGroupUnit;
  statuses = GroupStatus;
  isFacilitator: boolean;
  isAdmin: boolean;

  // prettier-ignore
  constructor(private userService: UserService, private modalService: ModalsService, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.isAdmin = this.userService.isAdmin();
    this.isFacilitator = this.userService.isFacilitator();
  }

  openModal(modalName: Modals, scope: ModalActions, uuid: string = null, index: number = null, payload: any = null): void {
    this.modalService.openModal(modalName, new ModalRequest<any>(scope, uuid, index, payload));
  }

  openSkipMeetingModal(meeting: AdminGroupMeeting, index: number): void {
    const meetingIndex = this.computeMeetingIndex(index).toString();
    const date = this.datePipe.transform(meeting.startTime, "MMMM dd', 'yyyy', 'h:mm a");
    const text = `Are you sure you want to skip Session ${meetingIndex} - ${date}?`;
    this.openModal(Modals.CONFIRM_SKIP_MEETING, ModalActions.SKIP_MEETING, this.group.uuid, null, {
      text: text,
      meetingId: meeting.uuid,
      meetingType: this.selectedUnit.meetingFrequency,
    });
  }

  openAttendanceModal(meeting: AdminGroupMeeting, index: number): void {
    const payload = new GroupParticipants(this.selectedUnit.uuid, this.selectedUnit.approvedParticipants, meeting);
    const modalRequest = new ModalRequest<GroupParticipants>(ModalActions.FILL_ATTENDANCE, meeting.uuid, this.computeMeetingIndex(index), payload);
    if (meeting.started) this.modalService.openModal(Modals.GROUP_ATTENDANCE_EDIT, modalRequest);
  }

  computeMeetingIndex(index: number): number {
    const previouslySkippedMeetings = this.selectedUnit.meetings.slice(0, index).filter((meeting) => meeting.skipped).length;
    return index - previouslySkippedMeetings + 1;
  }
}
