<div class="w-100">
  <div class="d-flex justify-content-between">
    <div>
      <h1 class="text-left font-weight-bold media-header">
        <i class="far fa-question-circle pr-2 primary-color" *ngIf="media.fileType"></i>{{ media.label }}
      </h1>
    </div>
    <div>
      <h1 class="media-header" (click)="close()"><i class="fas fa-times gray-color pointer"></i></h1>
    </div>
  </div>
  <div class="content mt-md-3" *ngIf="media.fileType; else iframeContainer">
    <video #video [src]="media.url" controls (click)="playPause($event)"></video>
    <div class="v-content">
      <div class="play" (click)="playPause($event)">
        <i [ngClass]="paused ? 'fas fa-play' : 'fas fa-pause'"></i>
      </div>
    </div>
  </div>
  <ng-template #iframeContainer>
    <div class="d-flex align-items-center justify-content-center">
      <iframe [src]="sanitizedUrl" width="967wha" height="544" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>
  </ng-template>
</div>
