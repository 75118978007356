<div class="custom-container">
  <h1 class="primary-color">Attendance</h1>
  <div class="local-time mb-3 custom-local-time-margin">in your local time</div>
  <div class="bg-warning px-1 px-sm-3 py-2 mb-4 font-weight-bold" [hidden]="(isFacilitator && !isAdmin) || group.status === statuses.ARCHIVED">
    To skip a session for your group, please click the
    <span class="red-color">red x</span>
    to the left of the session listed below. Once you click the
    <span class="red-color">red x</span>, the system will automatically add one more session. An update email and additional calendar invite for the
    added session will also be sent to all group members. If you have questions, please email
    <a class="main-color" href="mailto:team@faithdrivenentrepreneur.org"><u>groups@faithdrivenentrepreneur.org</u></a
    >.
  </div>
  <div class="d-flex justify-content-between flex-wrap mb-1" *ngFor="let meeting of selectedUnit.meetings; let i = index">
    <div class="meetings">
      <div *ngIf="group.status !== statuses.ARCHIVED" class="skip-icon-container">
        <i
          class="fas fa-times red-color pointer mr-2"
          (click)="openSkipMeetingModal(meeting, i)"
          [hidden]="meeting.skipped || i === 0 || (isFacilitator && !isAdmin) || (isAdmin && (meeting.submitted || meeting.completed))"
        >
        </i>
      </div>
      <div class="attendance">
        <div [ngClass]="{ 'green-color': meeting.completed, 'gray-color': !meeting.started }">
          <span *ngIf="!meeting.skipped" class="font-weight-bold meetings-text">
            Session {{ computeMeetingIndex(i) }} - {{ meeting.startTime | date : "MMM dd', 'yyyy', ' h:mm a" }}
          </span>
          <span *ngIf="meeting.skipped" class="meetings-text font-weight-bold gray-color ml-1">
            SKIPPED - {{ meeting.startTime | date : "MMM dd', 'yyyy', ' h:mm a" }}
          </span>
        </div>

        <div
          class="font-weight-bold"
          *ngIf="group.status !== statuses.ARCHIVED"
          [ngClass]="meeting.completed ? 'pointer' : meeting.started && !meeting.skipped ? 'primary-color pointer' : 'gray-color'"
          (click)="openAttendanceModal(meeting, i)"
        >
          <i
            *ngIf="meeting.started && !meeting.skipped && selectedUnit.approvedParticipants.length"
            class="mr-1"
            [ngClass]="meeting.submitted ? 'far fa-eye' : meeting.completed ? 'fas fa-save' : 'fas fa-pencil-alt'"
          ></i>
          <span *ngIf="!meeting.skipped">
            <span *ngIf="meeting.started && selectedUnit.approvedParticipants.length">
              <span>{{ meeting.submitted ? 'Attendance submitted' : meeting.completed ? 'Attendance saved' : 'Add attendance' }}</span>
            </span>
            <span *ngIf="!meeting.started">Event Pending</span>
          </span>
        </div>
        <hr *ngIf="meeting.skipped" />
      </div>
    </div>
  </div>
</div>
