import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegisterRequest } from '../models/User';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly resourceUrl: string = '/v1/users';

  // prettier-ignore
  constructor(
    private apiService: ApiService
  ) {
  }

  register(registerRequest: RegisterRequest, token: string): Observable<any> {
    return token
      ? this.apiService.post(`${this.resourceUrl}/register-invited-user`, registerRequest, { tokenValue: token, platform: 'GroupFinder' })
      : this.apiService.post(`${this.resourceUrl}/register`, registerRequest, { platform: 'GroupFinder' });
  }

  logout(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/logout`, null);
  }

  getUserInfo(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/get-user-info`);
  }

  updateAccount(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/update-account`, body);
  }

  changePassword(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/change-password`, body);
  }

  confirmEmail(token: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/confirm-email/${token}`);
  }

  resendEmail(emailOrToken: string): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/resend-email/${emailOrToken.trim()}`, null);
  }

  getInvitedUserDetails(token): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/get-invited-user-details/${token}`, null);
  }

  getChatNotifications(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/notifications-mattermost`);
  }

  setPimaryTypeAndSubtype(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/add-user-type`, body);
  }

  setSecondaryTypeAndSubtype(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/add-user-secondary-type`, body);
  }
}
