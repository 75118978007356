<div class="d-flex justify-content-between align-items-center px-4 py-3 bg-gray">
  <h1 class="mb-0">Filter by region</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>

<div class="px-3 pt-3 mt-2 d-flex flex-column align-items-center">
  <button
    *ngFor="let region of regionFilters"
    mat-flat-button
    [color]="region == selectedRegion ? 'primary' : 'white'"
    class="mt-4 mr-3 mat-mobile-button"
    (click)="selectRegion(region)"
  >
    {{ region }}
  </button>
</div>
