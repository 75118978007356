import { Component, Input } from '@angular/core';
import { HistoryResponse } from 'src/app/_core/models/GroupHistory';

@Component({
  selector: 'app-history-card[historyItems]',
  templateUrl: './history-card.component.html',
  styleUrls: ['./history-card.component.scss'],
})
export class HistoryCardComponent {
  @Input() historyItems: HistoryResponse[];
}
