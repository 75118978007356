import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Urls } from 'src/app/_core/constants/Urls';
import Utils from 'src/app/_core/helpers/Utils';
import { FilterType } from '../../../_core/constants/Home';

@Component({
  selector: 'app-no-results-found',
  templateUrl: './no-results-found.component.html',
  styleUrls: ['./no-results-found.component.scss'],
})
export class NoResultsFoundComponent implements OnInit {
  @Input() filter: FilterType;
  @Input() message: string;
  @Input() comingSoon: boolean = false;

  filterType = FilterType;
  urls: Urls;

  // prettier-ignore
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {}

  navigate(url: string): void {
    Utils.navigate(url);
  }

  goToGlobalGroups(): void {
    this.router.navigate([`${Urls.APP}/${Urls.GROUPS}`], { queryParams: { filter: FilterType.VIRTUAL_GROUPS } });
  }
}
