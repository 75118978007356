import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ModalActions, ModalEmitActions } from 'src/app/_core/constants/Modals';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from '../../../_core/services/modals.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent implements OnInit, OnDestroy {
  modalRequest: ModalRequest<any>;
  breakpointSubscription: Subscription;
  modalActions = ModalActions;
  showWarningMessage: boolean;

  // prettier-ignore
  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    private modalService: ModalsService,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', 'auto')
        : this.dialogRef.updateSize('580px', '280px');
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.setWarningMessage();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  close(): void {
    this.modalService.closeModal();
  }

  setWarningMessage(): void {
    switch (this.modalRequest.scope) {
      case ModalActions.ARCHIVE_GROUP_FROM_VIEW_MODAL:
      case ModalActions.ARCHIVE_GROUP:
      case ModalActions.RESTORE_GROUP_FROM_VIEW_MODAL:
      case ModalActions.RESTORE_GROUP:
        this.showWarningMessage = false;
        return;
      default:
        this.showWarningMessage = true;
        return;
    }
  }

  emit(confirm: boolean): ModalResponse {
    switch (this.modalRequest.scope) {
      case ModalActions.DOWNGRADE_FACILITATOR:
        return new ModalResponse(confirm, this.modalRequest, ModalEmitActions.DOWNGRADE_CONFIRMED);
      case ModalActions.ARCHIVE_GROUP_FROM_VIEW_MODAL:
        return new ModalResponse(confirm, this.modalRequest, ModalEmitActions.ARCHIVE_GROUP_FROM_VIEW_MODAL);
      case ModalActions.RESTORE_GROUP_FROM_VIEW_MODAL:
        return new ModalResponse(confirm, this.modalRequest, ModalEmitActions.RESTORE_GROUP_FROM_VIEW_MODAL);
      case ModalActions.RESTORE_GROUP:
        return new ModalResponse(confirm, this.modalRequest, ModalEmitActions.RESTORE_GROUP);
      case ModalActions.DELETE_COHORT:
      case ModalActions.DELETE_AUDIENCE:
      case ModalActions.DELETE_OWNER:
        return new ModalResponse(confirm, this.modalRequest);
      default:
        return new ModalResponse(confirm, this.modalRequest, ModalEmitActions.DELETE_CONFIRMED);
    }
  }
}
