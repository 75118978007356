import { Router } from '@angular/router';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, exhaustMap, first, tap } from 'rxjs/operators';
import { ToastrMessages } from '../constants/ToastrMessages';
import { Urls } from '../constants/Urls';
import StorageHelper from '../helpers/Storage.helper';
import { InterceptorSkipBearer } from '../services/group-dropdown.service';
import { LoaderService } from '../services/loader.service';
import { UserService } from '../services/user.service';
import { GlobalLoaderRequests, LOADER_TYPE, NoLoaderRequests } from '../constants/Loader';
import { AuthService } from '../api/auth.service';
import { TokenData } from '../models/User';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();

  // prettier-ignore
  constructor(
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private router: Router,
    private injector: Injector
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const noLoaderRequest = !!NoLoaderRequests.find((reqName) => request.url.indexOf(reqName) >= 0);
    const isGlobalLoaderRequest = !!GlobalLoaderRequests.find((reqName) => request.url.indexOf(reqName) >= 0);
    let loader: LOADER_TYPE;
    if (!noLoaderRequest) {
      loader = isGlobalLoaderRequest ? LOADER_TYPE.GLOBAL : LOADER_TYPE.LOCAL;
      this.loaderService.show(loader);
    }
    request = this.setHeaders(request);
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide(loader);
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          this.loaderService.hide(loader);
          switch (error.status) {
            case 400:
              if (error?.error?.error_description?.indexOf('Invalid refresh token') > -1) {
                this.injector.get(UserService).killSession();
                this.isRefreshing = false;
                location.reload();
                this.toastrService.error(...ToastrMessages.EXPIRED_SESSION);
                return of(error);
              } else {
                const err = error.error.message || error.error.error;
                return throwError(err);
              }
            case 401:
              return this.handle401Error(request, next);
            case 502:
              this.router.navigate([Urls.SERVER_ERROR], { skipLocationChange: true });
            default:
              const err = error.error.message || error.error.error;
              return throwError(err);
          }
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      StorageHelper.clearToken();
      this.isRefreshing = true;
      return this.authService.refreshToken().pipe(
        catchError((error: any) => {
          this.injector.get(UserService).killSession();
          this.isRefreshing = false;
          this.toastrService.error(...ToastrMessages.EXPIRED_SESSION);
          return of(error);
        }),
        tap((response: TokenData) => StorageHelper.saveTokensAndUsername(response.access_token, response.refresh_token, StorageHelper.getUsername())),
        exhaustMap((tokenData: TokenData) => {
          StorageHelper.saveToken(tokenData.access_token);
          this.refreshTokenSubject.next(tokenData.access_token);
          this.isRefreshing = false;
          return next.handle(this.setHeaders(request));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        first(),
        concatMap(() => next.handle(this.setHeaders(request)))
      );
    }
  }

  private setHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const token = StorageHelper.getToken();

    const skipBearer = request.headers.has(InterceptorSkipBearer);
    let headers = request.headers.set('Access-Control-Allow-Origin', '*').set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');

    headers =
      token && !skipBearer
        ? headers.set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`)
        : headers.set('Authorization', 'Basic Z3JvdXBmaW5kZXI6c2VjcmV0');

    if (skipBearer) {
      headers = headers.delete(InterceptorSkipBearer);
    }

    return request.clone({ headers });
  }
}
