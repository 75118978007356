import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminPartnerOrganizationDetails } from '../models/AdminChurches';
import { BasicPaginatedRequestWithSort } from '../models/Shared';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminPartnerOrganizationService {
  private readonly resourceUrl: string = '/v1/partner-organization';

  constructor(private apiService: ApiService) {}

  getAdminPartnerOrganizations(body: BasicPaginatedRequestWithSort): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/admin/get-partner-organizations`, body);
  }

  getPartnerOrganization(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/${uuid}`);
  }

  createUpdatePartnerOrganization(body: AdminPartnerOrganizationDetails): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/create-update`, body);
  }

  approvePartnerOrganization(partnerOrganizationUuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/confirm-partner-organization/${partnerOrganizationUuid}`);
  }

  deletePartnerOrganization(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/${uuid}`);
  }
}
