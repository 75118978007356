<div class="content" *ngIf="group && activeUnit">
  <div
    class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3"
    [class.bg-primary]="!activeUnit.study.isOnboardingStudy"
    [class.bg-main]="activeUnit.study.isOnboardingStudy"
  >
    <h1 class="modal-header-name mb-0 text-white">{{ group.name }}</h1>
    <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer text-white"></i></a>
  </div>
  <div class="px-3 px-md-5">
    <div *ngIf="canSeeMeetingDetails && modalRequest.scope === modalActions.VIEW_GROUP_FROM_MY_GROUPS" class="pt-3">
      <h1 class="primary-color">Thank you for applying for this group.</h1>
      <div class="main-text mb-3">Once you have been approved, you will see the meeting location below.</div>
      <ng-container *ngIf="activeUnit.userStatus === participantType.APPROVED">
        <ng-container *ngIf="activeUnit?.meetingPlaceType === groupTypes.IN_PERSON; else notGeoBased">
          <div class="gray-label pb-1">Meeting Address</div>
          <div class="main-text font-weight-bold">
            <span *ngIf="activeUnit.location.locationDetails">{{ activeUnit.location.locationDetails + ', ' }}</span>
            <span *ngIf="activeUnit.location.address">{{ activeUnit.location.address }}</span>
            <span *ngIf="!activeUnit.location.address">{{ activeUnit.location.city + ', ' + activeUnit.location.stateProvince }}</span>
          </div>
        </ng-container>
        <ng-template #notGeoBased>
          <div class="gray-label pb-1">Meeting Link</div>
          <a class="main-text font-weight-bold font-italic pointer" (click)="externalNavigate(activeUnit.meetingLink)">
            <u>{{ activeUnit.meetingLink }}</u>
          </a>
        </ng-template>
      </ng-container>
    </div>
    <div
      class="w-100 custom-container d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row"
      *ngIf="activeUnit.groupFormat !== groupFormat.SINGLE"
    >
      <div>
        <div class="gray-color font-weight-bold">THE GROUP MEETS {{ activeUnit.frequencyText | uppercase }}</div>
        <h2 class="primary-color mb-0">
          {{ activeUnit.meetingDay + 's' | firstUpperCase }} at {{ activeUnit.startTimeWithDaylightSavingTime | date : 'h:mm a' }}
        </h2>
        <div class="local-time">in your local time</div>
      </div>
      <div class="d-flex align-items-center" *ngIf="!hasStarted">
        <div class="gray-color font-weight-bold mr-2">STARTS ON</div>
        <h2 class="mb-0">{{ activeUnit.startsOn | date : 'MMM d, yyyy' }}</h2>
      </div>
      <div class="mt-4 mt-lg-0">
        <button
          *ngIf="(activeUnit.userStatus === participantType.APPROVED || activeUnit.userStatus === participantType.PENDING) && !this.hasEnded"
          mat-flat-button
          class="mat-medium-button"
          color="warn"
          (click)="withdrawFromGroup()"
        >
          {{ activeUnit.userStatus === participantType.PENDING ? 'Cancel application' : 'Withdraw' }}
        </button>
        <button
          *ngIf="(activeUnit.userStatus === null || activeUnit.userStatus === participantType.WITHDRAWN) && canApply && activeUnit.spotsLeft > 0"
          mat-flat-button
          color="primary"
          class="mat-medium-button pulse-button"
          (click)="attemptApplication()"
        >
          Apply for this group
        </button>
      </div>
    </div>

    <div class="custom-container d-flex justify-content-between flex-wrap" *ngIf="activeUnit.groupFormat === groupFormat.SINGLE">
      <div>
        <table class="table table-borderless event-table">
          <thead>
            <tr class="gray-color font-weight-bold font-14">
              <th scope="col" class="pl-0">EVENT DATES</th>
              <th scope="col">STARTING HOUR</th>
              <th scope="col">ENDING HOUR</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let event of activeUnit.meetings">
              <tr>
                <th scope="row" class="primary-color mb-0 pl-0">{{ event.meetingStartTime | date : 'MMMM dd, yyyy' }}</th>
                <th>{{ event.meetingStartTime | date : 'shortTime' }}</th>
                <th>{{ event.meetingEndTime | date : 'shortTime' }}</th>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div *ngIf="showSuccessMessage || applyButton" class="mt-4 mt-lg-0">
        <button
          *ngIf="activeUnit.userStatus === participantType.APPROVED || activeUnit.userStatus === participantType.PENDING"
          mat-flat-button
          class="mat-medium-button"
          color="warn"
          (click)="withdrawFromGroup()"
        >
          {{ activeUnit.userStatus === participantType.PENDING ? 'Cancel application' : 'Withdraw' }}
        </button>
        <button
          *ngIf="(activeUnit.userStatus === null || activeUnit.userStatus === participantType.WITHDRAWN) && canApply"
          mat-flat-button
          color="primary"
          class="mat-medium-button pulse-button"
          (click)="attemptApplication()"
        >
          Apply for this group
        </button>
      </div>
    </div>

    <div class="custom-container">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h1 class="primary-color mb-0">What to expect</h1>
        <ng-container [ngSwitch]="activeUnit.meetingPlaceType">
          <div *ngSwitchCase="groupTypes.IN_PERSON"><i class="fas fa-users fa-2x primary-color"></i></div>
          <div *ngSwitchCase="groupTypes.HYBRID" class="d-flex flex-nowrap">
            <i class="fas fa-users fa-2x primary-color"></i> <span class="big-text primary-color">+</span>
            <i class="fas fa-laptop fa-2x primary-color"></i>
          </div>
          <div *ngSwitchCase="groupTypes.VIRTUAL"><i class="fas fa-laptop fa-2x primary-color"></i></div>
          <div *ngSwitchDefault></div>
        </ng-container>
      </div>
      <div class="main-text mb-2" *ngIf="activeUnit.groupFormat !== groupFormat.SINGLE">
        <i class="fas fa-clock gray-color mr-3"></i>Group Meetings last about {{ activeUnit.durationMinutes }} minutes.
      </div>
      <div class="main-text mb-2"><i class="fas fa-comment-alt gray-color mr-3"></i>We'll watch the videos together and meet for discussion.</div>
      <ng-container [ngSwitch]="activeUnit.meetingPlaceType">
        <div class="main-text mb-2" *ngSwitchCase="groupTypes.IN_PERSON">
          <i class="fas fa-map-marker-alt gray-color mr-3"></i>This group will be meeting in-person in {{ buildLocationString() }}.
        </div>
        <div class="main-text mb-2" *ngSwitchCase="groupTypes.HYBRID">
          <i class="fas fa-map-marker-alt gray-color mr-3"></i>This is a hybrid group, which will meet online and in-person in
          {{ buildLocationString() }}.
        </div>
        <div class="main-text mb-2" *ngSwitchCase="groupTypes.VIRTUAL">
          <i class="fas fa-map-marker-alt gray-color mr-3"></i>This group will be meeting online.
        </div>
        <div *ngSwitchDefault></div>
      </ng-container>
      <div class="main-text mb-2" *ngIf="activeUnit.groupFormat !== groupFormat.SINGLE">
        <i class="far fa-calendar-alt gray-color mr-3"></i>This group meets {{ activeUnit.frequencyText }}.
      </div>
      <div class="main-text mb-2"><i class="fas fa-globe gray-color mr-3"></i>This group is facilitated in {{ groupLanguages }}.</div>
    </div>

    <div class="custom-container">
      <h1 class="primary-color">Study discussed</h1>
      <app-study-section-modal [study]="activeUnit.study"></app-study-section-modal>
    </div>

    <div *ngIf="activeUnit.facilitatorsList.length > 0" class="custom-container">
      <h1 class="primary-color">About your facilitators</h1>
      <app-facilitator-section-modal [facilitators]="activeUnit.facilitatorsList"></app-facilitator-section-modal>
    </div>

    <div *ngIf="group.churchesList?.length > 0" class="custom-container">
      <h1 class="primary-color">About your churches</h1>
      <app-facilitator-section-modal [churches]="group.churchesList"></app-facilitator-section-modal>
    </div>

    <div *ngIf="group.partnerOrganizationsList?.length > 0" class="custom-container">
      <h1 class="primary-color">About your partner organizations</h1>
      <app-facilitator-section-modal [churches]="group.partnerOrganizationsList"></app-facilitator-section-modal>
    </div>

    <ng-container
      *ngIf="
        group.groupState === groupState.OPEN && (activeUnit.userStatus === null || activeUnit.userStatus === participantType.WITHDRAWN) && !hasEnded;
        else groupClosedCase
      "
    >
      <div class="custom-container" *ngIf="activeUnit.spotsLeft === 0; else alreadyStarted">
        <h1 class="primary-color">Group Completed.</h1>
        <div class="main-text">This group is now full. Applications are now closed.</div>
        <div class="font-weight-bold mt-3">{{ activeUnit.spotsLeft }} spots left</div>
      </div>

      <ng-template #alreadyStarted>
        <div class="custom-container" *ngIf="!canApply; else userStatus">
          <h1 class="primary-color">Group started.</h1>
          <div class="main-text mb-4">This group has already started. Applications are now closed.</div>
          <div class="font-weight-bold mt-2">{{ activeUnit.spotsLeft }} spots left</div>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #groupClosedCase>
      <div class="custom-container" *ngIf="group.groupState === groupState.CLOSED && activeUnit.userStatus === null && !hasEnded; else userStatus">
        <h1 class="primary-color">Group Closed.</h1>
        <div *ngIf="group.groupRole === groupUserType.ENTREPRENEUR" class="main-text mb-3">
          This group is closed for registration. If you would like to join this group or need assistance finding another, please email us at
          <a class="primary-color" href="mailto:groups@faithdrivenentrepreneur.org">groups@faithdrivenentrepreneur.org</a>.
        </div>
        <div *ngIf="group.groupRole !== groupUserType.ENTREPRENEUR" class="main-text mb-3">
          This group is closed for registration, but we would love to connect with you to get you plugged in to the next cohort. Please email us at
          <a class="primary-color" href="mailto:fdi-groups@faithdriveninvestor.org">fdi-groups@faithdriveninvestor.org</a>.
        </div>
      </div>
      <div class="custom-container" *ngIf="hasEnded && activeUnit.userStatus === null">
        <h1 class="primary-color">Group Ended.</h1>
        <div class="main-text mb-3">This group has ended. Applications are now closed.</div>
      </div>
    </ng-template>

    <ng-template #userStatus>
      <div id="userState">
        <div class="custom-container" [ngSwitch]="true">
          <ng-container *ngSwitchCase="pendingOrDeclinedUser">
            <h1 #showSuccessMessage class="primary-color">Application Complete.</h1>
            <div class="main-text mb-3">
              Thank you for showing interest in this group. An admin will look over your application and give you an answer soon.
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="modalRequest.scope !== modalActions.VIEW_GROUP_FROM_MY_GROUPS">
            <ng-container *ngIf="activeUnit.userStatus === participantType.APPROVED">
              <h1 class="primary-color">Thank you for applying for this group.</h1>
              <div class="main-text mb-3">Once you have been approved, you will see the meeting location below.</div>
            </ng-container>
            <ng-container *ngIf="canSeeMeetingDetails">
              <ng-container *ngIf="activeUnit?.meetingPlaceType === groupTypes.IN_PERSON; else notGeoBased">
                <div class="gray-label pb-1">Meeting Address</div>
                <div class="main-text font-weight-bold">
                  <span *ngIf="activeUnit.location.locationDetails">{{ activeUnit.location.locationDetails + ', ' }}</span>
                  <span *ngIf="activeUnit.location.address">{{ activeUnit.location.address }}</span>
                  <span *ngIf="!activeUnit.location.address">{{ activeUnit.location.city + ', ' + activeUnit.location.stateProvince }}</span>
                </div>
              </ng-container>
              <ng-template #notGeoBased>
                <div class="gray-label pb-1">Meeting Link</div>
                <a class="main-text font-weight-bold font-italic pointer" (click)="externalNavigate(activeUnit.meetingLink)">
                  <u>{{ activeUnit.meetingLink }}</u>
                </a>
              </ng-template>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="activeUnit.userStatus === participantType.WITHDRAWN && !canApply">
            <div class="custom-container">
              <h1 class="primary-color">Group started.</h1>
              <div class="main-text mb-4">This group has already started. Applications are now closed.</div>
              <div class="font-weight-bold mt-2">{{ activeUnit.spotsLeft }} spots left</div>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container
              *ngIf="
                !hasEnded &&
                (activeUnit.userStatus === null || activeUnit.userStatus === participantType.WITHDRAWN) &&
                canApply &&
                activeUnit.spotsLeft > 0
              "
            >
              <h1 class="primary-color">Ready to get started?</h1>
              <div class="main-text mb-4">Start your journey with us!</div>
              <button #applyButton mat-flat-button color="primary" class="mat-medium-button pulse-button" (click)="attemptApplication()">
                Apply for this group
              </button>
              <div class="font-weight-bold mt-1">{{ activeUnit.spotsLeft }} spots available</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <div class="d-flex justify-content-between py-1 mb-3">
      <div class="d-flex align-items-center" *ngAccess-admin>
        <button mat-button class="uppercase min-w-85p" color="primary" (click)="goToGroupSetup()"><i class="fas fa-pencil-alt mr-2"></i>Edit</button>
        <div class="edit-divider"></div>
      </div>
      <div class="d-flex flex-wrap align-items-center" [class.ml-3]="isAdmin">
        <h2 class="primary-color mb-0 mr-2 share-text">Share this group</h2>
        <div class="d-flex flex-nowrap align-items-center">
          <a class="mx-2 pointer" (click)="openWindow(socialMedia.FACEBOOK)"><i class="fab fa-facebook-f fa-lg"></i></a>
          <a class="mx-2 pointer" (click)="openWindow(socialMedia.LINKEDIN)"><i class="fab fa-linkedin-in fa-lg"></i></a>
          <a class="mx-2 pointer" (click)="openWindow(socialMedia.TWITTER)"><i class="fab fa-twitter fa-lg"></i></a>
          <div class="pointer mx-2" (click)="copyToClipboard()">
            <i class="fas fa-link fa-lg"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-spinner" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
