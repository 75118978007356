import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminCohort } from 'src/app/internal-app/admin/tags/tags.model';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminCohortsService {
  private readonly resourceUrl: string = '/v1/cohort';

  constructor(private apiService: ApiService) {}

  getCohorts(): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/get-cohorts`);
  }

  createUpdateCohort(body: AdminCohort): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/create-update`, body);
  }

  deleteCohort(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/${uuid}`);
  }
}
