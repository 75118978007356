<div class="d-flex flex-column justify-content-between h-100">
  <a class="text-right" (click)="emit(false)"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center">
    <h3>
      <span *ngIf="modalRequest.payload.meetingPlaceType === groupTypes.IN_PERSON">The group you applied for is meeting in</span>
      <span *ngIf="modalRequest.payload.meetingPlaceType === groupTypes.HYBRID">The group you applied for may meet on occasion in</span>
      <p class="primary-color">
        <b>{{ cityStateCountryString }}</b>
      </p>
    </h3>
    <h3>Are you able to attend this group in-person?</h3>
  </div>

  <ng-container *ngIf="!isAuth; else authView">
    <div class="d-flex justify-content-between mt-4">
      <button mat-flat-button class="mat-medium-button" color="primary" (click)="close()">Cancel</button>
      <button mat-flat-button class="mat-medium-button" color="accent" (click)="emit(true)">Yes, I can</button>
    </div>
  </ng-container>
  <ng-template #authView>
    <div class="d-flex flex-column">
      <button mat-flat-button class="mat-medium-button rounded w-100 mb-3" color="primary" (click)="emit(true)">Yes, I can</button>
      <button mat-stroked-button class="mat-outline-button bg-white text-dark rounded" (click)="emit(false)">No, find another group</button>
    </div>
  </ng-template>
</div>
