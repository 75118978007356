import { Environment } from '../app/_core/constants/Environments';

export const environment = {
  production: false,
  name: Environment.TEST,
  url: 'https://test.groups.faithdrivenentrepreneur.org',
  server: 'https://test.groups.faithdrivenentrepreneur.org/api',
  authServer: 'https://test.groups.faithdrivenentrepreneur.org/api-auth',
  mattermostUrl: 'https://test.mattermost.faithdriveninvestor.org',
  placesApiKey: 'AIzaSyAP_QSIbrgr7186CebjAOsFmgr9mQ45o7o',
  recaptchaApiKey: '6LcmW9oaAAAAAH7CduvrhqLxMmtGeRC7JHR9TQLr',
  firebaseConfig: {
    apiKey: 'AIzaSyDjwvSGdkm-trjGoNqNMhgD7FxsuzExRLw',
    authDomain: 'faith-driven-test.firebaseapp.com',
    projectId: 'faith-driven-test',
    storageBucket: 'faith-driven-test.appspot.com',
    messagingSenderId: '178385172906',
    appId: '1:178385172906:web:1d3437cf15c7fefb23d3d3',
    measurementId: 'G-W7CCTEEMX0',
  },
  faithDrivenInvestorUuid: '39a5d098-9698-4d64-9b90-0398c26188e9',
  faithDrivenEntrepreneurUuid: 'a5dd758f-e0f6-4fbb-8c5b-d41e058621f8',
  faithDrivenEntrepreneurStudyUuid: 'a5dd758f-e0f6-4fbb-8c5b-d41e058621f8',
  completeProfileEntrepreneurUrl: 'https://faithdriven.typeform.com/to/pwBj6LSB#email=',
  completeProfileEntrepreneur2Url: 'https://faithdriven.typeform.com/to/G0MeFWSd#email=',
  completeProfileAccreditedUrl: 'https://faithdriven.typeform.com/test-accinvest#email=',
  completeProfileFundManagerUrl: 'https://faithdriven.typeform.com/to/bPf6XoPs#email=',
  completeProfileFinancialAdvisorUrl: 'https://faithdriven.typeform.com/to/kZn1xuYO#email=',
  completeProfileMinistryLeaderUrl: 'https://faithdriven.typeform.com/to/at821OKu#email=',
  completeProfileRetailUrl: 'https://faithdriven.typeform.com/to/vdEnfB5T#email=',
  completeProfileQualifiedPurchaserUrl: 'https://faithdriven.typeform.com/test-qp#email=',
};
