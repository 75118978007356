import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export default class FormUtils {
  public static clearValueAndValidator(control: AbstractControl | UntypedFormArray, array?: boolean) {
    array ? control.patchValue([]) : control.patchValue(null);
    control.clearValidators();
    control.updateValueAndValidity();
  }

  public static clearValueAndValidatorFormGroup(form: UntypedFormGroup | UntypedFormArray) {
    for (const key in form.controls) {
      this.clearValueAndValidator(form.get(key));
    }
    form.clearValidators();
    form.updateValueAndValidity();
  }

  public static updateValueAndValidator(control: AbstractControl, validators: ValidatorFn[], value?: any): void {
    control.setValidators(validators);
    control.updateValueAndValidity();
    if (value) {
      control.patchValue(value);
    }
  }

  public static clearAndUpdateValueAndValidator(control: AbstractControl, validators: ValidatorFn[], value?: any): void {
    control.clearValidators();
    control.setValidators(validators);
    control.updateValueAndValidity();
    if (value) {
      control.patchValue(value);
    }
  }

  public static compareById(obj1: any, obj2: any): boolean {
    return obj1 && obj2 && obj1.id === obj2.id;
  }
}
