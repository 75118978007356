<div class="group-card-not-closed mb-5 p-3">
  <div class="d-flex flex-column flex-md-row">
    <img
      class="study-image"
      [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
      [lazyLoad]="groupStudy.study.imgUrl"
    />
    <div class="ml-0 ml-md-3 mt-3 mt-md-0">
      <h2 class="mb-0">{{ groupStudy.study.name }}</h2>
      <div class="primary-color smaller-text uppercase font-weight-bold">{{ groupStudy.study.author }}</div>
      <ng-container *ngIf="!isMobile">
        <p class="gray-label pt-2 pb-1">About the study</p>
        <div class="m-0">
          {{ groupStudy.study.description }}
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="isMobile">
    <p class="gray-label pt-3 pb-1">About the study</p>
    <div class="m-0">
      {{ groupStudy.study.description }}
    </div>
  </ng-container>
  <mat-divider class="my-3 my-md-4 pb-1"></mat-divider>
  <ng-container *ngFor="let group of groupStudy.groups; let i = index">
    <app-study-card-facilitator [group]="group" [isMobile]="isMobile"></app-study-card-facilitator>
    <mat-divider class="mt-4 mt-lg-3 mb-3 mb-lg-4" *ngIf="i !== groupStudy.groups.length - 1"></mat-divider>
  </ng-container>
</div>
