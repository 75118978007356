import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Urls } from 'src/app/_core/constants/Urls';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Output() searchEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() searchText = '';

  placeholder: string;
  searching = false;

  // prettier-ignore
  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.setPlaceholder(this.router.url);
  }

  search(): void {
    this.searchEvent.emit(this.searchText.trim());
  }

  setPlaceholder(url: string): void {
    switch (url) {
      case `/${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_GROUPS}`:
        this.placeholder = 'Search groups';
        break;
      case `/${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_FACILITATORS}`:
        this.placeholder = 'Search facilitator';
        break;
      case `/${Urls.APP}/${Urls.ADMIN}/${Urls.MANAGE_PARTNER_ORGANIZATIONS}`:
        this.placeholder = 'Search partner organization';
        break;
      default:
        this.placeholder = 'Search';
        break;
    }
  }
}
