<div class="d-flex flex-column justify-content-start m-2">
  <a class="text-right" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center mb-4 mt-2 mx-3">
    <h2>The Faith Driven Entrepreneur Study must be completed prior to starting another study.</h2>
    <div>
      Before going through other studies, we ask that you first complete the Faith Driven Entrepreneur Study to understand the heart of the movement.
      Explore open groups for the Faith Driven Entrepreneur Study below to get started.
    </div>
  </div>

  <div class="d-flex justify-content-between mt-4 mx-4">
    <span class="mat-medium-button link" (click)="close()">Back</span>
    <button mat-flat-button class="mat-medium-button" color="main" (click)="navigate()">Go to FDE Studies</button>
  </div>
  <div class="text-center mb-4 mt-4 mx-3">
    If you have already completed a Faith Driven Entrepreneur Study, welcome back! <br />
    Please let our team know at <a class="link" href="mailto:groups@faithdrivenentrepreneur.org">groups@faithdrivenentrepreneur.org</a> <br />
    Once your profile is updated, you can apply for another study.
  </div>
</div>
