import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MediaModel } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-media-modal',
  templateUrl: './media-modal.component.html',
  styleUrls: ['./media-modal.component.scss'],
})
export class MediaModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('video') video: ElementRef;
  media: MediaModel;
  sanitizedUrl: SafeUrl;
  breakpointSubscription: Subscription;
  paused: boolean = true;

  // prettier-ignore
  constructor(
    private modalsService: ModalsService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<MediaModalComponent>,
    private sanitizer: DomSanitizer,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', 'auto')
        : this.dialogRef.updateSize('1280px', 'auto');
    });
  }

  ngOnInit(): void {
    this.media = this.modalsService.params;
    if (!this.media.fileType) {
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.media.url);
    }
  }

  ngAfterViewInit(): void {
    this.catchPlayButtonEvent();
  }

  playPause(event): void {
    this.video.nativeElement.paused ? this.video.nativeElement.play() : this.video.nativeElement.pause();
    event.preventDefault();
  }

  catchPlayButtonEvent(): void {
    this.video?.nativeElement.addEventListener('play', () => (this.paused = !this.paused));
    this.video?.nativeElement.addEventListener('pause', () => (this.paused = !this.paused));
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  close(): void {
    this.modalsService.closeModal();
  }
}
