import { ChangeDetectorRef, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { LOADER_TYPE } from 'src/app/_core/constants/Loader';
import { LoaderData } from 'src/app/_core/models/Loader';
import { LoaderService } from 'src/app/_core/services/loader.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() forceLoading: boolean;
  @Input() disableScroll: boolean;
  @Input() small: boolean;
  loading: boolean;
  loadingSub: Subscription = new Subscription();

  // prettier-ignore
  constructor(private loaderService: LoaderService, private ref: ChangeDetectorRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.loadingSub.add(
      this.loaderService.loading$.subscribe((data: LoaderData) => {
        if (data.type === LOADER_TYPE.LOCAL) {
          this.loading = data.visible || this.forceLoading;
          this.handleScroll();
          this.ref.detectChanges();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.loadingSub.unsubscribe();
    if (this.disableScroll) this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  handleScroll(): void {
    if (!this.disableScroll) return;
    if (this.loading) this.renderer.setStyle(document.body, 'overflow', 'hidden');
    else this.renderer.setStyle(document.body, 'overflow', 'auto');
  }
}
