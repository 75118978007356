import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UsersImport } from '../../_modals/import-users-modal/import-users.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-import-users-table',
  templateUrl: './import-users-table.component.html',
  styleUrls: ['./import-users-table.component.scss'],
})
export class ImportUsersTableComponent implements OnInit, OnDestroy {
  @Input() importUsers: UsersImport;
  subscription: Subscription;
  isUnder1500Px: boolean;
  isUnder992Px: boolean;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.subscription = this.breakpointObserver.observe(['(min-width: 992px)', '(min-width: 1500px)']).subscribe(() => {
      this.isUnder1500Px = !breakpointObserver.isMatched(['(min-width: 1500px)']);
      this.isUnder992Px = !breakpointObserver.isMatched(['(min-width: 992px)']);
      if (this.importUsers && this.isUnder992Px) {
        this.importUsers.users.forEach((user) => (user.expanded = true));
      }
    });
  }

  ngOnInit(): void {
    if (this.isUnder992Px) {
      this.importUsers.users.forEach((user) => (user.expanded = true));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  expandAll(): void {
    this.importUsers.users.forEach((user) => (user.expanded = true));
  }

  collapseAll(): void {
    this.importUsers.users.forEach((user) => (user.expanded = false));
  }
}
