import { ChangeDetectorRef, Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../../_core/services/user.service';

@Directive({
  selector: '[ngAccess-admin]',
})
export class NormalAdminAccess implements OnInit {
  // prettier-ignore
  constructor(private userService: UserService, private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  ngOnInit(): void {
    this.userService.currentUser$.subscribe(() => {
      if (this.userService.isAdmin()) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
    });
  }
}

@Directive({
  selector: '[ngAccess-superAdmin]',
})
export class SuperAdminAccess implements OnInit {
  // prettier-ignore
  constructor(
    private userService: UserService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userService.currentUser$.subscribe(() => {
      if (this.userService.isSuperAdmin()) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }
      this.changeDetector.detectChanges();
    });
  }
}
