import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Urls } from 'src/app/_core/constants/Urls';
import Utils from 'src/app/_core/helpers/Utils';
import { UUIDName } from 'src/app/_core/models/GenericObject';
import { SmartAdminGroupUnit } from '../../view-group.model';

@Component({
  selector: 'app-view-group-about-info',
  templateUrl: './view-group-about-info.component.html',
  styleUrls: ['./view-group-about-info.component.scss'],
})
export class ViewGroupAboutInfoComponent implements OnInit {
  //TODO change this variable name to unit
  @Input() group: SmartAdminGroupUnit;
  @Input() companySizes: UUIDName[];
  viewedByFacilitator: boolean;

  // prettier-ignore
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.viewedByFacilitator = this.router.url.indexOf(Urls.GROUPS_I_LEAD) > -1;
  }

  openLink(): void {
    Utils.navigate(this.group.meetingLink);
  }
}
