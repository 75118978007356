import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss'],
})
export class RegionComponent implements OnInit {
  @Output() searchAddress: EventEmitter<Address> = new EventEmitter<Address>();

  constructor() {}

  ngOnInit(): void {}

  handleAddressChange(address: Address): void {
    this.searchAddress.emit(address);
  }
}
