import { TitleCasePipe } from '@angular/common';
import { AdminAction } from '../constants/AdminGroups';
import { LAST_MODIFIED_FILTER_DATA, USER_STATUS_FILTER_DATA } from '../constants/AdminUsers';
import { FilterType, GROUP_DATA, LAST_MODIFIED_DATA, NEW_USERS_DATA, START_DATE_STUDY_DATA, STUDY_DATA, USER_STATUS_DATA } from '../constants/Home';
import { SortValue } from '../constants/SortByOptions';
import { UserStatusEnum } from '../constants/UserEnums';
import { CHURCH_DATA } from './../constants/Home';
import { Filter, FilterOption, SortBy, SortByOption, SortOption } from './Filters';
import { Church, Group, Region, Study, TextCode } from './Home';
import { PaginatedResponse } from './Shared';

export interface AdminUser {
  email: string;
  firstName: string;
  isFacilitator: boolean;
  lastName: string;
  position: string;
  role: string;
  unitsCount: number;
  uuid: string;
}

export interface GetUsersRequest {
  userStatus: string[];
  groupsUuids: string[];
  lastModifiedDays: number;
  facilitators: boolean;
  newUsersDays: number;
  page: number;
  searchText: string;
  size: number;
  sortBy: SortByOption<'asc' | 'desc'>;
  startDateOfStudy: string;
  studiesUuids: string[];
}

export interface UsersResponse<T> {
  users: PaginatedResponse<T>;
}

export interface UserGroupsResposnse<T> {
  userAttendingGroups: PaginatedResponse<T>;
}

export class AdminUsersFilters {
  filters: UsersFilters;
  action: AdminAction;
  filterSelected: Filter;

  constructor(filters: UsersFilters, action: AdminAction) {
    this.filters = filters;
    this.action = action;
    this.setSortByOptions();
  }

  private setSortByOptions(): void {
    this.filters.sortBy = [
      new SortOption(SortValue.USERNAME, null, false),
      new SortOption(SortValue.POSITION, null, false),
      new SortOption(SortValue.EMAIL, null, false),
      new SortOption(SortValue.GROUP, 'desc', true),
    ];
  }
}

export class UsersFilters extends SortBy {
  study: Filter<Study>;
  church: Filter<Church>;
  group: Filter<Group>;
  userStatus: Filter;
  lastModified: Filter;
  newUser: Filter;
  filtersArray: Filter[];
  startDateOfStudy: Filter;
  preloaded: string[];
  anythingSelected: boolean;
  selectAll: boolean;
  searchText: string;
  size: number;
  page: number;

  constructor(size: number = 10, page: number = 0) {
    super();
    this.study = new Filter<Study>(FilterType.STUDY, [], STUDY_DATA);
    this.church = new Filter<Church>(FilterType.CHURCH, [], CHURCH_DATA);
    this.group = new Filter<Group>(FilterType.GROUP, [], GROUP_DATA);
    this.userStatus = new Filter<FilterOption>(
      FilterType.USER_STATUS,
      USER_STATUS_FILTER_DATA.map((item: UserStatus) => new FilterOption(item.status, new TitleCasePipe().transform(item.text), false)),
      USER_STATUS_DATA
    );
    this.lastModified = new Filter<FilterOption>(
      FilterType.LAST_MODIFIED,
      LAST_MODIFIED_FILTER_DATA.map((item: LastDays) => new FilterOption(item.days.toString(), new TitleCasePipe().transform(item.text), false)),
      LAST_MODIFIED_DATA
    );
    this.newUser = new Filter<FilterOption>(
      FilterType.NEW_USERS,
      LAST_MODIFIED_FILTER_DATA.map((item: LastDays) => new FilterOption(item.days.toString(), new TitleCasePipe().transform(item.text), false)),
      NEW_USERS_DATA
    );
    this.startDateOfStudy = new Filter<FilterOption>(FilterType.START_DATE_STUDY, [], START_DATE_STUDY_DATA);
    this.filtersArray = [this.study, this.group, this.userStatus, this.lastModified, this.newUser, this.startDateOfStudy];
    this.preloaded = [];
    this.size = size;
    this.page = page;
    this.searchText = '';
    this.selectAll = false;
    this.anythingSelected = false;
  }

  clearFilters(): void {
    this.filtersArray.forEach((filter) => {
      filter.misc.selected = false;
      filter.searchText = '';
      filter.filters?.forEach((subFilter) => (subFilter.selected = false));
    });
    this.page = 0;
    this.searchText = '';
    this.preloaded = [];
    this.selectAll = false;
  }

  checkSelectedSubFilter(filter: Filter): void {
    this.anythingSelected = filter.filters.some((subFilter: FilterOption) => subFilter.selected);
  }

  markSelectedFilters(): void {
    this.filtersArray.forEach((filter: Filter) => (filter.misc.selected = filter.checkAnythingSelected()));
  }

  updateFiltersOrder(filter: Filter): void {
    let index = filter.filters.length - 1;
    let c = 0;
    while (index >= c) {
      const element = filter.filters[index];
      if (element.selected) {
        filter.filters.splice(index, 1);
        filter.filters.unshift(element);
        index++;
        c++;
      }
      index--;
    }
  }
}

export class UserGroupFilters {
  searchText: string;
  size: number;
  page: number;
  userStatus: UserStatusEnum;

  constructor(size: number = 10, page: number = 0) {
    this.size = size;
    this.page = page;
    this.searchText = null;
    this.userStatus = UserStatusEnum.APPROVED;
  }
}

export interface CreateUpdateUserRequest {
  uuid: string;
  countryCode: string;
  location: Region;
  bio: string;
  email: string;
  firstName: string;
  lastName: string;
  linkedinProfile: string;
  phoneNumber: PhoneNumber;
  title: string;
  overrideUserInfo?: boolean;
  profilePictureUUID?: string;
  facilitator: boolean;
  visible: boolean;
  timezone: string;
  timezoneName: string;
}

export interface PhoneNumber {
  countryCode: string;
  nationalNumber: string;
  completeNumber: string;
}

export interface UserStatus extends TextCode {
  status: string;
}

export interface LastDays extends TextCode {
  days: number;
}
