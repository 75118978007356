export enum SocialLinks {
  LINK_FOOTER_TWITTER = 'https://twitter.com/FaithDrivenEnt',
  LINK_FOOTER_FACEBOOK = 'https://www.facebook.com/faithdrivenentrepreneur/',
  LINK_FOOTER_LINKEDIN = 'https://www.linkedin.com/company/faithdrivenentrepreneur/',
}

export enum SocialMedia {
  FACEBOOK,
  TWITTER,
  LINKEDIN,
}
