import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FACILITATOR_TYPE } from '../models/Facilitators';
import { BasicPaginatedRequest } from '../models/Shared';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class FacilitatorsService {
  private readonly resourceUrl: string = '/v1/users';
  private readonly InterceptorSkipBearer = 'X-Skip-Authorization-Bearer';

  constructor(private apiService: ApiService) {}

  getUsers(body: any): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/get-users`, body);
  }

  deleteFacilitator(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/${uuid}`);
  }

  getFacilitatorDetails(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/${uuid}`);
  }

  downgradeFacilitator(uuid: string): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/downgrade-facilitator`, null, { currentFacilitatorUuid: uuid });
  }

  getPaginatedFacilitators(payload: BasicPaginatedRequest, facilitatorType: FACILITATOR_TYPE, groupRole: string = null): Observable<any> {
    const Headers = new HttpHeaders().set(this.InterceptorSkipBearer, '');
    const QueryParams = {
      groupRole: groupRole ? groupRole?.toUpperCase() : 'NONE',
      facilitatorPagingType: facilitatorType,
    };
    return this.apiService.post(`${this.resourceUrl}/get-facilitators-main`, payload, QueryParams, Headers);
  }
}
