import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { FilterType } from 'src/app/_core/constants/Home';
import { ModalActions, ModalEmitActions } from 'src/app/_core/constants/Modals';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import { Filter, FilterOption } from 'src/app/_core/models/Filters';
import { GroupsFilters } from 'src/app/_core/models/Home';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { GroupDropdownService } from 'src/app/_core/services/group-dropdown.service';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-schedule-filter',
  templateUrl: './schedule-filter.component.html',
  styleUrls: ['./schedule-filter.component.scss'],
})
export class ScheduleFilterComponent implements OnInit {
  filter: Filter;
  selectedAll: boolean;
  filterType = FilterType;
  loading = false;
  groupsFilters: GroupsFilters;
  scheduleForm: UntypedFormGroup;
  dropdownsSubscription: Subscription;
  breakpointSubscription: Subscription;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private dialogRef: MatDialogRef<ScheduleFilterComponent>,
    private breakpointObserver: BreakpointObserver,
    private groupDropdownsService: GroupDropdownService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.dropdownsSubscription = this.groupDropdownsService.filtersDropdowns$.subscribe((filters: GroupsFilters) => {
      this.groupsFilters = filters;
    });
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vw')
        : this.dialogRef.updateSize('800px', '100vh');
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.patchForm();
  }

  createForm(): void {
    this.scheduleForm = this.formBuilder.group({
      cohort: [null],
      startDate: [null],
      dayMonth: this.formBuilder.group({
        day: [null],
        month: [null],
        year: [null],
      }),
    });
  }

  patchForm(): void {
    const cohorts = this.groupsFilters.cohorts.filters
      ?.filter((subFilter: FilterOption) => subFilter.selected === true)
      .map((subFilter) => subFilter.uuid);
    const days = this.groupsFilters.days.filters?.filter((subFilter: FilterOption) => subFilter.selected === true).map((subFilter) => subFilter.uuid);
    const months = this.groupsFilters.months.filters
      ?.filter((subFilter: FilterOption) => subFilter.selected === true)
      .map((subFilter) => subFilter.uuid);
    const years = this.groupsFilters.years.filters
      ?.filter((subFilter: FilterOption) => subFilter.selected === true)
      .map((subFilter) => subFilter.uuid);

    this.scheduleForm.patchValue({
      startDate: this.groupsFilters.startDate !== '' ? this.groupsFilters.startDate : null,
      cohort: cohorts,
      dayMonth: {
        day: days,
        month: months,
        year: years,
      },
    });
  }

  applyFilter(): void {
    if (
      (this.startDate.value !== null && (this.day.value?.length > 0 || this.month.value?.length > 0 || this.year.value?.length > 0)) ||
      (this.startDate.value !== null && this.cohort.value?.length > 0) ||
      (this.cohort.value?.length && (this.day.value?.length > 0 || this.month.value?.length > 0 || this.year.value?.length > 0))
    ) {
      return;
    }

    this.startDate.value ? (this.groupsFilters.startDate = DateUtils.formatDateString(this.startDate.value)) : (this.groupsFilters.startDate = '');

    this.resetFilter(this.day, this.groupsFilters.days);
    this.resetFilter(this.month, this.groupsFilters.months);
    this.resetFilter(this.year, this.groupsFilters.years);
    this.resetFilter(this.cohort, this.groupsFilters.cohorts);

    this.groupsFilters.unselectMainFilters();
    this.groupsFilters.days.misc.selected = true;
    this.groupsFilters.checkAnythingSelected();

    this.modalService.emitResponse(
      new ModalResponse<FilterType>(
        true,
        new ModalRequest<FilterType>(ModalActions.SELECT_GROUPS_FILTER, null, null, FilterType.SCHEDULE),
        ModalEmitActions.FILTER_SELECTED
      )
    );
    this.close();
  }

  reset(): void {
    this.scheduleForm.patchValue({
      startDate: null,
      cohort: null,
      dayMonth: {
        day: null,
        month: null,
        year: null,
      },
    });
  }

  resetFilter(formControl: AbstractControl, filter: Filter<any>): void {
    if (formControl.value?.length > 0) {
      filter.filters.forEach((item) => {
        formControl.value.indexOf(item.uuid) > -1 ? (item.selected = true) : (item.selected = false);
      });
    } else {
      filter.filters.forEach((item) => (item.selected = false));
    }
  }

  close(): void {
    this.modalService.closeModal();
  }

  get cohort(): AbstractControl {
    return this.scheduleForm.get('cohort');
  }

  get startDate(): AbstractControl {
    return this.scheduleForm.get('startDate');
  }

  get dayMonth(): UntypedFormGroup {
    return this.scheduleForm.get('dayMonth') as UntypedFormGroup;
  }

  get day(): AbstractControl {
    return this.scheduleForm.get('dayMonth').get('day');
  }

  get month(): AbstractControl {
    return this.scheduleForm.get('dayMonth').get('month');
  }

  get year(): AbstractControl {
    return this.scheduleForm.get('dayMonth').get('year');
  }
}
