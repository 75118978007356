import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatHeaderName',
})
export class FormatHeaderNamePipe implements PipeTransform {
  transform(value: string): string {
    if (value.startsWith('HEADER_')) {
      let formattedValue = value.substring(7).toLowerCase();
      return formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
    }
    return value;
  }
}
