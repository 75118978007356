import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterType } from '../../../_core/constants/Home';
import { UserService } from '../../../_core/services/user.service';
import { Filter } from '../../../_core/models/Filters';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Input() filters;
  @Output() filterSelected: EventEmitter<Filter> = new EventEmitter<Filter>();
  filterType = FilterType;

  // prettier-ignore
  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit(): void {}

  computeCondition(filter: Filter): boolean {
    return !(this.userService?.currentUser?.facilitator && !this.userService.isAdmin() && filter.name === FilterType.FACILITATOR);
  }
}
