import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Mainland } from 'src/app/_core/models/AdminChurches';
import { AdminGroupMeeting } from 'src/app/internal-app/admin/groups/_helpers/view-group/view-group.model';
import { GROUP_CATEGORY } from 'src/app/internal-app/groups/groups/groups.const';
import { Frequencies, GroupFormat, GroupStates, GroupTypes } from '../constants/AdminGroups';
import { FilterType } from '../constants/Home';
import DateUtils from '../helpers/DateUtils';
import Utils from '../helpers/Utils';
import { CustomAddress } from './Address';
import { Meeting, Participant, UnitMeeting, UnitStudy } from './AdminGroups';
import { Region, Study } from './Home';
import { BasicPaginatedRequest } from './Shared';

export interface GroupsResponse {
  objects: MultiFilterGroup[];
  pageNumber: number;
  size: number;
  totalObjects: number;
}

export interface GroupsDateOrdered {
  startsOnDate: string;
  meetingDay: string;
  groups: Group[];
}

export interface GroupsStudyOrdered {
  study: Study;
  groups: Group[];
}

export interface GroupFacilitator {
  bio: string;
  firstName: string;
  imgUrl: string;
  lastName: string;
  facilitatorsUuid: string;
  uuid?: string;
  title: string;
  linkedinUrl?: string;
  isPrimary: boolean;
}

export interface GroupChurchPartnerOrganization {
  uuid?: string;
  churchUuid: string;
  imageUrl: string;
  mainland: Mainland;
  name: string;
  websiteUrl: string;
  headline: string;
  description: string;
}

export interface Group {
  bio: string;
  comment: string;
  durationMinutes: number;
  endsOn: any;
  firstName: string;
  uuid: string;
  lastName: string;
  maxParticipants: number;
  meetingTime: string;
  meetingDay: string;
  name: string;
  imgUrl: string;
  spotsTaken: number;
  spotsLeft: number;
  companySize: string[];
  startsOn: any;
  openUntilDate?: any;
  studyAuthor: string;
  studyDescription: string;
  studyImage: string;
  studyName: string;
  studyUuid: string;
  title: string;
  userUuid: string;
  facilitatorsDtoList?: GroupFacilitator[];
  groupType: string;
  location: Region;
  countryCode: string;
  meetings: Meeting[];
  meetingType: GroupFormat;
  churches: GroupChurchPartnerOrganization[];
  city: string;
  mainlands: Mainland[];
  groupState?: GroupStates;
}

export interface MultiFilterGroup {
  uuid: string;
  churches: GroupChurchPartnerOrganization[];
  groupState: GroupStates;
  name: string;
  openUntilDate: any;
  activeUnit: GroupCardUnit;
}

export interface GroupCardUnit {
  dayName: string[];
  endsOn: number;
  interval: string;
  facilitators: GroupFacilitator[];
  frequencyType: Frequencies;
  location: CustomAddress;
  mainlands: Mainland[];
  maxParticipants: number;
  meetingPlaceType: GroupTypes;
  meetings: UnitMeeting[];
  meetingTime: string;
  meetingDay: string;
  spotsLeft: number;
  spotsTaken: number;
  study: UnitStudy;
  pendingUsers: number;
  startsOn: number;
  timezone: string;
  uuid: string;
  startTimeWithDaylightSavingTime: string;
}

export class GroupCard {
  alreadyStarted: boolean;
  cancelButtonText: string;
  church: GroupChurchPartnerOrganization;
  dayName: string[];
  endsOn: number;
  frequency: string;
  isNew: boolean;
  location: string;
  meetingTime: string;
  name: string;
  nextMeetingDate: string;
  spotsLeft: number;
  startingDate: number;
  state: GroupStates;
  type: GroupTypes;
  uuid: string;
  timezone: string;
  startTimeWithDaylightSavingTime: string;

  constructor(groupInfo: MultiFilterGroup, groupCategory?: GROUP_CATEGORY) {
    DateUtils.setLocalTime(groupInfo.activeUnit);
    this.alreadyStarted = new Date(groupInfo.activeUnit.startsOn) < new Date();
    if (groupInfo.churches?.length) this.church = groupInfo.churches[0];
    this.dayName = [DateUtils.getDayNameOutOfMilliseconds(groupInfo.activeUnit.startsOn)];
    this.endsOn = groupInfo.activeUnit.endsOn;
    this.frequency = this.getFrequency(groupInfo.activeUnit);
    this.isNew = groupInfo.activeUnit.study.onboardingStudy;
    this.type = groupInfo.activeUnit.meetingPlaceType;
    if (this.type !== GroupTypes.VIRTUAL) this.location = this.getLocation(groupInfo.activeUnit, groupCategory);
    this.meetingTime = groupInfo.activeUnit.meetingTime;
    this.name = groupInfo.name;
    this.nextMeetingDate = DateUtils.UTCtoLocalNoOffset((DateUtils.getNextMeeting(groupInfo.activeUnit.meetings) as UnitMeeting)?.startTime);
    this.uuid = groupInfo.uuid;
    this.spotsLeft = groupInfo.activeUnit.spotsLeft;
    this.startingDate = groupInfo.activeUnit.startsOn;
    this.state = groupInfo.groupState;
    this.timezone = groupInfo.activeUnit.timezone;
    this.startTimeWithDaylightSavingTime = DateUtils.getStartDateTimeWithDaylightSavingTime(this.startingDate, this.timezone);
  }

  getFrequency(unitDto: GroupCardUnit): string {
    switch (unitDto.frequencyType) {
      case Frequencies.MONTHLY:
      case Frequencies.WEEKLY:
        return unitDto.frequencyType;
      case Frequencies.EVERY_2_WEEKS:
        return unitDto.frequencyType.split('_').join(' ');
      case Frequencies.CUSTOM:
        return parseInt(unitDto.interval) === 1 ? 'Weekly' : `Every ${unitDto.interval} weeks`;
    }
  }

  getLocation(groupUnit: GroupCardUnit, groupCategory: GROUP_CATEGORY): string {
    if (!groupUnit.location?.stateProvince) return groupUnit.mainlands ? groupUnit.mainlands[0].region : null;
    const address = [groupUnit.location.city, groupUnit.location.stateProvince].filter(Boolean).join(', ');
    if (groupCategory !== GROUP_CATEGORY.IN_PERSON_REGIONAL) return address;
    const distanceInKm = Math.floor(groupUnit.location.distanceInKm);
    const distanceInMiles = Math.floor(Utils.convertKmToMiles(distanceInKm));
    return distanceInKm ? `${address} - ${distanceInKm} km / ${distanceInMiles} mi` : `${address} - <1 km/mi`;
  }
}

export interface StudyInfo {
  studyAuthor: string;
  studyDescription: string;
  studyImage: string;
  studyName: string;
  studyUuid: string;
}
export interface GetGroupsRequest {
  timezone: number;
  weekDayIds: string[];
  dayIntervalIds?: string[];
  monthIds?: string[];
  years?: string[];
  startDate?: string;
  filter: FilterType;
  facilitatorUuids: string[];
  mainlandUuids?: string[];
  companySizeUuids: string[];
  deselectedWeekDayIds: string[];
  studyUuids: string[];
  deselected: string[];
  selectAll?: boolean;
  // location: Region;
  page: number;
  size: number;
  groupTypes: GroupTypes[];
  churchesUuids: string[];
  cohorts?: string[];
  mainlandUuid?: string;
}

export interface RunningOrPastGroupsRequest extends BasicPaginatedRequest {
  facilitatorUuids?: string[];
  mainlandUuid: string;
  groupRole: string;
  meetingType: string;
}

export class Groups {
  groupsDateOrdered: GroupsDateOrdered[];
  groupsFacilitatorOrdered: Group[];
  groupsStudyOrdered: GroupsStudyOrdered[];
  multiFilterResponse: MultiFilterGroup[];

  constructor() {
    this.groupsFacilitatorOrdered = [];
    this.groupsDateOrdered = [];
    this.groupsStudyOrdered = [];
    this.multiFilterResponse = [];
  }
}

export class CurrentlyRunningFilter {
  page: number;
  size: number;
  groups: Group[];
  searchText: string;

  constructor() {
    this.page = 0;
    this.size = 5;
    this.searchText = '';
    this.groups = [];
  }
}

export interface ApplyToGroup {
  groupUuid: string;
  timezone: number;
}

export interface AdminApplyUserToGroup {
  userEmail: string;
  groupUuid: string;
  unitUuid: string;
  before3days?: boolean;
  before7days?: boolean;
  freeBooks?: boolean;
  foundationGroup: boolean;
}

export interface GroupSurveyAnswersRequest {
  groupUuid: string;
  userUuid: string;
}

export interface UpdateMeetingAttendantsRequest {
  attendants: string[];
  unitUuid: string;
  meetingUuid: string;
}

export class GroupParticipants {
  groupUuid: string;
  participants: Participant[];
  attendanceSaved: boolean;
  attendanceSubmitted: boolean;

  constructor(groupUuid: string, participants: Participant[], meeting: AdminGroupMeeting) {
    this.groupUuid = groupUuid;
    this.participants = participants;
    this.attendanceSaved = meeting.completed && !meeting.submitted;
    this.attendanceSubmitted = meeting.submitted;
  }
}

export class SearchLocation {
  address: Address;
  groupType: GroupTypes;

  constructor(address: Address, groupType: GroupTypes) {
    this.address = address;
    this.groupType = groupType;
  }
}

export interface SkipMeetingRequest {
  groupUuid: string;
  meetingUuid: string;
}

export interface AlreadyApprovedGroups {
  groupName: string;
  facilitatorsName: string[];
}

export class WeekDay {
  name: string;
  selected: boolean;
  disabled: boolean;

  constructor(dayName: string) {
    this.name = dayName;
    this.selected = false;
    this.disabled = false;
  }
}
