import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminUserService } from 'src/app/_core/api/admin-user.service';
import { ModalActions, Modals } from 'src/app/_core/constants/Modals';
import { ServerError } from 'src/app/_core/constants/ServerErrors';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import { AdminFacilitatorGetDetails, AdminGroupResume } from 'src/app/_core/models/AdminFacilitators';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UserService } from 'src/app/_core/services/user.service';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss'],
})
export class ViewUserComponent implements OnInit, OnDestroy {
  modalRequest: ModalRequest;
  modals = Modals;
  modalActions = ModalActions;
  userDetails: AdminFacilitatorGetDetails;
  breakpointSubscription: Subscription;
  areGroups: boolean;
  isAdmin: boolean;

  // prettier-ignore
  constructor(
    private toastr: ToastrService,
    private modalService: ModalsService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<ViewUserComponent>,
    private adminUserService: AdminUserService,
    private currentUserService: UserService,
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vh')
        : this.dialogRef.updateSize('75vw', '100vh');
    });
  }

  ngOnInit(): void {
    this.isAdmin = this.currentUserService.isAdmin();
    this.getUserDetails();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  getUserDetails(): void {
    this.modalRequest = this.modalService.params;
    this.adminUserService.getUserDetails(this.modalRequest.uuid).subscribe(
      (res: ResponseObject<AdminFacilitatorGetDetails>) => {
        this.userDetails = res.response;
        this.userDetails.groups.approved?.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.userDetails.groups.pending?.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.userDetails.groups.declined?.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.userDetails.groups.withdrawn?.forEach((item: AdminGroupResume) => DateUtils.setLocalTime(item));
        this.areGroups = Object.values(this.userDetails.groups).some((val) => Array.isArray(val) && val.length !== 0);
      },
      (error) => {
        this.close();
        switch (error) {
          case ServerError.USER_NOT_FOUND:
            this.toastr.error(...ToastrMessages.VIEW_USER_NOT_FOUND);
            break;
          default:
            this.toastr.error(...ToastrMessages.BASIC_ERROR);
        }
      }
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  openModal(modalName: Modals, modalAction: ModalActions, payload: any = null): void {
    this.modalService.openModal(modalName, { ...this.modalRequest, scope: modalAction, payload });
  }
}
