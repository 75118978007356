export enum GroupStatus {
  ARCHIVED = 'ARCHIVED',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export enum UnitBuilder {
  CREATE_FIRST_UNIT = 'CREATE_FIRST_UNIT',
  CREATE_ADDITIONAL_UNIT = 'CREATE_ADDITIONAL_UNIT',
  PREFILL_FORM = 'PREFILL_FORM',
}
