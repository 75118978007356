<div class="carousel-container">
  <div class="col-sm-12 px-0">
    <ngx-slick-carousel
      ngxSlickItem
      class="carousel d-flex"
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (init)="slideInit($event)"
      (beforeChange)="beforeChange($event)"
      (click)="handleCarouselClick($event)"
    >
      <div *ngFor="let facilitator of facilitators" class="slide">
        <div
          class="card"
          (click)="emitFacilitator(facilitator)"
          [ngClass]="hasStarted ? 'started-group' : ''"
          [ngClass]="!readOnly && facilitator.uuid === selectedFacilitator?.uuid ? 'selected-facilitator' : ''"
        >
          <div class="d-flex align-items-center">
            <div class="p-2">
              <img
                class="card-img"
                [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
                [lazyLoad]="facilitator.imgUrl"
              />
            </div>
            <div class="card-body px-3 py-2">
              <div class="truncated-single-line">{{ facilitator.firstName }} {{ facilitator.lastName }}</div>
              <div
                class="small-text uppercase negative-margin truncated-single-line"
                [ngClass]="!readOnly && facilitator.uuid === selectedFacilitator?.uuid ? 'text-white' : 'primary-color'"
              >
                {{ facilitator.title }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let church of churches" class="slide">
        <div
          class="card"
          (click)="emitChurch(church)"
          [ngClass]="hasStarted ? 'started-group' : ''"
          [ngClass]="!readOnly && church.uuid === selectedChurch?.uuid ? 'selected-church' : ''"
        >
          <div class="d-flex align-items-center">
            <div class="p-2">
              <img
                class="card-img"
                [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
                [lazyLoad]="church.imageUrl"
              />
            </div>
            <div class="card-body px-3 py-2">
              <div class="truncated-single-line">{{ church.name }}</div>
              <div
                class="small-text uppercase negative-margin truncated-single-line"
                [ngClass]="!readOnly && church.uuid === selectedChurch?.uuid ? 'text-white' : 'primary-color'"
              >
                {{ church.mainland?.state }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
