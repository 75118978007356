<div class="d-flex flex-column justify-content-between h-100 position-relative">
  <div class="position-absolute pointer" style="right: 0" (click)="close()">
    <i class="fas fa-times gray-color pointer"></i>
  </div>
  <div class="text-center">
    <h2 class="font-weight-bold mt-3">Import users</h2>
  </div>

  <div
    *ngIf="!importUsers; else usersTable"
    class="file-drop h-100 drop-normal align-items-center justify-content-center"
    data-name="images"
    ng2FileDrop
    (onFileDrop)="fileChangeEvent($event)"
    [uploader]="uploader"
  >
    <div class="d-flex flex-column align-items-center justify-content-center h-100">
      <i class="fas fa-folder-plus fa-7x mb-4"></i>
      <div *ngIf="!loading; else loadingMessage">
        Drop your file here, or
        <label class="browse pointer"
          >browse
          <input
            class="d-none"
            type="file"
            ng2FileSelect
            placeholder="Upload file"
            [uploader]="uploader"
            (onFileSelected)="fileChangeEvent($event)"
            accept="text/plain,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv"
          />
        </label>
      </div>
      <ng-template #loadingMessage>We are validating the data, please wait...</ng-template>
    </div>
  </div>

  <ng-template #usersTable>
    <div class="table-container">
      <div class="d-flex flex-sm-row flex-column justify-content-sm-between align-items-sm-center mb-4">
        <div class="file-name mb-sm-2">{{ file.file.rawFile.name }}</div>
        <div class="d-flex">
          <ng-container *ngIf="!importUsers.submitted">
            <button mat-button class="black-button mr-3" (click)="removeFile()">Remove File</button>
            <button [disabled]="importUsers.hasInvalidEntries" mat-flat-button color="primary" (click)="submit()">Submit File</button>
          </ng-container>
          <button *ngIf="importUsers.submitted" mat-flat-button color="primary" (click)="close()">Close</button>
        </div>
      </div>

      <app-import-users-table [importUsers]="importUsers"></app-import-users-table>
      <div class="loading-spinner" *ngIf="loading">
        <app-loading-spinner [forceLoading]="loading"></app-loading-spinner>
      </div>
    </div>
  </ng-template>
</div>
