import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminChurchDetails } from '../models/AdminChurches';
import { BasicPaginatedRequestWithSort } from '../models/Shared';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminChurchService {
  private readonly resourceUrl: string = '/v1/church';
  private readonly resourceGroupsUrl: string = '/v1/groups';

  constructor(private apiService: ApiService) {}

  getAdminChurches(body: BasicPaginatedRequestWithSort): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/admin/get-churches`, body);
  }

  getChurch(uuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/${uuid}`);
  }

  createUpdateChurch(body: AdminChurchDetails): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/create-update`, body);
  }

  approveChurch(churchUuid: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/confirm-church/${churchUuid}`);
  }

  deleteChurch(uuid: string): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/${uuid}`);
  }

  getMainlandForPrefill(mainlandName: string, shortCode: string): Observable<any> {
    return this.apiService.get(`${this.resourceGroupsUrl}/search-mainland`, { mainlandName, shortCode });
  }

  searchMainland(searchText: string): Observable<any> {
    return this.apiService.get(`${this.resourceGroupsUrl}/v2/search-mainlands`, { mainlandName: searchText });
  }
}
