import { Component, Input, OnInit } from '@angular/core';
import { EntrepreneurTypes, InvestorTypes } from 'src/app/_core/constants/AdminGroups';
import Utils from '../../../../../_core/helpers/Utils';
import { AdminFacilitatorDetailsUser } from '../../../../../_core/models/AdminFacilitators';

@Component({
  selector: 'app-user-details-section',
  templateUrl: './user-details-section.component.html',
  styleUrls: ['./user-details-section.component.scss'],
})
export class UserDetailsSectionComponent implements OnInit {
  @Input() userInfo: AdminFacilitatorDetailsUser;
  userRoles = { ...EntrepreneurTypes, ...InvestorTypes };
  constructor() {}

  ngOnInit(): void {}

  externalNavigate(value: string): void {
    Utils.navigate(value);
  }

  getAddressString(): string {
    return [this.userInfo?.city, this.userInfo?.stateProvince, this.userInfo?.country]
      .filter((el) => {
        return el != null;
      })
      .join(', ');
  }
}
