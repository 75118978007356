export enum Modals {
  CONFIRM_ACTION,
  CONFIRM_DELETE,
  CONFIRM_ACCREDITED,
  DELETE_SPECIAL_CASE,
  GROUP_DETAILS,
  SELECT_FILTER,
  SELECT_SCHEDULE_FILTER,
  SELECT_USER_MAIN_FILTER,
  SELECT_USER_FILTER,
  SEARCH_REGION,
  USER_MENU,
  GROUP_UPDATE_NOTES,
  VIEW_GROUP,
  VIEW_FACILITATOR,
  CROP_IMAGE,
  VIEW_USER_DETAILS,
  ADD_USER,
  ADD_STUDY,
  VIEW_STUDY,
  MEDIA,
  FDI_STUDY_DISCLAIMER,
  GROUP_ENROLL_SURVEY,
  UPGRADE_USER_CONFIRMATION,
  ADD_NEW_PARTICIPANT,
  GROUP_ATTENDANCE_EDIT,
  GROUP_ATTENDANCE_VIEW,
  SEND_EMAIL,
  EDIT_LOCATION,
  ADD_CHURCH,
  EDIT_CHURCH_REMOTELY,
  REGION_FILTER_MOBILE,
  VIEW_CHURCH,
  APPLY_TO_MONTHLY_GROUP,
  INVESTOR_QUESTIONS,
  CONFIRM_SKIP_MEETING,
  CONFIRM_CREATE_ACCOUNT,
  ALREADY_APPROVED,
  APPLY_TO_IN_PERSON_GROUP,
  MULTI_FILTER,
  EDIT_COHORT,
  EDIT_TAG,
  IMPORT_USERS,
  ADD_FACILITATOR_REMOTELY,
  GROUP_HISTORY,
  ADD_PARTNER_ORGANIZATION,
  EDIT_PARTNER_ORGANIZATION_REMOTELY,
  VIEW_PARTNER_ORGANIZATION,
  MERGE_ACCOUNTS,
}

export const MODALS_STYLES = [
  {
    name: Modals.CONFIRM_ACTION,
    style: {
      width: '580px',
      height: '280px',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.CONFIRM_CREATE_ACCOUNT,
    style: {
      width: '580px',
      height: '280px',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.CONFIRM_DELETE,
    style: {
      width: '580px',
      height: '280px',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.CONFIRM_ACCREDITED,
    style: {
      width: '400px',
      height: 'auto',
      maxWidth: '90vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.CONFIRM_SKIP_MEETING,
    style: {
      width: '580px',
      height: '280px',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.DELETE_SPECIAL_CASE,
    style: {
      width: '580px',
      height: '280px',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.SEND_EMAIL,
    style: {
      width: '580px',
      height: 'auto',
      maxHeight: '95vh',
      autoFocus: false,
    },
  },
  {
    name: Modals.GROUP_DETAILS,
    style: {
      width: '75vw',
      height: '100vh',
      maxWidth: '1150px',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.SELECT_FILTER,
    style: {
      width: '800px',
      height: '100vh',
      maxWidth: '100vh',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.SELECT_SCHEDULE_FILTER,
    style: {
      width: '800px',
      height: '100vh',
      maxWidth: '100vh',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.SELECT_USER_FILTER,
    style: {
      width: '800px',
      height: '100vh',
      maxWidth: '100vh',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.SEARCH_REGION,
    style: {
      width: '800px',
      height: '100vh',
      maxWidth: '100wh',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.USER_MENU,
    style: {
      width: '400px',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.VIEW_GROUP,
    style: {
      width: '75vw',
      height: '100vh',
      maxWidth: '1150px',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.GROUP_HISTORY,
    style: {
      width: '75vw',
      height: '100vh',
      maxWidth: '1150px',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.VIEW_USER_DETAILS,
    style: {
      width: '75vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.ADD_USER,
    style: {
      width: '50vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.VIEW_FACILITATOR,
    style: {
      width: '75vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.CROP_IMAGE,
    style: {
      width: '750px',
      height: '460px',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.ADD_STUDY,
    style: {
      width: '875px',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.VIEW_STUDY,
    style: {
      width: '75vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.MEDIA,
    style: {
      width: '1015px',
      height: 'auto',
      maxHeight: '95vh',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.FDI_STUDY_DISCLAIMER,
    style: {
      width: '750px',
      height: '480px',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.UPGRADE_USER_CONFIRMATION,
    style: {
      width: '660px',
      height: '370px',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.REGION_FILTER_MOBILE,
    style: {
      width: '800px',
      height: '100vh',
      maxWidth: '100vh',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.SELECT_USER_MAIN_FILTER,
    style: {
      width: '800px',
      height: '100vh',
      maxWidth: '100vh',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.ADD_NEW_PARTICIPANT,
    style: {
      width: '580px',
      height: 'auto',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.GROUP_ATTENDANCE_EDIT,
    style: {
      width: '900px',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.GROUP_ATTENDANCE_VIEW,
    style: {
      width: '50vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.EDIT_LOCATION,
    style: {
      width: '50vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.INVESTOR_QUESTIONS,
    style: {
      width: '580px',
      height: 'auto',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.ADD_CHURCH,
    style: {
      width: '50vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.EDIT_CHURCH_REMOTELY,
    style: {
      width: '700px',
      // height: '966px',
      maxHeight: '80vh',
      maxWidth: '98vw',
      autoFocus: false,
      panelClass: 'custom-overhead-dialog-container',
    },
  },
  {
    name: Modals.VIEW_CHURCH,
    style: {
      width: '75vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.ADD_PARTNER_ORGANIZATION,
    style: {
      width: '50vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.VIEW_PARTNER_ORGANIZATION,
    style: {
      width: '75vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.EDIT_PARTNER_ORGANIZATION_REMOTELY,
    style: {
      width: '700px',
      // height: '966px',
      maxHeight: '80vh',
      maxWidth: '98vw',
      autoFocus: false,
      panelClass: 'custom-overhead-dialog-container',
    },
  },
  {
    name: Modals.ADD_FACILITATOR_REMOTELY,
    style: {
      width: '700px',
      height: '966px',
      maxHeight: '80vh',
      maxWidth: '98vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
    },
  },
  {
    name: Modals.ALREADY_APPROVED,
    style: {
      width: '580px',
      height: 'auto',
      maxWidth: '100vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.APPLY_TO_IN_PERSON_GROUP,
    style: {
      width: '580px',
      height: 'auto',
      maxWidth: '90vw',
      autoFocus: false,
    },
  },
  {
    name: Modals.MULTI_FILTER,
    style: {
      width: '800px',
      height: '100vh',
      maxWidth: '100vh',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.EDIT_COHORT,
    style: {
      width: '800px',
      height: '100vh',
      maxWidth: '100vh',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.EDIT_TAG,
    style: {
      width: '800px',
      height: '100vh',
      maxWidth: '100vh',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
  {
    name: Modals.IMPORT_USERS,
    style: {
      width: '480px',
      height: '340px',
      maxWidth: '100vw',
      maxHeight: '90vh',
      autoFocus: false,
    },
  },
  {
    name: Modals.GROUP_UPDATE_NOTES,
    style: {
      width: '600px',
      height: 'auto',
      maxWidth: '90vw',
      maxHeight: '90vh',
      autoFocus: false,
    },
  },
  {
    name: Modals.MERGE_ACCOUNTS,
    style: {
      width: '75vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      position: {
        top: '0px',
        right: '0px',
      },
    },
  },
];

export enum ModalActions {
  CROP,
  MY_PROFILE,
  MY_PROFILE_THEN_TRY_ENROLL,
  CONFIRM_CREATE_ACCOUNT,
  CONFIRM_ACCREDITED,

  // Filters
  OPEN_MULTI_FILTER,
  SELECT_GROUPS_FILTER,
  SELECT_MAIN_FILTER_ADMIN,
  SELECT_USER_FILTER,
  SELECT_USER_MAIN_FILTER,
  SELECT_USER_MAIN_FILTER_ADMIN,
  SELECT_MOBILE_REGION_FILTER,

  // Admin Groups
  ARCHIVE_GROUP,
  RESTORE_GROUP,
  ARCHIVE_GROUP_FROM_VIEW_MODAL,
  RESTORE_GROUP_FROM_VIEW_MODAL,
  ADD_GROUP_UPDATE_NOTES,
  VIEW_GROUP,
  VIEW_GROUP_FROM_MY_GROUPS,
  VIEW_GROUP_APPLY,
  VIEW_GROUP_APPLY_AFTER_PROFILE_COMPLETED,
  ADD_NEW_PARTICIPANT,
  FILL_ATTENDANCE,
  VIEW_ATTENDANCE,
  SEND_EMAIL_DECLINE_PARTICIPANT,
  SEND_EMAIL_APPROVE_PARTICIPANT,
  SEND_EMAIL_NEW_FACILITATOR,
  WITHDRAWN_PARTICIPANT,
  PENDING_PARTICIPANT,
  VIEW_GROUP_HISTORY,

  // Admin Users
  ADD_USER,
  VIEW_USER,
  VIEW_PARTICIPANT_USER,
  DELETE_USER,
  EDIT_USER,
  PROMOTE_TO_FACILITATOR_CONFIRM,
  PROMOTE_TO_FACILITATOR,
  IMPORT_USERS,
  MERGE_ACCOUNTS,

  // Admin Facilitator
  VIEW_FACILITATOR,
  VIEW_PARTICIPANT_FACILITATOR,
  ADD_FACILITATOR,
  EDIT_FACILITATOR,
  DELETE_FACILITATOR,
  DELETE_FACILITATOR_SPECIAL_CASE,
  DOWNGRADE_FACILITATOR_SPECIAL_CASE,
  DOWNGRADE_FACILITATOR,
  UPGRADE_TO_FACILITATOR,
  SKIP_MEETING,
  ADD_FACILITATOR_REMOTELY,
  ADD_CO_FACILITATOR_REMOTELY,

  // Admin Study
  ADD_STUDY,
  EDIT_STUDY,
  VIEW_STUDY,
  DELETE_STUDY,
  DELETE_STUDY_SPECIAL_CASE,

  // Admin Location
  EDIT_LOCATION,

  //Admin Church
  ADD_CHURCH,
  EDIT_CHURCH,
  EDIT_CHURCH_REMOTELY,
  ADD_CHURCH_REMOTELY,

  DELETE_CHURCH,
  DELETE_CHURCH_SPECIAL_CASE,
  VIEW_CHURCH,

  //Admin partner organization
  ADD_PARTNER_ORGANIZATION,
  EDIT_PARTNER_ORGANIZATION,
  ADD_PARTNER_ORGANIZATION_REMOTELY,
  EDIT_PARTNER_ORGANIZATION_REMOTELY,

  DELETE_PARTNER_ORGANIZATION,
  DELETE_PARTNER_ORGANIZATION_SPECIAL_CASE,
  VIEW_PARTNER_ORGANIZATION,

  //Admin Cohort
  EDIT_COHORT,
  DELETE_COHORT,
  DELETE_COHORT_SPECIAL_CASE,

  //Admin Audience
  ADD_AUDIENCE,
  EDIT_AUDIENCE,
  DELETE_AUDIENCE,
  DELETE_AUDIENCE_SPECIAL_CASE,

  //Admin Owners
  ADD_OWNER,
  EDIT_OWNER,
  DELETE_OWNER,
  DELETE_OWNER_SPECIAL_CASE,

  //Admin Language
  ADD_LANGUAGE,
  EDIT_LANGUAGE,
  DELETE_LANGUAGE,
  DELETE_LANGUAGE_SPECIAL_CASE,

  // Pending Groups
  SELECT_REGION,

  // FDI STUDY
  OPEN_DISCLAIMER,

  //Apply to group
  APPLY_TO_GROUP_CONFIRM,
  APPLY_TO_MONTHLY_GROUP,
  FIND_GROUPS,
  ANSWER_INVESTOR_QUESTIONS,
  APPLY_TO_IN_PERSON_GROUP,
  REGISTER_APPLY_TO_IN_PERSON_GROUP,
  ALREADY_APPROVED_FOR_ANOTHER_GROUP,

  RESEND_CONFIRMATION_EMAIL,

  //Admin Industry
  ADD_INDUSTRY,
  EDIT_INDUSTRY,
  DELETE_INDUSTRY,
  DELETE_INDUSTRY_SPECIAL_CASE,
}

export enum ModalEmitActions {
  CROPPED,
  DELETE_CONFIRMED,
  ARCHIVE_GROUP_FROM_VIEW_MODAL,
  RESTORE_GROUP_FROM_VIEW_MODAL,
  CONFIRM_ACCREDITED,

  // Groups
  TRY_ENROLL,

  // Studies
  STUDY_ADDED,
  STUDY_EDITED,
  DELETE_STUDY_SPECIAL_CASE_REQUEST,
  DELETE_STUDY_GO_TO_GROUPS,

  // Users
  PROMOTE_TO_FACILITATOR,
  PROMOTE_TO_FACILITATOR_SUCCESS,
  PROMOTE_TO_LEVEL_TWO,
  ADD_USER_SUCCESS,

  // Facilitators
  DELETE_FACILITATOR_SPECIAL_CASE_REQUEST,
  DOWNGRADE_FACILITATOR_SPECIAL_CASE_REQUEST,
  DOWNGRADE_CONFIRMED,
  RESTORE_GROUP,
  DELETE_FACILITATOR_GO_TO_GROUPS,
  UPGRADE_USER_CONFIRMATION,
  FACILITATOR_UPDATED,
  ADD_FACILITATOR_REMOTELY,

  // Region
  FILTER_REGION_SELECTED,
  LOCATION_IMAGE_UPDATED,

  // Group Requests
  GROUP_REQUEST_APPROVED,
  GROUP_REQUEST_UPDATED,
  GROUP_REQUEST_DECLINED,
  PARTICIPANT_DECLINED_SEND_EMAIL,
  PARTICIPANT_APPROVED_SEND_EMAIL,
  PARTICIPANT_PENDING,
  PARTICIPANT_WITHDRAWN,

  // Manage Groups
  GROUP_ADDED,
  GROUP_EDITED,
  ADD_GROUP_UPDATE_NOTES,
  ADMIN_ADDED_USER_TO_GROUP,
  ATTENDANCE_SAVED,
  ATTENDANCE_SUBMITTED,
  ATTENDANCE_RESET,
  NEW_FACILITATOR_SEND_EMAIL,
  SKIP_MEETING,

  // Manage churches
  CHURCH_ADDED,
  CHURCH_EDITED,
  DELETE_CHURCH_SPECIAL_CASE,
  CHURCH_VERIFIED,

  // Manage partner organization
  PARTNER_ORGANIZATION_ADDED,
  PARTNER_ORGANIZATION_EDITED,
  DELETE_PARTNER_ORGANIZATION_SPECIAL_CASE,
  PARTNER_ORGANIZATION_VERIFIED,

  // Filters
  MAIN_FILTER_SELECTED,
  FILTER_SELECTED,
  USER_FILTER_SELECTED,
  REGION_MOBILE_FILTER_SELECTED,

  // Apply to group
  APPLIED_TO_MONTHLY_GROUP,
  GO_TO_WEEKLY_GROUPS,
  FIND_GROUPS,
  APPLY_TO_IN_PERSON_GROUP,
  REGISTER_APPLY_TO_IN_PERSON_GROUP,
  ALREADY_APPROVED_FOR_ANOTHER_GROUP,
  RESEND_CONFIRMATION_EMAIL,
}
