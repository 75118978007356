<div [ngClass]="{ 'mat-h2': options?.header, 'search-by-header': options?.column }">
  <button
    mat-flat-button
    color="primary"
    class="mat-medium-button mx-2 uppercase"
    *ngIf="options.inPersonGroups && isMobile"
    (click)="searchGroups.emit(groupTypes.IN_PERSON)"
  >
    Go
  </button>
</div>
