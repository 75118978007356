<div class="d-flex flex-column justify-content-between h-100">
  <a class="text-right" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center">
    <h2>{{ modalRequest.payload.message || modalRequest.payload }}</h2>
    <div *ngIf="showWarningMessage">Once you submit this action there is no way around.</div>
  </div>

  <div class="d-flex justify-content-between mt-5">
    <button mat-flat-button class="mat-medium-button" color="primary" [mat-dialog-close]="emit(false)">Cancel</button>
    <button mat-flat-button class="mat-medium-button" [color]="modalRequest.payload.actionButtonColor || 'warn'" [mat-dialog-close]="emit(true)">
      {{ modalRequest.payload.actionButtonText || 'Delete' }}
    </button>
  </div>
</div>
