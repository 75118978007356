import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstUpperCase',
})
export class FirstUpperCasePipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.charAt(0).toUpperCase() + value.substr(1, value.length).toLowerCase() : null;
  }
}
