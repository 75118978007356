import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { FilterType } from 'src/app/_core/constants/Home';
import { ModalActions, ModalEmitActions } from 'src/app/_core/constants/Modals';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import { UsersFilters } from 'src/app/_core/models/AdminUsers';
import { Filter } from 'src/app/_core/models/Filters';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UserDropdownService } from 'src/app/_core/services/user-dropdown.service';
import { SelectFilterComponent } from 'src/app/_shared/_modals/select-filter/select-filter.component';

@Component({
  selector: 'app-select-user-filter',
  templateUrl: './select-user-filter.component.html',
  styleUrls: ['./select-user-filter.component.scss'],
})
export class SelectUserFilterComponent implements OnInit, OnDestroy {
  filter: Filter;
  selectedAll: boolean;
  selections = [];
  filterType = FilterType;
  loading = false;
  dropdownsSubscription: Subscription;
  usersFilters: UsersFilters;
  breakpointSubscription: Subscription;
  startDateOfStudy: string;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private dialogRef: MatDialogRef<SelectFilterComponent>,
    private breakpointObserver: BreakpointObserver,
    private userDropdownsService: UserDropdownService,
  ) {
    this.dropdownsSubscription = this.userDropdownsService.filtersDropdowns$.subscribe((filters: UsersFilters) => {
      this.usersFilters = filters;
    });
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vw')
        : this.dialogRef.updateSize('800px', '100vh');
    });
   }

  ngOnInit(): void {
    this.filter = this.modalService.params.payload;
    if (this.filter.name !== this.filterType.START_DATE_STUDY) {
      this.addItems();
      if (!this.usersFilters.selectAll) {
        this.checkSelections();
      } else {
        this.selectedAll = true;
      }
    } else {
      this.startDateOfStudy = this.filter.searchText;
    }
  }

  ngOnDestroy(): void {
    this.dropdownsSubscription.unsubscribe();
    this.breakpointSubscription.unsubscribe();
  }

  checkSelections(): void {
    this.selectedAll = !this.selections.some((item) => !item.selected);
  }

  applyFilter(): void {
    this.usersFilters.selectAll = this.selectedAll;
    this.selections.forEach((item: any, index: number) => (this.filter.filters[index].selected = item.selected));
    this.usersFilters.markSelectedFilters();
    this.emit();
  }

  emit(): void {
    if (this.filter.name === FilterType.START_DATE_STUDY) {
      this.filter.searchText = this.startDateOfStudy;
      this.filter.misc.selected = this.filter.searchText != '';
    }

    this.modalService.emitResponse(
      new ModalResponse<FilterType>(
        true,
        new ModalRequest<FilterType>(ModalActions.SELECT_USER_FILTER, null, null, this.filter.name),
        ModalEmitActions.USER_FILTER_SELECTED
      )
    );
    this.close();
  }

  selectAll(): void {
    this.selectedAll = !this.selectedAll;
    this.selections.forEach((item) => (item.selected = this.selectedAll));
  }

  onScrollDown(): void {
    if (this.filter.pagination.totalObjects > this.filter.pagination.to && !this.loading) {
      this.loading = true;
      switch (this.filter.name) {
        case FilterType.STUDY:
          this.userDropdownsService.loadStudies().subscribe(() => this.addItems(true));
          break;
        case FilterType.GROUP:
          this.userDropdownsService.loadGroups().subscribe(() => this.addItems(true));
          break;
        case FilterType.CHURCH:
          this.userDropdownsService.loadChurches().subscribe(() => this.addItems(true));
          break;
        default:
          break;
      }
      this.loading = false;
    }
  }

  addItems(checkSelected: boolean = false): void {
    for (let index = this.selections.length; index < this.filter.filters.length; index++) {
      this.selections.push(
        Object.assign({}, { ...this.filter.filters[index], selected: checkSelected ? this.selectedAll : this.filter.filters[index].selected })
      );
    }
  }

  getStartDate(event): void {
    this.startDateOfStudy = DateUtils.formatDateString(event.value);
  }

  close(): void {
    this.modalService.closeModal();
  }
}
