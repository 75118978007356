export class UUIDName {
  uuid: string;
  name: string;

  constructor(uuid: string, name: string) {
    this.uuid = uuid;
    this.name = name;
  }
}

export class UUIDNameRoleType extends UUIDName {
  groupRoleTypes: string[];

  constructor(uuid: string, name: string, groupRoleTypes: string[]) {
    super(uuid, name);
    this.groupRoleTypes = groupRoleTypes;
  }
}

export class UUIDIndex {
  uuid: string;
  index: number;

  constructor(uuid: string, index: number) {
    this.uuid = uuid;
    this.index = index;
  }
}

export interface ResponseObject<T> {
  response: T;
}
