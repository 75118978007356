<div class="d-flex flex-column justify-content-between h-100">
  <a class="text-right" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <h2 class="text-center">Review your changes</h2>
  <mat-tab-group [selectedIndex]="0" [class.hide-tabs]="unitsFormGroups.length < 2">
    <ng-container *ngFor="let unit of unitsFormGroups; index as i">
      <mat-tab [label]="unit.controls.study.value.onboardingStudy ? 'Foundation Study' : 'Ongoing Study'" class="font-17 uppercase w-100">
        <div class="d-flex flex-column w-100">
          <p *ngIf="unit.helperData.showUpdateNotes" class="mb-0 mt-2">
            The changes you made will trigger an update email to the group facilitator and group members. Are you sure you want to make changes?
          </p>
          <ul class="mt-2 mb-0">
            <li>
              <b>Group Name: </b>
              <span class="primary-color">{{ modalRequest.payload.controls.name.value }}</span>
            </li>
            <li>
              <b>Primary Facilitator: </b>
              <span class="primary-color">{{ unit.controls.primaryFacilitator.value.name }}</span>
            </li>
            <li>
              <b>Study: </b>
              <span class="primary-color">{{ unit.controls.study.value.name }}</span>
            </li>
            <li>
              <b>Frequency: </b>
              <span class="primary-color">{{ unit.controls.frequency.value | enumToString | lowercase | titlecase }}</span>
            </li>
            <li>
              <b>Starting Date: </b>
              <span class="primary-color">{{ unit.controls.startDate.value | date }}</span>
            </li>
            <li>
              <b>Ending Date: </b>
              <span class="primary-color">{{ (unit.controls.endDate.value | date) || 'never' }}</span>
            </li>
            <li>
              <b>Starting Time: </b>
              <span class="primary-color">{{ unit.controls.startTime.value }}</span>
            </li>
            <li>
              <b>Ending Time: </b>
              <span class="primary-color">{{ unit.controls.endTime.value }}</span>
            </li>
            <li>
              <b>Timezone: </b>
              <span class="primary-color">{{ unit.controls.timezone.value.timezone }}</span>
            </li>
            <li *ngIf="unit.controls.occurrences.value">
              <b>Occurrences: </b>
              <span class="primary-color">{{ unit.controls.occurrences.value }}</span>
            </li>
            <li>
              <b>Group Type: </b>
              <span class="primary-color">{{ unit.controls.groupType.value | enumToString | lowercase | titlecase }}</span>
            </li>
            <li>
              <b>Participants Allowed: </b>
              <span class="primary-color">{{ unit.controls.participantsAllowed.value }}</span>
            </li>
            <li *ngIf="unit.helperData.coFacilitators">
              <b>Co-facilitators: </b>
              <span class="primary-color">{{ unit.helperData.coFacilitators }}</span>
            </li>
            <li *ngIf="unit.controls.groupType.value !== meetingType.IN_PERSON">
              <b>Meeting Link: </b>
              <span class="primary-color">{{ unit.controls.meetingLink.value }}</span>
            </li>
            <li *ngIf="unit.controls.groupType.value !== meetingType.VIRTUAL">
              <b>Meeting Address: </b>
              <span class="primary-color">{{ unit.controls.location.value.address }}</span>
            </li>
            <li *ngIf="unit.controls.groupType.value !== meetingType.VIRTUAL && unit.controls.locationDetails.value">
              <b>Meeting Address Details: </b>
              <span class="primary-color">{{ unit.controls.locationDetails.value }} </span>
            </li>
          </ul>
          <div *ngIf="unit.helperData.showUpdateNotes" class="admin-textarea">
            <label for="updateNotes" class="primary-color label">Update Notes</label>
            <mat-form-field appearance="outline" class="w-100">
              <textarea rows="3" id="updateNotes" matInput [(ngModel)]="unit.helperData.updateEmailNotes" maxlength="500"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
  <div class="d-flex justify-content-between mt-4">
    <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="close()">Cancel</button>
    <button mat-flat-button class="filter-button uppercase" color="accent" (click)="submit()">Submit</button>
  </div>
</div>
