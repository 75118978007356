import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalEmitActions } from 'src/app/_core/constants/Modals';
import { REGION_TYPES, RegionType } from 'src/app/_core/constants/RegionType';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { RegionsTabService } from 'src/app/_core/services/regions-tab.service';

@Component({
  selector: 'app-select-region-mobile-filter',
  templateUrl: './select-region-mobile-filter.component.html',
  styleUrls: ['./select-region-mobile-filter.component.scss'],
})
export class SelectRegionMobileFilterComponent implements OnInit {
  regionFilters = REGION_TYPES;
  subscription: Subscription = new Subscription();
  selectedRegion: RegionType;
  modalRequest: ModalRequest<RegionType>;

  @Output() selectedRegionEmitter = new EventEmitter<RegionType>();

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private regionsTabService: RegionsTabService,
  ) { }

  ngOnInit(): void {
    this.regionsTabService.currentRegion$.subscribe((region: RegionType) => {
      this.selectedRegion = region;
    });
    this.modalRequest = this.modalService.params;
  }

  close(): void {
    this.modalService.closeModal();
  }

  selectRegion(filter: RegionType) {
    if (filter === this.selectedRegion) {
      this.selectedRegion = null;
      this.regionsTabService.changeRegion(RegionType.ALL);
      this.modalRequest.payload = RegionType.ALL;
      this.emit();
      return;
    }
    this.selectedRegion = filter;
    this.regionsTabService.changeRegion(filter);
    this.modalRequest.payload = filter;
    this.emit();
  }

  emit(): void {
    this.modalService.emitResponse(new ModalResponse(true, this.modalRequest, ModalEmitActions.REGION_MOBILE_FILTER_SELECTED));
    this.close();
  }
}
