import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GroupsFilters } from 'src/app/_core/models/Home';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { GroupDropdownService } from 'src/app/_core/services/group-dropdown.service';
import { FilterType } from '../../../_core/constants/Home';
import { ModalActions, ModalEmitActions } from '../../../_core/constants/Modals';
import { Filter, FilterOption } from '../../../_core/models/Filters';
import { ModalsService } from '../../../_core/services/modals.service';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent implements OnInit, OnDestroy {
  filter: Filter;
  modalRequest: ModalRequest<Filter>;
  selectedAll: boolean;
  selections = [];
  filterType = FilterType;
  loading = false;
  groupsFilters: GroupsFilters;
  scheduleForm: UntypedFormGroup;
  groupRole: string;
  dropdownsSubscription: Subscription;
  breakpointSubscription: Subscription;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private dialogRef: MatDialogRef<SelectFilterComponent>,
    private breakpointObserver: BreakpointObserver,
    private groupDropdownsService: GroupDropdownService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
  ) {
    this.dropdownsSubscription = this.groupDropdownsService.filtersDropdowns$.subscribe((filters: GroupsFilters) => {
      this.groupsFilters = filters;
    });
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vw')
        : this.dialogRef.updateSize('800px', '100vh');
    });
  }

  ngOnInit(): void {
    this.groupRole = this.route.snapshot.routeConfig?.path.toUpperCase();
    this.filter = this.modalService.params.payload;
    this.modalRequest = this.modalService.params;
    this.createForm();
    this.addItems();
    if (!this.filter.selectAll) {
      this.checkSelections();
    } else {
      this.selectedAll = true;
    }
  }

  ngOnDestroy(): void {
    this.dropdownsSubscription.unsubscribe();
    this.breakpointSubscription.unsubscribe();
  }

  createForm(): void {
    this.scheduleForm = this.formBuilder.group({
      startDate: [null],
      day: [null],
      month: [null],
    });
  }

  checkSelections(): void {
    const paginatedFilter: boolean = this.filter.filters.length === this.filter.pagination.totalObjects || !this.filter.pagination?.totalObjects;
    this.selectedAll = paginatedFilter && !this.selections.some((item) => !item.selected);
  }

  applyFilter(): void {
    if (this.filter.name === FilterType.STUDY || this.filter.name === FilterType.FACILITATOR || this.filter.name === FilterType.CHURCH) {
      this.filter.selectAll = this.selectedAll;
    } else {
      this.filter.selectAll = false;
    }

    this.selections.forEach((item: FilterOption, index: number) => {
      this.filter.filters[index].selected = item.selected;
      if (this.filter.selectAll && !item.selected) {
        this.filter.deselected.push(item.uuid);
      }
    });
    this.groupsFilters.checkAnythingSelected();
    this.modalService.emitResponse(
      new ModalResponse<FilterType>(
        true,
        new ModalRequest<FilterType>(ModalActions.SELECT_GROUPS_FILTER, null, null, this.filter.name),
        ModalEmitActions.FILTER_SELECTED
      )
    );
    this.filter.misc.selected = true;
    this.close();
  }

  selectAll(): void {
    this.selectedAll = !this.selectedAll;
    this.selections.forEach((item) => (item.selected = this.selectedAll));
  }

  onScrollDown(): void {
    if (this.filter.pagination.totalObjects > this.filter.pagination.to && !this.loading) {
      this.loading = true;
      switch (this.filter.name) {
        case FilterType.STUDY:
          this.groupDropdownsService.loadStudies(this.groupRole).subscribe(() => this.addItems(true));
          break;
        // case FilterType.FACILITATOR:
        //   this.groupDropdownsService.loadFacilitators(this.groupRole).subscribe(() => this.addItems(true));
        //   break;
        case FilterType.CHURCH:
          this.groupDropdownsService.loadChurches(null, this.groupRole).subscribe(() => this.addItems(true));
          break;
        default:
          break;
      }
      this.loading = false;
    }
  }

  addItems(checkSelected: boolean = false): void {
    for (let index = this.selections.length; index < this.filter.filters.length; index++) {
      this.selections.push(
        Object.assign({}, { ...this.filter.filters[index], selected: checkSelected ? this.selectedAll : this.filter.filters[index].selected })
      );
    }
  }

  close(): void {
    this.modalService.closeModal();
  }

  get startDate(): AbstractControl {
    return this.scheduleForm.get('startDate');
  }

  get day(): AbstractControl {
    return this.scheduleForm.get('day');
  }

  get month(): AbstractControl {
    return this.scheduleForm.get('month');
  }
}
