<div class="d-flex flex-column justify-content-between h-100 position-relative">
  <div class="w-100 py-3 px-5">
    <div class="d-flex justify-content-between">
      <div>
        <h1 class="text-left font-weight-bold">Merge Accounts</h1>
      </div>
      <div>
        <h1 (click)="close()"><i class="fas fa-times gray-color pointer"></i></h1>
      </div>
    </div>

    <div class="content">
      <mat-form-field class="autocomplete-wrapper w-100 mt-3 mb-5 py-3" appearance="outline">
        <div class="d-flex">
          <input type="text" placeholder="Search user" matInput [(ngModel)]="searchText" [matAutocomplete]="auto" (keydown.enter)="searchUsers()" />

          <ng-container *ngIf="isLoading"><mat-spinner diameter="30" mat-icon-button matSuffix></mat-spinner></ng-container>

          <ng-container *ngIf="!isLoading">
            <button class="search-btn d-flex align-items-center justify-content-center" mat-icon-button matSuffix (click)="searchUsers()">
              <mat-icon>search</mat-icon>
            </button>
          </ng-container>
        </div>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChange($event.option.value)">
          <mat-option *ngFor="let user of users" [value]="user"> {{ user.firstName + ' ' + user.lastName }} - {{ user.email }} </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="d-flex">
        <div class="col">
          <h2 class="text-center">Account to Keep:</h2>

          <ng-container *ngIf="mainAccountUser">
            <h4>
              First Name:
              <span>
                <b>{{ mainAccountUser.firstName }}</b>
              </span>
            </h4>

            <h4>
              Last Name:
              <span>
                <b>{{ mainAccountUser.lastName }}</b>
              </span>
            </h4>

            <h4>
              Email:
              <span>
                <b>{{ mainAccountUser.email }}</b>
              </span>
            </h4>

            <h4>
              Job Title:
              <span>
                <b>{{ mainAccountUser.title }}</b>
              </span>
            </h4>

            <h4>
              Role:
              <span>
                <b>{{ mainAccountUser.role }}</b>
              </span>
            </h4>
            <h4>
              Type:
              <span>
                <b>{{ mainAccountUser.userType }}</b>
              </span>
            </h4>
            <h4>
              Phone Number:
              <span>
                <b>{{ mainAccountUser.phoneNumber?.completeNumber }}</b>
              </span>
            </h4>
            <h4>
              Timezone:
              <span>
                <b>{{ mainAccountUser.timezone }}</b>
              </span>
            </h4>
            <h4>
              Location:
              <span>
                <b>{{ mainAccountUser.country }} {{ mainAccountUser.stateProvince }}, {{ mainAccountUser.city }}</b>
              </span>
            </h4>
            <h4>
              LinkedIn:
              <span>
                <b>{{ mainAccountUser.linkedinProfile }}</b>
              </span>
            </h4>
            <h4>
              Bio:
              <span>
                <b>{{ mainAccountUser.description }}</b>
              </span>
            </h4>
          </ng-container>
        </div>

        <div class="col">
          <h2 class="text-center">Account to be Remove:</h2>

          <ng-container *ngIf="toBeRemovedAccountUser">
            <h4>
              First Name:
              <span>
                <b>{{ toBeRemovedAccountUser.firstName }}</b>
              </span>
            </h4>

            <h4>
              Last Name:
              <span>
                <b>{{ toBeRemovedAccountUser.lastName }}</b>
              </span>
            </h4>

            <h4>
              Email:
              <span>
                <b>{{ toBeRemovedAccountUser.email }}</b>
              </span>
            </h4>

            <h4>
              Job Title:
              <span>
                <b>{{ toBeRemovedAccountUser.title }}</b>
              </span>
            </h4>

            <h4>
              Role:
              <span>
                <b>{{ toBeRemovedAccountUser.role }}</b>
              </span>
            </h4>

            <h4>
              Type:
              <span>
                <b>{{ toBeRemovedAccountUser.userType }}</b>
              </span>
            </h4>
            <h4>
              Phone Number:
              <span>
                <b>{{ toBeRemovedAccountUser.phoneNumber?.completeNumber }}</b>
              </span>
            </h4>
            <h4>
              Timezone:
              <span>
                <b>{{ toBeRemovedAccountUser.timezone }}</b>
              </span>
            </h4>
            <h4>
              Location:
              <span>
                <b>{{ toBeRemovedAccountUser.country }} {{ toBeRemovedAccountUser.stateProvince }}, {{ toBeRemovedAccountUser.city }}</b>
              </span>
            </h4>
            <h4>
              LinkedIn:
              <span>
                <b>{{ toBeRemovedAccountUser.linkedinProfile }}</b>
              </span>
            </h4>
            <h4>
              Bio:
              <span>
                <b>{{ toBeRemovedAccountUser.description }}</b>
              </span>
            </h4>
          </ng-container>
        </div>
      </div>

      <div *ngIf="selectedUser" class="w-100 mt-5">
        <div class="bg-warning px-1 px-sm-3 py-2 mb-4 font-weight-bold">
          By enabling an option, you have chosen to retain the information from the "Account to be removed" within your "Account to Keep".
        </div>
        <h2 class="text-center">Choose what gets merged:</h2>

        <form [formGroup]="mergeForm" (ngSubmit)="onMergeSubmit()">
          <div class="d-flex justify-content-center">
            <div class="d-flex flex-column mr-5">
              <mat-slide-toggle formControlName="role">Role</mat-slide-toggle>
              <mat-slide-toggle formControlName="userType">User Type</mat-slide-toggle>
              <mat-slide-toggle formControlName="firstName">First Name</mat-slide-toggle>
              <mat-slide-toggle formControlName="lastName">Last Name</mat-slide-toggle>
              <mat-slide-toggle formControlName="phone">Phone</mat-slide-toggle>
            </div>

            <div class="d-flex flex-column">
              <mat-slide-toggle formControlName="timezone">Timezone</mat-slide-toggle>
              <mat-slide-toggle formControlName="location">Location</mat-slide-toggle>
              <mat-slide-toggle formControlName="linkedinProfile">Linkedin Profile</mat-slide-toggle>
              <mat-slide-toggle formControlName="bio">Bio</mat-slide-toggle>
              <mat-slide-toggle formControlName="title">Title</mat-slide-toggle>
            </div>
          </div>

          <div class="text-center">
            <button type="submit" class="btn btn-danger mt-5">Merge</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="loading-spinner" *ngIf="isLoadingMerge">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
