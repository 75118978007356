<div class="group-card p-3 pt-4">
  <div *ngIf="comingSoon && filter === filterType.CHURCH; else noResultsFound">
    <h2>
      Thank you for your interest in joining an Faith Driven Entrepreneur Group. We're just getting set up with churches leading groups. Here are two
      great options:
    </h2>
    <h2><a class="primary-color">1.</a> Start a group with your church in your area</h2>
    <span class="main-text"
      >We give you all the tools and its easy to get started. Connect with our team
      <a class="primary-color pointer" (click)="navigate('https://www.faithdrivenentrepreneur.org/church')">here</a>.</span
    >
    <div class="d-flex mt-3 mb-3">
      <h2 class="gray-color m-0">OR</h2>
      <mat-divider class="ml-3 w-100 align-self-center"></mat-divider>
    </div>
    <h2><a class="primary-color">2.</a> Join an online group</h2>
    <span class="main-text"
      >While there's not a group specifically for your area, you can join any of these
      <a class="primary-color pointer" (click)="goToGlobalGroups()"> global groups</a> meeting online.</span
    >
  </div>
  <ng-template #noResultsFound>
    <ng-container *ngIf="filter; else other">
      <h2 *ngIf="filter !== filterType.REGION">
        Thank you for your interest in joining an Faith Driven Entrepreneur Group. We’re just getting started and don’t have a group meeting in your
        area. Here are two great options:
      </h2>
      <h2><a class="primary-color">1.</a> Start a group in your area</h2>
      <span class="main-text"
        >We give you all the tools and its easy to get started. Connect with our team
        <a class="primary-color pointer" (click)="navigate('https://www.faithdrivenentrepreneur.org/lead-a-group')">here</a>.</span
      >
      <div class="d-flex mt-3 mb-3">
        <h2 class="gray-color m-0">OR</h2>
        <mat-divider class="ml-3 w-100 align-self-center"></mat-divider>
      </div>
      <h2><a class="primary-color">2.</a> Join an online group</h2>
      <span class="main-text">While there's not a group specifically for your area, you can join any of these global groups meeting online.</span>
    </ng-container>
    <ng-template #other>
      <h2 class="mb-0">{{ message }}</h2>
    </ng-template>
  </ng-template>
</div>
