<ng-container *ngIf="!importUsers.columnErrors.length; else invalidColumns">
  <div class="d-lg-flex d-none pb-3">
    <button mat-button class="white-button mr-3" (click)="expandAll()">Expand All</button>
    <button mat-button class="white-button" (click)="collapseAll()">Collapse All</button>
  </div>

  <div class="row ml-3 d-lg-flex d-none">
    <div class="col-3 font-weight-bold text-gray col-header uppercase" [ngClass]="{ 'col-xl-4 col-l-9 col-5': isUnder1500Px }">Name</div>
    <div class="col-3 font-weight-bold text-gray col-header uppercase" [ngClass]="{ 'col-xl-4 col-5': isUnder1500Px }">Email</div>
    <div class="col-2 font-weight-bold text-gray col-header uppercase d-xl-block d-none">Phone Number</div>
    <div class="font-weight-bold text-gray col-header uppercase d-xxl-block d-none" [ngClass]="importUsers.submitted ? 'col-2' : 'col-3'">
      Location
    </div>
    <div
      class="col-1 font-weight-bold text-gray col-header uppercase"
      [ngClass]="{ 'd-flex justify-content-center': !importUsers.submitted, 'col-2': isUnder1500Px || importUsers.submitted }"
    >
      {{ importUsers.submitted ? 'Status' : 'Validation' }}
    </div>
  </div>

  <mat-card class="w-100 mb-1" [class.invalid]="user.listOfErrorFlags.length" *ngFor="let user of importUsers.users; let i = index">
    <mat-card-content>
      <div class="d-lg-flex d-none align-items-center pt-2" [class.pb-2]="!user.expanded">
        <div class="pointer ml-2 mr-3 pt-1" style="width: 12.5px" (click)="user.expanded = !user.expanded">
          <ng-container *ngIf="user.expanded; else collapsed">
            <i class="fas fa-caret-down font-20"></i>
          </ng-container>
          <ng-template #collapsed>
            <i class="fas fa-caret-right text-gray font-20"></i>
          </ng-template>
        </div>
        <div class="row w-100">
          <div class="col-3 truncated-single-line" [ngClass]="{ 'col-xl-4 col-5': isUnder1500Px }">{{ user.name }}</div>
          <div class="col-3 d-lg-block d-none" [ngClass]="{ 'col-xl-4 col-5': isUnder1500Px }">{{ user.email }}</div>
          <div class="col-2 d-xl-block d-none">{{ user.phoneNumber }}</div>
          <div class="d-xxl-block d-none" [ngClass]="importUsers.submitted ? 'col-2' : 'col-3'">{{ user.address }}</div>
          <ng-container
            *ngTemplateOutlet="validationOrStatus; context: { user: user, isMobile: false, submitted: importUsers.submitted }"
          ></ng-container>
        </div>
      </div>
      <div *ngIf="user.expanded" class="d-flex flex-column py-lg-2 px-lg-0 p-3 mx-lg-3 mt-lg-2 m-0 top-divider">
        <div class="d-flex d-lg-none">
          <span class="font-weight-bold text-gray col-header uppercase mr-2">{{ importUsers.submitted ? 'Status' : 'Validation' }}</span>
          <ng-container
            *ngTemplateOutlet="validationOrStatus; context: { user: user, isMobile: true, submitted: importUsers.submitted }"
          ></ng-container>
        </div>
        <div class="d-block d-lg-none">
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Name</span>
          <span>{{ user.name }}</span>
        </div>
        <div class="d-block d-lg-none">
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Email</span>
          <span>{{ user.email }}</span>
        </div>
        <div class="d-xl-none d-block">
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Phone Number</span>
          <span>{{ user.phoneNumber }}</span>
        </div>
        <div>
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Linkedin Profile</span>
          <span>{{ user.linkedinProfile }}</span>
        </div>
        <div *ngIf="isUnder1500Px">
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Location</span>
          <span>{{ user.address }}</span>
        </div>
        <div>
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Country Code</span>
          <span>{{ user.countryCode }}</span>
        </div>
        <div>
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Coordinates</span>
          <span>{{ user.coordinates }}</span>
        </div>
        <div>
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Timezone</span>
          <span>{{ user.timezone }}</span>
        </div>
        <div>
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Timezone Name</span>
          <span>{{ user.timezoneName }}</span>
        </div>
        <div>
          <span class="font-weight-bold text-gray col-header uppercase mr-2">How did you learn about us?</span>
          <span>{{ user.learnedAbout }}</span>
        </div>
        <div *ngIf="user.groupUuid">
          <span class="font-weight-bold text-gray col-header uppercase mr-2">Group to join</span>
          <span>{{ user.groupName || user.groupUuid }}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <ng-template #validationOrStatus let-user="user" let-isMobile="isMobile" let-submitted="submitted">
    <ng-container *ngIf="submitted; else checkOnly">
      <div [class]="'font-weight-bold ' + user.status.color + '-color'" [class.col-2]="!isMobile">{{ user.status.name }}</div>
    </ng-container>
    <ng-template #checkOnly>
      <div [class]="isMobile ? 'd-flex' : 'col-1 d-flex justify-content-center'" [class.col-2]="isUnder1500Px && !isMobile">
        <div *ngIf="!user.listOfErrorFlags.length" [matMenuTriggerFor]="valid" class="pointer mx-1">
          <i class="fas fa-check-circle font-24 green-color"></i>
        </div>
        <div *ngIf="user.listOfInfoFlags.length" [matMenuTriggerFor]="infos" class="pointer mx-1">
          <i class="fas fa-info-circle font-24 blue-color"></i>
        </div>
        <div *ngIf="user.listOfErrorFlags.length" [matMenuTriggerFor]="errors" class="pointer mx-1">
          <i class="fas fa-times-circle font-24 red-color"></i>
        </div>
      </div>
    </ng-template>
    <mat-menu #infos="matMenu" xPosition="before">
      <div class="px-2 import-message">
        <span class="font-weight-bold">{{ user.listOfInfoFlags.length }} info message(s):</span>
        <ng-container *ngFor="let error of user.listOfInfoFlags">
          <div class="d-flex align-items-center">
            <i class="fas fa-info-circle blue-color mr-2"></i>
            {{ user.infoMessages[error].message }}
          </div>
        </ng-container>
      </div>
    </mat-menu>
    <mat-menu #errors="matMenu" xPosition="before">
      <div class="px-2 import-message">
        <span class="font-weight-bold">This user's data contains {{ user.listOfErrorFlags.length }} error(s):</span>
        <ng-container *ngFor="let error of user.listOfErrorFlags">
          <div class="d-flex align-items-center">
            <i class="fas fa-times-circle red-color mr-2"></i>
            {{ user.errorMessages[error].message }}
          </div>
        </ng-container>
      </div>
    </mat-menu>

    <mat-menu #valid="matMenu" xPosition="before">
      <div class="d-flex align-items-center px-2 import-message">
        <i class="fas fa-check-circle green-color mr-2"></i>
        This user is ready to be imported
      </div>
    </mat-menu>
  </ng-template>
</ng-container>
<ng-template #invalidColumns>
  <ng-container *ngFor="let error of importUsers.columnErrors">
    <div class="d-flex align-items-center"><i class="fas fa-times-circle red-color mr-2"></i>{{ error }}</div>
  </ng-container>
</ng-template>
