import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { Role } from 'src/app/_core/constants/UserEnums';
import Utils from 'src/app/_core/helpers/Utils';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { UserMenu, UserMenuLinks, UserMenuSection } from '../../../_core/constants/Links';
import { ModalActions, Modals } from '../../../_core/constants/Modals';
import { UserLink } from '../../../_core/models/Links';
import { ModalsService } from '../../../_core/services/modals.service';
import { UserService } from '../../../_core/services/user.service';
import { AnalyticsService } from './../../../_core/services/analytics.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  links: UserLink[];
  breakpointSubscription: Subscription;

  // prettier-ignore
  constructor(
    private router: Router,
    private modalService: ModalsService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<UserMenuComponent>,
    private analyticsService: AnalyticsService
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches
        ? this.dialogRef.updateSize('100vw', '100vh')
        : this.dialogRef.updateSize('400px', '100vh');
    });
  }

  ngOnInit(): void {
    this.setLinks();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  setLinks(): void {
    if (this.userService.isAdmin()) {
      if (this.userService.currentUser.facilitator) {
        this.links = UserMenuLinks.filter((item: UserLink) => item.userRole.includes(Role.SUPER_ADMIN) || item.facilitator);
      } else {
        this.links = UserMenuLinks.filter((item: UserLink) => item.userRole.includes(Role.SUPER_ADMIN));
      }
      return;
    }
    if (this.userService.currentUser.facilitator) {
      this.links = UserMenuLinks.filter((item: UserLink) => item.facilitator);
      return;
    }
    this.links = UserMenuLinks.filter((item: UserLink) => item.userRole.includes(Role.USER));
  }

  close(): void {
    this.modalService.closeModal();
  }

  handleMenuAction(item: UserLink): void {
    if (item.disabled) return;
    switch (item.name) {
      case UserMenu.MY_PROFILE:
        this.analyticsService.logClickEvent(AnalyticsEvent.MY_PROFILE);
        this.modalService.openModal(Modals.ADD_USER, new ModalRequest(ModalActions.MY_PROFILE));
        break;
      case UserMenu.SIGN_OUT:
        this.analyticsService.logClickEvent(AnalyticsEvent.SIGN_OUT);
        this.userService.logout();
        this.close();
        break;
      default:
        this.analyticsService.logClickEvent(AnalyticsEvent[item.name]);
        if (item.section === UserMenuSection.FACILITATOR_RESOURCES) {
          Utils.navigate(item.url);
        } else {
          this.router.navigate([item.url]);
        }
        this.close();
        break;
    }
  }
}
