import { Sort } from '@angular/material/sort';
import { GroupRoles } from '../constants/AdminGroups';
import { FilterType } from '../constants/Home';
import { SortValue } from '../constants/SortByOptions';
import { UUIDName } from './GenericObject';
import { FilterMenu } from './Home';
import { Pagination } from './Pagination';

export class Filter<T extends FilterOption = FilterOption, W = undefined> {
  name: FilterType;
  misc: FilterMenu;
  page: number;
  size: number;
  searchText: string;
  filters: Array<T>;
  preloaded: string[];
  deselected: string[];
  selectAll: boolean;
  pagination: Pagination;
  custom: W;
  region: string;

  constructor(name: FilterType, filters: Array<T>, misc = null, custom: W = null, page = 0, size = 10, searchText = '', region = '') {
    this.name = name;
    this.misc = misc;
    this.page = page;
    this.size = size;
    this.searchText = searchText;
    this.pagination = new Pagination();
    this.filters = filters;
    this.preloaded = [];
    this.deselected = [];
    this.custom = custom;
    this.selectAll = false;
    this.region = region;
  }

  indexInFiltersArray(index: number): number {
    return index + this.page * this.size;
  }

  isDeselected(uuid: string): boolean {
    return this.deselected.some((filter) => filter === uuid);
  }

  shouldBeSelected(index: number, uuid: string): boolean {
    const selectAllCase = this.selectAll && !this.isDeselected(uuid);
    return this.preloaded.length > this.indexInFiltersArray(index) || selectAllCase;
  }

  setFilterState(subFilters: string[], selectAll: boolean, deselected: string[]): void {
    this.preloaded = subFilters;
    this.selectAll = selectAll;
    this.deselected = deselected;
  }

  setSubFilters(): void {
    this.filters?.forEach((subFilter: FilterOption) => {
      const selectAllCase = this.selectAll && !this.isDeselected(subFilter.uuid);
      subFilter.selected = this.preloaded.some((uuid) => subFilter.uuid === uuid) || selectAllCase;
    });
  }

  setSubFilter(preloaded: string[]) {
    this.filters.forEach((subFilter: FilterOption) => {
      subFilter.selected = preloaded.some((uuid) => subFilter.uuid === uuid);
    });
  }

  getFiltersUuids(selected: boolean): string[] {
    return this.filters?.filter((filter) => filter.selected === selected).map((filter) => filter.uuid);
  }

  checkAnythingSelected(): boolean {
    return this.filters.some((filter: FilterOption) => filter.selected);
  }

  getSelectedItems(): T[] {
    return this.filters.filter((filter: FilterOption) => filter.selected);
  }

  unselectAllItems(): void {
    this.filters.forEach((filter: FilterOption) => (filter.selected = false));
  }

  clearFilter(): void {
    this.page = 0;
    this.searchText = '';
    this.filters = [];
    this.preloaded = [];
    this.deselected = [];
    this.selectAll = false;
  }

  resetSubFilter(): void {
    this.filters.forEach((element) => (element.selected = false));
  }
}

export class FilterOption extends UUIDName {
  selected: boolean;
  groupRoleTypes?: GroupRoles[];
  type?: string;

  constructor(uuid: string, name: string, selected = false, groupRoleTypes = null, type = null) {
    super(uuid, name);
    this.selected = selected;
    this.groupRoleTypes = groupRoleTypes;
    this.type = type;
  }
}

export class SortByOption<T> {
  field: SortValue | string;
  order: T;

  constructor(field: SortValue | string, order: T) {
    this.field = field;
    this.order = order;
  }
}

export class SortOption<T = 'asc' | 'desc'> extends SortByOption<T> {
  checked: boolean;

  constructor(field: SortValue, order: T, checked = false) {
    super(field, order);
    this.checked = checked;
  }
}

export interface SortByRequest {
  field: string;
  order: string;
}

export class SortBy {
  sortBy: SortOption[];

  constructor() {}

  changeSortByOption(sort: Sort): void {
    const index: number = +sort.active;
    this.deselectPrevious(index);
    this.sortBy[index].order = sort.direction || null;
    this.sortBy[index].checked = !!this.sortBy[index].order;
  }

  private deselectPrevious(index: number): void {
    this.sortBy.forEach((option: SortOption) => {
      if (option.field !== this.sortBy[index].field) {
        option.checked = false;
        option.order = null;
      }
    });
  }
}

export interface SortByRequest {
  field: string;
  order: string;
}
