import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-note-expansion-panel[fieldName]',
  templateUrl: 'note-expansion-panel.component.html',
  styleUrls: ['./note-expansion-panel.component.scss'],
})
export class NoteExpansionPanel {
  @Input() fieldName: string;

  panelExpanded: boolean;
  constructor() {}
}
