export enum GROUP_CATEGORY {
  //OLD TO BE PURGED
  IN_PERSON_REGIONAL = 'IN_PERSON_REGIONAL',
  ONLINE_REGIONAL = 'ONLINE_REGIONAL',
  INDUSTRY = 'INDUSTRY',
  GLOBAL = 'GLOBAL',
  // OTHER_ONLINE = 'OTHER_ONLINE',

  ALPHABETIZED_IN_PERSON = 'ALPHABETIZED_IN_PERSON',
  ALPHABETIZED_ONLINE = 'ALPHABETIZED_ONLINE',

  //NEW
  PROXIMITY_IN_PERSON = 'PROXIMITY_IN_PERSON',
  PROXMITY_ONLINE = 'PROXMITY_ONLINE',
  PROXIMITY_OTHER_ONLINE = 'PROXIMITY_OTHER_ONLINE',

  ALPHABETISED_IN_PERSON = 'ALPHABETISED_IN_PERSON',
  ALPHABETISED_ONLINE = 'ALPHABETISED_ONLINE',
  ALPHABETISED_OTHER_ONLINE = 'ALPHABETISED_OTHER_ONLINE',

  IN_PERSON = 'IN_PERSON',
  ONLINE = 'ONLINE',
  OTHER_ONLINE = 'OTHER_ONLINE',
}
