import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterType } from 'src/app/_core/constants/Home';
import { ModalActions, Modals } from 'src/app/_core/constants/Modals';
import { UsersFilters } from 'src/app/_core/models/AdminUsers';
import { Filter } from 'src/app/_core/models/Filters';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { UserDropdownService } from 'src/app/_core/services/user-dropdown.service';

@Component({
  selector: 'app-select-user-main-filter',
  templateUrl: './select-user-main-filter.component.html',
  styleUrls: ['./select-user-main-filter.component.scss'],
})
export class SelectUserMainFilterComponent implements OnInit, OnDestroy {
  filters: UsersFilters;
  dropdownSubscription: Subscription;

  constructor(private modalService: ModalsService, private userDropdownsService: UserDropdownService) {
    this.dropdownSubscription = this.userDropdownsService.filtersDropdowns$.subscribe((filters: UsersFilters) => {
      this.filters = filters;
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.dropdownSubscription.unsubscribe();
  }

  close(): void {
    this.modalService.closeModal();
  }

  handleFilterClick(filter: Filter): void {
    switch (filter.name) {
      case FilterType.STUDY:
        if (filter.page > 0) {
          this.selectFilter(filter);
        } else {
          this.userDropdownsService.reloadStudies().subscribe(() => this.selectFilter(filter));
        }
        break;
      case FilterType.GROUP:
        if (filter.page > 0) {
          this.selectFilter(filter);
        } else {
          this.userDropdownsService.reloadGroups().subscribe(() => this.selectFilter(filter));
        }
        break;
      case FilterType.CHURCH:
        if (filter.page > 0) {
          this.selectFilter(filter);
        } else {
          this.userDropdownsService.reloadChurches().subscribe(() => this.selectFilter(filter));
        }
        break;
      default:
        this.selectFilter(filter);
        break;
    }
  }

  selectFilter(filter: Filter): void {
    this.openModal<Filter>(Modals.SELECT_USER_FILTER, ModalActions.SELECT_USER_FILTER, null, null, filter);
    this.close();
  }

  openModal<T = void>(modalName: Modals, scope: ModalActions, uuid: string = null, index: number = null, payload: T = null): void {
    this.modalService.openModal(modalName, new ModalRequest<T>(scope, uuid, index, payload));
  }
}
