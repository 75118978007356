<div class="d-flex justify-content-between align-items-center px-4 py-3 bg-gray">
  <h1 class="mb-0">Attendance - Session {{ attendanceInfo.index + 1 }}</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>

<div class="row px-3 px-md-5 mt-4" *ngIf="!loading">
  <div class="col-sm-6">
    <h2 lass="mt-3 mt-sm-0">Present</h2>
    <div
      *ngFor="let user of presetUsers; let i = index"
      class="truncated-single-line"
      data-title="name"
      [title]="user.firstName + ' ' + user.lastName"
    >
      {{ i + 1 + '. ' + user.firstName + ' ' + user.lastName }}
    </div>
  </div>
  <div class="col-sm-6">
    <h2 class="mt-3 mt-sm-0">Missed</h2>
    <div
      *ngFor="let user of missedUsers; let i = index"
      class="truncated-single-line"
      data-title="name"
      [title]="user.firstName + ' ' + user.lastName"
    >
      {{ i + 1 + '. ' + user.firstName + ' ' + user.lastName }}
    </div>
  </div>
</div>
