import { Component, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddUserFacilitatorComponent } from 'src/app/_shared/_modals/add-user-facilitator/add-user-facilitator.component';
import { ConfirmAccreditedComponent } from 'src/app/auth/_helpers/confirm-accredited/confirm-accredited.component';
import { AddChurchComponent } from 'src/app/internal-app/admin/churches/_helpers/add-church/add-church.component';
import { ViewChurchComponent } from 'src/app/internal-app/admin/churches/_helpers/view-church/view-church.component';
import { ViewFacilitatorComponent } from 'src/app/internal-app/admin/facilitators/_helpers/view-facilitator/view-facilitator.component';
import { GroupHistoryComponent } from 'src/app/internal-app/admin/groups/_helpers/group-history/group-history.component';
import { GroupUpdateNotesComponent } from 'src/app/internal-app/admin/groups/_helpers/group-update-notes/group-update-notes.component';
import { ScheduleFilterComponent } from 'src/app/internal-app/admin/groups/_helpers/schedule-filter/schedule-filter.component';
import { AddPartnerOrganizationComponent } from 'src/app/internal-app/admin/partner-organizations/_helpers/add-partner-organization/add-partner-organization.component';
import { ViewPartnerOrganizationComponent } from 'src/app/internal-app/admin/partner-organizations/_helpers/view-partner-organization/view-partner-organization.component';
import { AddStudyComponent } from 'src/app/internal-app/admin/studies/_helpers/add-study/add-study.component';
import { ViewStudyComponent } from 'src/app/internal-app/admin/studies/_helpers/view-study/view-study.component';
import { CohortSetupComponent } from 'src/app/internal-app/admin/tags/_helpers/cohort-setup/cohort-setup.component';
import { TagSetupComponent } from 'src/app/internal-app/admin/tags/_helpers/tag-setup/tag-setup.component';
import { SelectUserFilterComponent } from 'src/app/internal-app/admin/users/_helpers/select-user-filter/select-user-filter.component';
import { SelectUserMainFilterComponent } from 'src/app/internal-app/admin/users/_helpers/select-user-main-filter/select-user-main-filter.component';
import { ViewUserComponent } from 'src/app/internal-app/admin/users/_helpers/view-user/view-user.component';
import { FdiStudyDisclaimerComponent } from 'src/app/internal-app/fdi-study/_helpers/fdi-study-disclaimer/fdi-study-disclaimer.component';
import { MODALS_STYLES, Modals } from '../../../_core/constants/Modals';
import { ModalsService } from '../../../_core/services/modals.service';
import { EditAttendanceComponent } from '../../../internal-app/admin/groups/_helpers/edit-attendance/edit-attendance.component';
import { ViewAttendanceComponent } from '../../../internal-app/admin/groups/_helpers/view-attendance/view-attendance.component';
import { ViewGroupComponent } from '../../../internal-app/admin/groups/_helpers/view-group/view-group.component';
import { UserMenuComponent } from '../../../internal-app/user-account/user-menu/user-menu.component';
import { GroupDetailsComponent } from '../../components/groups/group-details/group-details.component';
import { AddParticipantModalComponent } from '../add-participant-modal/add-participant-modal.component';
import { AlreadyApprovedForGroupComponent } from '../already-approved-for-group/already-approved-for-group.component';
import { ApplyToInPersonGroupComponent } from '../apply-to-in-person-group/apply-to-in-person-group.component';
import { ConfirmCreateAccountComponent } from '../confirm-create-account/confirm-create-account.component';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { ConfirmSkipMeetingComponent } from '../confirm-skip-meeting/confirm-skip-meeting.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { CropModalComponent } from '../crop-modal/crop-modal.component';
import { DeleteSpecialCaseComponent } from '../delete-special-case/delete-special-case.component';
import { EditLocationComponent } from '../edit-location/edit-location.component';
import { ImportUsersModalComponent } from '../import-users-modal/import-users-modal.component';
import { MediaModalComponent } from '../media-modal/media-modal.component';
import { MergeAccountsModalComponent } from '../merge-accounts/merge-accounts.component';
import { MultiFilterModalComponent } from '../multi-filter-modal/multi-filter-modal.component';
import { SelectFilterComponent } from '../select-filter/select-filter.component';
import { SelectRegionMobileFilterComponent } from '../select-region-mobile-filter/select-region-mobile-filter.component';
import { SendEmailComponent } from '../send-email/send-email.component';
import { UpgradeUserConfirmationComponent } from '../upgrade-user-confirmation/upgrade-user-confirmation.component';

@Component({
  selector: 'app-modal-container',
  template: ``,
})
export class ModalContainerComponent implements OnDestroy {
  modalRef: MatDialogRef<any>;
  modalSubscription: Subscription;
  latestModal: Modals;

  constructor(private dialog: MatDialog, private modalService: ModalsService) {
    this.modalSubscription = modalService.modal$.subscribe((response: any) => {
      if (response === 'close') {
        this.closeModal();
        return;
      }
      if (response === 'closeAll') {
        this.dialog.closeAll();
        return;
      }
      this.setModal(response);
      this.modalRef.afterClosed().subscribe((res: any) => {
        this.modalService.emitResponse(res);
      });
    });
  }

  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  setModal(response: any): void {
    this.latestModal = response;
    const style = MODALS_STYLES.find((modal) => modal.name === response).style;
    switch (response) {
      case Modals.DELETE_SPECIAL_CASE:
        this.openModal(DeleteSpecialCaseComponent, style, true, true);
        break;
      case Modals.CONFIRM_DELETE:
        this.openModal(ConfirmDeleteComponent, style, true, true);
        break;
      case Modals.CONFIRM_ACCREDITED:
        this.openModal(ConfirmAccreditedComponent, style, false, true);
        break;
      case Modals.SEND_EMAIL:
        this.openModal(SendEmailComponent, style, true, true);
        break;
      case Modals.GROUP_DETAILS:
        this.openModal(GroupDetailsComponent, style, false);
        break;
      case Modals.SELECT_FILTER:
        this.openModal(SelectFilterComponent, style, true);
        break;
      case Modals.SELECT_SCHEDULE_FILTER:
        this.openModal(ScheduleFilterComponent, style, true);
        break;
      case Modals.SELECT_USER_FILTER:
        this.openModal(SelectUserFilterComponent, style, true);
        break;
      case Modals.SELECT_USER_MAIN_FILTER:
        this.openModal(SelectUserMainFilterComponent, style, true);
        break;
      case Modals.USER_MENU:
        this.openModal(UserMenuComponent, style, false);
        break;
      case Modals.GROUP_UPDATE_NOTES:
        this.openModal(GroupUpdateNotesComponent, style, true, true);
        break;
      case Modals.VIEW_GROUP:
        this.openModal(ViewGroupComponent, style, true, true);
        break;
      case Modals.GROUP_HISTORY:
        this.openModal(GroupHistoryComponent, style, true, true);
        break;
      case Modals.ADD_USER:
        this.openModal(AddUserFacilitatorComponent, style, true);
        break;
      case Modals.VIEW_FACILITATOR:
        this.openModal(ViewFacilitatorComponent, style, true, true);
        break;
      case Modals.CROP_IMAGE:
        this.openModal(CropModalComponent, style, false, true);
        break;
      case Modals.VIEW_USER_DETAILS:
        this.openModal(ViewUserComponent, style, true, true);
        break;
      case Modals.ADD_STUDY:
        this.openModal(AddStudyComponent, style, true);
        break;
      case Modals.VIEW_STUDY:
        this.openModal(ViewStudyComponent, style, true);
        break;
      case Modals.MEDIA:
        this.openModal(MediaModalComponent, style, false, true);
        break;
      case Modals.FDI_STUDY_DISCLAIMER:
        this.openModal(FdiStudyDisclaimerComponent, style, true, true);
        break;
      case Modals.UPGRADE_USER_CONFIRMATION:
        this.openModal(UpgradeUserConfirmationComponent, style, true, true);
        break;
      case Modals.ADD_NEW_PARTICIPANT:
        this.openModal(AddParticipantModalComponent, style, true, true);
        break;
      case Modals.GROUP_ATTENDANCE_EDIT:
        this.openModal(EditAttendanceComponent, style, true, true);
        break;
      case Modals.GROUP_ATTENDANCE_VIEW:
        this.openModal(ViewAttendanceComponent, style, true, true);
        break;
      case Modals.EDIT_LOCATION:
        this.openModal(EditLocationComponent, style, true, true);
        break;
      case Modals.ADD_FACILITATOR_REMOTELY:
        this.openModal(AddUserFacilitatorComponent, style, true, true);
        break;
      case Modals.ADD_CHURCH:
        this.openModal(AddChurchComponent, style, true);
        break;
      case Modals.VIEW_CHURCH:
        this.openModal(ViewChurchComponent, style, true, true);
        break;
      case Modals.EDIT_CHURCH_REMOTELY:
        this.openModal(AddChurchComponent, style, true, true);
        break;
      case Modals.ADD_PARTNER_ORGANIZATION:
        this.openModal(AddPartnerOrganizationComponent, style, true);
        break;
      case Modals.VIEW_PARTNER_ORGANIZATION:
        this.openModal(ViewPartnerOrganizationComponent, style, true, true);
        break;
      case Modals.EDIT_PARTNER_ORGANIZATION_REMOTELY:
        this.openModal(AddPartnerOrganizationComponent, style, true, true);
        break;
      case Modals.REGION_FILTER_MOBILE:
        this.openModal(SelectRegionMobileFilterComponent, style, true);
        break;
      case Modals.CONFIRM_SKIP_MEETING:
        this.openModal(ConfirmSkipMeetingComponent, style, true, true);
        break;
      case Modals.CONFIRM_CREATE_ACCOUNT:
        this.openModal(ConfirmCreateAccountComponent, style, true, true);
        break;
      case Modals.ALREADY_APPROVED:
        this.openModal(AlreadyApprovedForGroupComponent, style, true, true);
        break;
      case Modals.APPLY_TO_IN_PERSON_GROUP:
        this.openModal(ApplyToInPersonGroupComponent, style, true, true);
        break;
      case Modals.MULTI_FILTER:
        this.openModal(MultiFilterModalComponent, style, true);
        break;
      case Modals.EDIT_COHORT:
        this.openModal(CohortSetupComponent, style, true);
        break;
      case Modals.EDIT_TAG:
        this.openModal(TagSetupComponent, style, true);
        break;
      case Modals.CONFIRM_ACTION:
        this.openModal(ConfirmationModalComponent, style, true, true);
        break;
      case Modals.IMPORT_USERS:
        this.openModal(ImportUsersModalComponent, style, true, true);
        break;
      case Modals.MERGE_ACCOUNTS:
        this.openModal(MergeAccountsModalComponent, style, true, true);
        break;
      default:
        break;
    }
  }

  openModal(modalComponent, style, disableClose: boolean = false, cancel: boolean = false): void {
    if (this.dialog.openDialogs.length && !cancel) {
      this.dialog.closeAll();
      this.dialog.afterAllClosed
        .pipe(take(1))
        .subscribe(() => (this.modalRef = this.dialog.open(modalComponent, { ...style, disableClose, closeOnNavigation: false })));
    } else {
      this.modalRef = this.dialog.open(modalComponent, { ...style, disableClose, data: this.latestModal });
      this.checkForDublicate();
    }
  }

  closeModal(): void {
    this.modalRef.close();
  }

  checkForDublicate(): void {
    let duplicateRemoved = false;
    this.dialog.openDialogs.forEach((openModal) => {
      if (duplicateRemoved) return;
      const duplicates = this.dialog.openDialogs.filter(
        (modal) => modal._containerInstance._config.data === openModal._containerInstance._config.data
      );
      if (duplicates.length > 1) {
        openModal.close();
        duplicateRemoved = true;
      }
    });
  }
}
