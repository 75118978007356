<div class="d-flex justify-content-between align-items-center px-4 py-3 bg-gray">
  <h1 class="mb-0">Select your {{ filter.misc?.secondaryText || filter.misc.text }}</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>
<ng-container *ngIf="filter.name !== filterType.START_DATE_STUDY; else startDateFilter">
  <div class="d-flex justify-content-end mr-5 pr-4 mt-3">
    <u class="primary-color font-weight-bold pointer" (click)="selectAll()">{{ selectedAll ? 'Deselect All' : 'Select All' }}</u>
  </div>
  <div class="px-1 px-sm-3 pt-2 position-relative">
    <div
      class="container d-flex flex-column"
      infiniteScroll
      (scrolled)="onScrollDown()"
      [infiniteScrollContainer]="'.mat-dialog-container'"
      [fromRoot]="true"
    >
      <div *ngFor="let item of selections; let i = index">
        <mat-checkbox class="mr-2" [(ngModel)]="item.selected">
          <div *ngIf="filter.name !== filterType.GROUP; else filterTypeGroup" class="d-flex align-items-center">
            <img
              *ngIf="filter.name === filterType.STUDY"
              class="study-image"
              [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
              [lazyLoad]="item.profileImgUrl || item.imgUrl"
            />
            <div class="ml-2 p-1">
              <h2 class="mb-0 truncated-single-line">{{ item.name | titlecase }}</h2>
              <div class="primary-color text-uppercase font-weight-bold truncated-single-line">{{ item.title || item.author }}</div>
            </div>
          </div>
          <ng-template #filterTypeGroup>
            <div class="">
              <h2 class="m-0 truncated-single-line">{{ item.name }}</h2>
              <div class="m-0 mt-2 d-flex align-items-baseline">
                <i class="fas fa-calendar-alt primary-color mr-2"></i>
                <div class="d-flex align-items-center">
                  <span class="text-sm-nowrap">{{ item.startsOn | date: 'MMMM dd, yyyy' }}</span>
                  <i class="fas fa-arrow-right gray-color px-2"></i>
                  <span class="text-sm-nowrap">{{ item.endsOn | date: 'MMMM dd, yyyy' }}</span>
                </div>
              </div>
              <div>
                <i class="fas fa-clock primary-color mr-2"></i>
                {{ item.startsOn | date: 'HH:mm' }}
              </div>
              <div>
                <i class="fas fa-user primary-color mr-2"></i>
                Facilitated by: <span class="font-weight-bold">{{ item.facilitators?.join(', ') }}</span>
              </div>
            </div>
          </ng-template>
        </mat-checkbox>
        <mat-divider *ngIf="i !== selections.length - 1" class="my-3"></mat-divider>
      </div>
    </div>
    <div class="sticky-div position-sticky">
      <mat-divider class="mt-3"></mat-divider>
      <div class="py-3 px-3">
        <button mat-flat-button color="primary" class="mat-medium-button" (click)="applyFilter()">Apply Filter</button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #startDateFilter>
  <div class="d-flex justify-content-end mr-5 pr-4 mt-3">
    <u class="primary-color font-weight-bold pointer" (click)="startDateOfStudy = ''">Reset</u>
  </div>
  <div class="admin-input w-50 ml-3 mb-3">
    <label for="startDate" class="label">Starting Date <span class="primary-color">*</span></label>
    <mat-form-field appearance="outline" class="w-100 date-input">
      <input
        id="startDate"
        placeholder="MM/DD/YYYY"
        matInput
        required
        readonly
        [matDatepicker]="startDatePicker"
        (click)="startDatePicker.open()"
        (dateChange)="getStartDate($event)"
        [(ngModel)]="startDateOfStudy"
      />
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #startDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="sticky-div position-sticky">
    <mat-divider class="mt-3"></mat-divider>
    <div class="py-3 px-3">
      <button mat-flat-button color="primary" class="mat-medium-button" (click)="emit()">Apply Filter</button>
    </div>
  </div>
</ng-template>
