import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { GroupFormat } from 'src/app/_core/constants/AdminGroups';
import { ModalEmitActions } from 'src/app/_core/constants/Modals';
import { ModalRequest, ModalResponse } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-confirm-skip-meeting',
  templateUrl: './confirm-skip-meeting.component.html',
  styleUrls: ['./confirm-skip-meeting.component.scss'],
})
export class ConfirmSkipMeetingComponent implements OnInit {
  breakpointSubscription: Subscription;
  modalRequest: ModalRequest;
  skipMeetingTitle: string;
  skipMeetingDescription: string;
  actionButtonText: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    private modalService: ModalsService
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches ? this.dialogRef.updateSize('100vw', 'auto') : this.dialogRef.updateSize('580px', '280px');
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
    this.skipMeetingTitle = this.modalService.params.payload.text;
    this.generateSkipActionButtonText();
    this.generateDescription();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  generateSkipActionButtonText(): void {
    this.actionButtonText = 'Yes, skip this ' + this.getMeetingType();
  }

  generateDescription(): void {
    this.skipMeetingDescription = 'Once you skip this ' + this.getMeetingType() + ", you can't undo this action.";
  }

  getMeetingType(): string {
    return this.modalService.params.payload.meetingType === GroupFormat.SINGLE ? 'event' : 'session';
  }

  close(): void {
    this.modalService.closeModal();
  }

  emit(confirm: boolean): ModalResponse {
    if (confirm) {
      return new ModalResponse(confirm, this.modalRequest, ModalEmitActions.SKIP_MEETING);
    }
  }
}
