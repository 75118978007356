<div class="d-flex flex-column justify-content-between h-100">
  <a class="text-right" (click)="emit(false)"><i class="fas fa-times gray-color pointer"></i></a>

  <ng-container *ngIf="this.modalRequest.scope === modalActions.SEND_EMAIL_DECLINE_PARTICIPANT">
    <div class="text-center">
      <h2>Are you sure you want to move {{ participant.firstName }} {{ participant.lastName }} to DECLINED PARTICIPANTS?</h2>
    </div>

    <div *ngIf="participant.prevType !== participantTypes.APPROVED" class="d-flex flex-column justify-content-center">
      <mat-checkbox
        [disabled]="participant.declinedEmailOption && participant.declinedEmailOption !== declinedEmailOptions.NO_SPOTS_LEFT"
        class="mr-2"
        color="primary"
        #sendDeclineFullEmail
        (change)="handleDeclinedEmail($event.checked, declinedEmailOptions.NO_SPOTS_LEFT)"
      >
        <div class="ml-2 p-1"><p class="mb-0">Declined Email - Full Group</p></div>
      </mat-checkbox>
      <mat-checkbox
        [disabled]="participant.declinedEmailOption && participant.declinedEmailOption !== declinedEmailOptions.TOO_MANY_GROUPS"
        class="mr-2"
        color="primary"
        #sendDeclineApprovedEmail
        (change)="handleDeclinedEmail($event.checked, declinedEmailOptions.TOO_MANY_GROUPS)"
      >
        <div class="ml-2 p-1"><p class="mb-0">Declined Email - Already Approved for Another Group</p></div>
      </mat-checkbox>
      <mat-checkbox
        [disabled]="participant.declinedEmailOption && participant.declinedEmailOption !== declinedEmailOptions.DIFFERENT_LOCATION"
        class="mr-2"
        color="primary"
        #sendRedirectEmail
        (change)="handleDeclinedEmail($event.checked, declinedEmailOptions.DIFFERENT_LOCATION)"
      >
        <div class="ml-2 p-1"><p class="mb-0">Redirect Email - Wrong Location</p></div>
      </mat-checkbox>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="emit(false)">Cancel</button>
      <button mat-flat-button class="filter-button uppercase" color="warn" (click)="emitDeclineParticipants()">Yes</button>
    </div>
  </ng-container>

  <ng-container *ngIf="this.modalRequest.scope === modalActions.SEND_EMAIL_APPROVE_PARTICIPANT">
    <div class="text-center">
      <h3 *ngIf="periodOfTime != ''">
        This user is being approved for this group less than {{ periodOfTime }} before the selected group starts. Select the checkbox for the
        automated emails you would like them to receive, then click <strong>Approve</strong>.
      </h3>
      <div class="font-italic mb-2">If you don't want to send the automated email, leave the box unchecked.</div>
    </div>

    <div class="d-flex flex-column align-items-start ml-3">
      <mat-checkbox *ngIf="participant.hasOwnProperty('before7days')" class="mr-2" color="primary" [(ngModel)]="participant.before7days">
        <div class="ml-2 p-1">
          <p class="mb-0">"Video Access, Google Calendar invite, and group details" Email</p>
        </div>
      </mat-checkbox>
      <mat-checkbox *ngIf="participant.hasOwnProperty('before3days')" class="mr-2" color="primary" [(ngModel)]="participant.before3days">
        <div class="ml-2 p-1">
          <p class="mb-0">"Reminder - 3 Days Before Start" Email</p>
        </div>
      </mat-checkbox>
      <mat-checkbox
        *ngIf="participant.hasOwnProperty('freeBooks') && modalRequest.payload.groupFormat === groupFormats.WEEKLY"
        class="mr-2"
        color="primary"
        [(ngModel)]="participant.freeBooks"
      >
        <div class="ml-2 p-1">
          <p class="d-flex flex-wrap mb-0">"Free E-Book" Email</p>
        </div>
      </mat-checkbox>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="emit(false)">Cancel</button>
      <button mat-flat-button class="filter-button uppercase" color="accent" (click)="emit(true)">Approve</button>
    </div>
  </ng-container>

  <ng-container *ngIf="this.modalRequest.scope === modalActions.SEND_EMAIL_NEW_FACILITATOR">
    <div class="text-center">
      <h3>
        {{ mutipleNewFacilitators() ? 'These facilitators are' : 'This facilitator is' }} being added to lead this group before the selected group
        starts. Select the checkbox for the automated emails you would like them to receive, then click <strong>Approve</strong>.
      </h3>
      <div class="font-italic mb-2">If you don't want to send the automated email, leave the box unchecked.</div>
    </div>

    <div *ngFor="let unit of groupUnits" class="ml-3">
      <ng-container *ngFor="let facilitator of unit.selectedFacilitators">
        <ng-container *ngIf="facilitator.newFacilitator && soonerThan(28, unit.startDate)">
          <div>{{ facilitator.name }}</div>
          <div class="d-flex flex-column align-items-start ml-3">
            <mat-checkbox *ngIf="soonerThan(14, unit.startDate)" class="mr-2" color="primary" [(ngModel)]="facilitator.sendEmail">
              <div class="ml-2 p-1">
                <p class="mb-0">"2 weeks before" Email</p>
              </div>
            </mat-checkbox>
            <mat-checkbox *ngIf="soonerThan(28, unit.startDate)" class="mr-2" color="primary" [(ngModel)]="facilitator.sendFreeBookEmail">
              <div class="ml-2 p-1">
                <p class="mb-0">"Free E-Book" Email</p>
              </div>
            </mat-checkbox>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="emit(false)">Cancel</button>
      <button mat-flat-button class="filter-button uppercase" color="accent" (click)="emit(true)">Approve</button>
    </div>
  </ng-container>

  <ng-container *ngIf="modalRequest.scope === modalActions.PENDING_PARTICIPANT || modalRequest.scope === modalActions.WITHDRAWN_PARTICIPANT">
    <div class="text-center">
      <h2 *ngIf="!isCancelApplicationAction">
        Are you sure you want to move {{ participant.firstName }} {{ participant.lastName }} to
        {{ modalRequest.scope === modalActions.PENDING_PARTICIPANT ? 'PENDING' : 'WITHDRAWN' }} PARTICIPANTS?
      </h2>
      <h2 *ngIf="isCancelApplicationAction">
        Are you sure you want to remove {{ participant.firstName }} {{ participant.lastName }} from PENDING PARTICIPANTS?
      </h2>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="emit(false)">Cancel</button>
      <button mat-flat-button class="filter-button uppercase" color="accent" (click)="emit(true)">
        {{ modalRequest.scope === modalActions.WITHDRAWN_PARTICIPANT ? 'Yes' : 'Approve' }}
      </button>
    </div>
  </ng-container>
</div>
