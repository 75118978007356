import { FileType } from '../constants/FileType';

export class File {
  uuid: string;
  name: string;
  path: string;
  fileType: FileType;
  cover: boolean;

  constructor(uuid: string, name: string, path: string, fileType: FileType, cover: boolean = false) {
    this.uuid = uuid;
    this.name = name;
    this.path = path;
    this.fileType = fileType;
    this.cover = cover;
  }
}

export class FileDetails extends File {
  pendingUpload: number = 0;
  isFormatValid: boolean = true;
  progress: number = 0;

  constructor(uuid: string, name: string, path: string, fileType: FileType) {
    super(uuid, name, path, fileType);
  }
}
