import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-confirm-create-account',
  templateUrl: './confirm-create-account.component.html',
  styleUrls: ['./confirm-create-account.component.scss'],
})
export class ConfirmCreateAccountComponent implements OnInit {
  breakpointSubscription: Subscription;
  modalRequest: ModalRequest;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    private modalService: ModalsService
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 767px)').subscribe((result: BreakpointState) => {
      result.matches ? this.dialogRef.updateSize('100vw', 'auto') : this.dialogRef.updateSize('580px', '280px');
    });
  }

  ngOnInit(): void {
    this.modalRequest = this.modalService.params;
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  close(): void {
    window.localStorage.removeItem('userFirstRegister');
    this.modalService.closeModal();
  }
}
