<div class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
  <h1 class="mb-0">Group History</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>
<!-- 
<div *ngIf="groupHistory">
  <app-history-card [historyItems]="groupHistory"></app-history-card>
</div> -->

<!-- [class.hide-tabs]="churchDetails.historyList.length === 0 || churchDetails.groups.length === 0"
[selectedIndex]="churchDetails.groups.length > 0 ? 0 : 1" -->
<mat-tab-group mat-stretch-tabs>
  <ng-container>
    <mat-tab *ngIf="groupHistory?.length > 0" label="General Group Details" class="font-20">
      <div class="custom-container w-100">
        <!-- <ng-container> -->
        <!-- <h1 *ngIf="churchDetails.historyList.length === 0" class="primary-color">Church's groups</h1> -->
        <app-history-card [historyItems]="groupHistory"></app-history-card>
        <!-- </ng-container> -->
      </div>
    </mat-tab>
    <ng-container *ngFor="let unit of unitsHistory; let idx = index">
      <mat-tab *ngIf="unit?.length" [label]="this.modalRequest.payload.units[idx].onboardingStudy ? 'Foundation study' : 'Ongoing study'">
        <div class="custom-container w-100">
          <ng-container>
            <!-- <h1 *ngIf="churchDetails.historyList.length === 0" class="primary-color">Church's groups</h1> -->
            <app-history-card [historyItems]="unit"></app-history-card>
          </ng-container>
        </div>
      </mat-tab>
    </ng-container>
  </ng-container>
</mat-tab-group>
