export const ValidationMessages = {
  // add-group
  name: [
    { type: 'required', message: 'Name of the Group is required' },
    { type: 'maxlength', message: 'Group Name cannot be longer than 35 characters' },
  ],
  language: [{ type: 'required', message: 'Primary language is required' }],
  languages: [{ type: 'required', message: 'Group language is required' }],
  startDate: [
    { type: 'required', message: 'Starting Date is required' },
    { type: 'matDatepickerMin', message: 'Starting Date for this unit is before the end date from previous unit' },
  ],
  eventStartDate: [{ type: 'required', message: 'Starting Date is required' }],
  endDate: [
    { type: 'required', message: 'Ending Date is required' },
    { type: 'matDatepickerMin', message: 'Ending Date must be after Starting Date' },
  ],
  startTime: [
    { type: 'required', message: 'Starting Time is required' },
    { type: 'startTimeBefore', message: 'Starting Time needs to be after current time' },
  ],
  endTime: [{ type: 'required', message: 'Ending Time is required' }],
  openUntilDate: [
    { type: 'required', message: 'Open Until Date is required' },
    { type: 'matDatepickerMax', message: 'Open Until Date cannot be after Ending Date' },
  ],
  eventStartTime: [{ type: 'required', message: 'Starting Time is required' }],
  eventEndTime: [{ type: 'required', message: 'Ending Time is required' }],
  time: [{ type: 'endTime', message: 'Ending Time needs to be after Starting Time' }],
  event: [
    { type: 'startTimeBefore', message: 'Starting Time needs to be after current time' },
    { type: 'endTime', message: 'Ending Time needs to be after Starting Time' },
  ],
  study: [{ type: 'required', message: 'Study is required' }],
  primaryFacilitator: [{ type: 'required', message: 'Primary facilitator is required' }],
  companySize: [{ type: 'required', message: 'Company size is required' }],
  mainlands: [{ type: 'required', message: 'Region & State is required' }],
  meetingLink: [
    { type: 'required', message: 'Meeting Link is required' },
    { type: 'invalidWebsite', message: 'Meeting Link is invalid' },
  ],
  participantsAllowed: [
    { type: 'required', message: 'Participants Allowed is required' },
    { type: 'min', message: 'Participants Allowed number cannot be lower than approved users count' },
    { type: 'invalid', message: 'Participants Allowed must be a number' },
  ],
  interval: [
    { type: 'required', message: 'Interval is required' },
    { type: 'min', message: 'Interval value cannot be lower than 1' },
    { type: 'max', message: 'Interval value cannot be higher than 9' },
  ],
  meetingDaysNames: [{ type: 'required', message: 'Repeat on is required' }],
  ends: [{ type: 'required', message: 'Select one of the three options' }],
  groupState: [{ type: 'required', message: 'Group State is required' }],
  groupType: [{ type: 'required', message: 'Group Type is required' }],
  groupFrequency: [{ type: 'required', message: 'Group Frequency is required' }],
  groupRole: [{ type: 'required', message: 'Group Role is required' }],
  recurring: [{ type: 'required', message: 'Recurring period is required' }],
  postalCode: [{ type: 'required', message: 'Postal code is required' }],
  cohort: [{ type: 'required', message: 'Cohort is required' }],
  audiences: [{ type: 'required', message: 'Audience is required' }],
  groupUnits: [{ type: 'duplicateControlValueInArray', message: 'End date cannot be the same in both units' }],
  // Auth module validation messages
  firstName: [
    { type: 'required', message: 'First Name is required' },
    { type: 'noNameFormat', message: 'First Name must contain only letters' },
    { type: 'maxlength', message: 'First Name must be less than 50 characters long' },
  ],
  lastName: [
    { type: 'required', message: 'Last Name is required' },
    { type: 'noNameFormat', message: 'Last Name must contain only letters' },
    { type: 'maxlength', message: 'Last Name must be less than 50 characters long' },
  ],
  email: [
    { type: 'required', message: 'Email is required' },
    { type: 'email', message: 'Email is invalid' },
    { type: 'maxlength', message: 'Email must be less than 255 characters long' },
    { type: 'alreadyInserted', message: 'Email is already present in the form' },
    { type: 'alreadyInvited', message: 'Email already has an invitation sent' },
    {
      type: 'alreadyUsed',
      message:
        'Email is already used on another account. Try “Forgot Password” to reset your password or <a href="mailto:groups@faithdrivenentrepreneur.org" class="primary-color">contact our team<a/>',
    },
    {
      type: 'onboardingStudyNotReached',
      message: 'This participant needs to complete the FDE or FDI Study first. Please add him to that group first.',
    },
    {
      type: 'alreadyApprovedToFoundationGroup',
      message:
        "This participant is already approved to another Foundation Group. If you really want to add this user to this group please press 'Add participant' again.",
    },
    { type: 'alreadyExists', message: 'This participant is already added to the group.' },
    { type: 'groupNotFound', message: 'The group you tried to apply to was not found.' },
    { type: 'maximumApplicationsReached', message: 'The user reached the maximum applications count and could not be added to the group' },
    { type: 'groupAlreadyFull', message: 'This group is already full' },
    { type: 'alreadyFacilitator', message: 'This participant is the facilitator of the group.' },
    { type: 'doesNotExists', message: 'No account found with this email. Please add new user before adding participant to a group.' },
    { type: 'groupEnded', message: 'This group has ended. You cannot add new participants to the group.' },
    {
      type: 'timezoneNotFound',
      message: "This user has an incomplete profile: timezone missing . Please complete the user's profile before adding to the group",
    },
    {
      type: 'investorQuestionsNotAnswered',
      message:
        "This user has an incomplete profile: accredited investor questions are missing . Please complete the user's profile before adding to the group",
    },
  ],
  repeatEmail: [{ type: 'confEmail', message: 'Your email and confirmation email do not match' }],
  country: [{ type: 'required', message: 'Country is required' }],
  stateProvince: [{ type: 'required', message: 'State/Province is required' }],
  city: [{ type: 'required', message: 'City is required' }],
  contactPhone: [
    { type: 'required', message: 'Phone is required' },
    { type: 'invalidPhone', message: 'Phone is not valid' },
    { type: 'maxlength', message: 'Phone must be less than 10 characters' },
  ],
  password: [
    { type: 'required', message: 'Password is required' },
    { type: 'password', message: 'Password must include at least one lowercase, one uppercase, one symbol and one digit' },
    { type: 'whitespace', message: 'Password cannot be empty' },
    { type: 'minlength', message: 'Password must be at least 8 characters long' },
  ],
  repeatPassword: [{ type: 'confPass', message: 'Your password and confirmation password do not match' }],
  userType: [{ type: 'required', message: 'You must select one option' }],
  organizationName: [
    { type: 'required', message: 'Organization Name is required' },
    { type: 'maxlength', message: 'Organization Name must be less than 100 characters long' },
  ],
  areTermsAccepted: [{ type: 'required', message: 'Please agree to the terms of use and privacy policy to register your account.' }],
  recaptcha: [
    { type: 'required', message: 'Recaptcha is required' },
    { type: 'invalid', message: 'Recaptcha is invalid' },
  ],

  //Add/Edit User/Facilitator
  jobTitle: [
    { type: 'required', message: 'Job title is required' },
    { type: 'maxlength', message: 'Job title must be less than 255 characters long' },
  ],
  description: [
    { type: 'required', message: 'Description is required' },
    { type: 'maxlength', message: 'Description must be less than 500 characters long' },
  ],
  studyDescription: [
    { type: 'required', message: 'Description is required' },
    { type: 'maxlength', message: 'Description must be less than 700 characters long' },
  ],
  phoneNumber: [
    { type: 'required', message: 'Phone number is required' },
    { type: 'validatePhoneNumber', message: 'The phone number format is invalid' },
  ],
  linkedinProfile: [
    { type: 'required', message: 'LinkedIn profile is required' },
    { type: 'invalidWebsite', message: 'LinkedIn profile is invalid' },
    { type: 'maxlength', message: 'LinkedIn profile must be less than 1000 characters long' },
  ],
  // Add / Edit Study
  studyName: [{ type: 'required', message: 'Study name is required' }],
  author: [{ type: 'required', message: 'Author name is required' }],
  groupRoleTypes: [{ type: 'required', message: 'Role is required' }],
  studyType: [{ type: 'required', message: 'Type is required' }],
  nrOfSession: [
    { type: 'required', message: 'Number of sessions is required' },
    { type: 'invalid', message: 'Number of sessions must be a number' },
    { type: 'max', message: 'Number of sessions value cannot be higher than 100' },
  ],
  studyPictureUuid: [{ type: 'required', message: 'Study image is required' }],
  trailerLink: [
    { type: 'required', message: 'Trailer Link is required' },
    { type: 'invalidWebsite', message: 'Trailer Link is invalid' },
    { type: 'linkToEmbed', message: 'Only source links from www.rightnowmedia.org are accepted.' },
  ],
  timezone: [{ type: 'required', message: 'Timezone is required' }],
  headline: [{ type: 'maxlength', message: 'Headline must be less than 150 characters long' }],
  // create-upate cohort
  year: [{ type: 'required', message: 'Year is required' }],
  type: [{ type: 'required', message: 'Type is required' }],
  month: [{ type: 'required', message: 'Month is required' }],
  hubspotGroupName: [{ type: 'required', message: 'Hubspot Group Name is required' }],
  hubspotGroupStatus: [{ type: 'required', message: 'Hubspot Group Status is required' }],

  //Church form
  churchPictureUrl: [{ type: 'required', message: 'Church picture is required' }],
  churchName: [{ type: 'required', message: 'Church name is required' }],
  mainland: [
    { type: 'required', message: 'Church location is required' },
    { type: 'invalidMainland', message: 'Please select a valid region' },
  ],
  website: [
    { type: 'required', message: 'Church website is required' },
    { type: 'maxlength', message: 'Church website must be less than 255 characters long' },
    { type: 'invalidWebsite', message: 'Please add a valid website' },
  ],
  visible: [{ type: 'required', message: 'Church visibility is required' }],

  partnerOrganizationName: [{ type: 'required', message: 'Partner organization name is required' }],
  partnerOrganizationPictureUrl: [{ type: 'required', message: 'Partner organization picture is required' }],
  partnerOrganizationWebsite: [{ type: 'required', message: 'Partner organization website is required' }],
  partnerOrganizationMainland: [{ type: 'required', message: 'Partner organization region & location is required' }],

  // Group tags
  tagFirstName: [{ type: 'required', message: 'Name is required' }],
  tagLastName: [{ type: 'required', message: 'Last name is required' }],
  tagIndustryType: [{ type: 'required', message: 'Industry type is required' }],
};
