export const ToastrMessages = {
  BASIC_ERROR: ['There was a problem with the server, please try again later'],

  NO_TRAILER_LINK: ["This study doesn't have a trailer just yet."],

  COPIED_TO_CLIPBOARD: ['Copied to clipboard!'],

  EXPIRED_SESSION: ['Please sign in again', 'Expired session'],
  ERROR_MAX_FILE_SIZE: ['File exceeds the maximum file size permitted'],
  NOT_FOUND: ['Resource not found'],
  INVALID_FORMAT: ['File has an invalid format'],
  LOAD_IMG_IN_CROPPER_ERROR: ['There was an error loading the uploaded image in the crop tool'],

  // Auth
  CAPTCHA_INVALID: ['Captcha is invalid'],
  USER_NOT_FOUND: ['The email address you entered doesn\'t match any account. Please click "Register" to sign up for an account.'],
  USER_UNVERIFIED: ['You must first verify your account before signing in'],
  BAD_CREDENTIALS: ['Invalid email address or password'],
  EMAIL_RESENT: ['A confirmation email was successfully sent'],
  ACCOUNT_CREATED: ['Your account has been successfully created'],
  EMAIL_USED: ['There is another account with this email'],
  INCOMPLETE_FORM: ['You must complete all the fields before submitting'],
  INVALID_PASSWORD_FORMAT: ['Password must be at least 8 characters long'],
  NEW_PASSWORD_SAME_AS_OLD: [
    "Please choose a password that you haven't used before. For your account protection, " +
      'you need to choose a new password every time you reset it.',
  ],
  TOKEN_NOT_FOUND: [
    'This link is no longer available, please fill in your email address so that a new email to reset your password can be sent to you.',
  ],
  FORGOT_PASSWORD: ['An email has been sent with instructions for you to reset your password'],
  RESET_PASSWORD: ['Password was successfully reset'],
  LINK_EXPIRED: ['The link you clicked is no longer valid. Check for a newer email with the newer link.'],
  CHECK_USER_EMAIL_ERROR: ['Something went wrong, please try again later'],
  INVALID_EMAIL: ['Email is invalid'],
  CHAT_LOGIN_FAILED: ['Chat login has failed'],
  NOTIFICATIONS_ERROR: ['Could not receive chat notifications, please try again later'],
  USER_TYPE_OR_SUBTYPE: ['User type or subtype not selected. Please select an option.'],

  // App
  ACCOUNT_UPDATED: ['Account has been successfully updated'],

  GROUP_NOT_FOUND: ['The requested group was not found'],
  APPLY_FOR_GROUP_SUCCESS: [
    "Wooohooo! 🎉 You just successfully submitted your application for this group. Please check your email inbox for a confirmation email. If you don't see it in your inbox, please check your spam or junk folder. Thank you so much!",
  ],
  FACILITATOR_APPLY_FOR_HIS_GROUP: ['You are the facilitator of this group. You cannot apply as a participant'],
  GROUP_ENDED: ['This group already ended'],
  ADMIN_APPLY_USER_FOR_GROUP_SUCCESS: ['The user was successfully added to the group'],
  REMOVE_FROM_GROUP_SUCCESS: ['You have successfully removed yourself from the pending list!'],
  GROUP_DELETED: ['The group was successfully deleted'],
  GROUP_ARCHIVED: ['The group was successfully archived'],
  GROUP_RESTORED: ['The group was successfully restored. You can find it under Group Requests'],
  GROUP_DELETED_ERROR: ['The group could not be deleted'],
  GROUP_ARCHIVE_ERROR: ['The group could not be archived'],
  GROUP_RESTORE_ERROR: ['The group could not be restored'],
  HANDLE_PARTICIPANT: ['Participant was successfully moved'],
  HANDLE_PARTICIPANT_ERROR: ['Participant could not be moved'],
  NO_MAINLAND_FOR_GROUP: ['The group has no location'],
  NO_CHANGES: ['There are no changes to save'],
  UNIT_1_NO_END_DATE: ['Unit 1 does not have an end date'],
  CREATE_GROUP: ['The group was successfully created'],
  PENDING_GROUP: ['The group was successfully requested'],
  GROUP_REQUEST_UPDATED: ['The group request was successfully updated'],
  GROUP_REQUEST_APPROVED: ['The group request was successfully approved'],
  GROUP_REQUEST_DECLINED: ['The group request was declined'],
  PAERICIPANT_DECLINED_ENUM_NOT_FOUND: ['Please select a reason for declining the participant.'],
  CREATE_GROUP_ERROR: ['There was a problem creating this group'],
  GROUP_CREATED_BY_FACILITATOR: ['You request for leading a group has been sent. An admin will soon review your request'],
  UPDATE_GROUP: ['The group was successfully updated'],
  UPDATE_GROUP_ERROR: ['There was a problem updating this group'],
  APPROVED_USERS_COUNT_BIGGER_THAN_MAX_PARTICIPANTS: ['Participants Allowed number cannot be lower than approved users count'],
  FACILITATOR_ALREADY_PARTICIPANT: ['A newly added facilitator is already a participant on this group'],
  PRIMARY_FACILITATOR_NOT_ADDED: ['A primary facilitator was not added to the group.'],
  OPEN_UNTIL_DATE_GREATER_THAN_END_DATE_ERROR: ['Open until date cannot be grater than end date.'],
  CANNOT_MODIFY_UNIT_START_DATE_FOR_UNITS_WITH_SUBMITTED_MEETINGS: ['Cannot change start date due to submitted attendance for a group session.'],
  ATTENDANCE: ['Attendance has been successfully updated'],
  ATTENDANCE_RESET: ['Attendance has been successfully reset'],
  ADMIN_GROUP_NO_SPOTS_LEFT: [
    'The group has reached the maximum number of participants allowed. To increase number of participants, please edit the group.',
  ],
  SKIP_MEETING_SUCCESS: ['Meeting has been successfully skipped'],
  SKIP_MEETING_ERROR: ['There was a problem with skipping this meeting. Please try again later'],
  UNIT1_CANNOT_END_AFTER_UNIT2_STARTS: ['Meeting cannot be skipped because the unit cannot be extended over the next unit.'],
  EMAIL_SETTINGS_UPDATED: ['The email settings were updated successfully'],

  VIEW_USER_NOT_FOUND: ['Requested user was not found'],
  USER_INVITED: ['User invitation was successfully sent'],
  USER_UPDATED: ['User was successfully updated'],
  USER_DELETED: ['User was successfully deleted'],
  USER_DELETED_ERROR: ['User could not bet deleted'],
  USER_PROMOTED_TO_LEVEL_2: ['User was successfully promoted to Level 2'],
  USER_LEVEL_2: ['User is Level 2 already'],
  CALCULATE_END_DATE_ERROR: ['There was a problem when calculating the end date'],

  FACILITATOR_INVITED: ['Facilitator invitation was successfully sent'],
  FACILITATOR_ADDED: ['User was successfully promoted to a facilitator'],
  FACILITATOR_UPDATED: ['Facilitator was successfully updated'],
  FACILITATOR_DELETED: ['Facilitator was successfully deleted'],
  FACILITATOR_DELETED_ERROR: ['Facilitator could not be deleted'],
  FACILITATOR_DOWNGRADE_SUCCESS: ['Facilitator was successfully downgraded to user'],
  FACILITATOR_DOWNGRADE_ERROR: ['Facilitator could not be downgraded'],
  FACILITATOR_NOT_FOUND: ['Requested facilitator was not found'],
  FACILITATORS_LIST_ERROR: ['The list of facilitators could not be retrieved'],

  STUDY_DELETED: ['Study was successfully deleted'],
  STUDY_DELETED_ERROR: ['Study could not be deleted'],
  STUDY_NOT_FOUND: ['Requested study was not found'],
  STUDY_SUCCESSFULLY_ADDED: ['Study was successfully added'],
  STUDY_SUCCESSFULLY_EDITED: ['Study was successfully edited'],

  MISSING_REQUIRED_FIELDS: ['Please fill in all the required fields'],

  GROUP_REQUEST_ACCEPTED: ['Group request was accepted'],
  GROUP_REQUEST_DELETED: ['Group request was deleted'],
  GROUP_ALREADY_APPLIED: ['You have already applied for this group'],

  INVITE_INVALID_NAME: ['The name format is invalid. Only a-Z characters and dashes are allowed'],
  INVITE_INVALID_NAME_LENGTH: ['First Name and Last Name cannot have more than 50 characters each'],
  INVITE_INVALID_PHONE: ['The phone number format is invalid'],
  INVITE_ALREADY_INVITED: ['The requested user already has a pending invitation'],
  INVITE_EXISTING_FACILITATOR: ['A facilitator with this email is already registered on the platform'],
  INVITE_EXISTING_USER: ['A user with this email is already registered on the platform'],

  DOCUMENT_DOWNLOAD: ['File was successfully downloaded'],
  ERROR_DOCUMENT_DOWNLOAD: ['File could not be downloaded'],
  DATE_CANNOT_BE_LOWER_THAN_CURRENT: ['Meeting time cannot be set in the past of the current time'],
  TIMEZONES_REQUEST_ERROR: ['The timezones could not be loaded'],
  TIMEZONES_NOT_FOUND_ERROR: ["The profile doesn't have a timezone. Please go to My Profile and set a timezone."],
  GROUP_ALREADY_FULL_ERROR: ['This group is already full.'],
  MAXIMUM_APPLICATIONS_REACHED_ERROR: [
    'It looks like you have applied for 3 other groups already. If you want to apply for more groups, please email us at groups@faithdrivenentrepreneur.org.',
  ],
  PREVIOUS_MEETING_NOT_SUBMITTED: ['The attendance for the previous meeting was not submited'],
  MEETING_NOT_SUBMITTED_OR_SAVED: ['The attendance for this meeting has not been submitted or saved'],
  MEETING_ALREADY_SUBMITTED: ['The attendance for this meeting was already submitted'],
  SYNCHRONIZE_HUBSPOT: ['Hubspot was successfully synchronized'],
  HUBSPOT_SYNC_IN_PROGRESS: ['Hubspot synchronization in progress. You will receive an email notification with the results'],
  HUBSPOT_ERROR: ['There was a problem with the Hubspot synchronization'],
  HUBSPOT_SYNC_RUNNING: ['Hubspot synchronization is already in progress'],

  CHURCH_SUCCESSFULLY_EDITED: ['Church was successfully edited'],
  CHURCH_SUCCESSFULLY_ADDED: ['Church was successfully added'],
  CHURCH_DELETED: ['Church was successfully deleted'],
  CHURCH_DELETED_ERROR: ['Church could not be deleted'],
  CHURCH_CANT_BE_DELETED: ['Church cannot be deleted because it is assigned to a group'],
  CHURCH_APPROVED: ['The church has been approved successfully'],
  CHURCH_APPROVAL_FAILED: ['The church could not be approved'],

  PARTNER_ORGANIZATION_SUCCESSFULLY_EDITED: ['Partner organization was successfully edited'],
  PARTNER_ORGANIZATION_SUCCESSFULLY_ADDED: ['Partner organization was successfully added'],
  PARTNER_ORGANIZATION_DELETED: ['Partner organization was successfully deleted'],
  PARTNER_ORGANIZATION_DELETED_ERROR: ['Partner organization could not be deleted'],
  PARTNER_ORGANIZATION_CANT_BE_DELETED: ['Partner organization cannot be deleted because it is assigned to a group'],
  PARTNER_ORGANIZATION_APPROVED: ['The partner organization has been approved successfully'],
  PARTNER_ORGANIZATION_APPROVAL_FAILED: ['The partner organization could not be approved'],

  COHORT_REQUEST_ERROR: ['The cohorts could not be loaded'],
  COHORT_CREATED: ['The cohort has been created'],
  COHORT_UPDATED: ['The cohort has been updated'],
  COHORT_DELETED: ['The cohort has been deleted'],
  COHORT_ALREADY_EXISTS: ['There is already a cohort with the same information'],
  WITHDRAW_SUCCESS: ['You have successfully withdrawn from this group'],
  WITHDRAW_ERROR: ['Could not withdraw from this group, please try again later'],

  // Tags
  AUDIENCE_ADDED: ['Group Audience has been created'],
  AUDIENCE_UPDATED: ['Group Audience has been updated'],
  AUDIENCE_DELETED: ['Group Audience has been deleted'],
  DUPLICATED_AUDIENCE: ['There is already a group audience with that name'],
  OWNER_ADDED: ['Group Owner has been created'],
  OWNER_UPDATED: ['Group Owner has been updated'],
  OWNER_DELETED: ['Group Owner has been deleted'],
  DUPLICATED_OWNER: ['There is already a group owner with that name'],
  AUDIENCE_NOT_FOUND: ['We could not find the selected audience'],
  OWNER_NOT_FOUND: ['We could not find the selected owner'],
  LANGUAGE_ADDED: ['New language has been added'],
  LANGUAGE_UPDATED: ['Language has been updated'],
  LANGUAGE_DELETED: ['Language has been deleted'],
  DUPLICATED_LANGUAGE: ['There is already a language with that name'],
  LANGUAGE_NOT_FOUND: ['We could not find the selected language'],
  INDUSTRY_ADDED: ['New industry has been added'],
  INDUSTRY_UPDATED: ['Industry has been updated'],
  INDUSTRY_DELETED: ['Industry has been deleted'],
  DUPLICATED_INDUSTRY: ['There is already a industry with that name'],
  INDUSTRY_NOT_FOUND: ['We could not find the selected industry'],

  //Accredited Investor questions
  ACCREDITED_INVESTOR_NOT_CHECKED: ['You must check if you are an Accredited Investor'],
  ACCREDITED_INVESTOR_QUESTIONS_ANSWERED: ['Accredited Investor questions where successfully answered'],
  GOOGLE_PLACES_NOT_FOUND: ['Please search by CITY or ZIP CODE to find groups near you.'],
};
