import { FileType } from '../constants/FileType';
import { ModalActions, ModalEmitActions } from '../constants/Modals';

export class ModalRequest<T = void> {
  uuid: string;
  index: number;
  scope: ModalActions;
  payload: T;

  constructor(scope: ModalActions, uuid: string = null, index: number = null, payload: T = null) {
    this.uuid = uuid;
    this.index = index;
    this.scope = scope;
    this.payload = payload;
  }
}

export class ModalResponse<T = void> {
  confirmed: boolean;
  modalRequest: ModalRequest<T>;
  modalEmitAction: ModalEmitActions;

  constructor(confirmed: boolean, modalRequest: ModalRequest<T> = null, modalEmitAction: ModalEmitActions = null) {
    this.modalRequest = modalRequest;
    this.confirmed = confirmed;
    this.modalEmitAction = modalEmitAction;
  }
}

export class ModalFileResponse<T = void> extends ModalResponse<T> {
  payload: string;
  fileType: FileType;
  target: string;
  index: number;

  constructor(
    confirmed: boolean,
    modalRequest: ModalRequest<T>,
    modalEmitAction: ModalEmitActions,
    payload: string,
    fileType: FileType,
    target: string,
    index: number
  ) {
    super(confirmed, modalRequest, modalEmitAction);
    this.payload = payload;
    this.fileType = fileType;
    this.target = target;
    this.index = index;
  }
}

export class MediaModel {
  url: string;
  label: string;
  fileType: FileType;

  constructor(url: string, label: string, fileType: FileType) {
    this.url = url;
    this.label = label;
    this.fileType = fileType;
  }
}
