import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import StorageHelper from './_core/helpers/Storage.helper';
import { AnalyticsService } from './_core/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // prettier-ignore
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private analytics: AnalyticsService
  ) {
    this.router.events.subscribe((val: NavigationEnd) => {
      if(val?.url && val instanceof NavigationEnd) {
        this.analytics.trackScreen(val.url);
      }
    })
  }

  ngOnInit(): void {
    if (!StorageHelper.getToken() && StorageHelper.getSelectedGroupUuid() && !this.activatedRoute.snapshot.queryParams.reason) {
      StorageHelper.clearSelectedGroupUuid();
    }
  }
}
