import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { AnalyticsEvent } from 'src/app/_core/constants/AnalyticsEvents';
import { GroupsFilters } from 'src/app/_core/models/Home';
import { AnalyticsService } from 'src/app/_core/services/analytics.service';
import { GroupsComponent } from 'src/app/internal-app/groups/groups/groups.component';
import { AdminGroupService } from '../../../_core/api/admin-group.service';
import { SearchObject } from '../../../_core/constants/AdminGroups';
import { UUIDName } from '../../../_core/models/GenericObject';
import { MultiFilter, MultiFilterItem } from './multi-filter.model';

@Injectable()
export class MultiFilterFormManagerService {
  multiFilterForm: UntypedFormGroup;
  multiFilterObject: MultiFilter;
  subscription: Subscription = new Subscription();
  liveSearchEmitor: Subject<MultiFilterItem> = new Subject<MultiFilterItem>();

  searchResultsChurches: Observable<UUIDName[]>;
  searchResultsFacilitators: Observable<UUIDName[]>;
  searchResultsStudies: Observable<UUIDName[]>;
  searchResultsAudiences: UUIDName[];
  searchResultsLanguage: UUIDName[];
  searchResultsOwners: UUIDName[];
  searchResultsCompanySizes: UUIDName[];

  // prettier-ignore
  constructor(
    private formBuilder: UntypedFormBuilder,
    private adminGroupService: AdminGroupService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute
  ) {}

  initForm(multiFilter: MultiFilter, dropdowns: GroupsFilters): void {
    this.multiFilterObject = multiFilter;
    this.multiFilterForm = this.formBuilder.group({
      advancedSearch: [false],
      searchChurches: [],
      searchFacilitators: [],
      searchStudies: [],
      searchAudiences: [],
      searchLanguages: [],
      searchOwners: [],
      searchCompanySizes: [],
      industryAffinity: [],
      companySize: [],
    });

    this.churchLiveSearch();
    this.facilitatorLiveSearch();
    this.studyLiveSearch();
    this.resetAdvancedSearch();
    this.audienceLiveSearch(dropdowns);
    this.languageLiveSearch(dropdowns);
    this.ownersLiveSearch(dropdowns);
    this.companySizeLiveSearch(dropdowns);
  }

  prefillAdvancedSearch() {
    this.multiFilterForm.patchValue({
      advancedSearch: [true],
    });
  }

  //Church live search and select
  churchLiveSearch(): void {
    this.searchResultsChurches = this.searchChurches.valueChanges.pipe(
      filter((value) => typeof value === 'string' && value.trim().length > 0),
      debounceTime(500),
      switchMap((value: string) =>
        this.adminGroupService.searchStudyFacilitatorOrChurch(SearchObject.CHURCH, value).pipe(catchError((err) => of(err)))
      ),
      map((value) => value.response)
    ) as Observable<UUIDName[]>;
  }

  selectedChurch(event: MatAutocompleteSelectedEvent, inputChurchRef: HTMLInputElement): void {
    this.multiFilterObject.churches.setSelected(event.option.value.uuid, event.option.value.name);
    inputChurchRef.value = '';
    this.logAnalyticsEvent(AnalyticsEvent.churches);
    this.churchLiveSearch();
    this.liveSearchEmitor.next(this.multiFilterObject.churches);
  }

  //Facilitator live search
  facilitatorLiveSearch(): void {
    this.searchResultsFacilitators = this.searchFacilitators.valueChanges.pipe(
      filter((value) => typeof value === 'string' && value.trim().length > 0),
      debounceTime(500),
      switchMap((value: string) =>
        this.adminGroupService.searchStudyFacilitatorOrChurch(SearchObject.FACILITATOR, value).pipe(catchError((err) => of(err)))
      ),
      map((value) => value.response)
    ) as Observable<UUIDName[]>;
  }

  selectedFacilitator(event: MatAutocompleteSelectedEvent, inputFacilitatorRef: HTMLInputElement): void {
    this.multiFilterObject.facilitators.setSelected(event.option.value.uuid, event.option.value.name);
    inputFacilitatorRef.value = '';
    this.logAnalyticsEvent(AnalyticsEvent.facilitator);
    this.facilitatorLiveSearch();
    this.liveSearchEmitor.next(this.multiFilterObject.facilitators);
  }

  //Study live search
  studyLiveSearch(): void {
    this.searchResultsStudies = this.searchStudies.valueChanges.pipe(
      filter((value) => typeof value === 'string' && value.trim().length > 0),
      debounceTime(500),
      switchMap((value: string) =>
        this.adminGroupService.searchStudyFacilitatorOrChurch(SearchObject.STUDY, value).pipe(catchError((err) => of(err)))
      ),
      map((value) => value.response)
    ) as Observable<UUIDName[]>;
  }

  selectedStudy(event: MatAutocompleteSelectedEvent, inputStudyRef: HTMLInputElement): void {
    this.multiFilterObject.studies.setSelected(event.option.value.uuid, event.option.value.name);
    inputStudyRef.value = '';
    this.logAnalyticsEvent(AnalyticsEvent.study);
    this.studyLiveSearch();
    this.liveSearchEmitor.next(this.multiFilterObject.studies);
  }

  //Audience live search
  audienceLiveSearch(dropdowns: GroupsFilters): void {
    this.searchAudiences.valueChanges.pipe(filter((value) => typeof value === 'string')).subscribe((text: string) => {
      this.searchResultsAudiences = dropdowns.audience.filters.filter((option) => option.name.toLowerCase().indexOf(text?.toLowerCase()) >= 0);
    });
  }
  selectedAudience(event: MatAutocompleteSelectedEvent): void {
    this.multiFilterObject.groupAudience.setSelected(event.option.value.uuid, event.option.value.name);
    this.liveSearchEmitor.next(this.multiFilterObject.groupAudience);
    this.searchResultsAudiences = [];
  }

  //Language live search
  languageLiveSearch(dropdowns: GroupsFilters): void {
    this.searchLanguages.valueChanges.pipe(filter((value) => typeof value === 'string')).subscribe((text: string) => {
      this.searchResultsLanguage = dropdowns.language.filters.filter((option) => option.name.toLowerCase().indexOf(text?.toLowerCase()) >= 0);
    });
  }
  selectedLanguage(event: MatAutocompleteSelectedEvent): void {
    this.logAnalyticsEvent(AnalyticsEvent.groupLanguage);
    this.multiFilterObject.groupLanguage.setSelected(event.option.value.uuid, event.option.value.name);
    this.liveSearchEmitor.next(this.multiFilterObject.groupLanguage);
    this.searchResultsLanguage = [];
  }

  //Owners live search
  ownersLiveSearch(dropdowns: GroupsFilters): void {
    this.searchOwners.valueChanges.pipe(filter((value) => typeof value === 'string')).subscribe((text: string) => {
      this.searchResultsOwners = dropdowns.owners.filters.filter((option) => option.name.toLowerCase().indexOf(text.toLowerCase()) >= 0);
    });
  }
  selectedOwner(event: MatAutocompleteSelectedEvent): void {
    this.multiFilterObject.groupOwners.setSelected(event.option.value.uuid, event.option.value.name);
    this.liveSearchEmitor.next(this.multiFilterObject.groupOwners);
    this.searchResultsOwners = [];
  }

  //Company Size live search
  companySizeLiveSearch(dropdowns: GroupsFilters): void {
    this.searchCompanySizes.valueChanges.pipe(filter((value) => typeof value === 'string')).subscribe((text: string) => {
      this.searchResultsCompanySizes = dropdowns.companySize.filters.filter((option) => option.name.toLowerCase().indexOf(text?.toLowerCase()) >= 0);
    });
  }

  selectedCompanySize(event: MatAutocompleteSelectedEvent): void {
    this.logAnalyticsEvent(AnalyticsEvent.companySize);
    this.multiFilterObject.companySize.setSelected(event.option.value.uuid, event.option.value.name);
    this.liveSearchEmitor.next(this.multiFilterObject.companySize);
    this.searchResultsCompanySizes = [];
  }

  resetAdvancedSearch(): void {
    this.advancedSearch.valueChanges.subscribe((value) => {
      if (!value) {
        if (this.multiFilterObject.churches.options.length || this.searchChurches.value) {
          this.searchChurches.reset();
          this.multiFilterObject.churches.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.churches);
        }
        if (this.multiFilterObject.facilitators.options.length || this.searchFacilitators.value) {
          this.searchFacilitators.reset();
          this.multiFilterObject.facilitators.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.facilitators);
        }
        if (this.multiFilterObject.studies.options.length || this.searchStudies.value) {
          this.searchStudies.reset();
          this.multiFilterObject.studies.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.studies);
        }
        if (this.multiFilterObject.groupAudience.options.length || this.searchAudiences.value) {
          this.searchAudiences.reset();
          this.multiFilterObject.groupAudience.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.groupAudience);
        }
        if (this.multiFilterObject.groupOwners.options.length || this.searchOwners.value) {
          this.searchOwners.reset();
          this.multiFilterObject.groupOwners.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.groupOwners);
        }
        if (this.multiFilterObject.groupLanguage.options.length || this.searchLanguages.value) {
          this.searchLanguages.reset();
          this.multiFilterObject.groupLanguage.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.groupLanguage);
        }
        if (this.companySize.value) {
          this.companySize.reset();
          this.multiFilterObject.companySize.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.companySize);
        }
        if (this.industryAffinity.value) {
          this.industryAffinity.reset();
          this.multiFilterObject.industryAffinity.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.industryAffinity);
        }
        if (this.multiFilterObject.unitsCounter.options.some((option) => option.selected)) {
          this.multiFilterObject.unitsCounter.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.unitsCounter);
        }
        if (this.multiFilterObject.weekDays.options.some((option) => option.selected)) {
          this.multiFilterObject.weekDays.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.weekDays);
        }
        if (this.multiFilterObject.timeIntervals.options.some((option) => option.selected)) {
          this.multiFilterObject.timeIntervals.reset();
          this.liveSearchEmitor.next(this.multiFilterObject.timeIntervals);
        }
      }
    });
  }

  logAnalyticsEvent(event: AnalyticsEvent): void {
    if (this.route.snapshot.component === GroupsComponent) {
      this.analyticsService.logClickEvent(event);
    }
  }

  get advancedSearch(): AbstractControl {
    return this.multiFilterForm.get('advancedSearch') as AbstractControl;
  }
  get searchChurches(): AbstractControl {
    return this.multiFilterForm.get('searchChurches') as AbstractControl;
  }
  get searchFacilitators(): AbstractControl {
    return this.multiFilterForm.get('searchFacilitators') as AbstractControl;
  }
  get searchStudies(): AbstractControl {
    return this.multiFilterForm.get('searchStudies') as AbstractControl;
  }
  get searchAudiences(): AbstractControl {
    return this.multiFilterForm.get('searchAudiences') as AbstractControl;
  }
  get searchLanguages(): AbstractControl {
    return this.multiFilterForm.get('searchLanguages') as AbstractControl;
  }
  get searchOwners(): AbstractControl {
    return this.multiFilterForm.get('searchOwners') as AbstractControl;
  }
  get searchCompanySizes(): AbstractControl {
    return this.multiFilterForm.get('searchCompanySizes') as AbstractControl;
  }
  get industryAffinity(): AbstractControl {
    return this.multiFilterForm.get('industryAffinity') as AbstractControl;
  }
  get companySize(): AbstractControl {
    return this.multiFilterForm.get('companySize') as AbstractControl;
  }
}
