<div class="mt-2rem row no-gutters justify-content-center">
  <ng-container *ngIf="!isMobile; else mobileRegions">
    <ng-container *ngFor="let filter of regionFilters; let i = index">
      <div class="col col-lg col-sm-3 col-12 mb-2 px-lg-1 px-2" [ngClass]="{ 'pr-lg-3': i < regionFilters.length - 1 }">
        <button
          mat-button
          class="w-100 region-button text-white black-button"
          [class.white-button]="filter === selectedFilter"
          (click)="selectFilter(filter)"
        >
          {{ filter }}
        </button>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #mobileRegions>
    <button mat-button class="w-100 black-button mx-2" (click)="openRegionsModal()">Filter by Region</button>
  </ng-template>
</div>
