<div class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
  <h1 class="mb-0">{{ partnerOrganizationDetails?.name }}</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>
<ng-container *ngIf="partnerOrganizationDetails">
  <div class="px-3 px-md-5">
    <div class="custom-container">
      <h1 class="primary-color">About</h1>
      <div class="d-flex">
        <div class="d-flex flex-column">
          <img
            class="logo-image"
            [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/empty_profile_picture.svg'"
            [lazyLoad]="partnerOrganizationDetails.imageUrl"
          />
        </div>
        <div class="ml-4 ml-xs-0">
          <h2 class="mb-0">{{ partnerOrganizationDetails?.name }}</h2>
          <h3 class="mt-3 mb-0">Website</h3>
          <div class="link" (click)="externalNavigate(partnerOrganizationDetails?.website)">{{ partnerOrganizationDetails?.website }}</div>
          <h3 class="mt-3 mb-0">Region & State</h3>
          <div class="bold">{{ addressString }}</div>
          <ng-container *ngIf="partnerOrganizationDetails?.headline">
            <h3 class="mt-3 mb-0">Headline</h3>
            <div class="bold">{{ partnerOrganizationDetails?.headline }}</div>
          </ng-container>
          <ng-container *ngIf="partnerOrganizationDetails?.description">
            <h3 class="mt-3 mb-0">Description</h3>
            <div class="bold">{{ partnerOrganizationDetails?.description }}</div>
          </ng-container>
          <ng-container *ngIf="!partnerOrganizationDetails?.verified && isSuperAdmin">
            <div class="d-flex align-items-center mt-3 mb-1">
              <i class="fas fa-exclamation-circle mr-2 primary-color"></i>
              <h3 class="mb-0 font-weight-bold">This partner organization contains unapproved changes</h3>
            </div>
            <button
              mat-flat-button
              color="accent"
              class="filter-button"
              [ngClass]="isMobile ? 'mat-medium-button' : 'mat-longer-button'"
              (click)="confirmPartnerOrganization()"
            >
              Approve
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <mat-tab-group
      [class.hide-tabs]="partnerOrganizationDetails.historyList.length === 0 || partnerOrganizationDetails.groups.length === 0"
      [selectedIndex]="partnerOrganizationDetails.groups.length > 0 ? 0 : 1"
    >
      <ng-container>
        <mat-tab label="Church's groups" class="font-17">
          <div class="custom-container w-100">
            <ng-container *ngIf="partnerOrganizationDetails?.groups?.length > 0">
              <h1 *ngIf="partnerOrganizationDetails.historyList.length === 0" class="primary-color">Church's groups</h1>
              <h2 class="text-center primary-color">APPROVED</h2>
              <div class="mb-4" *ngFor="let group of partnerOrganizationDetails.groups">
                <app-group-resume-admin [group]="group"></app-group-resume-admin>
              </div>
            </ng-container>
          </div>
        </mat-tab>
        <mat-tab label="Change history" class="font-17">
          <div class="custom-container w-100">
            <ng-container *ngIf="partnerOrganizationDetails?.historyList?.length > 0">
              <h1 *ngIf="partnerOrganizationDetails.groups.length === 0" class="primary-color">Change history</h1>
              <div class="mb-4" *ngFor="let history of partnerOrganizationDetails.historyList">
                <app-change-history [history]="history"></app-change-history>
              </div>
            </ng-container>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>

    <div class="custom-container py-1 mb-5">
      <button
        mat-button
        class="uppercase"
        color="primary"
        (click)="openModal(modals.ADD_PARTNER_ORGANIZATION, modalActions.EDIT_PARTNER_ORGANIZATION)"
      >
        <i class="fas fa-pencil-alt mr-2"></i>Edit
      </button>
      <button
        mat-button
        class="uppercase"
        color="warn"
        (click)="
          openModal(modals.CONFIRM_DELETE, modalActions.DELETE_PARTNER_ORGANIZATION, 'Are you sure you want to delete this partner organization?')
        "
      >
        <i class="far fa-trash-alt mr-2"></i>Delete
      </button>
    </div>
  </div>
</ng-container>
<div class="modal-loading-spinner" *ngIf="loading">
  <app-loading-spinner [forceLoading]="loading"></app-loading-spinner>
</div>
