export enum Role {
  UNVERIFIED = 'ROLE_UNVERIFIED',
  USER = 'ROLE_USER',
  SUPER_ADMIN = 'ROLE_SUPERADMIN',
  FACILITATOR = 'ROLE_FACILITATOR',

  PARTNER = 'ROLE_PARTNER',
  COMPANY = 'ROLE_COMPANY',
  FUND_MANAGER = 'ROLE_FUND_MANAGER',
  CHAMPION = 'ROLE_ADMIN_CHAMPION',
  WEALTH_ADVISOR = 'WEALTH_ADVISOR',
}

export enum UserStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPERADMIN',
}
