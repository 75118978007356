import { FormGroup } from '@angular/forms';
import { MultiFilter } from 'src/app/_shared/components/multi-filter/multi-filter.model';
import { GroupStatus } from 'src/app/internal-app/admin/_helpers/add-group/add-group.const';
import { GroupForm, GroupFormValue } from 'src/app/internal-app/admin/_helpers/add-group/group-form.model';
import { EntrepreneurTypes, GroupTypes, InvestorTypes } from '../constants/AdminGroups';
import { FileType } from '../constants/FileType';
import { AddressComponentTypes } from '../constants/GooglePlaces';
import { SortValue } from '../constants/SortByOptions';
import {
  AdminChurchDetails,
  AdminChurchesPartnerOganizationsFilters,
  AdminPartnerOrganizationDetails,
  MultiFilterGroups,
} from '../models/AdminChurches';
import { AdminGetFacilitatorsRequest } from '../models/AdminFacilitators';
import { ChangeType, CreateUpdateGroupRequest, GetAdminGroupsRequest, GroupUnitRequest, HandleParticipantRequest } from '../models/AdminGroups';
import { GetAdminLocationsRequest } from '../models/AdminLocation';
import { AdminStudiesFilters, AdminStudyDetails } from '../models/AdminStudies';
import { AdminUsersFilters, CreateUpdateUserRequest, GetUsersRequest } from '../models/AdminUsers';
import { Filter, SortByOption, SortOption } from '../models/Filters';
import { UUIDName } from '../models/GenericObject';
import { GroupUnitHistoryRequest } from '../models/GroupHistory';
import {
  AdminApplyUserToGroup,
  GetGroupsRequest,
  GroupParticipants,
  GroupSurveyAnswersRequest,
  RunningOrPastGroupsRequest,
  UpdateMeetingAttendantsRequest,
} from '../models/Groups';
import { GroupsFilters, Industry, Language, Region, RegionData } from '../models/Home';
import { Pagination } from '../models/Pagination';
import {
  BasicFilters,
  BasicPaginatedRequest,
  BasicPaginatedRequestMain,
  BasicPaginatedRequestWithSort,
  ChurchesPaginatedRequest,
  ChurchesPaginatedRequestWithSort,
  DeleteImage,
} from '../models/Shared';
import { RegisterRequest } from '../models/User';
import DateUtils from './DateUtils';
import Utils from './Utils';

export default class ComputePayload {
  public static createAdminMultiFilterGroupsPayload(
    multiFilterObject: MultiFilter,
    pagination: Pagination,
    getCountOnly: boolean
  ): MultiFilterGroups {
    const userRole =
      (multiFilterObject?.investorTypes.getValue() as string[]).length > 0
        ? multiFilterObject?.investorTypes.getValue()
        : multiFilterObject.userRole.getValue()
        ? [multiFilterObject.userRole.getValue()]
        : [];
    return {
      userRole: userRole as string[],
      userType: multiFilterObject.userType.getValue() as string[],
      unitStatuses: multiFilterObject.unitStatuses.getValue() as string[],
      unitsCounter: multiFilterObject.unitsCounter.getValue() as string[],
      meetingPlaceTypes: multiFilterObject.meetingType.getValue() as string[],
      groupStateList: multiFilterObject.groupState.getValue() as string[],
      weekDayIds: multiFilterObject.weekDays.getValue() as string[],
      dayIntervalIds: multiFilterObject.timeIntervals.getValue() as string[],
      mainlandsUuid: multiFilterObject.mainlands.getSelectedChildrenUuids() as string[],
      churchesUuid: multiFilterObject.churches.getValue() as string[],
      facilitatorsUuid: multiFilterObject.facilitators.getValue() as string[],
      studiesUuid: multiFilterObject.studies.getValue() as string[],
      sortBy: multiFilterObject.getSortOption(),
      cohortUuid: multiFilterObject.cohort.getValue() as string,
      startDate: multiFilterObject.startDate.getValue() as number,
      groupAudiencesUuids: multiFilterObject.groupAudience.getValue() as string[],
      languagesUuid: multiFilterObject.groupLanguage.getValue() as string[],
      groupOwnersUuids: multiFilterObject.groupOwners.getValue() as string[],
      archived: !!multiFilterObject.archived.getValue(),
      requestFromAdmin: true,
      timezone: DateUtils.extractTimeZone(),
      searchText: multiFilterObject?.searchText,
      page: pagination?.page || 0,
      size: 15,
      getCounts: getCountOnly,
      groupIndustriesUuid: multiFilterObject.industryAffinity.getValue() as string,
      companySizeUuid: (multiFilterObject.companySize.getValue() ? [multiFilterObject.companySize.getValue()] : []) as string[],
    };
  }

  public static getGroupsRequest(filters: GroupsFilters, groupType: GroupTypes): GetGroupsRequest {
    return {
      timezone: DateUtils.extractTimeZone(),
      weekDayIds: Utils.extractFiltersUuidsFromFilter(filters.days),
      dayIntervalIds: Utils.extractFiltersUuidsFromFilter(filters.dayIntervals),
      facilitatorUuids: Utils.extractFiltersUuidsFromFilter(filters.facilitators),
      companySizeUuids: Utils.extractFiltersUuidsFromFilter(filters.companySize),
      groupTypes: this.computeGroupTypes(groupType),
      studyUuids: Utils.extractFiltersUuidsFromFilter(filters.study),
      mainlandUuid: filters.region.custom.region.stateProvince,
      deselectedWeekDayIds: [],
      selectAll: Utils.computeSelectAllForPayload(filters),
      deselected: filters.selectedFilter.deselected,
      filter: filters.selectedFilter?.name,
      size: filters.size,
      page: filters.page,
      churchesUuids: Utils.extractFiltersUuidsFromFilter(filters.churches),
    };
  }

  public static getRunningOrPastGroupsRequest<T extends BasicPaginatedRequest>(
    payload: T,
    filters: GroupsFilters,
    groupRole: string,
    meetingType: string
  ): RunningOrPastGroupsRequest {
    return {
      page: payload.page,
      size: payload.size,
      searchText: payload.searchText,
      facilitatorUuids: filters?.facilitators.filters.length === 0 ? [] : Utils.extractFiltersUuidsFromFilter(filters?.facilitators),
      mainlandUuid: filters.region.custom.region.stateProvince,
      groupRole: groupRole ? groupRole?.toUpperCase() : 'NONE',
      meetingType: meetingType,
    };
  }

  public static getUsersRequest(filters: AdminUsersFilters): GetUsersRequest {
    return {
      userStatus: Utils.extractFiltersUuidsFromFilter(filters.filters.userStatus),
      groupsUuids: Utils.extractFiltersUuidsFromFilter(filters.filters.group),
      lastModifiedDays: Utils.extractFiltersDaysFromFilter(filters.filters.lastModified),
      facilitators: false,
      newUsersDays: Utils.extractFiltersDaysFromFilter(filters.filters.newUser),
      page: filters.filters.page,
      searchText: filters.filters.searchText,
      size: filters.filters.size,
      sortBy: this.extractSortOption(filters.filters.sortBy, SortValue.GROUP),
      startDateOfStudy: filters.filters.startDateOfStudy.searchText,
      studiesUuids: Utils.extractFiltersUuidsFromFilter(filters.filters.study),
    };
  }

  public static getAdminGroupsRequest(filters: GroupsFilters): GetAdminGroupsRequest {
    return {
      sortBy: this.extractSortOption(filters.sortBy, SortValue.PENDING_USERS),
      timezone: DateUtils.extractTimeZone(),
      weekDayIds: Utils.extractFiltersUuidsFromFilter(filters.days),
      monthIds: Utils.extractFiltersUuidsFromFilter(filters.months),
      years: Utils.extractFiltersNamesFromFilter(filters.years),
      startDate: filters.startDate,
      facilitatorUuids: Utils.extractFiltersUuidsFromFilter(filters.facilitators),
      mainlandUuids: Utils.extractFiltersUuidsFromFilter(filters.mainlands),
      companySizeUuids: Utils.extractFiltersUuidsFromFilter(filters.companySize),
      studyUuids: Utils.extractFiltersUuidsFromFilter(filters.study),
      deselectedWeekDayIds: [],
      selectAll: Utils.computeSelectAllForPayload(filters),
      deselected: filters.selectedFilter.deselected,
      groupTypes: filters.groupsType,
      // location: this.extractRegion(filters.region.custom),
      searchText: filters.searchText,
      filter: filters.selectedFilter?.name,
      page: filters.page,
      size: filters.size,
      churchesUuids: Utils.extractFiltersUuidsFromFilter(filters.churches),
      cohorts: Utils.extractFiltersUuidsFromFilter(filters.cohorts),
    };
  }

  public static getAdminLocationRequest(filters: any): GetAdminLocationsRequest {
    return {
      region: filters.region,
      searchText: filters.searchText,
      page: filters.page,
      size: filters.size,
      groupRole: 'NONE',
    };
  }

  public static handleParticipant(unitUuid: string, uuid: string, changeType: ChangeType): HandleParticipantRequest {
    return {
      unitUuid: unitUuid,
      groupUuid: uuid,
      userStatusInGroup: changeType.newType,
      userUuid: changeType.uuid,
      sendEmail: changeType.sendEmail,
      before7days: changeType.before7days,
      before3days: changeType.before3days,
      freeBooks: changeType.freeBooks,
      declinedEnum: changeType.declinedEmailOption,
    };
  }

  public static createUpdateGroup(uuid: string, formGroup: FormGroup<GroupForm>, groupStatus: GroupStatus): CreateUpdateGroupRequest {
    const formData: GroupFormValue = formGroup.getRawValue();
    let openUntil: number = null;
    if (formData.openUntilDate) {
      openUntil = DateUtils.createDateAtSpecificTimezone(formData.openUntilDate, formData.units[0].startTime, formData.units[0].timezone.timezone);
    }
    const groupUnitsRequest = formGroup.controls.units.controls.map((unit) => new GroupUnitRequest(unit, formData.format));
    return {
      uuid: uuid,
      groupFormat: formData.format,
      name: formData.name,
      groupRole: formData.groupRole,
      groupAudiencesUuids: formData.audiences.map((audience: UUIDName) => audience.uuid),
      groupIndustryCategoriesUuids: formData.industries.map((industry: Industry) => industry.uuid),
      language: formData.languages.map((language: Language) => language.uuid),
      cohortUuid: formData.cohort.uuid,
      churchesUuids: formData.churches.map((church: UUIDName) => church.uuid),
      partnerOrganizations: formData.partnerOrganizations.map((partnerOrganization: UUIDName) => partnerOrganization.uuid),
      companySizeUuids: formData.companySize.map((companySize: UUIDName) => companySize.uuid),
      groupOwnersUuids: formData.owners.map((owner: UUIDName) => owner.uuid),
      groupState: formData.groupState,
      openUntilDate: openUntil,
      openUntilAlways: formData.openUntilAlways,
      groupStatus: groupStatus,
      visible: formData.visible,
      units: groupUnitsRequest,
    };
  }

  public static createUpdateUser(uuid: string, form): CreateUpdateUserRequest {
    return {
      uuid: uuid,
      location: {
        address: form.addressInfo.country,
        city: form.addressInfo.city,
        latitude: form.addressInfo.lat,
        longitude: form.addressInfo.lng,
        stateProvince: form.addressInfo.stateProvince,
      },
      countryCode: form.addressInfo?.countryCode,
      bio: form.description,
      email: form.email.trim(),
      firstName: form.firstName.trim(),
      lastName: form.lastName.trim(),
      linkedinProfile: form.linkedinProfile,
      phoneNumber: {
        countryCode: form.phoneNumber.countryCode.toLowerCase(),
        nationalNumber: form.phoneNumber.number.split(' ').join(''),
        completeNumber: form.phoneNumber.e164Number,
      },
      profilePictureUUID: form?.profilePictureUUID,
      title: form.jobTitle,
      facilitator: form.facilitator,
      visible: form?.visible,
      timezone: form.timezone?.timezone,
      timezoneName: form.timezone?.timezoneName,
    };
  }

  public static registerRequest(registerForm: any, groupUuid: string): RegisterRequest {
    const userRoleTypes = { ...EntrepreneurTypes, ...InvestorTypes };
    return {
      email: registerForm.step1.emails.email.trim(),
      firstName: registerForm.step1.firstName,
      lastName: registerForm.step1.lastName,
      password: registerForm.passwords.password,
      token: registerForm.recaptcha,
      location: registerForm.address,
      phoneNumber: {
        countryCode: registerForm.phoneNumber.countryCode.toLowerCase(),
        nationalNumber: registerForm.phoneNumber.number.split(' ').join(''),
        completeNumber: registerForm.phoneNumber.e164Number,
      },
      groupUuid: groupUuid || null,
      userType: registerForm.userRole,
      userSubtype: Object.keys(userRoleTypes).find((key) => userRoleTypes[key] === registerForm.userRoleType),
    };
  }

  public static getFacilitatorsAdminRequest(payload: BasicFilters): AdminGetFacilitatorsRequest {
    return {
      facilitators: true,
      page: payload.page,
      size: payload.size,
      searchText: payload.searchText,
      sortBy: this.extractSortOption(payload.sortBy, SortValue.USERNAME),
    };
  }

  public static getStudiesAdminRequest(payload: AdminStudiesFilters): BasicPaginatedRequestWithSort {
    return {
      page: payload.page,
      searchText: payload.searchText,
      size: payload.size,
      sortBy: this.extractSortOption(payload.sortBy, SortValue.GROUPS),
    };
  }

  public static getChurchesPartnerOrganizationsAdminRequest(payload: AdminChurchesPartnerOganizationsFilters): ChurchesPaginatedRequestWithSort {
    return {
      page: payload.page,
      searchText: payload.searchText,
      size: payload.size,
      region: payload.region,
      sortBy: this.extractSortOption(payload.sortBy, SortValue.GROUPS),
      groupRole: 'NONE',
    };
  }

  private static extractSortOption(array: SortOption[], defaultSort: SortValue): SortByOption<'asc' | 'desc'> {
    const sortOption = array?.find((i) => i.checked === true);
    return sortOption?.order
      ? new SortByOption<'asc' | 'desc'>(sortOption.field, sortOption.order)
      : new SortByOption<'asc' | 'desc'>(defaultSort, 'desc');
  }

  public static extractRegion(regionFilter: RegionData): Region {
    if (Object.keys(regionFilter.address || {})?.length !== 0) {
      return regionFilter.address
        ? new Region(
            Utils.getGooglePlacesData(regionFilter.address.address_components, [
              AddressComponentTypes.LOCALITY,
              AddressComponentTypes.POSTAL_TOWN,
              AddressComponentTypes.SUBLOCALITY_LEVEL_1,
              AddressComponentTypes.ADMINISTRATIVE_AREA_LEVEL_1,
            ])?.long_name,
            Utils.getGooglePlacesData(regionFilter.address.address_components, [AddressComponentTypes.COUNTRY])?.short_name,
            Utils.getGooglePlacesData(regionFilter.address.address_components, [AddressComponentTypes.ADMINISTRATIVE_AREA_LEVEL_1])?.long_name,
            Utils.getGooglePlacesData(regionFilter.address.address_components, [AddressComponentTypes.POSTAL_CODE])?.long_name,
            regionFilter.address.geometry.location.lat(),
            regionFilter.address.geometry.location.lng()
          )
        : new Region();
    } else {
      return regionFilter.region;
    }
  }

  public static createUpdateStudy(payload): AdminStudyDetails {
    return {
      author: payload.author,
      groupRoleTypes: payload.groupRoleTypes,
      nrOfSession: payload.nrOfSession,
      description: payload.studyDescription,
      name: payload.studyName,
      studyPictureUuid: payload.studyPictureUuid,
      studyUuid: payload?.studyUuid,
      trailerLink: payload?.trailerLink,
      onBoardingStudy: payload.onboarding,
      visible: payload.visible,
      type: payload.studyType,
    };
  }

  public static createUpdateChurch(payload, verified: boolean = false): AdminChurchDetails {
    return {
      uuid: payload.churchUuid,
      name: payload.churchName,
      imageUrl: payload.churchPictureUrl,
      mainland: payload?.mainland,
      websiteUrl: payload.website,
      visible: payload.visible,
      description: payload.description,
      headline: payload.headline,
      verified: verified,
    };
  }

  public static createUpdatePartnerOrganization(payload, verified: boolean = false): AdminPartnerOrganizationDetails {
    return {
      uuid: payload.partnerOrganizationUuid,
      name: payload.partnerOrganizationName,
      imageUrl: payload.partnerOrganizationPictureUrl,
      mainland: payload?.mainland,
      website: payload.website,
      description: payload.description,
      headline: payload.headline,
      verified: verified,
    };
  }

  public static basicPaginatedRequestMain<T extends BasicPaginatedRequest>(payload: T): BasicPaginatedRequest {
    return {
      page: payload.page,
      size: payload.size,
      searchText: payload.searchText,
    };
  }

  public static basicPaginatedRequestStudies(payload: Filter): BasicPaginatedRequestMain {
    return {
      page: payload.page,
      size: payload.size,
      searchText: payload.searchText,
      entityUuids: payload.preloaded,
    };
  }

  public static basicPaginatedRequestChurches(payload: Filter, groupRole: string): ChurchesPaginatedRequest {
    return {
      page: payload.page,
      size: payload.size,
      searchText: payload.searchText,
      region: payload.region,
      groupRole: groupRole ? groupRole?.toUpperCase() : 'NONE',
    };
  }

  public static basicPaginatedRequestFacilitators(payload: Filter): BasicPaginatedRequest {
    return {
      page: payload.page,
      size: payload.size,
      searchText: payload.searchText,
    };
  }

  public static adminApplyUserToGroupRequest(formData, payload): AdminApplyUserToGroup {
    return {
      userEmail: formData.email.trim(),
      groupUuid: payload.uuid,
      unitUuid: payload.payload.payload.uuid,
      before3days: formData.before3days,
      before7days: formData.before7days,
      freeBooks: formData.freeBooks,
      foundationGroup: formData.anotherFoundationConfirm,
    };
  }

  public static deleteUserImageRequest(fileType: FileType, fileUuid: string, entityUuid: string): DeleteImage {
    let payload;
    if (entityUuid) {
      payload = {
        entityUuid,
        fileType,
        fileUuid,
      };
    } else {
      payload = {
        fileType,
        fileUuid,
      };
    }
    return payload;
  }

  public static groupSurveyAnswersRequest(groupUuid: string, userUuid: string): GroupSurveyAnswersRequest {
    return {
      groupUuid,
      userUuid,
    };
  }

  public static updateMeeting(meetingUuid: string, groupDetails: GroupParticipants): UpdateMeetingAttendantsRequest {
    return {
      attendants: groupDetails.participants.filter((p) => !p.selected).map((p) => p.uuid),
      unitUuid: groupDetails.groupUuid,
      meetingUuid: meetingUuid,
    };
  }

  private static computeGroupTypes(groupType: GroupTypes): GroupTypes[] {
    return groupType
      ? groupType === GroupTypes.IN_PERSON
        ? [GroupTypes.IN_PERSON, GroupTypes.HYBRID, GroupTypes.VIRTUAL]
        : [groupType]
      : [GroupTypes.VIRTUAL];
  }

  public static computeGroupUnitHistoryPayload(pagination: Pagination, uuid): GroupUnitHistoryRequest {
    return {
      uuid: uuid,
      page: pagination?.page || 0,
      size: 100,
    };
  }

  public static getSearchAccountPayload(body: BasicFilters): BasicPaginatedRequest {
    return {
      searchText: body.searchText,
      page: body?.page || 0,
      size: body.size,
    };
  }
}
