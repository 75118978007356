import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AdminGroupService } from 'src/app/_core/api/admin-group.service';
import { ParticipantType } from 'src/app/_core/constants/AdminGroups';
import { ModalActions, Modals } from 'src/app/_core/constants/Modals';
import { ToastrMessages } from 'src/app/_core/constants/ToastrMessages';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import Utils from 'src/app/_core/helpers/Utils';
import { ChangeType, Participant, ParticipantHistory } from 'src/app/_core/models/AdminGroups';
import { FileResponse } from 'src/app/_core/models/DownloadResponse';
import { ResponseObject } from 'src/app/_core/models/GenericObject';
import { ModalRequest } from 'src/app/_core/models/ModalEvent';
import { ModalsService } from 'src/app/_core/services/modals.service';
import { GroupStatus } from '../../../../../_helpers/add-group/add-group.const';
import { SmartAdminGroup, SmartAdminGroupUnit } from '../../view-group.model';

@Component({
  selector: 'app-view-group-participants',
  templateUrl: './view-group-participants.component.html',
  styleUrls: ['./view-group-participants.component.scss'],
})
export class ViewGroupParticipantsComponent implements OnDestroy, OnChanges {
  @Output() changeType: EventEmitter<any> = new EventEmitter<any>();
  @Input() group: SmartAdminGroup;
  @Input() selectedUnit: SmartAdminGroupUnit;
  types = ParticipantType;
  statuses = GroupStatus;
  breakpointSubscription: Subscription;
  isMobile: boolean;
  seeAll: boolean;
  selectedTabIndex: number = 0;
  participantsHistory: ParticipantHistory[] = [];
  loading: boolean;

  // prettier-ignore
  constructor(
    private breakpointObserver: BreakpointObserver,
    private modalService: ModalsService,
    private adminGroupService: AdminGroupService,
    private toastr: ToastrService
  ) {
    this.breakpointSubscription = this.breakpointObserver.observe('(max-width: 600px)').subscribe((result: BreakpointState) => {
      this.isMobile = result.matches;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedUnit.previousValue !== changes.selectedUnit.currentValue) {
      this.selectedTabIndex = 0;
    }
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  openAddParticipantModal(): void {
    if (!this.selectedUnit.spotsLeft) {
      this.toastr.error(...ToastrMessages.ADMIN_GROUP_NO_SPOTS_LEFT);
    } else {
      const payload = { formData: null, payload: this.selectedUnit };
      this.openModal(Modals.ADD_NEW_PARTICIPANT, ModalActions.ADD_NEW_PARTICIPANT, this.group.uuid, null, payload);
    }
  }

  getParticipantsHistory(): void {
    this.loading = true;
    const payload = { groupUuid: this.group.uuid, unitUuid: this.selectedUnit.uuid };
    this.adminGroupService.getParticipantsHistory(payload).subscribe({
      next: (data: ResponseObject<any>) => {
        this.participantsHistory = data.response;
        this.loading = false;
      },
      error: () => {
        this.toastr.error(...ToastrMessages.BASIC_ERROR);
        this.loading = false;
      },
    });
  }

  handleChangeType(user: Participant, index: number, prevType: ParticipantType, selectedType: ParticipantType): void {
    switch (selectedType) {
      case this.types.PENDING:
        this.openModal(Modals.SEND_EMAIL, ModalActions.PENDING_PARTICIPANT, null, index, { ...user, prevType });
        break;
      case this.types.WITHDRAWN:
        this.openModal(Modals.SEND_EMAIL, ModalActions.WITHDRAWN_PARTICIPANT, null, index, { ...user, prevType });
        break;
      case this.types.DECLINED:
        this.openModal(Modals.SEND_EMAIL, ModalActions.SEND_EMAIL_DECLINE_PARTICIPANT, null, index, {
          ...user,
          meetingType: this.selectedUnit.frequencyType,
          prevType,
          sendEmail: false,
        });
        break;
      case this.types.APPROVED:
        if (DateUtils.soonerThan(this.selectedUnit.startsOn, 3)) {
          this.openModal(Modals.SEND_EMAIL, ModalActions.SEND_EMAIL_APPROVE_PARTICIPANT, null, index, {
            ...user,
            prevType,
            before3days: false,
            before7days: false,
            freeBooks: false,
            groupFormat: this.selectedUnit.meetingFrequency,
          });
        } else if (DateUtils.soonerThan(this.selectedUnit.startsOn, 7)) {
          this.openModal(Modals.SEND_EMAIL, ModalActions.SEND_EMAIL_APPROVE_PARTICIPANT, null, index, {
            ...user,
            prevType,
            before7days: false,
            freeBooks: false,
            groupFormat: this.selectedUnit.meetingFrequency,
          });
        } else if (DateUtils.soonerThan(this.selectedUnit.startsOn, 21) && this.selectedUnit.isNew) {
          this.openModal(Modals.SEND_EMAIL, ModalActions.SEND_EMAIL_APPROVE_PARTICIPANT, null, index, {
            ...user,
            prevType,
            freeBooks: false,
            groupFormat: this.selectedUnit.meetingFrequency,
          });
        } else {
          this.changeType.emit({ changeType: new ChangeType(user.uuid, index, selectedType), prevType });
        }
        break;
      default:
        this.changeType.emit({ changeType: new ChangeType(user.uuid, index, selectedType), prevType });
    }
  }

  openModal(modalName: Modals, scope: ModalActions, uuid: string = null, index: number = null, payload: any = null): void {
    this.modalService.openModal(modalName, new ModalRequest<any>(scope, uuid, index, payload));
  }

  downloadGroupMembers(): void {
    this.adminGroupService.downloadGroupMembersFile(this.selectedUnit.uuid).subscribe(
      (data: ResponseObject<FileResponse>) => {
        const blob = Utils.b64toBlob(data.response.body, 'text/plain');
        saveAs(blob, `Participants-Unit-${this.group.units.findIndex((unit) => unit.uuid === this.selectedUnit.uuid) + 1}-Export.csv`);
        this.toastr.success(...ToastrMessages.DOCUMENT_DOWNLOAD);
      },
      () => {
        this.toastr.error(...ToastrMessages.ERROR_DOCUMENT_DOWNLOAD);
      }
    );
  }

  openConfirmationEmailModal(participant: Participant): void {
    const participantName = `${participant.firstName} ${participant.lastName}`;
    this.modalService.openModal(Modals.CONFIRM_ACTION, {
      text: `Are you sure you want to resend the confirmation email (includes calendar invites) to ${participantName}?`,
      confirmButtonText: 'Send',
      payload: {
        userData: participant,
        facilitator: false,
      },
    });
  }

  openUserDetailsModal(participant: Participant): void {
    participant.facilitator
      ? this.openModal(Modals.VIEW_FACILITATOR, ModalActions.VIEW_PARTICIPANT_FACILITATOR, participant.uuid)
      : this.openModal(Modals.VIEW_USER_DETAILS, ModalActions.VIEW_PARTICIPANT_USER, participant.uuid);
  }
}
