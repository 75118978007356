<div class="text-center">
  <p class="m-0 font-18">We are passionate about connecting similar stage investors together.</p>
  <h3 class="font-weight-bold font-18">Please confirm you are accredited.</h3>
  <div class="d-flex justify-content-center my-4">
    <button mat-flat-button class="mat-medium-button rounded w-100" color="primary" (click)="confirm()">I confirm</button>
  </div>
  <a href="https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor" target="_blank" class="primary-color"
    >See Accredited Investor definitions</a
  >
</div>
