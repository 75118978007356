import { Component, Input } from '@angular/core';
import { History } from 'src/app/_core/models/AdminChurches';

@Component({
  selector: 'app-change-history[history]',
  templateUrl: './change-history.component.html',
  styleUrls: ['./change-history.component.scss'],
})
export class ChangeHistoryComponent {
  @Input() history: History;
}
