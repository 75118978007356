<div class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
  <h1 class="mb-0">{{ studyDetails ? studyDetails.name : 'Add new study' }}</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>

<div class="px-3 px-md-5 mt-4">
  <img class="profilePic" [defaultImage]="'https://fdi-files.s3.amazonaws.com/public/image-placeholder.png'" [lazyLoad]="studyPhoto?.path" />
  <h5 class="accent-color pointer mb-0 mt-1" (click)="handleImageUpload()">
    <i class="fas fa-upload"></i> {{ studyPhoto?.path ? 'DELETE PICTURE' : 'UPLOAD PICTURE' }}
  </h5>
  <mat-error *ngIf="studyPictureUuid.invalid && (studyPictureUuid.dirty || studyPictureUuid.touched || studyDetails)" class="error">
    <app-validation-message [control]="studyPictureUuid" [controlName]="'studyPictureUuid'"></app-validation-message>
  </mat-error>
  <form [formGroup]="addStudyForm">
    <!-- Study Name-->
    <div class="admin-input">
      <label for="studyName" class="label">Study name <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline" class="w-100">
        <input id="studyName" matInput formControlName="studyName" required maxlength="100" />
      </mat-form-field>
      <mat-error *ngIf="studyName.invalid && (studyName.dirty || studyName.touched || studyDetails)" class="error">
        <app-validation-message [control]="studyName" [controlName]="'studyName'"></app-validation-message>
      </mat-error>
    </div>

    <!-- Author -->
    <div class="admin-input">
      <label for="author" class="label">Author <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline" class="w-100">
        <input id="author" matInput formControlName="author" required maxlength="100" />
      </mat-form-field>
      <mat-error *ngIf="author.invalid && (author.dirty || author.touched || studyDetails)" class="error">
        <app-validation-message [control]="author" [controlName]="'author'"></app-validation-message>
      </mat-error>
    </div>

    <!--Group Role-->
    <div class="admin-input">
      <label for="groupRoleTypes" class="label">Group Role <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline">
        <mat-select multiple formControlName="groupRoleTypes">
          <mat-option *ngFor="let item of groupRoles" [value]="item" [disabled]="disabledGroupRoles[item]">
            {{ item | enumToString | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="groupRoleTypes.invalid && (groupRoleTypes.dirty || groupRoleTypes.touched || studyDetails)" class="error">
        <app-validation-message [control]="groupRoleTypes" [controlName]="'groupRoleTypes'"> </app-validation-message>
      </mat-error>
    </div>

    <!--Study Type-->
    <div class="admin-input" *ngIf="groupRoleTypes.value?.length > 0">
      <label for="studyType" class="label">Study Type <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="studyType">
          <mat-option *ngFor="let item of studyTypeOptions" [value]="item">{{ item | enumToString | titlecase }} </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="studyType.invalid && (studyType.dirty || studyType.touched || studyDetails)" class="error">
        <app-validation-message [control]="studyType" [controlName]="'studyType'"> </app-validation-message>
      </mat-error>
    </div>

    <!-- Nr of Sessions-->
    <div class="admin-input">
      <label for="nrOfSession" class="label">Number of sessions <span *ngIf="onboarding.value" class="primary-color">*</span></label>
      <mat-form-field appearance="outline" class="w-100">
        <input #sessions id="nrOfSession" matInput type="number" (wheel)="sessions.blur()" formControlName="nrOfSession" max="100" />
      </mat-form-field>
      <mat-error *ngIf="nrOfSession.invalid && (nrOfSession.dirty || nrOfSession.touched || studyDetails)" class="error">
        <app-validation-message [control]="nrOfSession" [controlName]="'nrOfSession'"> </app-validation-message>
      </mat-error>
    </div>

    <!--Embed Link-->
    <div class="admin-textarea">
      <label for="trailerEmbed" class="label">Trailer Embed</label>
      <mat-form-field appearance="outline" class="w-100">
        <textarea rows="4" id="trailerEmbed" matInput formControlName="trailerEmbed" maxlength="500"></textarea>
      </mat-form-field>
    </div>

    <!--Trailer Link-->
    <div class="admin-input" *ngIf="trailerEmbed.value || trailerLink.value">
      <label class="label d-block">Trailer Link <span class="primary-color">*</span></label>
      <a class="font-weight-bold pointer" (click)="openModal(modals.MEDIA)">{{ trailerLink.value }}</a>
      <mat-error *ngIf="trailerLink.invalid && (trailerLink.dirty || trailerLink.touched || studyDetails)" class="error">
        <app-validation-message [control]="trailerLink" [controlName]="'trailerLink'"></app-validation-message>
      </mat-error>
    </div>

    <!--Description-->
    <div class="admin-textarea">
      <label for="studyDescription" class="label">Study Description <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline" class="w-100">
        <textarea rows="4" id="studyDescription" matInput formControlName="studyDescription" required maxlength="700"></textarea>
      </mat-form-field>
      <mat-error *ngIf="studyDescription.invalid && (studyDescription.dirty || studyDescription.touched || studyDetails)" class="error">
        <app-validation-message [control]="studyDescription" [controlName]="'studyDescription'"></app-validation-message>
      </mat-error>
    </div>

    <div class="d-flex gap-1 flex-md-row flex-column-reverse mt-2 mb-3">
      <!-- Email Settings -->
      <div class="bg-gray px-3 py-2 w-md-50 w-100">
        <div class="label mt-0 mb-2">ONBOARDING</div>
        <mat-checkbox color="primary" formControlName="onboarding">
          <p class="d-flex flex-wrap mb-0 label titlecase">Onboarding Study</p>
        </mat-checkbox>
      </div>

      <!-- Visible -->
      <div class="bg-gray px-3 py-2 w-md-50 w-100">
        <div class="label mt-0 mb-2">VISIBILITY</div>
        <div class="d-flex flex-nowrap mt-2">
          <div
            class="label mr-2 mt-0 width-fit titlecase"
            [class.opacity-4]="studyDetails?.nrOfUnits > 0"
            [class.disabled-text]="studyDetails?.nrOfUnits > 0"
          >
            Invisible
          </div>
          <mat-slide-toggle formControlName="visible" color="primary" class="visible label mt-0 titlecase" [disabled]="studyDetails?.nrOfUnits > 0"
            >Visible
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </form>

  <!-- Delete Section  -->
  <div class="bg-gray overflow-auto mb-5" *ngIf="modalRequest?.scope === modalActions.EDIT_STUDY">
    <div class="px-3 pt-2 mt-3">
      <div class="label mt-0">DELETE STUDY</div>
    </div>
    <button mat-button color="warn" (click)="emit(modalEmitActions.DELETE_STUDY_SPECIAL_CASE_REQUEST)">
      <span class="d-flex align-items-center"><i class="far fa-trash-alt mr-2"></i>Delete</span>
    </button>
  </div>

  <div class="d-flex justify-content-between my-4 pb-4">
    <button mat-flat-button class="filter-button mr-2 uppercase" color="primary" (click)="close()">Cancel</button>
    <button mat-flat-button class="filter-button uppercase" color="accent" [disabled]="tryToSubmit" (click)="submit()">Save</button>
  </div>
</div>
