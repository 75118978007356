<div class="bg-main">
  <div *ngIf="user?.completedProfile === false" class="bg-primary text-center text-white font-weight-bold w-100 py-1">
    <u class="pointer" (click)="openModal(modals.ADD_USER, modalActions.MY_PROFILE)">PLEASE COMPLETE YOUR PROFILE INFORMATION</u>
  </div>
  <div *ngIf="isMobile && showGoToBtn" class="bg-primary text-center text-white font-weight-bold w-100 py-1">
    <div class="pointer text-uppercase" (click)="navigateToInvestorOrEntrepreneur()">Go To {{ goToEntrepreneurOrInvestor + ' Groups' }}</div>
  </div>
  <div class="d-flex justify-content-between header align-items-center bg-main px-3">
    <img
      class="header-logo pointer"
      src="https://fde-group-finder-tool.s3.amazonaws.com/logos/FDEFDI_logo_horizontal_FDEFDI_white_horizontal.png"
      alt="logo-img"
      (click)="goTo(urls.APP + '/' + urls.HOME)"
    />
    <div class="d-flex flex-column-reverse flex-md-row">
      <ng-container *ngIf="!isMobile && showGoToBtn">
        <div>
          <button mat-button class="uppercase text-white" disableRipple (click)="navigateToInvestorOrEntrepreneur()">
            <ng-container *ngIf="!isMobile"> Go To {{ goToEntrepreneurOrInvestor + ' Groups' }} </ng-container>
          </button>
        </div>
        <div class="vertical-bar my-2 d-none d-md-block"></div>
      </ng-container>
      <div class="d-flex align-items-center text-white header-menu" *ngIf="user; else notLoggedContainer">
        <div class="font-weight-bold mr-4 d-none d-md-block pl-3">{{ user.firstName + ' ' + user.lastName | uppercase }}</div>

        <app-messages [hidden]="!user?.mattermostEnabled"></app-messages>

        <button [disableRipple]="true" mat-button class="menu-button" (click)="openModal(modals.USER_MENU, null)">
          <span class="material-icons font-28"> menu </span>
        </button>
      </div>
      <ng-template #notLoggedContainer>
        <button
          id="register-or-log-in-button"
          mat-button
          class="uppercase text-white register-button"
          disableRipple
          (click)="goTo(urls.AUTH + '/' + urls.LOGIN)"
        >
          Register or Log In
        </button>
      </ng-template>
    </div>
  </div>
</div>
