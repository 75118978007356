<div class="d-flex flex-column justify-content-between h-100 position-relative">
  <a class="text-right" (click)="close()"><i class="fas fa-times gray-color pointer"></i></a>
  <div class="text-center">
    <h2 class="font-weight-bold">Add new participant</h2>
    <div class="gray-color font-weight-bold px-4">Please search your participant by email in the input below</div>
  </div>

  <form [formGroup]="addParticipantForm">
    <!--Email-->
    <div class="admin-input mx-3">
      <label for="email" class="label">Participant Email <span class="primary-color">*</span></label>
      <mat-form-field appearance="outline" class="w-100">
        <input id="email" type="email" matInput formControlName="email" required />
      </mat-form-field>
      <mat-error *ngIf="email.invalid && (email.dirty || email.touched || submited) && !email.errors?.doesNotExists" class="error">
        <app-validation-message [control]="email" [controlName]="'email'"></app-validation-message>
      </mat-error>
      <mat-error *ngIf="email.errors?.doesNotExists" class="error">
        No account found with this email. Please
        <span class="font-weight-bold primary-color underline pb-0 pointer" (click)="openAddNewUserModal()"> add new user </span>
        before adding participant to a group.
      </mat-error>
      <div class="d-flex justify-content-center my-2" *ngIf="timezoneNotFound || investorQuestionsNotAnswered">
        <div class="green-background text-white pointer px-2" (click)="openEdit()">Click here to update profile</div>
      </div>
      <div class="d-flex justify-content-center my-2" *ngIf="alreadyApprovedToFoundationGroup">
        <div class="warning-text px-2">
          This participant is already approved to another Foundation Group. If you really want to add this user to this group please press
          <span class="text-dark font-weight-bold">'Add participant'</span> again.
        </div>
      </div>
    </div>

    <div class="text-center mx-3">
      <h3 *ngIf="soonerThan(21)">
        This user is being approved for this group less than {{ periodOfTime }} before the selected group starts. Select the checkbox for the
        automated emails you would like them to receive, then click <strong>Approve</strong>.
      </h3>
    </div>

    <div class="d-flex flex-column align-items-start ml-3">
      <mat-checkbox *ngIf="soonerThan(7)" class="mr-2" color="primary" formControlName="before7days">
        <div class="ml-2 p-1">
          <p class="d-flex flex-wrap mb-0">"Video Access, Google Calendar invite, and group details" Email</p>
        </div>
      </mat-checkbox>
      <mat-checkbox *ngIf="soonerThan(3)" class="mr-2" color="primary" formControlName="before3days">
        <div class="ml-2 p-1">
          <p class="d-flex flex-wrap mb-0">"Reminder - 3 Days Before Start" Email</p>
        </div>
      </mat-checkbox>
      <mat-checkbox
        *ngIf="soonerThan(21) && modalRequest.payload.payload.frequencyType === frequencies.WEEKLY"
        class="mr-2"
        color="primary"
        formControlName="freeBooks"
      >
        <div class="ml-2 p-1">
          <p class="d-flex flex-wrap mb-0">"Free E-Book" Email</p>
        </div>
      </mat-checkbox>
    </div>
  </form>

  <div class="action-buttons d-flex justify-content-between flex-wrap py-3 mx-3">
    <button mat-flat-button class="mat-medium-button mr-2" color="primary" [mat-dialog-close]>Cancel</button>
    <button mat-flat-button class="mat-medium-button" color="accent" (click)="addNewParticipant()">Add participant</button>
  </div>
  <div class="loading-spinner" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
