<ng-container *ngIf="userDetails">
  <div class="sticky-header d-flex justify-content-between align-items-center px-3 px-md-5 py-3 bg-gray">
    <h1 class="mb-0">{{ userDetails.userInfo.firstName + ' ' + userDetails.userInfo.lastName }}</h1>
    <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
  </div>

  <div class="px-3 px-md-5 mt-4">
    <div class="custom-container">
      <h1 class="primary-color">About</h1>
      <app-user-details-section [userInfo]="userDetails.userInfo"></app-user-details-section>
    </div>
    <div class="custom-container" *ngIf="areGroups && isAdmin">
      <h1 class="primary-color">User’s groups</h1>
      <ng-container *ngIf="userDetails?.groups.approved?.length > 0">
        <h2 class="text-center primary-color">APPROVED</h2>
        <div class="mb-4" *ngFor="let group of userDetails.groups.approved">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </ng-container>
      <ng-container *ngIf="userDetails?.groups.pending?.length > 0">
        <h2 class="text-center primary-color">PENDING</h2>
        <div class="mb-4" *ngFor="let group of userDetails.groups.pending">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </ng-container>
      <ng-container *ngIf="userDetails?.groups.declined?.length > 0">
        <h2 class="text-center primary-color">DECLINED</h2>
        <div class="mb-4" *ngFor="let group of userDetails.groups.declined">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </ng-container>
      <ng-container *ngIf="userDetails?.groups.withdrawn?.length > 0">
        <h2 class="text-center primary-color">WITHDRAWN</h2>
        <div class="mb-4" *ngFor="let group of userDetails.groups.withdrawn">
          <app-group-resume-admin [group]="group"></app-group-resume-admin>
        </div>
      </ng-container>
    </div>

    <div class="custom-container py-1 mb-5" *ngIf="modalRequest.scope !== modalActions.VIEW_PARTICIPANT_USER">
      <button mat-button class="uppercase" color="primary" (click)="openModal(modals.ADD_USER, modalActions.EDIT_USER)">
        <i class="fas fa-pencil-alt mr-2"></i>Edit
      </button>
      <button
        mat-button
        class="uppercase"
        color="warn"
        (click)="openModal(modals.CONFIRM_DELETE, modalActions.DELETE_USER, 'Are you sure you want to delete this user?')"
      >
        <i class="far fa-trash-alt mr-2"></i>Delete
      </button>
    </div>
  </div>
</ng-container>
