import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../../../../../_core/services/modals.service';
import { AdminGroupService } from '../../../../../_core/api/admin-group.service';
import { ToastrService } from 'ngx-toastr';
import { ModalRequest } from '../../../../../_core/models/ModalEvent';
import { ToastrMessages } from '../../../../../_core/constants/ToastrMessages';
import { Participant, UserInfo } from '../../../../../_core/models/AdminGroups';
import { ResponseObject } from '../../../../../_core/models/GenericObject';

@Component({
  selector: 'app-view-attendance',
  templateUrl: './view-attendance.component.html',
  styleUrls: ['./view-attendance.component.scss'],
})
export class ViewAttendanceComponent implements OnInit {
  attendanceInfo: ModalRequest<Participant[]>;
  presetUsers: UserInfo[];
  missedUsers: Participant[];
  loading = true;

  // prettier-ignore
  constructor(
    private modalService: ModalsService,
    private adminGroupService: AdminGroupService,
    private toastrService: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.attendanceInfo = this.modalService.params;
    this.getAttendants();
  }

  getAttendants(): void {
    this.adminGroupService.getAttendants(this.attendanceInfo.uuid).subscribe(
      (response: ResponseObject<UserInfo[]>) => {
        this.presetUsers = response.response;
        this.missedUsers = this.attendanceInfo.payload.filter(
          (participant: Participant) => !this.presetUsers.some((user: UserInfo) => user.uuid === participant.uuid)
        );
        this.loading = false;
      },
      () => this.toastrService.error(...ToastrMessages.BASIC_ERROR)
    );
  }

  close(): void {
    this.modalService.closeModal();
  }
}
