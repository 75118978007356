import { AnalyticsEvent } from './../constants/AnalyticsEvents';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private analytics: AngularFireAnalytics) {}

  trackScreen(url: string): void {
    this.analytics.logEvent('screen_view', { firebase_screen: url });
  }

  logClickEvent(eventName: AnalyticsEvent | string): void {
    this.analytics.logEvent('click', { name: eventName });
  }

  logCustomEvent(eventName: AnalyticsEvent | string): void {
    this.analytics.logEvent(eventName);
  }
}
