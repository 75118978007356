export enum SortValue {
  // admin groups
  NAME = 'NAME',
  TIME = 'TIME',
  FACILITATOR = 'FACILITATOR',
  STAGE_SIZE = 'STAGE_SIZE',
  PARTICIPANTS = 'PARTICIPANTS',
  PENDING_USERS = 'PENDING_USERS',

  // admin users
  // admin facilitators
  USERNAME = 'USERNAME',
  POSITION = 'POSITION',
  EMAIL = 'EMAIL',
  GROUP = 'GROUP',

  // admin studies
  AUTHOR = 'AUTHOR',
  GROUPS = 'GROUPS',

  //admin churches
  STATE = 'STATE',
  SUB_REGION = 'SUB_REGION',
  REGION = 'REGION',
}
