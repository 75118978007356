import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { GroupChurchPartnerOrganization, GroupFacilitator } from 'src/app/_core/models/Groups';

@Component({
  selector: 'app-facilitators-slider',
  templateUrl: './facilitators-slider.component.html',
  styleUrls: ['./facilitators-slider.component.scss'],
})
export class FacilitatorsSliderComponent implements OnInit {
  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;
  @Input() facilitators: GroupFacilitator[];
  @Input() churches: GroupChurchPartnerOrganization[];
  @Input() readOnly: boolean = true;
  @Input() hasStarted: boolean = false;
  @Output() facilitatorEmitter: EventEmitter<GroupFacilitator> = new EventEmitter<GroupFacilitator>();
  @Output() churchEmitter: EventEmitter<GroupChurchPartnerOrganization> = new EventEmitter<GroupChurchPartnerOrganization>();
  selectedFacilitator: GroupFacilitator;
  selectedChurch: GroupChurchPartnerOrganization;
  breakpointSubscription: Subscription;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    mobileFirst: true,
    nextArrow: '<i class="fa fa-2x fa-chevron-circle-right"></i>',
    prevArrow: '<i class="fa fa-2x fa-chevron-circle-left"></i>',
    variableWidth: true,
    variableHeight: false,
    infinite: false,
  };

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    if (this.facilitators) {
      this.selectedFacilitator = this.facilitators[0];
    } else {
      this.selectedChurch = this.churches[0];
    }
  }

  slideInit(event): void {
    this.observeWidth();
    event.slick.$prevArrow.hide();
    if (event.slick.slideCount <= this.slideConfig.slidesToShow) {
      event.slick.$nextArrow.hide();
    } else {
      event.slick.$nextArrow.show();
    }
  }

  beforeChange(event): void {
    if (event.nextSlide >= event.slick.slideCount - this.slideConfig.slidesToShow) {
      event.slick.$nextArrow.hide();
    } else {
      event.slick.$nextArrow.show();
    }

    if (event.nextSlide) {
      event.slick.$prevArrow.show();
    } else {
      event.slick.$prevArrow.hide();
    }
  }

  observeWidth(): void {
    this.breakpointObserver
      .observe(['(max-width: 1400px)'])
      .pipe(first())
      .subscribe((result) => {
        switch (true) {
          case !result.matches:
            this.slideConfig.slidesToShow = 4;
            break;
          case this.breakpointObserver.isMatched(['(max-width: 580px)']):
            this.slideConfig.slidesToShow = 1;
            break;
          case this.breakpointObserver.isMatched(['(min-width: 581px) and (max-width: 767px)']):
            this.slideConfig.slidesToShow = 2;
            break;
          case this.breakpointObserver.isMatched(['(min-width: 768px) and (max-width: 860px)']):
            this.slideConfig.slidesToShow = 1;
            break;
          case this.breakpointObserver.isMatched(['(min-width: 861px) and (max-width: 1105px)']):
            this.slideConfig.slidesToShow = 2;
            break;
          case this.breakpointObserver.isMatched(['(min-width: 1106px) and (max-width: 1347px)']):
            this.slideConfig.slidesToShow = 3;
            break;
        }
      });
  }

  emitFacilitator(facilitator: GroupFacilitator) {
    if (!this.readOnly) {
      this.selectedFacilitator = facilitator;
      this.selectedChurch = null;
      this.facilitatorEmitter.emit(facilitator);
    }
  }

  emitChurch(church: GroupChurchPartnerOrganization) {
    this.selectedChurch = church;
    this.selectedFacilitator = null;
    this.churchEmitter.emit(church);
  }

  handleCarouselClick(event): void {
    if (event.target.classList.contains('slick-arrow')) event.stopPropagation();
  }
}
