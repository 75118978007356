import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { skip } from 'rxjs/operators';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-multi-filter-modal',
  templateUrl: './multi-filter-modal.component.html',
  styleUrls: ['./multi-filter-modal.component.scss'],
})
export class MultiFilterModalComponent implements OnInit {
  fromAdmin: boolean;
  filtersChanged: boolean;

  constructor(private modalsService: ModalsService, private activeRoute: ActivatedRoute) {
    this.activeRoute.queryParams.pipe(skip(1)).subscribe(() => {
      this.filtersChanged = true;
    });
  }

  ngOnInit(): void {
    this.fromAdmin = this.modalsService.params.payload.fromAdmin;
  }

  closeModal() {
    this.modalsService.closeModal();
  }
}
