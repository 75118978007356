export class Pagination {
  totalObjects: number;
  to: number;
  page: number = 0;
  resLength: number = -1;
  reachedEnd?: boolean;

  constructor() {}

  setPagination(totalObjects: number = 0, to: number, page: number = 0, resLength: number = -1): void {
    this.totalObjects = totalObjects;
    this.to = to;
    this.page = page;
    this.resLength = resLength;
  }
}

export class AdminGroupsPagination extends Pagination {
  totalGroups: number;
  totalUnits: number;

  constructor() {
    super();
  }

  setPagination(to: number = 0, page: number = 0, resLength: number = -1): void {
    super.setPagination(null, to, page, resLength);
  }

  setTotalItems(totalGroups: number, totalUnits: number): void {
    this.totalGroups = totalGroups;
    this.totalUnits = totalUnits;
  }
}
