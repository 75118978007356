import { GroupTypes } from 'src/app/_core/constants/AdminGroups';
import DateUtils from 'src/app/_core/helpers/DateUtils';
import { CustomAddress } from 'src/app/_core/models/Address';
import { Mainland } from 'src/app/_core/models/AdminChurches';
import { SortByOption } from 'src/app/_core/models/Filters';
import { GroupsFilters } from 'src/app/_core/models/Home';
import { MultiFilter } from 'src/app/_shared/components/multi-filter/multi-filter.model';
import { GroupCard, MultiFilterGroup } from '../../../_core/models/Groups';
import { Pagination } from '../../../_core/models/Pagination';
import { GROUP_CATEGORY } from './groups.const';

export class GroupsList {
  //OLD TO BE PURGED WITH FIRE AND HOLY WATER
  inPersonRegional: GroupsCategory;
  onlineRegional: GroupsCategory;
  industry: GroupsCategory;
  global: GroupsCategory;
  // otherOnline: GroupsCategory;
  alphabetized: GroupCard[] = [];
  alphabetizedOnline: GroupCard[] = [];

  otherOnlineGroups: GroupCard[] = [];

  alphabetizedInPerson: GroupsCategory;
  onlineRegionalOnline: GroupsCategory;
  aplhabetizedPage: number = 0;
  otherOnlinePage: number = 0;

  //NEW - THIS IS BUT JUST A BABY :D
  proximityInPerson: GroupsCategory;
  proxmityOnline: GroupsCategory;
  proximityOtherOnline: GroupsCategory;

  alphabetisedInPerson: GroupsCategory;
  alphabetisedOnline: GroupsCategory;
  alphabetisedOtherOnline: GroupsCategory;

  // NEW NEW
  inPerson: GroupsCategory;
  online: GroupsCategory;
  otherOnline: GroupsCategory;

  constructor() {
    this.setEmptyLists();
  }

  addGroupsToCategory(groups: MultiFilterGroup[]): void {
    if (!this.oldActiveCategory) return;
    const groupCardsList = groups
      .map((groupInfo) => {
        if (this.totalProximityItems.find((group) => group.uuid === groupInfo.uuid)) return null;
        return new GroupCard(groupInfo, this.oldActiveCategory.categoryName);
      })
      .filter((group) => group);
    this.oldActiveCategory.items = [...this.oldActiveCategory.items, ...groupCardsList];
    this.oldActiveCategory.pagination.page++;
  }

  oldAddGroupsToActiveCategory(multiFilterGroups: MultiFilterGroup[]) {
    if (!this.activeGroupCategory) return;

    const groupCardsList = multiFilterGroups.map((mfGroup: MultiFilterGroup) => new GroupCard(mfGroup, this.oldActiveCategory.categoryName));
    this.oldActiveCategory.items = [...this.oldActiveCategory.items, ...groupCardsList];
    this.oldActiveCategory.pagination.page++;
  }

  addGroupsToActiveCategory(multiFilterGroups: MultiFilterGroup[]) {
    const groupCardsList = multiFilterGroups.map((mfGroup: MultiFilterGroup) => new GroupCard(mfGroup, this.activeGroupCategory.categoryName));
    this.activeGroupCategory.items = [...this.activeGroupCategory.items, ...groupCardsList];
    this.activeGroupCategory.pagination.page++;
  }

  activateNextCategory(): void {
    switch (true) {
      case this.inPersonRegional.active:
        this.inPersonRegional.active = false;
        this.onlineRegional.active = true;
        break;
      case this.onlineRegional.active:
        this.onlineRegional.active = false;
        this.industry.active = true;
        break;
      case this.industry.active:
        this.industry.active = false;
        this.global.active = true;
        break;
      case this.global.active:
        this.global.active = false;
        this.otherOnline.active = true;
        break;
      case this.otherOnline.active:
        this.otherOnline.active = false;
        break;
    }
  }

  activateNextGroupCategory(): void {
    switch (true) {
      case this.inPerson.active:
        this.inPerson.active = false;
        this.online.active = true;
        break;
      case this.online.active:
        this.online.active = false;
        this.otherOnline.active = true;
        break;
      case this.otherOnline.active:
        this.otherOnline.active = false;
        break;
    }
  }

  setEmptyLists(): void {
    //TODO ask Vali when this are filed with items
    //OLD - TO BE PURGED
    this.inPersonRegional = new GroupsCategory(GROUP_CATEGORY.IN_PERSON_REGIONAL);
    this.inPersonRegional.active = true;
    this.onlineRegional = new GroupsCategory(GROUP_CATEGORY.ONLINE_REGIONAL);
    this.industry = new GroupsCategory(GROUP_CATEGORY.INDUSTRY);
    this.global = new GroupsCategory(GROUP_CATEGORY.GLOBAL);
    this.otherOnline = new GroupsCategory(GROUP_CATEGORY.OTHER_ONLINE);
    this.alphabetized = [];
    this.alphabetizedOnline = [];
    this.otherOnlineGroups = [];
    this.aplhabetizedPage = 0;
    this.otherOnlinePage = 0;

    //NEW
    this.proximityInPerson = new GroupsCategory(GROUP_CATEGORY.PROXIMITY_IN_PERSON);
    this.proxmityOnline = new GroupsCategory(GROUP_CATEGORY.PROXMITY_ONLINE);
    this.proximityOtherOnline = new GroupsCategory(GROUP_CATEGORY.PROXIMITY_OTHER_ONLINE);

    this.alphabetisedInPerson = new GroupsCategory(GROUP_CATEGORY.ALPHABETISED_IN_PERSON);
    this.alphabetisedOnline = new GroupsCategory(GROUP_CATEGORY.ALPHABETISED_ONLINE);
    this.alphabetisedOtherOnline = new GroupsCategory(GROUP_CATEGORY.ALPHABETISED_OTHER_ONLINE);

    //NEW NEW
    this.inPerson = new GroupsCategory(GROUP_CATEGORY.IN_PERSON);
    this.inPerson.active = true;
    this.online = new GroupsCategory(GROUP_CATEGORY.ONLINE);
    this.otherOnline = new GroupsCategory(GROUP_CATEGORY.OTHER_ONLINE);
  }

  get isInPersonActive(): boolean {
    return this.oldActiveCategory?.categoryName === GROUP_CATEGORY.IN_PERSON_REGIONAL;
  }

  get isInPersonGroupsActive(): boolean {
    return this.activeGroupCategory?.categoryName === GROUP_CATEGORY.IN_PERSON;
  }

  get isOnlineRegionalActive(): boolean {
    return this.oldActiveCategory?.categoryName === GROUP_CATEGORY.ONLINE_REGIONAL;
  }

  get totalProximityItems(): GroupCard[] {
    return [...this.inPersonRegional.items, ...this.onlineRegional.items, ...this.industry.items, ...this.global.items];
  }

  get hasOpenGroups(): boolean {
    return [...this.totalProximityItems, ...this.alphabetized, ...this.alphabetizedOnline].some((group) => group.spotsLeft > 0);
  }

  get hasRegionalGroups(): boolean {
    return !!this.inPersonRegional.items.length || !!this.onlineRegional.items.length;
  }

  get oldActiveCategory(): GroupsCategory {
    switch (true) {
      case this.inPersonRegional.active:
        return this.inPersonRegional;
      case this.onlineRegional.active:
        return this.onlineRegional;
      case this.industry.active:
        return this.industry;
      case this.global.active:
        return this.global;
      case this.otherOnline.active:
        return this.otherOnline;
      default:
        return null;
    }
  }

  get activeGroupCategory(): GroupsCategory {
    switch (true) {
      case this.inPerson.active:
        return this.inPerson;
      case this.online.active:
        return this.online;
      case this.otherOnline.active:
        return this.otherOnline;
      default:
        return null;
    }
  }
}

export class GroupsCategory {
  items: GroupCard[];
  pagination: Pagination;
  categoryName: GROUP_CATEGORY;
  active: boolean;

  constructor(categoryName: GROUP_CATEGORY) {
    this.items = [];
    this.categoryName = categoryName;
    this.pagination = new Pagination();
  }
}

//OLD TO BE DELETED
export class GroupsListPayload {
  userRole: string[];
  cohortUuid?: string;
  startDate?: number;
  userType: string[];
  meetingPlaceTypes: string[];
  weekDayIds: string[];
  dayIntervalIds: string[];
  churchesUuid: string[];
  facilitatorsUuid: string[];
  studiesUuid: string[];
  groupOwnersUuids?: string[];
  languagesUuid?: string[];
  timezone: number;
  sortBy: SortByOption<any>;
  page: number;
  size: number = 10;
  searchText: string = '';
  mainlandsUuid: string[];
  requestFromAdmin: boolean = false;
  groupIndustriesUuid: string;
  companySizeUuid: string[];
  groupAudiencesUuids?: string[];

  constructor(
    multiFilter: MultiFilter,
    page: number,
    locationUuid: string = null,
    groupFormat: GroupTypes = null,
    otherOnline: boolean = false,
    dropdowns: GroupsFilters = null
  ) {
    const meetingPlaceTypes = groupFormat
      ? multiFilter.meetingType.getValue() === GroupTypes.IN_PERSON
        ? [groupFormat, GroupTypes.HYBRID]
        : [groupFormat]
      : (multiFilter.meetingType.getValue() as string[]).includes(GroupTypes.IN_PERSON)
      ? [...(multiFilter.meetingType.getValue() as string[]), GroupTypes.HYBRID]
      : (multiFilter.meetingType.getValue() as string[]);

    this.churchesUuid = otherOnline ? null : (multiFilter.churches.getValue() as string[]);
    this.dayIntervalIds = otherOnline ? null : (multiFilter.timeIntervals.getValue() as string[]);
    this.facilitatorsUuid = otherOnline ? null : (multiFilter.facilitators.getValue() as string[]);
    this.meetingPlaceTypes = otherOnline ? [GroupTypes.VIRTUAL] : meetingPlaceTypes;
    this.mainlandsUuid = otherOnline ? null : locationUuid ? [locationUuid] : [];
    this.userType = otherOnline ? null : (multiFilter.userType.getValue() as string[]);
    this.page = page;
    this.studiesUuid = otherOnline ? null : (multiFilter.studies.getValue() as string[]);
    this.timezone = DateUtils.extractTimeZone();
    this.userRole = otherOnline ? null : this.setUserRole(multiFilter);
    this.weekDayIds = otherOnline ? null : (multiFilter.weekDays.getValue() as string[]);
    this.languagesUuid = otherOnline ? null : (multiFilter.groupLanguage.getValue() as string[]);
    this.groupIndustriesUuid = otherOnline ? null : (multiFilter.industryAffinity.getValue() as string);
    this.companySizeUuid = otherOnline ? null : ((multiFilter.companySize.getValue() ? [multiFilter.companySize.getValue()] : []) as string[]);
    this.groupAudiencesUuids = otherOnline ? [dropdowns.audience.filters.find((audience) => audience.name === 'Global Group').uuid] : null;
  }

  setUserRole(multiFilter: MultiFilter): string[] {
    const investorTypesArray = (multiFilter.investorTypes.getValue() as string[]) || [];
    if (investorTypesArray.length) return investorTypesArray;
    if (multiFilter.userRole.getValue()) return [multiFilter.userRole.getValue() as string];
    return [];
  }
}

//NEW GROUPS PAYLOAD
export class AlphabetizedGroupListPayload {
  userRole: string[];
  cohortUuid?: string;
  startDate?: number;
  userType: string[];
  meetingPlaceTypes: string[];
  weekDayIds: string[];
  dayIntervalIds: string[];
  churchesUuid: string[];
  facilitatorsUuid: string[];
  studiesUuid: string[];
  groupOwnersUuids?: string[];
  languagesUuid?: string[];
  timezone: number;
  sortBy: SortByOption<any>;
  page: number;
  size: number = 10;
  searchText: string = '';
  mainlandsUuid: string[];
  requestFromAdmin: boolean = false;
  groupIndustriesUuid: string;
  companySizeUuid: string[];
  groupAudiencesUuids?: string[];
  otherOnlineSection: boolean;
  excludeGroupsUuids: string[];
  groupStateList: string[];

  constructor(multiFilter: MultiFilter, activeCategory: GroupsCategory, mainland: Mainland, dropdowns: GroupsFilters, groupCards: GroupCard[]) {
    this.otherOnlineSection = activeCategory.categoryName === GROUP_CATEGORY.OTHER_ONLINE;
    this.churchesUuid = this.otherOnlineSection ? null : (multiFilter.churches.getValue() as string[]);
    this.weekDayIds = this.otherOnlineSection ? null : (multiFilter.weekDays.getValue() as string[]);
    this.dayIntervalIds = this.otherOnlineSection ? null : (multiFilter.timeIntervals.getValue() as string[]);
    this.facilitatorsUuid = this.otherOnlineSection ? null : (multiFilter.facilitators.getValue() as string[]);
    this.userType = multiFilter.userType.getValue() as string[];
    this.page = activeCategory.pagination.page;
    this.studiesUuid = this.otherOnlineSection ? null : (multiFilter.studies.getValue() as string[]);
    this.languagesUuid = this.otherOnlineSection ? null : (multiFilter.groupLanguage.getValue() as string[]);
    this.companySizeUuid = this.otherOnlineSection ? null : multiFilter.companySize.getValue() ? [multiFilter.companySize.getValue() as string] : [];
    this.groupIndustriesUuid = this.otherOnlineSection ? null : (multiFilter.industryAffinity.getValue() as string);
    this.groupAudiencesUuids = this.otherOnlineSection
      ? dropdowns.audience.filters
          .filter((audience) => audience.name === 'Global Group' || audience.name === 'Industry Group')
          .map((audience) => audience.uuid)
      : null;
    this.userRole = this.setUserRole(multiFilter);
    this.meetingPlaceTypes = this.setMeetingPlaceTypes(multiFilter, activeCategory);
    this.mainlandsUuid = mainland?.uuid ? [mainland.uuid] : [];
    this.timezone = DateUtils.extractTimeZone();
    this.excludeGroupsUuids = this.otherOnlineSection ? groupCards.map((groupCard: GroupCard) => groupCard.uuid) : null;
    this.groupStateList = this.otherOnlineSection ? null : (multiFilter.groupState.getValue() as string[]);
  }

  setUserRole(multiFilter: MultiFilter): string[] {
    const investorTypesArray = (multiFilter.investorTypes.getValue() as string[]) || [];
    if (investorTypesArray.length) return investorTypesArray;
    if (multiFilter.userRole.getValue()) return [multiFilter.userRole.getValue() as string];
    return [];
  }

  setMeetingPlaceTypes(multiFilter: MultiFilter, activeCategory: GroupsCategory) {
    if (
      (multiFilter.meetingType.getValue() as string[]).includes(GroupTypes.VIRTUAL) &&
      (multiFilter.meetingType.getValue() as string[])?.length === 1
    ) {
      return [GroupTypes.VIRTUAL];
    } else {
      return activeCategory.categoryName === GROUP_CATEGORY.ONLINE || activeCategory.categoryName === GROUP_CATEGORY.OTHER_ONLINE
        ? [GroupTypes.VIRTUAL]
        : [GroupTypes.IN_PERSON, GroupTypes.HYBRID];
    }
  }
}

export class ProximityGroupListPayload extends AlphabetizedGroupListPayload {
  location: CustomAddress;
  inPersonProximity: boolean;

  constructor(
    multiFilter: MultiFilter,
    activeCategory: GroupsCategory,
    mainland: Mainland,
    dropdowns: GroupsFilters,
    customAddress: CustomAddress,
    groupCards: GroupCard[]
  ) {
    super(multiFilter, activeCategory, mainland, dropdowns, groupCards);
    this.inPersonProximity = !this.otherOnlineSection;
    this.location = customAddress;
  }
}

export class OldProximityGroupsListPayload extends GroupsListPayload {
  location: CustomAddress;
  inPersonProximity: boolean;
  constructor(multiFilter: MultiFilter, groupsCategory: GroupsCategory, location: CustomAddress, dropdowns: GroupsFilters, mainlandUuid: string) {
    super(multiFilter, groupsCategory.pagination.page);
    this.groupAudiencesUuids = this.setAudience(groupsCategory, dropdowns);
    this.meetingPlaceTypes = this.setMeetingType(multiFilter, groupsCategory);
    this.inPersonProximity = [GROUP_CATEGORY.IN_PERSON_REGIONAL, GROUP_CATEGORY.ONLINE_REGIONAL].includes(groupsCategory.categoryName);
    this.location = location;
    this.mainlandsUuid = [mainlandUuid];
  }

  setAudience(groupsCategory: GroupsCategory, dropdowns: GroupsFilters): string[] {
    if (groupsCategory.categoryName === GROUP_CATEGORY.INDUSTRY) {
      const affinityAudience = dropdowns.audience.filters.find((audience) => audience.name === 'Industry Group');
      return [affinityAudience.uuid];
    }
    if (groupsCategory.categoryName === GROUP_CATEGORY.GLOBAL) {
      const globalAudience = dropdowns.audience.filters.find((audience) => audience.name === 'Global Group');
      return [globalAudience.uuid];
    }
    return [];
  }

  setMeetingType(multiFilter: MultiFilter, groupsCategory: GroupsCategory): string[] {
    const meetingTypesArray = (multiFilter.meetingType.getValue() as string[]) || [];
    if (meetingTypesArray.length === 1) {
      if (meetingTypesArray[0] === GroupTypes.IN_PERSON && groupsCategory.categoryName !== GROUP_CATEGORY.IN_PERSON_REGIONAL) {
        return [GroupTypes.VIRTUAL];
      }
      return [...meetingTypesArray, GroupTypes.HYBRID];
    }
    if (groupsCategory.categoryName === GROUP_CATEGORY.IN_PERSON_REGIONAL) return [GroupTypes.IN_PERSON, GroupTypes.HYBRID];
    return [GroupTypes.VIRTUAL];
  }
}
