<div class="d-flex justify-content-between align-items-center px-4 py-3 bg-gray">
  <h1 class="mb-0">Menu</h1>
  <a (click)="close()"><i class="fas fa-2x fa-times gray-color pointer"></i></a>
</div>

<div class="px-4 pt-4 menu d-flex flex-column justify-content-between">
  <div>
    <ng-container *ngFor="let item of links; let i = index">
      <ng-container *ngIf="i !== links.length - 1">
        <div *ngIf="i === 0 || item.section !== links[i - 1].section" class="text-gray font-weight-bold ml-1 mt-4">
          {{ item.section | enumToString | uppercase }}
        </div>
        <mat-divider class="my-2" *ngIf="item?.divider"></mat-divider>
        <a class="text-black" [class.disabled]="item.disabled" (click)="handleMenuAction(item)">
          <hr class="my-2" *ngIf="item.disabled" />
          <h2 class="mb-0">{{ item.name | enumToString | titlecase }}</h2>
          <p class="mb-2" *ngIf="item.description">{{ item.description }}</p>
        </a>
      </ng-container>
    </ng-container>
  </div>
  <div id="sign-out-menu-button">
    <a class="text-black" (click)="handleMenuAction(links[links.length - 1])">
      <h2 class="mb-5">{{ links[links.length - 1].name | enumToString | titlecase }}</h2>
    </a>
  </div>
</div>
