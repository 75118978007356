import { Component, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-server-error-page',
  templateUrl: './server-error-page.component.html',
  styleUrls: ['./server-error-page.component.scss'],
})
export class ServerErrorPageComponent implements OnInit {
  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {
    this.modalsService.closeAll();
  }
}
