import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../../_core/services/loader.service';
import { Subscription } from 'rxjs';
import { LoaderData } from 'src/app/_core/models/Loader';
import { LOADER_TYPE } from 'src/app/_core/constants/Loader';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  loading: boolean;
  loadingSub: Subscription;

  // prettier-ignore
  constructor(
    private loaderService: LoaderService,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.loadingSub = this.loaderService.loading$.subscribe((data: LoaderData) => {
      if (data.type === LOADER_TYPE.GLOBAL) {
        this.loading = data.visible;
        this.ref.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.loadingSub.unsubscribe();
  }
}
